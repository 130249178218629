/* eslint-disable */
import React, { Component } from "react";
import auth, { ajaxErrorEndForced } from "../js/auth";
import { connect } from "react-redux";

class ErrorTemplate extends Component {
  render() {
    const { local } = this.props;
    if (local)
      return (
        <div id="errorModal" className="modal" data-backdrop="true" data-dismiss="modal" role="dialog" data-keyboard="false" style={{ zIndex: "10000", background: "gray", opacity: ".9" }}>
          <div style={{ height: "100%", width: "100%", display: "flex", flexFlow: "column" }}>
            <div style={{ flexGrow: "1", display: "flex", alignItems: "flex-end" }}>
              <img id="errorImg" className="highlight-4" style={{ width: "10rem", height: "10rem", position: "relative", left: "50%", transform: "translateX(-50%)" }} src="img/cloud-thumb-down-512.png" />
            </div>
            <div style={{ flexGrow: "1", display: "flex", alignItems: "flex-start", flexFlow: "column" }}>
              <span style={{ fontSize: "3rem", position: "relative", left: "50%", transform: "translateX(-50%)", textShadow: "-1px0white,01pxwhite,1px0white,0-1pxwhite" }} id="errorMessage" />
              <span
                style={{ width: "80%", marginTop: "2rem", maxHeight: "30%", overflowY: "auto", backgroundColor: "white", fontSize: "1rem", position: "relative", left: "50%", transform: "translateX(-50%)" }}
                id="errorText"
              />
              <button onClick={location.reload} style={{ marginTop: "2rem", fontSize: "3rem", position: "relative", left: "50%", transform: "translateX(-50%)" }} className="btn btn-warning">
                {local.reload_page}
              </button>
              <button onClick={ajaxErrorEndForced} style={{ marginTop: "2rem", fontSize: "3rem", position: "relative", left: "50%", transform: "translateX(-50%)" }} className="btn btn-positive">
                {local.continue}
              </button>
            </div>
          </div>
        </div>
      );
    return null;
  }
}

const mapStateToProps = state => {
  return {
    local: state.local
  };
};

export default connect(mapStateToProps)(ErrorTemplate);
