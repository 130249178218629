import $ from "jquery";
import admin from "../admin";
import { startpoll, confirmDialog } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import "../cashRegisters";

class CashRegistersCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveCashRegister', 'deleteCashRegister', 'activateCashRegister', 'installCashRegisterServer']);
	}

	clearData = () => {

	}

	ready = () => {

		$('#main').replaceWith($('#cashRegistersMainTemplate').tmpl({
			...tmplparams()
		}));

		this.loadCashRegisters();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	reload = () => {
		this.loadCashRegisters();
	}

	loadCashRegisters = () => {
		admin.getCashRegisters(this.processCashRegisters);
	}

	serverSideMessageHandler(data) {
	}


	CashRegisters = null;
	processCashRegisters = (data) => {
		data.forEach(function (v, i) {
			v.title = v.name;
			v.type2 = v.type;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});
		if (this.CashRegisters == null)
			this.CashRegisters = createTree("table#CashRegisters", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.connectedDevice);
					$tdList.eq(3).text(node.data.format);
					$tdList.eq(4).text(node.data.type2);
					$tdList.eq(5).text(node.data.paymentMethods.map(p => p.name));
				}
			});
		else {
			this.CashRegisters.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editCashRegister').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			modal.find('#payment_methods label').removeClass('active');
			modal.find('#payment_methods input').prop('checked', false);
			// attributes
			if (recipient === 'edit') {
				var data = instance.CashRegisters.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#format').val(data.format);
				modal.find('#type').val(data.type2);
				modal.find('#detailed').prop("checked", data.detailed);
				modal.find('#serviceVat').prop("checked", data.serviceVat);
				if (data.isActive === true)
					modal.find('#active').prop("checked", true);
				else
					modal.find('#active').prop("checked", false);
				modal.find('#ntak').prop("checked", !!data.ntak);
				data.paymentMethods.forEach(pm => {
					modal.find('#payment_methods input#' + pm.id).parent().addClass('active');
					modal.find('#payment_methods input#' + pm.id).prop('checked', true);
				});
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#active').prop("checked", true);
				modal.find('#ntak').prop("checked", false);
				modal.find('#format').val("qrcode");
				modal.find('#type').val("fiscat");
				modal.find('#detailed').prop("checked", true);
				modal.find('#serviceVat').prop("checked", false);
			}
		})
	}

	saveCashRegister = () => {
		var modal = $('div#editCashRegister');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var format = modal.find('#format').val();
		var type = modal.find('#type').val();
		var active = modal.find('#active').prop('checked');
		var ntak = modal.find('#ntak').prop('checked');
		var detailed = modal.find('#detailed').prop('checked');
		var serviceVat = modal.find('#serviceVat').prop('checked');
		const paymentMethods = [];
		modal.find('#payment_methods input:checked').each((ind, pm) => {
			paymentMethods.push({ "id": Number($(pm).prop("id")) })
		})
		if (id !== '') {
			admin.modifyCashRegister({
				id: id,
				name: name,
				isActive: active,
				format: format,
				type: type,
				paymentMethods: paymentMethods,
				detailed: detailed,
				serviceVat: serviceVat,
				ntak: ntak
			},
				this.loadCashRegisters);
		} else {
			admin.addCashRegister({
				name: name,
				isActive: active,
				format: format,
				type: type,
				paymentMethods: paymentMethods,
				detailed: detailed,
				serviceVat: serviceVat
			}, this.loadCashRegisters);
		}
	}

	activateCashRegister = (active) => {
		var data = this.CashRegisters.getActiveNode().data;
		console.log(data);
		admin.modifyCashRegister({
			id: data.id,
			name: data.name,
			isActive: active,
			format: data.format,
			type: data.type2,
			paymentMethods: data.paymentMethods
		}, this.loadCashRegisters);
	}

	deleteCashRegister = () => {
		var data = this.CashRegisters.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_cashregister'), data.name)).done(function () {
			admin.deleteCashRegister(data.id, instance.loadCashRegisters);
		});

	}

}

export default CashRegistersCode;