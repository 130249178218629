import React from "react";
import { store } from "../..";
import { Grid } from "react-redux-grid";
import { Actions } from "react-redux-grid/dist/actions";
import AbstractRestaurantEditor from "./AbstractRestaurantEditor";
import Buttons from "./Buttons";

class AbstractGrid extends AbstractRestaurantEditor {
  state = {};

  shouldComponentUpdate(props, state) {
    if (state !== null && state.restaurant && (this.state === null || typeof this.state.restaurant === "undefined" || this.state.restaurant.id !== state.restaurant.id)) {
      store.dispatch(
        Actions.GridActions.getAsyncData({
          stateKey: props.stateKey,
          dataSource: props.dataSource
        })
      );
    }
    return true;
  }

  static getDerivedStateFromProps(props, state) {
    return { restaurant: props.restaurant };
  }

  render() {
    return (
      <React.Fragment>
        <Grid style={{ height: "100%" }} {...this.props}></Grid>
        <Buttons restaurant={this.state.restaurant} changed={this.state.changed} handleCancel={() => this.props.handleCancel(this.state.restaurant)} handleSave={() => this.props.handleSave(this.state.restaurant)} />
      </React.Fragment>
    );
  }
}

export default AbstractGrid;
