import React, { Component } from "react";

import Line from './Line';
import Node from './Node';

import Graph from "react-graph-network";
import menuitemutil from "../../js/admin/menuitem";
import { getMenuCategory, getMenuItem, getMenuItemById } from "../../js/admin";

class MenuItemGraphs extends Component {

  state = {
  }

  componentDidMount() {
    if (!this.props.selected)
      return;
    const selected = [this.props.selected.entityType + "_" + this.props.selected.id];
    var source_selected = [this.props.selected.entityType + "_" + this.props.selected.id];

    var target_selected = [this.props.selected.entityType + "_" + this.props.selected.id];

    var data = this.getData(this.props.categories, source_selected, target_selected, selected);
    var data2 = data;
    do {
      data = data2;
      if (this.props.selected.defaultInventoryItem)
        target_selected = [...selected, ...data.nodes.filter(n => n.source).map(n => n.id)];
      else
        source_selected = [...selected, ...data.nodes.filter(n => n.target).map(n => n.id)];
      data2 = this.getData(this.props.categories, source_selected, target_selected, selected);
    } while (data.nodes.length < data2.nodes.length);
    this.setState({ categories: this.props.categories, data: data2 });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          <div id="main">
            {this.state.data ?
              <Graph
                id="graph-id"
                data={this.state.data}
                NodeComponent={Node}
                LineComponent={Line}
                nodeDistance={200}
                zoomDepth={10}
                hoverOpacity={.1}
                enableDrag={true}
                pullIn={false}
              /> : null}
          </div>
        </div>
      </React.Fragment>
    );
  }

  getData = (cats, source_selected, target_selected, selected) => {
    const newData = { nodes: [], links: [] };
    const menuItems = this.getFlatMenuItems(cats);
    newData.links = this.getLinks(menuItems, source_selected, target_selected);
    console.log(newData.links);
    newData.nodes = Object.values(menuItems, newData.links).filter(m => {
      const key = m.entityType + "_" + m.id;
      return newData.links.filter(l => l.source === key || l.target === key).length
    }).map(obj => {
      const source = newData.links.filter(m => m.source === obj.entityType + "_" + obj.id).length;
      //const target = newData.links.filter(m => m.target === obj.entityType + "_" + obj.id).length;
      const key = obj.entityType + "_" + obj.id;
      return {
        id: key,
        renderLabel: true,
        label: menuitemutil.getPath(obj),
        symbolType: obj.entityType === "Meal" ? "circle" : "triangle",
        size: selected === key ? 10 : source ? 3 * (Math.log(source + 2)) : 2 * (Math.log(source + 2)),
        color: selected === key ? "black" : obj.defaultInventoryItem ? (source ? "darkorange" : "lightgreen") : "lightblue",
      }
    });
    const nodes = {};
    newData.nodes.forEach(n => nodes[n.id] = n);
    newData.links.forEach(l => {
      nodes[l.source].source = true;
      nodes[l.target].target = true;
    })
    return newData;
  }

  getFlatMenuItems = (cats, set = {}) => {
    cats.forEach(cat => {
      set[cat.entityType + "_" + cat.id] = cat;
      if (cat.children)
        this.getFlatMenuItems(cat.children, set);
    })
    return set;
  }

  getLinks = (array, source_selected, target_selected) => {
    var links = {};
    Object.values(array).forEach(m => {
      m.menuItemAdditions.forEach(a => {
        const key = m.entityType + "_" + m.id;
        if (a.additionType === "mandatory" || a.additionType === "included") {
          var s = this.match(source_selected, key);
          switch (a.type) {
            case "meal_addition":
              var t = this.match(target_selected, "Meal_" + a.addition.id);
              if (s || t)
                if (array["Meal_" + a.addition.id] && !links[key + "->Meal_" + a.addition.id]) {
                  links[(s || key) + "Meal_" + a.addition.id] = { source: s || key, target: "Meal_" + a.addition.id, value: a.quantity };
                }
              break;
            case "drink_addition":
              var t = this.match(target_selected, "Drink_" + a.addition.id);
              if (s || t) {
                if (array["Drink_" + a.addition.id] && !links[m.entityType + "_" + m.id + "->Drink_" + a.addition.id]) {
                  links[(s || key) + "->Drink_" + a.addition.id] = { source: s || key, target: "Drink_" + a.addition.id, value: a.quantity };
                }
              }
              break;
            default:
          }
        }
        //console.log(a);
      })
    })
    return Object.values(links);
  }

  match = (s, key) => {
    return s.find(ss => this.getParents(ss).indexOf(key) !== -1);
  }

  getParents = (key) => {
    var keys = [key];
    var m = getMenuItem(key.split("_")[1]);
    while (m.menuCategoryId) {
      var cat = getMenuCategory(m.menuCategoryId);
      keys.push(cat.entityType + "_" + cat.id);
      m = cat;
    }
    return keys;
  }

}

export default MenuItemGraphs;
