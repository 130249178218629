import React, { Component } from "react";
import ImageButton from "../icon-buttons/ImageButton";

class BackButton extends Component {
  state = {};
  render() {
    return (
      <ImageButton
        clazz="icon icon-left-open hide-print"
        id="back"
        handleClick={() => {

          const c = () => {
            if (this.props.goBack) {
              this.props.history.goBack();
            }

            var path = "/";

            var a = this.props.history.location.pathname.split("/");
            for (let i = 0; i < a.length - 1; i++) {
              if (path !== "/") path += "/";
              path += a[i];
            }
            this.props.history.push(path);
          }

          if (this.props.onClick) {
            this.props.onClick().done(c);
          }
          else c();

        }
        }
        style={{ width: "24px", height: "24px", lineHeight: "24px" }}
      ></ImageButton >
    );
  }
}

export default BackButton;
