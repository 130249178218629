import React, { Component } from "react";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import auth, { ajaxCallStart, ajaxCallEnd, switchServer, post, getParameterByName } from "../../js/auth";
import LabeledInput from "../../widgets/LabeledInput";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import ChangeLanguage from "../icon-buttons/ChangeLanguage";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import admin, { getMyStatus } from "../../js/admin";
import { store } from "../..";
import EatWithMeActions from "../../actions/EatWithMeActions";
import { LoginClass } from "./Login";

class Register extends LoginClass {

  state = {
    activate: false
  }

  render() {
    return (
      <Modal className="loginDialog" backdrop="static" keyboard={false} show={true} centered>
        <Modal.Header>
          <Modal.Title>{this.state.activate ? I18n.t("local.activation") : I18n.t("local.registration")}</Modal.Title>
          <ChangeLanguage />
        </Modal.Header>

        <Modal.Body>
          <div
            className=""
            style={{
              left: "0px",
              width: "100%",
              zIndex: "5",
              overflowY: "auto",
              paddingLeft: "20px",
              paddingRight: "20px",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center",
              display: "block",
              maxHeight: "calc(100vh - 200px)",
              flexShrink: "1"
            }}
          >
            <form className="input-group" style={{ width: "100%", background: "unset", flexShrink: 1 }}>
              {this.state.activate ? null : <React.Fragment>
                {this.props.language === "hu" ?
                  (<div style={{ display: "flex", flexFlow: "row" }}>
                    <LabeledInput id="surname" label={I18n.t("local.surname")} placeHolder={I18n.t("local.provide_surname")} type="text" ref={node => (this.surnameNode = node)} />
                    <LabeledInput id="name" label={I18n.t("local.first_name")} placeHolder={I18n.t("local.provide_first_name")} type="text" ref={node => (this.nameNode = node)} />
                  </div>) :
                  (<div style={{ display: "flex", flexFlow: "row" }}>
                    <LabeledInput id="name" label={I18n.t("local.first_name")} placeHolder={I18n.t("local.provide_first_name")} type="text" ref={node => (this.nameNode = node)} />
                    <LabeledInput id="surname" label={I18n.t("local.surname")} placeHolder={I18n.t("local.provide_surname")} type="text" ref={node => (this.surnameNode = node)} />
                  </div>)}
                <LabeledInput id="company" label={I18n.t("admin_local.company")} placeHolder={I18n.t("admin_local.provide_company")} type="text" ref={node => (this.companyNode = node)} />
                <LabeledInput id="address" label={I18n.t("admin_local.address")} placeHolder={I18n.t("admin_local.provide_address")} type="text" ref={node => (this.addressNode = node)} />
                <LabeledInput id="city" label={I18n.t("admin_local.city")} placeHolder={I18n.t("admin_local.provide_city")} type="text" ref={node => (this.cityNode = node)} />
                <LabeledInput id="country" label={I18n.t("admin_local.country")} placeHolder={I18n.t("admin_local.provide_country")} type="text" ref={node => (this.countryNode = node)} />
                <LabeledInput id="phone" label={I18n.t("admin_local.phonenumber")} placeHolder={I18n.t("admin_local.provide_phonenumber")} type="text" ref={node => (this.phoneNumberNode = node)} />
              </React.Fragment>}
              <LabeledInput id="email" label={I18n.t("local.email")} placeHolder={I18n.t("local.provide_email")} type="text" ref={node => (this.emailNode = node)} />
              {!this.state.activate ? <LabeledInput key="password" id="password" label={I18n.t("local.password")} placeHolder={I18n.t("local.provide_password")} type="password" ref={node => (this.passwordNode = node)} />
                : <LabeledInput id="activationCode" key="activationCode" label={I18n.t("local.activation_code")} placeHolder={I18n.t("local.activation_code")} type="number" ref={node => (this.activationNode = node)} />
              }
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button style={{ margin: "0px" }} id="login" onClick={() => this.props.history.push("login")} className="btn btn-info icon">
            {I18n.t("local.back_to_login")}
          </Button>
          <FlexGrowSpace />
          {this.state.activate ?
            <Button
              id="activate"
              onClick={() => {
                this.activate();
              }}
              className="btn btn-primary icon icon-register"
            >
              {I18n.t("local.activate")}
            </Button>
            :
            <Button
              id="register"
              onClick={() => {
                this.register();
              }}
              className="btn btn-primary icon icon-register"
            >
              {I18n.t("local.register")}
            </Button>
          }
        </Modal.Footer>
      </Modal >
    );
  }


  activate = () => {
    var email = this.emailNode.inputNode.value;
    var password = this.state.password;
    var activationCode = this.activationNode.inputNode.value;
    ajaxCallStart("Activate");
    auth.getGlobal("authenticationService/activate?a=true&email=" + email + "&token=" + activationCode).done(data => {
      ajaxCallEnd("Activate");
      if (data === null) {
        alert("Failed to create a new profile for you!");
      } else if (data.error) {
        // eslint-disable-next-line no-eval
        auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
      } else {
        this.saveState();
        post(auth.globalserver + "/eatwithme.server/login", "username=" + encodeURIComponent(email.toLowerCase()) + "&password=" + encodeURIComponent(password) + "&redirect=true&keep=true", {}
        ).fail(this.loginFailureHandler)
          .done((data) => this.loginSuccessHandler(email, password, data));
      }
    }).fail(data => {
      auth.ajaxError = false;
      ajaxCallEnd("Activate");
      post(auth.globalserver + "/eatwithme.server/login", "username=" + encodeURIComponent(email.toLowerCase()) + "&password=" + encodeURIComponent(password) + "&redirect=true&keep=true", {}
      ).fail(() => {
        if (data.error) {
          // eslint-disable-next-line no-eval
          auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
        } else {
          auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.bad_activation_code"));
        }
      }).done((data) => this.loginSuccessHandler(email, password, data));
    });
  }
  register = () => {
    const t = this;
    var email = this.emailNode.inputNode.value;
    var name = this.nameNode.inputNode.value;
    var surname = this.surnameNode.inputNode.value;
    var password = this.passwordNode.inputNode.value;
    this.setState({ password: password });
    var phone = this.phoneNumberNode.inputNode.value;
    var address = this.addressNode.inputNode.value;
    var city = this.cityNode.inputNode.value;
    var company = this.companyNode.inputNode.value;
    var country = this.countryNode.inputNode.value;
    if (name === "") {
      auth.hightlightError($(this.nameNode.inputNode));
      return;
    }
    if (surname === "") {
      auth.hightlightError($(this.surnameNode.inputNode));
      return;
    }
    if (company === "") {
      auth.hightlightError($(this.companyNode.inputNode));
      return;
    }
    if (address === "") {
      auth.hightlightError($(this.addressNode.inputNode));
      return;
    }
    if (city === "") {
      auth.hightlightError($(this.cityNode.inputNode));
      return;
    }
    if (country === "") {
      auth.hightlightError($(this.countryNode.inputNode));
      return;
    }
    if (surname === "") {
      auth.hightlightError($(this.surnameNode.inputNode));
      return;
    }
    if (phone === "") {
      auth.hightlightError($(this.phoneNumberNode.inputNode));
      return;
    }
    if (email === "") {
      auth.hightlightError($(this.emailNode.inputNode));
      return;
    }
    if (password === "") {
      auth.hightlightError($(this.passwordNode.inputNode));
      return;
    }
    ajaxCallStart("Register");
    auth
      .getGlobal(
        "customerService/createUser" +
        "?authenticationType=eatwithme" +
        "&name=" +
        encodeURIComponent(name) +
        "&surname=" +
        encodeURIComponent(surname) +
        "&email=" +
        encodeURIComponent(email.toLowerCase()) +
        "&password=" +
        encodeURIComponent(password) +
        "&company=" +
        encodeURIComponent(company) +
        "&address=" +
        encodeURIComponent(address) +
        "&city=" +
        encodeURIComponent(city) +
        "&country=" +
        encodeURIComponent(country) +
        "&phone=" +
        encodeURIComponent(phone) +
        "&action=trial" +
        "&lang=" +
        $("html").attr("lang") +
        // eslint-disable-next-line no-restricted-globals
        "&redirect=" + location.href
      )
      .done(data => {
        ajaxCallEnd("Register");
        if (data === null) {
          alert("Failed to create a new profile for you!");
        } else if (data.error) {
          // eslint-disable-next-line no-eval
          auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
        } else if (data.success) {
          // eslint-disable-next-line no-eval
          auth.messageDialog(I18n.t("local.success_message"), String.format(I18n.t("local.activation_email_sent2"), email)).done(() => {
            this.setState({ activate: true });
            //t.props.history.push("/login");
          });
        } else {
          auth.messageDialog(I18n.t("local.error_message"), I18n.t("local.failed_to_send_activation_email"));
        }
      }).fail(() => {
        ajaxCallEnd("Register");
      });
  };
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      language: props.rootReducer.language
    };
  }
  return {};
};

export default connect(mapStateToProps)(Register);
