import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import { Translate } from "react-redux-i18n";

import { useState } from 'react';
import { MuiKeyboard } from '@autosys/react-mui-keyboard';
import { englishButtons, numbers } from '@autosys/react-mui-keyboard';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

class ChoiceDialog extends Component {
  state = {
    show: this.props.show,
    input: this.props.input,
    value: this.props.defaultValue,
    type: this.props.type || 'number',
    open: false
  };

  constructor(props) {
    super(props);
    this.state.show = this.props.show;
    this.state.showKeyboard = this.props.showKeyboard;
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this.props.container);
  }

  componentDidMount() {
    this.d.innerHTML = this.props.message;
    if (this.nameInput) {
      this.nameInput.focus();
      this.nameInput.select();
    }
  }

  inputChange = value => {
    this.setState({ value: value })
  }


  render() {
    if (this.state.show === false) {
      return null;
    }

    return (
      <Modal style={{ zIndex: 10000 }} show={this.state.show} onHide={this.props.def.reject} centered >
        <Modal.Header className="eatwithme no-close">
          <h1>{this.props.title}</h1>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1.8em" }}>
          <span ref={d => this.d = d}>{this.props.message}</span>
          {this.props.input ?
            <div>
              <App {...this.state} onChange={this.inputChange} />
            </div>
            : null}
        </Modal.Body>
        <Modal.Footer>
          {this.props.input ?
            <Button
              variant="danger"
              className="icon-keyboard"
              onClick={() => {
                this.setState({ showKeyboard: !this.state.showKeyboard });
              }}
            >
            </Button> :
            null}
          {this.props.buttons.map((button, ind) => {
            return (
              <Button
                key={ind}
                onClick={() => {
                  this.unmount();
                  this.props.def.resolve(ind, this.state.value);
                }}
              >
                <Translate value={button} />
              </Button>
            );
          })}
          <Button
            variant="danger"
            onClick={() => {
              this.unmount();
              this.props.def.reject();
            }}
          >
            <Translate value="local.cancel" />
          </Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value,
    };
  }

  handleUrlChange = (event) => {
    this.setState({ inputValue: event.target.value });
    if (this.props.onChange)
      this.props.onChange(event.target.value);
    // Uncomment the following line if you want to call props.onChange
    // this.props.onChange(event.target.value);
  };


  setInputValue = (value) => {
    const newValue = value(this.state.inputValue);
    this.setState({ inputValue: newValue });
    if (this.props.onChange)
      this.props.onChange(this.props.type === "number" ? Number(newValue) : newValue);
  }

  render() {
    const { checked, inputValue } = this.state;

    return (
      <MuiThemeProvider>
        <MuiKeyboard
          textField={
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <input
                type="text"
                onChange={this.handleUrlChange}
                style={{ marginTop: "20px" }}
                value={inputValue}
              />
            </div>
          }
          slide
          direction="up"
          checked={this.props.showKeyboard}
          setInputValue={this.setInputValue}
          numbers={this.props.type === "number" ? ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'] : numbers} // Assuming numbers and other variables are defined
          firstLanguage={this.props.type === "number" ? null : englishButtons}
          firstLangLabel="EN"
          numbersRows="3"
          numbersColumns={this.props.type === "number" ? 3 : 12}
          keyboardWidth={this.props.type === "number" ? '8rem' : 'auto'}
          buttonSize={this.props.type === "number" ? "LARGE" : "medium"}
          sx={{ display: 'flex' }}
        />
      </MuiThemeProvider>
    );
  }
}

export default ChoiceDialog;
