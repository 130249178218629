export const help = {
	basic: [
		{
			selector: "#back",
			"data-intro": {
				hu: "Vissza a menübe",
				en: "Back to the menu",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#user_img",
			"data-intro": {
				hu: "Aktuális felhasználó ikonja",
				en: "Image of the current user",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-lock",
			"data-intro": {
				hu: "<b>Gyorsított asztalfoglalás</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyet le szeretne foglalni!",
				en: "<b>Quick table lock</b><br/>You have 3 seconds to click on the table you want to lock!",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-lock-order",
			"data-intro": {
				hu: "<b>Gyorsított asztalfoglalás és rendelés leadás</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyet le szeretne foglalni majd rendelést felvinni hozzá!",
				en: "<b>Quick table lock and order</b><br/>You have 3 seconds to click on the table you want to lock and register orders!",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-paying",
			"data-intro": {
				hu: "<b>Gyorsított 'fizetés folyamatban' státusz</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyere be akarja állítani a 'fizetés folyamatban' státuszt!",
				en: "<b>Quick set for 'paying' status</b><br/>You have 3 seconds to click on the table you want to set the 'payment pending' status!",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-no-paying",
			"data-intro": {
				hu: "<b>Gyorsított 'fizetés folyamatban' státusz visszaállítása</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyet ki akarja szedni a 'fizetés folyamatban' státuszból!<br><i>Erre akkor lehet szükség, ha meggondolták magukat és tovább szeretnének fogyasztani.</i>",
				en: "<b>Quick unset for 'paying' status</b><br/>You have 3 seconds to click on the table you want to unset the 'payment pending' status!<br/><i>This might be needed if the client has changed his mind and wants to continue ordering!</i>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-paid",
			"data-intro": {
				hu: "<b>Gyorsított fizetési adatok regisztrálása</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyre fel szeretné vinni a fizetési adatokat és be szeretné állítani a 'fizetve' státuszt, majd akár az asztalt is felszabadíthatja!",
				en: "<b>Quick register payment info</b><br/>You have 3 seconds to click on the table you want to register the payment details and set the 'paid' status and eventually free up the table!",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#pre-unlock",
			"data-intro": {
				hu: "<b>Gyorsított asztal felszabadítás</b><br/>A gomb lenyomása után 3 másodpercen belül kattintson arra az asztalra, amelyet fel szeretne szabadítani!<br/><i>Csak olyan asztalt tud felszabadítani, amelyen nincs ki nem fizetett visszaigazolt rendelés!</i>",
				en: "<b>Quick register payment info</b><br/>You have 3 seconds to click on the table you want to register the payment details and set the 'paid' status!",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#showTableOccupationList",
			"data-intro": {
				hu: "<b>Az régi és jelen asztalfoglalások listájának megtekintése! Itt rákereshet már lezárt asztalfoglalásokra, áttekintheti a rendeléseket, fizetéseket és újranyomtathat ügyfélszámlákat számlákat.</b>",
				en: "<b>Browse the list of past and current table occupations! Here one can search for closed table occupations, review the related orders, payments and reprint invoices.</b>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".bar #printers",
			"data-intro": {
				hu: "<b>A rendszerben különböző célra használt nyomtatók státuszának áttekintése.</b><br/><i>Amennyiben <span class='highlight-6'>villog</span> az azt jelenti, hogy valamelyik nyomtató nem elérhető!<br/>A <span class='icon-connected'/> ikon azt jelenti, hogy a nyomtató elérhető!<br/>A <span class='icon-disconnected'/> ikon azt jelenti hogy az adott pályához, nyomtatási művelethez konfigurált virtuális nyomtatóhoz jelenleg nincs fizikai nyomtató kapcsolódva így nem lehet nyomtatni.<br/>A <span class='icon-error'/> ikon azt jelenti hogy az adott pályához, nyomatási művelethez kapcsolódó fizikai nyomtató hibás állapotban van és nem lehet nyomtatni.<br/><br/>Ugyanitt azt is megtekintheti, hogy az adott pálya vagy nyomtatási művelet (számla, asztalazonosító) mely virtuális illetve fizikai nyomtatón lesz kinyomtatva.<br/><span class='icon-connected'/> Bár->Főpincér->NyomatóSzerver-Start TSP100 Cutter azt jelenti, hogy a <b>Bár</b> pályára szánt címkék a <b>Főpincér</b> virtuális nyomtatóra vannak irányítva, amelyre a <b>NyomtatóSzerver</b>-en a <b>Start TSP100</b> fizikai nyomtató van beállítva.</i>",
				en: "<b>Browse the list of past and current table occupations! Here one can search for closed table occupations, review the related orders, payments and reprint invoices.</b>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".icon.icon-cog-outline",
			"data-intro": {
				hu: "<b>Előre konfigurált asztaltérképek között lehet váltogatni, illetve itt konfigurálható, hogy milyen egyéb (bárpult, tálaló) feladatlistája jelenjen meg a képernyőn.</b>",
				en: "<b>One can switch between predefined floorplans. Additinal task  panels (eg. for bar and kitchen) can be added to the screen.</b>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table:not(.occupied):not(.shareable)",
			"data-intro": {
				hu: "<div class='help eatwithme-table'>Nem megosztható szabad asztal. Foglalás lehetséges!</div>",
				en: "<div class='help eatwithme-table'>Nonshareable free table. Locking is possible!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.inactive",
			"data-intro": {
				hu: "<div class='help eatwithme-table inactive'>Inaktív asztal! Foglalás nem lehetséges!</div>",
				en: "<div class='help eatwithme-table inactive'>Inactive table. Locking is not possible!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.shareable:not(.occupied)",
			"data-intro": {
				hu: "<div class='help eatwithme-table shareable'>Megosztható szabad asztal.<br/><i>Több önálló foglalás is lehetséges!</i></div>",
				en: "<div class='help eatwithme-table shareable'>Shareable free table.<br/><i>Multiple locking is possible!</i></div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied:not(.paid):not(.withOrders):not(.wantsToPay)",
			"data-intro": {
				hu: "<div class='help eatwithme-table occupied'>Az asztal foglalt, de még nem volt rendelés</div>",
				en: "<div class='help eatwithme-table customer-managed'>The table is occupied without orders</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders.new-selections:not(.paid):not(.wantsToPay)",
			"data-intro": {
				hu: "<div class='help eatwithme-table occupied'>Rendelés folyamatban, új, még meg nem erősített rendelésekkel.</div>",
				en: "<div class='help eatwithme-table customer-managed'>Ordering in profress with not confirmed orders</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders:not(.new-selections):not(.paid):not(.wantsToPay)",
			"data-intro": {
				hu: "<div class='help eatwithme-table occupied'>Az asztal foglalt, rendelések is fel lettek véve</div>",
				en: "<div class='help eatwithme-table customer-managed'>The table is occupied with orders regsitered</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders.wantsToPay",
			"data-intro": {
				hu: "<div class='help eatwithme-table occupied'>Az asztal fizetés alatt</div>",
				en: "<div class='help eatwithme-table customer-managed'>The table is paying</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.shareable.multiple-occupation:not(.paid)",
			"data-intro": {
				hu: "<div class='help eatwithme-table occupied shareable multiple-occupation'>Többszöri fogaltság az asztalon!</div>",
				en: "<div class='help eatwithme-table occupied shareable multiple-occupation'>There are multiple occupations on this table!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.unpaid.customer-managed",
			"data-intro": {
				hu: "<div class='help eatwithme-table customer-managed'>Foglalt asztal! Az ügyfél is rendelhet online!</div>",
				en: "<div class='help eatwithme-table customer-managed'>Table is occupied and the customer can also make orders online!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.paid",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Az asztal már fizetett!</div>",
				en: "<div class='help eatwithme-table paid'>The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders.in-delay-90",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Gyors villogás. Túl hosszú ideig nem lett kiszállítva a rendelés (top 10%)!</div>",
				en: "<div class='help eatwithme-table paid'>Quick flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders.in-delay-75",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Normál villogás. Túl hosszú ideig nem lett kiszállítva a rendelés (top 75%)!</div>",
				en: "<div class='help eatwithme-table paid'>Normal flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied.withOrders.in-delay-50",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Lassú villogás. Túl hosszú ideig nem lett kiszállítva a rendelés (top 50%)!</div>",
				en: "<div class='help eatwithme-table paid'>Slow flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied:not(.withOrders).in-delay-90",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Gyors villogás. Túl hosszú ideig nem lett felvéve semmilyen rendelés (top 10%)!</div>",
				en: "<div class='help eatwithme-table paid'>Quick flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied:not(.withOrders).in-delay-75",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Normál villogás. Túl hosszú ideig nem lett felvéve semmilyen a rendelés (top 75%)!</div>",
				en: "<div class='help eatwithme-table paid'>Normal flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "g.eatwithme-table.occupied:not(.withOrders).in-delay-50",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Lassú villogás. Túl hosszú ideig nem lett felvéve semmilyen a rendelés (top 50%)!</div>",
				en: "<div class='help eatwithme-table paid'>Slow flashing. The table is fully paid</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "rect.svgadded.course.asap.unfinished",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Azonnal időzítésű <b>nem teljesített</b> rendelés</div>",
				en: "<div class='help eatwithme-table paid'>Pending order with asap timing</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},

		{
			selector: "rect.svgadded.course.asap.finished",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Azonnal időzítésű <b>teljesített</b> rendelés</div>",
				en: "<div class='help eatwithme-table paid'>Fulfilled order with asap timing</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "rect.svgadded.course.appetizer.unfinished",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Előétel időzítésű <b>nem teljesített</b> rendelés</div>",
				en: "<div class='help eatwithme-table paid'>Pending order with appetizer timing</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "rect.svgadded.course.appetizer.finished",
			"data-intro": {
				hu: "<div class='help eatwithme-table paid'>Előétel időzítésű <b>teljesített</b> rendelés</div>",
				en: "<div class='help eatwithme-table paid'>Fulfilled order with appetizer timing</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.accepted.unprinted",
			"data-intro": {
				hu: "<div class='help .svgadded.accepted'>A rendelések közül van olyan visszaigazolt/megrendelt tétel, amely nincs kinyomtatatva!</div>",
				en: "<div class='help .svgadded.accepted'>There are unprinted orders</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.accepted.printfailed",
			"data-intro": {
				hu: "<div class='help .svgadded.accepted'>A rendelések közül van olyan visszaigazolt/megrendelt tétel, amelyet nem sikerült kinyomtatni legalább egy pályához tartozó nyomtatón!</div>",
				en: "<div class='help .svgadded.accepted'>There are orders where printing failed on at least one printer of a production line.</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.accepted:not(.unprinted)",
			"data-intro": {
				hu: "<div class='help .svgadded.accepted'>Minden rendelés vissza lett igazolva!</div>",
				en: "<div class='help .svgadded.accepted'>All orders are accepted</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.producing",
			"data-intro": {
				hu: "<div class='help .svgadded.producing'>Rendelés elkészítése folyamatban!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.ordered",
			"data-intro": {
				hu: "<div class='help .svgadded.ordered'>Új nem visszaigazolt rendelés!</div>",
				en: "<div class='help .svgadded.ordered'>New non accepted orders!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".svgadded.delivering",
			"data-intro": {
				hu: "<div class='help .svgadded.delivering'>Kiszállítandó rendelések vannak!<br/>Ide klikkelve beállítható/jelezhető a rendszernek, hogy a kiszállítandó rendeléseket megkapta az vendég/ügyfél.</div>",
				en: "<div class='help .svgadded.delivering'>Orders to be delivered!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#editTable .btn.icon-add-table",
			"data-intro": {
				hu: "<div class='help'>Egy új asztal adható a jelenlegi foglaláshoz! Ez akkor szükséges, amikor együtt fizetnek!</div>",
				en: "<div class='help'>An additional table can be added to the current occupation! This is needed when they pay together!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: "#editTable .btn.icon-change-table",
			"data-intro": {
				hu: "<div class='help'>Át lehet ültetni az egész asztalt egy másik aszalhoz!</div>",
				en: "<div class='help'>You can move the whole table occupation to a different table!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".production-line-involved",
			"data-intro": {
				hu: "<div class='help'>Ez a pálya is résztvesz a alábbi rendelések elkészítésében!</div>",
				en: "<div class='help'>The production line is also involved in delivering these orders!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".orders-timing",
			"data-intro": {
				hu: "<div class='help'>Az alábbi rendelések időzítése!</div>",
				en: "<div class='help'>The course of the orders below!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".print.orders.printed",
			"data-intro": {
				hu: "<div class='help'>Az alábbi rendelések már ki lettek nyomtatva! Ide kattintva újra ki tudja nyomtatni az alábbi, illetve a fenti <u>visszaigazolt</u> rendeléseket!</div>",
				en: "<div class='help'>The below orders were already printed! All the orders below and before this course in <u>accepted</u> status can be reprinted by clicking here!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".print.orders.unprinted",
			"data-intro": {
				hu: "<div class='help'>Az alábbi rendelések között vannak olyanok, amelyek még nem lettek kinyomtatva! Ide kattintva ki tudja nyomtatni az alábbi, illetve a fenti <u>visszaigazolt</u> és ki nem nyomtatott rendeléseket!</div>",
				en: "<div class='help'>Some of the below orders were not yet printed! All the unprinted orders below and before this course in <u>accepted</u> status can be reprinted by clicking here!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".print.order.printed",
			"data-intro": {
				hu: "<div class='help'>A rendelés már ki lett nyomtatva! Ide kattintva újra ki tudja nyomtatni!</div>",
				en: "<div class='help'>The order was already printed! It can be reprinted by clicking here!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".print.order.unprinted",
			"data-intro": {
				hu: "<div class='help'>Az rendelés még nem lett kinyomtatva! Ide kattintva ki tudja nyomtatni!</div>",
				en: "<div class='help'>The order was not yet printed! It can be printed by clicking here!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},

		{
			selector: ".paying-status-selected",
			"data-intro": {
				hu: "<div class='help'>A rendelésekből adott számú fizetésre ki lett jelölve!</div>",
				en: "<div class='help'>From orders the given number of orders were selected for payment!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},

		{
			selector: ".paying-status-paid",
			"data-intro": {
				hu: "<div class='help'>Az adott számú rendelés már ki lett fizetve!</div>",
				en: "<div class='help'>From the orders the given number of orders were already paid!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".paying-status-to-be-paid",
			"data-intro": {
				hu: "<div class='help'>A fizetendő rendelések darabszáma!</div>",
				en: "<div class='help'>The count of payable orders!</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},

		{
			selector: ".paying-customer .badge.badge-primary.push.qrcode",
			"data-intro": {
				hu: "<div class='help'>Egy úgy személy hozzáadása az asztalhoz, amelyet az adott fizető fizet.</div>",
				en: "<div class='help'>Add a new person to the table paid by the paying customer selected.</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},
		{
			selector: ".delivering-tasks",
			"data-intro": {
				hu: "<div class='help .svgadded.delivering'>Itt jelennek meg azok az asztalok számai, ahova szánt rendelések kiszállítható állapotba kerültek.<br/>Dupla-klikkeléssel 'kiszállított' állapotba rakható minden 'kiszállítandó' rendelés.</div>",
				en: "<div class='help .svgadded.delivering'>The table numbers of the orders ready to be delivered will appear here.<br/>Double click to set all 'delivering' orders as 'delivered'.</div>",
			},
			"data-position": 'bottom',
			"data-scrollTo": 'tooltip'
		},




	]
}