import React, { Component } from "react";
import $ from "jquery";
import orderListUtil, { playClickSound, tmplparams } from "../../js/order-list-util";
import { connect } from "react-redux";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import AICommandBox from "../../js/ai/AICommandBox";
import auth, { getMyImageUrl, avoidMultipleClick, isDuplicateClickEvent, featureEnabled, getQuantityLabel, settingEnabled, lighten, stopPool } from "../../js/auth";
import EatWithMeText from "../../components/EatWIthMeText";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import BackButton from "../../components/menus/BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import menuitemutil, { availableQuantities, filterChanged, getPlaces, refreshConnectedRestaurants } from "../../js/admin/menuitem";
import { startpoll } from "../../js/auth";
import "daterangepicker";
import "daterangepicker/daterangepicker.css";
import { capturePhotoWithData, crop } from "../../js/camera-util";
import "../../css/menuitem.css";
import { showMenuItemCategory } from "../../js/chooseMenuItem";
import HelpButton from "../../components/icon-buttons/HelpButton";
import { additionOptionIsChecked, includedInCategoryOption, orderMenuItem } from "../../js/orderMenuItem";
import { activeMenuCategories, activeMenuItems, getMenuCategoryOptions } from "../../js/tables-overview";
import { getMenuCategory } from "../../js/admin";
import moment from "moment";


const entityType = "Drink";
const entitytype = "drink";

class Drinks extends Component {
  state = {
    initialized: false
  };

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;
    menuitemutil.entityType = entityType;
    menuitemutil.entitytype = entitytype;
    window.menuitemutil = menuitemutil;
    window.capturePhotoWithData = capturePhotoWithData;
    window.crop = crop;
    window.avoidMultipleClick = avoidMultipleClick;
    window.showMenuItemCategory = showMenuItemCategory;
    window.isDuplicateClickEvent = isDuplicateClickEvent;
    window.featureEnabled = featureEnabled;
    window.settingEnabled = settingEnabled;
    window.orderMenuItem = orderMenuItem;
    window.getQuantityLabel = getQuantityLabel;
    window.includedInCategoryOption = includedInCategoryOption;
    window.getActiveMenuItems = activeMenuItems;
    window.getActiveMenuCategories = activeMenuCategories;
    window.getMenuCategory = getMenuCategory;
    window.getMenuCategoryOptions = getMenuCategoryOptions;
    window.additionOptionIsChecked = additionOptionIsChecked;
    window.filterChanged = filterChanged;
    window.playClickSound = playClickSound;
    window.moment = moment;
    window.lighten = lighten;
    window.getPlaces = getPlaces;
    if (myStatus && myStatus.restaurant_id) {
      this.ready(this.props);
      this.setState({ initialized: true });
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized && this.props.restaurants) {
      this.ready(this.props);
      this.setState({ initialized: true });
    }
  }

  componentWillUnmount() {
    this.setState({ initialized: false });
    delete window.menuitemutil;
    delete window.capturePhotoWithData;
    delete window.crop;
    delete window.avoidMultipleClick;
    delete window.showMenuItemCategory;
    delete window.isDuplicateClickEvent;
    stopPool();
  }

  render() {
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      return (
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "100%", overflow: "hidden" }}>
          {!auth.isIncludedInFrame ? (
            <div className="menuItemHeader drinksheader">
              <EatWithMeText />
              <BackButton {...this.props} />
              <FlexGrowSpace />
              <div style={{ fontSize: "20px" }} className="drinks-index-title"></div>
              <FlexGrowSpace />
              <div className="right-box" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <ChangeLanguage />
                <UserImageButton src={getMyImageUrl()} />
                <div style={{ marginLeft: "10px" }}>
                  <LogoutButton query="keep=true&redirect=/tablesOverview" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div id="drinks" style={{ flexGrow: "100", height: "100%", overflow: "hidden" }}></div>
          <input id="file" type="file" className="hidden"></input>
          <input id="foodorafile" type="file" className="hidden"></input>
          <input id="woltfile" type="file" className="hidden"></input>
          <div id="editMenuItemDialog" />
          {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
        </div>
      );
    } else return null;
  }

  ready(props) {
    this.properties = props;
    this.onloadIndexHtml();
    menuitemutil.initialize(props);
    menuitemutil.hooks();
    menuitemutil.reload();

  }


  onloadIndexHtml() {
    $("#adminMenuitemTemplate")
      .tmpl({ entitytype: entitytype, ...tmplparams(), restaurants: this.properties.restaurants })
      .appendTo("#drinks");
    $("#editMenuCategoryTemplate")
      .tmpl({ onsave: "menuitemutil.reload", entityType: entityType, entitytype: entitytype, availableQuantities: availableQuantities, ...tmplparams() })
      .appendTo("#drinks");
    const webshopEnabled = featureEnabled('ordering/enabled') && ((featureEnabled('pickup/enabled') && featureEnabled('pickup/webshop')) || (featureEnabled('homedelivery/enabled') && featureEnabled('homedelivery/webshop')));
    $("#editMenuItemTemplate")
      .tmpl({ onsave: "menuitemutil.reload", entityType: entityType, entitytype: entitytype, availableQuantities: availableQuantities, webshopEnabled, ...tmplparams() })
      .appendTo("#drinks");

    $('input.connectedRestaurant').change(event => {
      var id = $(event.currentTarget).attr('id');
      var checked = $(event.currentTarget).prop('checked');
      localStorage['restaurant_copy_selected_' + id] = checked;
      refreshConnectedRestaurants(undefined, true);
    });

    //{{tmpl({onsave:'menuitemutil.reload'}) editMenuCategoryTemplate}}
    //{{tmpl({onsave:'menuitemutil.reload'}) editMenuItemTemplate}}
    const pointOfSales = [];
    this.props.tables.forEach(table => {
      if (table.pos === true) pointOfSales.push(table);
    });
  }

  serverSideMessageHandler(message) {
    if (message.type === "timeout" && message.message === "true") return;
    if (message.type === "refresh") {
      if (message.message === "menu changed") {
        menuitemutil.getMenuItems(menuitemutil.processMenuItems);
      } else {
      }
    }
  }
}
const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurants: props.rootReducer.restaurants,
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      tables: props.rootReducer.tables
    };
  }
  return {};
};

export default connect(mapStateToProps)(Drinks);
