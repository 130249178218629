import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import DailyClosing from "../../reports/DailyClosing";
import TableOccupations from "../../reports/TableOccupations";
import OrderSummary from "../../orders/OrderSummary";
import Report from "../../reports/Report";
import { get } from "../../js/auth";

class ReportsMenu extends AbstractMenu {
  state = {
    reports: []
  };

  componentDidMount = () => {
    get("superadminService/getAllReports").done(data => {
      this.setState({ reports: data });
    })

    if (typeof localStorage.tableOccupations_timestamp === undefined || (new Date().getTime() - Number(localStorage.tableOccupations_timestamp)) > 1000 * 3600 * 8) {
      localStorage.tableOccupations_toDate = new Date().getTime();
      localStorage.tableOccupations_fromDate = new Date().getTime() - 1000 * 3600 * 24 * 1;
      localStorage.tableOccupations_timestamp = new Date().getTime();
    }
    localStorage.tableOccupations_timestamp = new Date().getTime();

  }

  render() {
    const { admin_local, features } = this.props;
    const background = "#847556";
    if (admin_local)
      return (
        <Switch>
          <Route path="/reportsMenu/dailyClosing" render={props => <DailyClosing {...props} />} />
          <Route path="/reportsMenu/tableOccupations" render={props => <TableOccupations {...props} />} />
          <Route path="/reportsMenu/orderSummary" render={props => <OrderSummary {...props} />} />
          <Route path="/reportsMenu/report/:report" render={props => <Report {...props} />} />
          <Route path="/reportsMenu" render={props => (
            <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
              <SubmenuHeader {...this.props} header={admin_local.menus.reports} />
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                <MenuButton href="/reportsMenu/dailyClosing" i="i_income" background={background} name={admin_local.daily_closing} myStatus={this.props.myStatus}></MenuButton>
                <MenuButton href="/reportsMenu/tableOccupations" i="icon-list" background={background} name={admin_local.orders} myStatus={this.props.myStatus}></MenuButton>
                {features && (features.pickup.enabled || features.homedelivery.enabled) ?
                  <MenuButton href="/reportsMenu/orderSummary" i="icon-clock" background={background} name={admin_local.menus.orders} myStatus={this.props.myStatus}></MenuButton>
                  : null
                }
                <MenuButton href="/reportsMenu/report/todaytraffic" i="i_pending_turnover" background={background} name={admin_local.menus.todaytraffic} myStatus={this.props.myStatus}></MenuButton>
                <MenuButton href="/reportsMenu/report/traffic" i="i_pending_turnover" background={background} name={admin_local.menus.traffic} myStatus={this.props.myStatus}></MenuButton>
                <MenuButton href="/reportsMenu/report/menuItems" i="i_menu" background={background} name={admin_local.menus.products} myStatus={this.props.myStatus}></MenuButton>
                {this.state.reports.filter(r => r).map(r =>
                  <MenuButton href={"/reportsMenu/report/" + r.name} i="i_menu" background={background} name={r.name} myStatus={this.props.myStatus}></MenuButton>
                )}
              </div>
            </div>)} />
        </Switch >
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    features: state.rootReducer.myStatus.restaurant_settings ? state.rootReducer.myStatus.restaurant_settings["enabled-features"] : undefined
  };
};

export default connect(mapStateToProps)(ReportsMenu);
