import { connect } from "react-redux";
import ProductionLinesCode from "../js/admin/ProductionLinesCode";
import { withRouter } from "react-router-dom";
import EntityPage from "../components/EntityPage";


class ProductionLines extends EntityPage {

    constructor() {
        super('admin_local.menus.productionLines');
        this.code = new ProductionLinesCode()
    }

}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(ProductionLines));
