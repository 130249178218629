import $ from "jquery";
import orderListUtil, {
  processAllOrders,
  updateOrders,
  updateTableMates,
  updatePrintersStatus,
  _show,
  processOrders,
  update,
  deleteOrders,
  setSelected,
  selectPreviousOrder,
  selectNextOrder,
  prevState,
  toggleDetails,
  nextState,
  tmplparams,
  resetOrderListUtil,
  addRejectionReason
} from "./order-list-util";
import auth, { startpoll, isDuplicateClickEvent } from "./auth";
import admin, { getTableOccupations, getProductionLine } from "./admin";
import tableLayout from "./table-layout";

const menuItemType = "Meal";
const menuItemtype = "meal";

const isFiltered = () => {
  return true;
};

var updateCycle;
export const stopIntervals = () => {
  clearInterval(updateCycle);
};

export const ready = () => {
  localStorage.removeItem("productionLineSelected");

  resetOrderListUtil();

  orderListUtil.processOrdersDone = function () {
    floatTableHead();
    topUpdateHooks();
    updatePrintersStatus();
  };

  orderListUtil.show = function (order) {
    if (order.notDelivered || order.asapNotDelivered) return true;
    else {
      return new Date().getTime() - order.inStateTimeStamp["delivered"] < auth.myStatus.restaurant_settings["kitchen"].showDeliveredTimeout * 1000;
    }
  };

  orderListUtil.filter = function (order) {
    return order.menuItem.type === menuItemtype && (order.state !== "delivered" || new Date().getTime() - order.inStateTimeStamp < auth.myStatus.restaurant_settings["kitchen"].showDeliveredTimeout * 1000);
  };

  orderListUtil.updateOrdersDone = function () {
    topUpdateHooks();
  };

  updateCycle = setInterval(function () {
    if (typeof ordersCache != "undefined") {
      processAllOrders(orderListUtil.ordersCache);
      updateOrders([]);
    }
  }, 10000);

  window.isDuplicateClickEvent = isDuplicateClickEvent;
  window.addRejectionReason = addRejectionReason;
  window.getProductionLine = getProductionLine;

  orderListUtil.isFiltered = isFiltered;
  orderListUtil.showTableNumber = true;
  auth.recoverFromEventSourceError = recoverFromEventSourceError;
  orderListUtil.keyDownHandler = keyDownHandler;
  auth.resize = resize;

  orderListUtil.states = auth.myStatus.restaurant_settings["kitchen"].states;
  orderListUtil.statesShownOriginal = orderListUtil.statesShown = auth.myStatus.restaurant_settings["kitchen"].statesShown;
  orderListUtil.hidePaid = auth.myStatus.restaurant_settings["kitchen"]["hide-paid-state"];
  orderListUtil.ordersTemplate = "ordersTemplateCancelledAcceptedProducingDelivering";

  startpoll(serverSideMessageHandler, [
    {
      Restaurant: localStorage.restaurantSelected,
      Order: "*"
    },
    {
      Restaurant: localStorage.restaurantSelected,
      Menu: "*"
    }
  ]);
  $("#restaurantStyle").text(auth.myStatus.restaurant_css);
  $("#kitchenMainTemplate")
    .tmpl({ ...tmplparams(), hidePaid: true })
    .appendTo($("#main"));
  admin.getTables(function (tableLayouts) {
    tableLayout.tables = tableLayouts.restaurantTables;
    tableLayouts.restaurantTables.forEach(function (table) {
      tableLayout.tableMap.set(Number(table.number), table);
    });
    getTableOccupations(function (data) {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        admin.getUnprocessedOrders([menuItemType + "Order"], orders => { orderListUtil.ordersCache = orders; orderListUtil.processOrders(orders) }, {
          show: function (order) {
            return order.type === "meal";
          },
          done: function () {
            floatTableHead();
          }
        });
      }, localStorage.language);

      $("div#rejectionReason").on("shown.bs.modal", function (event) {
        var modal = $(this);
        var r = $(modal).find("#reason1");
        $(r).trigger("focus");
      });
    });

    admin.getPrintersStatus(updatePrintersStatus);
  });
};

function serverSideMessageHandler(message) {
  if (message.type === "timeout" && message.message === "true") return;
  console.log(message.message);
  if (message.type === "refresh") {
    if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
      if (message.data) update(message.data);
      else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
    } else if (message.message === "order cancelled") {
      if (message.data) {
        orderListUtil.deleteOrders(message.Order, true);
        update(message.data);
      } else {
        deleteOrders(message.Order);
      }
    } else if (message.message === "menu changed") {
      admin.getActiveMenuItems(function (categs) {
        admin.categories = categs;
        updateOrders([]);
      }, localStorage.language);
    } else if (message.message === "printer status changed") {
      admin.getPrintersStatus(updatePrintersStatus);
    }
  }
}

function reload() {
  var id = orderListUtil.selected.attr("id");
  var orderId = orderListUtil.selected.attr("orderId");
  var details = $("tr#" + id + ".detailrow.nothidden").length > 0;
  admin.getUnprocessedOrders(function (data) {
    processOrders(data);
    setSelected(id);
    if (details) {
      _show(orderListUtil.selected, true);
      $("tr#" + id + ".detailrow.nothidden").each(function (ind, row) {
        if ($(row).attr("orderId") === orderId) {
          _show($(row));
        }
      });
    }
  });
}

function recoverFromEventSourceError() {
  reload();
}

function topUpdateHooks() {
  orderListUtil.updateHooks();
}

const keyDownHandler = e => {
  switch (e.which) {
    case 37: // left
      prevState();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 38: // up
      selectPreviousOrder();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 39: // right
      nextState();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 40: // down
      selectNextOrder();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;

    case 13: // down
      toggleDetails();
      e.preventDefault(); // prevent the default action (scroll / move
      // caret)
      break;
    default:
  }
  if (!e.isDefaultPrevented() && orderListUtil.keyDownHandler !== keyDownHandler) orderListUtil.keyDownHandler(e);
};

const resize = () => {
  unfloatTableHead();
  floatTableHead();
};

var tableHeadFloated = false;

function floatTableHead() {
  const $id = $(".freeze-orderTable");
  if (tableHeadFloated === true) return;

  if ($id.length === 0) return;

  tableHeadFloated = true;

  $id.data("table.orderTable", true);

  $id.data(
    "freezeTable",
    new window.FreezeTable(".freeze-orderTable", {
      namespace: "orderTable",
      scrollable: true
    })
  );
}

function unfloatTableHead() {
  const $id = $(".freeze-orderTable");
  if ($id.data("tableHeadFloated") !== true) return;

  $id.data("freezeTable").destroy();
  $id.data("tableHeadFloated", false);
}

orderListUtil.printNewOrders = () => { };
