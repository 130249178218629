import React, { Component } from "react";
import BackButton from "./BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import EatWithMeText from "../EatWIthMeText";
import RestaurantSelector from "../RestaurantSelector";

class SubmenuHeader extends Component {
  state = {};
  render() {
    return (
      <div className="menuItemHeader"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          height: "50px",
          fontSize: "18px",
          backgroundColor: "#FAF8EA",
          color: "#837B6C",
          paddingRight: "30px",
          alignItems: "center"
        }}
      >
        <EatWithMeText />
        <BackButton {...this.props} />
        <FlexGrowSpace />
        <RestaurantSelector myStatus={this.props.myStatus} onReload={this.props.onReload} />
        <FlexGrowSpace />
        <h3 className="title">{this.props.header}</h3>
      </div>
    );
  }
}

export default SubmenuHeader;
