import React, { Component } from "react";
import QRCode from "qrcode.react";

class ReactQRCode extends Component {
  state = {};
  render() {
    return <QRCode value={this.props.url} size={this.props.size} includeMargin={true} />;
  }
}

export default ReactQRCode;
