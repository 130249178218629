import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { getMenuItem, getMenuItemById } from '../js/admin';
import { getLocale, getMenuItemImageUrl, getStandardQuantity, scaleMeasure } from "../js/auth";
import { getQuantity2 } from '../js/order-list-util';


class ScaleMeasure extends Component {
    state = { weight: 0, weight2: 0 }

    componentDidMount = () => {
        const instance = this;
        const quantity = this.state.quantity;
        if (this.state.menuItemId && !this.state.menuItem) {
            getMenuItemById(this.state.menuItemId, menuItem => {
                instance.setState({ menuItem: menuItem, quantity: quantity, imgUrl: getMenuItemImageUrl(menuItem, quantity) })
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        var _state = {
            menuItemId: props.menuItemId,
            menuItem: props.menuItem,
            quantity: props.quantity,
            imgUrl: props.menuItem ? getMenuItemImageUrl(props.menuItem, props.quantity) : null,
            weight: state.weight,
            weight2: state.weight2,
            barcode: props.barcode
        }
        return _state;
    }

    render() {
        if (this.props.show) {
            if (document.activeElement)
                document.activeElement.blur();
        }
        if (!this.state.scaleMeasureStarted && this.props.show) {
            setTimeout(this.startMeasurement, 100);
        }
        return (
            <div onClick={this.props.closeHandler} style={{ zIndex: 10000, display: this.props.show ? "flex" : "none", flexFlow: "column", "height": "100%", "position": "fixed", top: "0", left: "0", right: "0", "bottom": 0, justifyContent: "center", alignItems: "center", background: "#847c7c8f" }} >
                <div onClick={this.startMeasurement} style={{ display: "flex", flexFlow: "column", height: "550px", width: "300px", background: "lightgray", border: "4px solid white", borderRadius: "5px", color: "darkslategray" }}>
                    <div style={{ flexBasis: "40%", display: "flex", justifyContent: "center", alignItems: "center", flexShrink: 0, borderBottom: "1px solid white", background: "white", }}>
                        <div style={{ background: "#ffa5006b", width: "200px", height: "200px", "borderRadius": "100%", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", fontSize: "30px" }} >
                            <span>{this.state.weight} g</span>
                            {this.state.weight2 && this.state.menuItem && this.state.menuItem.quantityType !== "g" && this.state.menuItem.quantityType !== "dkg" && this.state.menuItem.quantityType !== "kg" ?
                                <span>{this.state.weight2} {I18n.t("local." + this.state.menuItem.quantityType)}</span>
                                : null}
                        </div>
                    </div>
                    <div style={{ flexBasis: "60%", display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center" }}>
                        {this.state.menuItem ? (
                            <React.Fragment>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img alt="" src={this.state.imgUrl} />
                                </div>
                                <div style={{ display: "flex", flexFlow: "column", justifyContent: "center", alignItems: "center", fontSize: "30px" }}>
                                    <span>{getLocale(this.state.menuItem.name)}</span>
                                    <span>{getQuantity2(this.state.quantity, this.state.menuItem.quantityType, this.state.menuItem.entityType)}</span>
                                </div>
                            </React.Fragment>)
                            : null}
                    </div>
                </div>
            </ div >
        );
    }

    startMeasurement = () => {
        if (!this.state.scaleMeasureStarted) {
            this.setState({ scaleMeasureStarted: true })
            scaleMeasure(localStorage.selected_scale).done(amount => {
                this.updateMeasure(amount);
                this.props.resultHandler(this.state.weight2);
                setTimeout(() => {
                    this.props.closeHandler();
                    this.setState({ scaleMeasureStarted: false, weight: 0, weight2: 0 })
                }, 1000)
            })
        }
    }

    updateMeasure = (amount) => {
        const menuItem = this.state.menuItem;
        var quantity = this.state.quantity;
        var standardQuantity = getStandardQuantity(quantity, menuItem.quantityType);
        const inventoryQuantity = this.getInventoryQuantity(menuItem, 1);
        var barcode = this.state.barcode;
        if (!barcode)
            barcode = menuItem.barcodes.find(q => q.quantity === standardQuantity * 100);
        //var barcode = menuItem.barcodes.find(q => q.quantity === standardQuantity);
        var tareWeight = barcode ? barcode.tareWeight : 0;
        var amount2 = amount - tareWeight;
        if (barcode) {
            amount2 = amount2 / (barcode.grossWeight - barcode.tareWeight);
            amount2 = amount2 * barcode.quantity / 100 / standardQuantity;
        } else {
            switch (menuItem.quantityType) {
                case "g":
                case "ml":
                    amount2 *= 1000;
                    break;
                case "dkg":
                case "cl":
                    amount2 *= 100;
                    break;
                case "dl":
                    amount2 *= 10;
                    break;
                default:
            }
            amount2 = amount2 / inventoryQuantity;
        }
        amount = Math.round(amount * 1000) / 1000;
        amount2 = Math.max(0, Math.round(amount2 * 1000) / 1000);
        amount2 = Math.round(amount2 * 1000) / 1000;
        this.setState({ weight: amount, weight2: amount2 })
    }

    quantityCache = {}

    getInventoryQuantity = (menuItem, quantity) => {
        menuItem = getMenuItem(menuItem.id);
        var ret = this.quantityCache[menuItem.id + "-" + quantity];
        var onlyInventoryQuantity = true;
        var inventoryQuantities = 0;
        if (ret !== undefined)
            return ret;
        menuItem.availableQuantities.forEach((q) => {
            if (q.inventoryItem)
                inventoryQuantities++;
            onlyInventoryQuantity &= q.inventoryItem;
            if (q.inventoryItem && Number(q.quantity) === Math.abs(quantity))
                ret = quantity;
        });
        if (!onlyInventoryQuantity && inventoryQuantities > 1) {
            this.quantityCache[menuItem.id + "-" + quantity] = 1;
            return 1;
        }
        if (ret) {
            this.quantityCache[menuItem.id + "-" + quantity] = ret;
            return ret;
        }
        console.log(JSON.stringify(menuItem.availableQuantities));

        console.log(menuItem.availableQuantities.find(q => q.inventoryItem));

        ret = menuItem.availableQuantities.find(q => q.inventoryItem).quantity;

        if (ret === undefined || ret === "undefined")
            ret = 1;
        this.quantityCache[menuItem.id + "-" + quantity] = ret;
        return ret;
    }
}

export default ScaleMeasure;