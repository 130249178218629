import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { getRolesByRestaurant } from "../../js/superadmin/superadmin";
import { allRoles } from "../../js/admin";
import { DropDownEditor, CustomerEmailEditor, RestaurantNameEditor } from "../../renderers/tablecelleditors";
import { flattenObject } from "../../components/utils";
import { rolesGridStateKey } from "../../reducers/rootReducer";
import EatWithMeActions from "../../actions/EatWithMeActions";


export const stateKey = rolesGridStateKey;

const stateful = false;

function getColumns({ myStatus, onChange, store }) {
  return [
    {
      name: <Translate value="admin_local.user" />,
      dataIndex: "customer.name",
      sortable: true,

      width: "130px",
      className: "",
      expandable: true,
      readOnly: true,
      renderer: props => {
        if (props.value) return <span>{props.row["customer.surname"] + " " + props.row["customer.name"]}</span>;
        else return <span></span>;
      }
    },
    {
      name: <Translate value="admin_local.email" />,
      dataIndex: "customer.email",
      sortable: true,

      width: "130px",
      className: "",
      expandable: true,
      readOnly: true,
      renderer: props => {
        if (props.row["role.id"]) return props.value ? <span>{props.value}</span> : <span>{props.row['role.email']}</span>;
        else return <CustomerEmailEditor {...props} myStatus={myStatus} onChange={onChange} stateKey={stateKey} />;
      }
    },
    {
      name: <Translate value="admin_local.role" />,
      dataIndex: "role.role",
      sortable: true,
      renderer: props => {
        return (
          <DropDownEditor
            {...props}
            onChange={onChange}
            stateKey={stateKey}
            options={allRoles.map(m => {
              const role = I18n.t("admin_local.role_names." + m);
              return { id: m, name: role };
            })}
          />
        );
      },
      readOnly: true,
      width: "130px",
      className: "",
      expandable: true
    },
    {
      name: <Translate value="admin_local.entity_type" />,
      dataIndex: "role.entityType",
      sortable: true,
      renderer: props => <span>{props.value}</span>,
      editor: props => <span>{props.value}</span>,
      width: "130px",
      className: "",
      expandable: true
    },
    {
      id: "entityName",
      name: <Translate value="admin_local.entity" />,
      dataIndex: "entity.name",
      sortable: true,
      renderer: props => {
        if (/*props.row["role.id"] && */props.value) return <span>{props.value}</span>;
        else return <RestaurantNameEditor {...props} onChange={onChange} stateKey={stateKey} />;
      },
      editor: props => <span>{props.value} </span>,
      width: "130px",
      className: "",
      expandable: true
    }
  ];
}

const rolesConfig = (props, store) => {
  return {
    store: store,
    reducerKeys: "nested",

    gridType: "grid",
    pageSize: 20,

    stateKey: stateKey,

    stateful: stateful,

    columns: getColumns({ ...props, store }),

    restaurant_id: null,

    height: false,

    dataSource: function getData({ pageIndex, pageSize }) {
      return new Promise(resolve => {
        if (store.getState().rootReducer.restaurant && store.getState().rootReducer.restaurant.id) {
          getRolesByRestaurant(store.getState().rootReducer.restaurant.id, data => {
            data.roles.forEach(r => {
              r.key = r.role.id;
            });
            data.roles.push({
              role: {
                entityType: "Restaurant",
              },
            });
            const d = data.roles.map(r => flattenObject(r));
            resolve({ data: d });
          });
        } else resolve({ data: [] });
      });
    },

    events: {},

    plugins: {
      COLUMN_MANAGER: {
        resizable: true,
        moveable: true
        /*
      sortable: {
        enabled: true,
        method: "local",
        sortingSource: "http://react-redux-grid.herokuapp.com/getfakeData"
      }*/
      },
      STICKY_HEADER: {
        enabled: true
      },
      STICKY_FOOTER: {
        enabled: true
      },
      /*
      EDITOR: {
        type: "inline",
        enabled: false,
        focusOnEdit: true
      },*/
      LOADER: {
        enabled: false
      },
      SELECTION_MODEL: {
        mode: "checkbox-multi",
        enabled: false,
        allowDeselect: true,
        activeCls: "active",
        selectionEvent: "singleclick",
        editEvent: "none"
      },
      ERROR_HANDLER: {
        defaultErrorMessage: "AN ERROR OCURRED",
        enabled: true
      },
      BULK_ACTIONS: {
        enabled: false,
        actions: [
          {
            text: "Move",
            EVENT_HANDLER: () => { }
          },
          {
            text: "Add",
            EVENT_HANDLER: () => { }
          }
        ]
      },
      GRID_ACTIONS: {
        iconCls: "action-icon",
        menu: [
          {
            text: I18n.t("admin_local.delete"),
            key: "delete",
            EVENT_HANDLER: ({ metaData }) => {
              store.dispatch(EatWithMeActions.deleteRow({ row: metaData.rowData, rowIndex: metaData.rowIndex, stateKey: stateKey }));
            }
          }
        ]
      }
    }
  };
};
export default rolesConfig;
