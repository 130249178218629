import $ from "jquery";
import admin from "../admin";
import auth, { startpoll, confirmDialog, getLocale } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

class QuantityTypesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveQuantityType', 'deleteQuantityType', 'activateQuantityType']);
	}

	ready = () => {

		$('#main').replaceWith($('#quantityTypesMainTemplate').tmpl({
			...tmplparams()
		}));


		this.loadQuantityTypes();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();

	}

	reload = () => {
		this.loadQuantityTypes();
	}

	loadQuantityTypes = () => {
		admin.getQuantityTypes(this.processQuantityTypes);
	}

	serverSideMessageHandler = (data) => {
	}

	quantityTypes = null;
	processQuantityTypes = (data) => {

		data.forEach(function (v, i) {
			v.title = v.name;
			v.folder = false;
		});
		if (this.quantityTypes == null)
			this.quantityTypes = createTree("table#quantityTypes", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 1
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					node.expanded = true;
					$tdList.eq(1).text(admin.getName(node.data.name, localStorage.language));
				}
			});
		else {
			this.quantityTypes.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editQuantityType').on('show.bs.modal', function (event) {

			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			auth.myStatus.restaurant_languages.forEach(function (lang) {
				modal.find('#name_' + lang).val("");
			});
			modal.find('#name_all').val("");
			if (recipient === 'edit') {
				var data = instance.quantityTypes.getActiveNode().data;

				modal.find('#id').val(data.id);
				auth.myStatus.restaurant_languages.forEach(function (lang) {
					modal.find('#name_' + lang).val(data.name[lang]);
				});
				modal.find('#name_all').val(data.name.all);
			} else {
				modal.find('#id').val("");

			}
		})
	}

	saveQuantityType = () => {
		var modal = $('div#editQuantityType');
		var id = modal.find('#id').val();
		var name = {};
		modal.find('.name').each(function (ind, v) {
			if ($(v).val() !== "") {
				name[$(v).attr('lang')] = $(v).val();
			}
		});
		if (id !== undefined && id !== '') {
			admin.modifyQuantityType(id, name, this.loadQuantityTypes);
		} else {
			admin.addQuantityType(name, this.loadQuantityTypes);
		}
	}

	activateQuantityType = (active) => {
		var data = this.quantityTypes.getActiveNode().data;
		admin.modifyQuantityType(data.id, data.name, active, this.loadQuantityTypes);
	}

	deleteQuantityType = () => {
		const instance = this;
		var data = this.quantityTypes.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_quantity_type'), getLocale(data.name))).done(function () {
			admin.deleteQuantityType(data.id, instance.loadQuantityTypes);
		});

	}

}

export default QuantityTypesCode;