import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";

class ProductionLinesMenu extends AbstractMenu {
  render() {
    const { admin_local, restaurant_production_lines } = this.props;
    const background = "#847556";
    if (restaurant_production_lines)
      return (
        <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
          <SubmenuHeader {...this.props} header={admin_local.menus.productionLines} />
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
            {restaurant_production_lines.map(production_line => {
              return (
                <MenuButton
                  key={production_line.id}
                  onClick={() => {
                    localStorage.productionLineSelected = production_line.id;
                    this.props.history.push("/productionLinesMenu/productionLine");
                  }}
                  i="i_production"
                  background={background}
                  name={production_line.name}
                  myStatus = {this.props.myStatus}
                ></MenuButton>
              );
            })}
            <MenuButton
              onClick={() => {
                var productionLines = "";
                restaurant_production_lines.forEach(p => {
                  productionLines += p.id + ",";
                });
                localStorage.productionLineSelected = productionLines;
                this.props.history.push("/productionLinesMenu/productionLine");
              }}
              i="i_production"
              background={background}
              name={admin_local.all_production_lines}
              myStatus = {this.props.myStatus}
            ></MenuButton>
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    restaurant_production_lines: state.rootReducer.myStatus.restaurant_production_lines
  };
};

export default connect(mapStateToProps)(ProductionLinesMenu);
