import $ from "jquery";
import admin from "../admin";
import { startpoll, confirmDialog } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

class VatCategoriesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveVATCategory', 'deleteVATCategory', 'activateVATCategory']);
	}

	ready = () => {

		$('#main').replaceWith($('#vatCategoriesMainTemplate').tmpl({
			...tmplparams()
		}));

		this.loadVATCategories();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	reload = () => {
		this.loadVATCategories();
	}

	loadVATCategories = () => {
		admin.getVATCategories(this.processVATCategories);
	}

	serverSideMessageHandler = (data) => {
	}


	vatCategories = null;
	processVATCategories = (data) => {
		data.forEach(function (v, i) {
			v.title = v.number;
			v.folder = false;
			v.key = v.id;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});
		if (this.vatCategories === null)
			this.vatCategories = createTree("table#vatCategories", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 1
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.percent);
				}
			});
		else {
			this.vatCategories.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editVATCategory').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			if (recipient === 'edit') {
				var data = instance.vatCategories.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#percent').val(data.percent);
				if (data.isActive === true)
					modal.find('#active').prop("checked", 'true');
				else
					modal.find('#active').removeAttr("checked");
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#percent').val("");
				modal.find('#active').prop("checked", 'true');

			}
		})
	}

	saveVATCategory = () => {
		var modal = $('div#editVATCategory');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var percent = modal.find('#percent').val() || null;
		var active = modal.find('#active').prop('checked');
		if (id !== '') {
			admin.modifyVATCategory(id, name, percent, active, this.loadVATCategories);
		} else {
			admin.addVATCategory(name, percent, active, this.loadVATCategories);
		}
	}

	activateVATCategory = (active) => {
		var data = this.vatCategories.getActiveNode().data;
		admin.modifyVATCategory(data.id, data.name, data.percent, active, this.loadVATCategories);
	}

	deleteVATCategory = () => {
		const instance = this;
		var data = this.vatCategories.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_vat_category'), data.name)).done(function () {
			admin.deleteVATCategory(data.id, instance.loadVATCategories);
		});

	}

}

export default VatCategoriesCode;