import { get, post } from "./auth";
import { admin, resultHandler } from "./admin";

// cash registers
function getCashRegisters(handler, restaurantId) {
    get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getCashRegisters?lang=" + localStorage.language, localStorage.restaurantSelected).done(function (data) {
        if (data === null) {
            console.log("Failed to get CashRegisters!");
        } else {
            handler(data);
        }
    });
}
admin.getCashRegisters = getCashRegisters;
function getCashRegistersStatus(handler, restaurantId) {
    get("adminService/" + (typeof restaurantId === "undefined" ? localStorage.restaurantSelected : restaurantId) + "/getCashRegistersStatus", localStorage.restaurantSelected).done(function (data) {
        if (data === null) {
            console.log("Failed to get CashRegisters status!");
        } else {
            handler(data);
        }
    });
}
admin.getCashRegistersStatus = getCashRegistersStatus;
function modifyCashRegister(cashRegister, handler, errorHandler) {
    post("adminService/" + localStorage.restaurantSelected + "/modifyCashRegister", cashRegister).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.modifyCashRegister = modifyCashRegister;
function addCashRegister(cashRegister, handler, errorHandler) {
    post("adminService/" + localStorage.restaurantSelected + "/addCashRegister", cashRegister).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.addCashRegister = addCashRegister;
function deleteCashRegister(id, handler, errorHandler) {
    get("adminService/" + localStorage.restaurantSelected + "/deleteCashRegister/" + id).done(function (data) {
        resultHandler(data, handler, errorHandler);
    });
}
admin.deleteCashRegister = deleteCashRegister;
