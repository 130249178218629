import React, { Component } from "react";
import orderListUtil, { toggleSound } from "../../js/order-list-util";
import { connect } from "react-redux";
import { ready, stopIntervals } from "../../js/production-line";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import LoginButton from "../../components/icon-buttons/LoginButton";
import AICommandBox from "../../js/ai/AICommandBox";
import auth, { getMyImageUrl } from "../../js/auth";
import EatWithMeText from "../../components/EatWIthMeText";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import BackButton from "../../components/menus/BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import "./style.css";

class ProductionLine extends Component {
  state = {
    initialized: false
  };

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id) {
      ready(this.props);
      this.setState({ initialized: true });
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      ready(this.props);
      this.setState({ initialized: true });
    }
  }

  componentWillUnmount() {
    stopIntervals();
    this.setState({ initialized: false });
  }

  render() {
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      return (
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          {!auth.isIncludedInFrame ? (
            <div
              className="menuItemHeader"
              style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px", color: "#837B6C" }}
            >
              <EatWithMeText />
              <BackButton {...this.props} />
              <FlexGrowSpace />
              <div style={{ fontSize: "20px" }} className="production-line-index-title"></div>
              <FlexGrowSpace />
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <span data-toggle="toggle" onClick={toggleSound} id="sound" className="btn icon icon-volume-off"></span>
                <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
                <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
                <ChangeLanguage />
                <UserImageButton src={getMyImageUrl()} />
                <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
                  <LoginButton query="keep=true&redirect=/tablesOverview" />
                  <LogoutButton query="keep=true&redirect=/tablesOverview" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div id="main" ></div>
          {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
        </div>
      );
    } else return null;
  }
}
const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable
    };
  }
  return {};
};

export default connect(mapStateToProps)(ProductionLine);
