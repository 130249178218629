import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../..";
import EatWithMeActions from "../../actions/EatWithMeActions";

class DayNight extends Component {

  render() {
    var clazz = this.props.on ? "icon-moon-inv hide-print" : "icon-moon hide-print";
    return (
      <div onClick={this.onClick} className={clazz} style={{ paddingLeft: "10px", paddingRight: "10px", fontSize: "18px" }}>
      </div>
    );
  }

  onClick = () => {
    store.dispatch(EatWithMeActions.daynight(!this.props.on));
  }
}

const mapStateToProps = (props, state) => {
  return {
    on: props.rootReducer.daynight,
  };
};

export default connect(mapStateToProps)(DayNight);
