import $ from "jquery";

export const insertCharacter = (item, number) => {
    var tokenizer = $(".tokenize.focus");
    var $item;

    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
    }
    if (!$item) $item = $(item);
    if ($item.length === 0) return;
    item = $item.get(0);
    var v = $item.val();
    if ($item.attr("type") === "text") {
        var s = item.selectionStart;
        $item.val(v.substring(0, item.selectionStart) + v.substring(item.selectionEnd));
        item.selectionStart = s;
        item.selectionEnd = s;
        v = $item.val();
        s = item.selectionStart;
        $item.val(v.substring(0, item.selectionStart) + number + v.substring(item.selectionEnd));
    } else {
        $item.val($item.val() + number);
    }
    //$item.focus();
    if ($item.attr("type") === "text") {
        v = $item.val();
        item.selectionStart = s + 1;
        item.selectionEnd = item.selectionStart;
        //$item.focus();
        if ($item.data("cleave")) {
            //$item.data('cleave').onFocus();
            $item.data("cleave").onChange();
        }
    }
    $item.trigger("change", [number]);
    if (tokenizer.scaleInput) {
        tokenizer.scaleInput();
    }
}
export const insertEnter = (item, event) => {
    var tokenizer = $(".tokenize.focus");
    var $item;
    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (!$item) $item = $(item);
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
        tokenizer.pressedDelimiter(event);
    } else {
        $item.val($item.val() + "\r\n");
    }
    $item.trigger("change", ["Enter"]);
}
export const backspaceCharacter = (item) => {
    var tokenizer = $(".tokenize.focus");
    var $item;
    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
    }
    if (!$item || !$item.length) $item = $(item);
    if ($item.length === 0) return;
    item = $item.get(0);
    var v = $item.val();
    var s = item.selectionStart;
    if (s === 0) return;
    $item.focus();
    if (s !== item.selectionEnd) {
        $item.val(v.substring(0, item.selectionStart) + v.substring(item.selectionEnd));
        item.selectionStart = s;
        item.selectionEnd = s;
    } else {
        $item.val(v.substring(0, item.selectionStart - 1) + v.substring(item.selectionStart));
        item.selectionStart = s - 1;
        item.selectionEnd = s - 1;
    }
    if ($item.data("cleave")) $item.data("cleave").onChange();
    $item.change();
    if (tokenizer.scaleInput) {
        tokenizer.scaleInput();
    }
}
export const leftCharacter = (item) => {
    var tokenizer = $(".tokenize.focus");
    var $item;
    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
    }
    if (!$item || $item.length === 0) $item = $(item);
    if ($item.length === 0) return;
    item = $item.get(0);
    var s = item.selectionStart;
    if (s === 0) return;
    $item.focus();
    item.selectionStart = s - 1;
    item.selectionEnd = s - 1;
    $item.change();
}
export const rightCharacter = (item) => {
    var tokenizer = $(".tokenize.focus");
    var $item;
    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
    }
    if (!$item || $item.length === 0) $item = $(item);
    if ($item.length === 0) return;
    item = $item.get(0);
    var s = item.selectionStart;
    $item.focus();
    item.selectionStart = s + 1;
    item.selectionEnd = s + 1;
    $item.change();
}
export const clearInput = (item) => {
    var tokenizer = $(".tokenize.focus");
    var $item;
    if ($('input[type!="radio"]:focus').length !== 0) {
        $item = $('input[type!="radio"]:focus');
    }
    if (tokenizer.length !== 0) {
        tokenizer = tokenizer.prev().tokenize2();
    }
    if (!$item || $item.length === 0) $item = $(item);
    if ($item.length === 0) return;
    item = $item.get(0);
    if ($item.hasClass("input-numeral")) $item.val("0");
    else $item.val("");
    if ($item.data("cleave")) $item.data("cleave").onChange();
    $item.focus();
    item.selectionStart = 0;
    item.selectionEnd = 1;
    if (tokenizer.scaleInput) {
        tokenizer.scaleInput();
    }
    $item.change();
}