import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import OpeningHours from "../../main/openingHours/OpeningHours";

class PlansMenu extends AbstractMenu {
  state = {};

  render() {
    const { admin_local } = this.props;
    const background = "#847556";
    if (admin_local)
      return (
        <Switch>
          <Route path="/adminMenu/plansMenu/openingHours" render={props => <OpeningHours {...props} />} />
          <Route path="/adminMenu/plansMenu" render={props => (
            <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
              <SubmenuHeader {...this.props} header={admin_local.menus.plans} />
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                <MenuButton href="/adminMenu/plansMenu/openingHours" i="icon-calendar" background={background} name={admin_local.menus.opening_hours} myStatus={this.props.myStatus}></MenuButton>
              </div>
            </div>)} />
        </Switch >
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    myStatus: state.rootReducer.myStatus,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    features: state.rootReducer.myStatus.restaurant_settings ? state.rootReducer.myStatus.restaurant_settings["enabled-features"] : undefined
  };
};

export default connect(mapStateToProps)(PlansMenu);
