import React, { Component } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import admin, { getPriceForQuantity } from "../../js/admin";
import "./customerScreen.css"
import auth from "../../js/auth";
import { stopIntervals } from "../../js/point-of-sale";

class CustomerScreen extends Component {

    state = {
        orders: [],
        index: 0
    }

    componentDidMount = () => {

        const handler = (event) => {
            if (event?.detail?.data)
                event.data = event.detail.data;
            if (event.data && event.data.source === this.props.match.params.tableNumber) {
                this.setState({ orders: event.data.data, images: event.data.images, index: Math.min(this.state.index, event.data.images.length) });
            }
            return;
        }

        window.addEventListener("message", handler, false);
        document.addEventListener("message", handler);
        this.interval = setInterval(this.nextPicture, 3000);

        admin.getActiveMenuItems(data => {
            admin.categories = data;
            this.setState({ initialized: true });
        })
    }

    componentWillUnmount = () => {
        stopIntervals(this.interval);
    }

    nextPicture = () => {

        if (this.state.images)
            this.setState({ index: this.state.index < this.state.images.length - 1 ? this.state.index + 1 : 0 })
    }

    render() {
        if (!this.props.myStatus.restaurant_base_currency || !this.state.initialized)
            return null;
        return (
            <div className="customerScreen" key="0">
                <div className="orderLevel1" key="1">
                    <div key="2" className="count head"><Translate value="admin_local.count" /></div>
                    <div key="3" className="name head"><Translate value="admin_local.name" /></div>
                    <div key="4" className="unitPrice head"><Translate value="admin_local.unit_price" /></div>
                    <div key="5" className="price head"><Translate value="admin_local.price" /></div>
                    {this.state.orders.map((order, ind) =>
                        <React.Fragment>
                            {order.orders && order.orders.map((order, ind1) =>
                                order.orders.map((order, ind2) =>
                                    <React.Fragment>
                                        <div key={order.orderIds + ".1"} className="count">{order.orderCount}  x&nbsp;</div>
                                        <div key={order.orderIds + ".2"} className="name">{order.menuItem.name} {this.getQuantity(order)}</div>
                                        <div key={order.orderIds + ".3"} className="unitPrice">{getPriceForQuantity(order.menuItem, order.quantity)}</div>
                                        <div key={order.orderIds + ".4"} className="price">{order.orderCount * order.price} {this.props.myStatus.restaurant_base_currency.name}</div>
                                        {order.childOrders.map((childOrder, ind3) =>
                                            <React.Fragment>
                                                <div key={order.orderIds + "." + ind3 + ".1"}></div>
                                                <div key={order.orderIds + "." + ind3 + ".2"} className="childName">{childOrder.menuItem.name} {this.getQuantity(childOrder)}</div>
                                                <div key={order.orderIds + "." + ind3 + ".3"} className="childPrice">{(childOrder.addition ? 1 : -1) * childOrder.fullPrice}</div>
                                                <div key={order.orderIds + "." + ind3 + ".4"}></div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            )}
                        </React.Fragment>
                    )}
                    <div key="6" className="count footer"><Translate value="admin_local.total" /></div>
                    <div key="7" className="price footer">{this.state.orders.reduce((a, b) => a + b.price, 0)} {this.props.myStatus.restaurant_base_currency.name}</div>
                </div>
                <div className="ads" key="2">
                    <div className="ads-img">
                        {this.state.images && this.state.images.length > 0 ?
                            <img alt="" src={auth.server + "/" + auth.war + "/adminService/" + localStorage.restaurantSelected + "/getImage/" + this.state.images[this.state.index]}></img>
                            : null}
                    </div>
                </div>
            </div>)
    }

    getQuantity = (o) => {
        var menuItem = o.menuItem;
        var quantityType = o.quantityType ? o.quantityType : menuItem.quantityType;
        if (quantityType === "undefined" || quantityType === "" || quantityType === "none") return "";
        var quantityLabel = "";
        var quantity = o.quantity;
        menuItem.availableQuantities.forEach(function (q) {
            if (Math.abs(q.quantity) === Math.abs(quantity)) {
                quantityLabel = q.quantityType ? q.quantityType.name : q.name;
                if (quantityLabel === "N/A") quantityLabel = "";
            }
        });
        if (quantityLabel !== "" && menuItem.entityType === "Meal") {
            return (quantity < 0 ? quantity + " " : "") + quantityLabel;
        } else {
            var v = quantityLabel !== "" ? quantityLabel + " " : "";
            v += Math.abs(quantity) === 1 && quantityType === "pcs" ? "" : "(" + (quantity + (quantity === 1 && quantityType === "pcs" ? "" : quantityType === "" ? "" : " " + I18n.t("local." + quantityType))) + ")";
            return v;
        }
    }


}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
            tables: props.rootReducer.tables
        };
    }
    return {};
};

export default connect(mapStateToProps)(CustomerScreen);
