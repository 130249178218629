import { CardContent, Card } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withRouter } from "react-router-dom";
import SubmenuHeader from "../components/menus/SubMenuHeader";
import { get } from "../js/auth";
import CardHeader from '@material-ui/core/CardHeader';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

class Falatozz extends Component {

    state = {
    }

    constructor(props) {
        super(props);

        this.useStyles = makeStyles((theme) => ({
            root: {
                width: '100%',
            },
            heading: {
                fontSize: theme.typography.pxToRem(15),
                flexBasis: '33.33%',
                flexShrink: 0,
            },
            secondaryHeading: {
                fontSize: theme.typography.pxToRem(15),
                color: theme.palette.text.secondary,
            },
        }));
    }

    render() {
        return <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "hidden", height: "100%" }}>
            <SubmenuHeader {...this.props} header={I18n.t("admin_local.menus.falatozz_administration")} />
            <div id="falatozzsynchronizer" >
                <div style={{ overflowY: "hidden", height: "100%", width: "100%" }}>
                    <Card style={{ width: "100%", backgroundColor: "inherit", color: "inherit" }}>
                        <CardHeader title={I18n.t("admin_local.status")} action={null} />
                        <CardContent>
                            {this.props.myStatus.restaurant_settings["enabled-features"].falatozz.places ? this.props.myStatus.restaurant_settings["enabled-features"].falatozz.places.map(place =>
                                <Button style={{ color: "inherit" }} onClick={() => this.onClick(place.name)}>Upload menu to {place.name}</Button>
                            ) : null}

                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    }

    onClick = (name) => {
        get("integration/falatozz/syncMenu/" + this.props.myStatus.restaurant_id + "/" + name);
    }


}



const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            myStatus: props.rootReducer.myStatus,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(Falatozz));
