const local_hu = {
  ml: "ml",
  dl: "dl",
  cl: "cl",
  l: "l",
  pcs: "db",
  g: "g",
  dkg: "dkg",
  kg: "kg",
  none: "nincs",
  day: "nap",
  hour: "óra",
  min: "perc",
  sign_up: "Regiszrálok",
  login: "Belépek",
  first_name: "Keresztnév",
  provide_first_name: "Kérem adja meg a keresztnevét",
  surname: "Vezetéknév",
  family_name: "Vezetéknév",
  provide_surname: "Kérem adja meg a családnevét",
  email: "Email cím",
  provide_email: "Kérem adja meg a email címét",
  password: "Jelszó",
  quick_access_code: "Gyorskód",
  require_quick_access_code: "Kötelező",
  provide_password: "Kérem adja meg a jelszavát",
  forgot_password: "Elfelejtette jelszavát?",
  need_sign_up: "Regisztrálni szeretne?",
  registration: "Regisztráció",
  register: "Regisztálás",
  back_to_login: "Vissza a belépéshez",
  social_login: "vagy lépjen be egy már létező profiljával",
  email_already_in_use: "Ez az email cím már foglalt!",
  activation_email_sent: "Az aktivációs emailt elküldük a(z) {0} címre. Kérem ellenőrizze az email fiókját! A spam könyvárat is ellenőrizze!",
  activation_email_sent2: "Az aktivációs emailt elküldük a(z) {0} címre. Kérem ellenőrizze az email fiókját! A spam könyvárat is ellenőrizze!",
  password_reset_email_sent: "A jelszava helyreállítását lehetővé tevő emailt sikeresen elküldtük a {0} címre!",
  password_reset_email_sent2: "A jelszava helyreállítását lehetővé tevő emailt sikeresen elküldtük a %{email} címre!",
  confirm_account_deletion: "Biztosan törölni szeretné a fiókját?",
  account_deleted_successfully: "A felhasználói fiókját sikeresen töröltük!",
  bad_password_request_token: "Hibás vagy lejárt jelszó visszaállítási kód! Kérem kérjen újat!",
  password_reset_successfully: "Új jelszó sikeresen beállítva!",
  missing_role: "Hozzáférés megtagadva! Önnek nincs megfelelő jogosultsága!",
  password_forgotten_text: "Add meg az email címedet, amivel regisztráltál. Egy új jelszó beállítását lehetővé tévő emailt küldünk neked.",
  register_first: "Hozzáférés megtagadva! Önnek nincs megfelelő jogosultsága! Kérjük először regisztráljon!",

  login_failed: "A belépés nem sikerült! Rossz email vagy a jelszó!",
  communication_error: "Kommunikációs hiba történt a szerverrel(hibakód: {0})!",

  join: "Csatlakozok",
  occupy: "Foglalok",
  ok: "Ok",
  cancel: "Mégsem",
  apply: "Alkalmaz",

  get_a_table: "Asztalt választok",
  locked_get_a_table_one_table: "Asztal: {0}\nFő: {2}",
  locked_get_a_table_more_tables: "Asztal: {0} db\nFő: {2}",
  joined_get_a_table: "Csatlakoztál\nAsztal: {0}",
  joined_not_authorized_get_a_table: "Csatlakozás jóváhagyásra vár",
  let_other_to_join_your_table: "Csatlakoztass mást is az asztalodhoz",

  the_lead_customer_cannot_be_unapproved: "A szervezőt csatlakozási státuszát nem lehet megváltoztatását!",

  confirmation: "Megerősítés",
  warning: "Figyelmeztetés",
  do_you_want_to_be_the_organizer: "Tényleg te szeretnél lenni a szervező?",
  approvedBy: "{0} jóváhagyta",
  paidBy: "{0} fizet",
  waiting_for_approval_from_somebody: "Jóváhagyásra vársz!",
  your_mate_is_not_intending_to_pay_his_bill: "Az asztaltársad nem kíván fizetni és még nem hívta meg senki!",
  your_mate_intends_to_pay_his_bill: "Az asztaltársad fizetne, de azért nem bánja, ha meghívja valaki!",
  your_mate_wants_to_pay: "Az asztaltársad fizet és nem engedi, hogy meghívják",
  shared_table_tooltip: "A nem lefoglalt helyeket elfoglalhatják mások!",
  request_from: "{0} engedélyt kér!",
  message_from: "{0} üzenete:",
  error_message: "Hibaüzenet",
  warning_message: "Figyelmeztetés",
  question: "Kérdés",
  suggestion: "Javaslat",
  information: "Információ",
  success_message: "Sikeres végrehajtás",
  organizer_request_denied: "Nem engedélyezem hogy te legyél a szervező!",
  organizer_request: "Kérlek engedélyezd, hogy én legyek a szervező!",
  organizer_confirmation: "Mostantól te vagy a szervező!",
  let_me_pay: "Én fizetek helyette",
  approve_joining: "Jóváhagyom a csatlakozását",
  reject_joining: "Elutasítom a csatlakozását",
  reject_paying: "Nem fizetek helyette",
  i_am_paying: "Én akarok fizetni",
  does_not_agree_invite: "{0} nem engedélyezte, hogy meghívják!",
  do_you_want_to_leave: "Tényleg el akarod hagyni ezt azt asztalt?",
  lock_another_table: "Foglalok még egy asztalt",
  unlock_table: "Elhagyom az asztalt",
  scanToAddTable: "Foglalok még egy asztalt",
  scanToChangeTable: "Átülök ehhez az asztalhoz",
  somebody: "Valaki",
  sending: "Jelszó helyreállító e-mail küldése",
  password_reset: "Új jelszó mentése",
  ask_password_reset: "Új jelszó beállítása",
  password_forgotten: "Elfelejtett jelszó",

  message_already_joined: "Már csatlakoztál ehhez az asztalhoz!",
  message_nobody_to_join: "Nincs kihez csatlakoznod ennél az asztalnál!",
  message_nobody_allows_joining: "Jelenleg senki nem engedélyez csatlakozást ennél az asztalnál!",
  message_do_you_want_to_switch_table: "Már csatlakoztál a {0}-es asztalhoz! Át szeretnél ülni az {1}-es asztalhoz?",
  message_do_you_want_: "Az asztalt {0} foglalta le és jóváhagyás után lehet hozzá csatlakozni! Szeretnél csatlakozni?",
  message_do_you_want_to_join_table_without_approval: "Az asztalt foglalt és jóváhagyás nélkül is lehet hozzá csatlakozni! Szeretnél csatlakozni?",
  message_do_you_want_to_join_table_with_approval: "Az asztalt foglalt és csak jóváhagyással lehet hozzá csatlakozni! Szeretnél csatlakozni?",
  massage_you_do_not_want_to_pay: "A beállítások szerint nem szeretne fizetni! Állítson a beállításokon, ha mégis szeretne fizetni!",
  message_do_you_really_want_to_change_table: "Tényleg át akarod mozgatni {0} nevű asztaltársadat a {1}-es asztalhoz?",
  message_do_you_really_want_to_occupy_another_table: "You have ocuppied {0} tables for {1} people! Are you sure you want to occupy another table for {2} people?",
  message_you_can_join_only_one_company: "Már csatlakoztál egy asztalhoz és csak ugyanazon társaság által lefoglalt asztalhoz ülhetsz át!",
  choose_between_joining_or_occupying_a_table: "Le szeretné foglalni ezt azt asztalt vagy csatlakozni szeretne a már itt ülő társasághoz?",
  do_you_really_want_to_occupy_this_shareable_table: "Ennél az asztalnál nem lehetséges a kizárólagos foglalás! Biztosan ezt azt asztalt szeretné lefoglalni?",
  this_table_is_occupied: "Ez az asztal foglalt!",
  this_table_is_occupied_by_multiple_parties_join_directly:
    "Ennél az asztalnál több társaság foglalt helyett! Olvassa le a csatlakozó QR kódot az egyik már csatlakozott résztvevő telefonjáról! Így kiderül melyik társasághoz szeretne csatlakozni!",
  would_you_create_a_picture_as_well: "Készítsen egy fényképet is róla! Így később könnyebb lesz beazonosítani!",
  message_do_you_really_want_to_unlock_table: "Tényleg felszabadítod az asztalt?",
  message_do_you_really_want_to_leave_the_table: "Tényleg elhagyod az asztalt?",
  message_do_you_really_want_to_unlock_table_not_yourself: "Tényleg elhagyja az asztalt?",
  are_you_sure_you_want_to_pay_for_yourself: "Tényleg te szeretnéd fizetni a fogyasztásodat {0} helyett?",
  are_you_sure_you_want_to_pay_instead: "Tényleg te szeretnéd fizetni {1} fogyasztását {0} helyett?",
  are_you_sure_you_want_to_pay_instead_and_more: "Tényleg te szeretnéd fizetni {1} és még {2} ember fogyasztását {0} helyett?",
  reject_invite: "{0} nem engedi hogy te fizesd a fogyasztását!",

  qr_code_generation_failed: "Nem sikerült beolvasni a QR kódot! Megpróbálja mégegyszer?",

  leave_table: "Elhagyom az asztalt",
  leave_the_table: "Elhagyja az asztalt",
  message_table_join_cancelled: "Sikeresen elhagyta az asztalt!",
  message_subprofile_join_cancelled: "{0} sikeresen törölve a résztvevők közül!",
  profileUpdatedSuccessfully: "A profile adatok mentése sikerült",

  new_subprofile: "Új alprofil hozzáadása (pl. a gyerekének)",
  profile_picture: "Profil&nbsp;kép",
  my_favorites: "Kedvenceim",
  favorite_of: "Kedvence&nbsp;vagyok",
  subprofiles: "Alprofiljaim",
  subprofile_tooltip:
    "Létrehozhatsz profilokat olyan embereknek (gyerekeidnek, szüleidnek), akiknek nincs email címük vagy jelenleg nem férnek hozzá az email fiókukhoz!<br/><br/>Így majd számukra is külön rendelhetsz anélkül, hogy ők regisztrálnának a rendszerbe!",
  my_favorites_tooltip: "A <b>megerősített</b> kedvenceidet bármikor hozzáadhatod az asztalaidhoz a hozzájárulásuk nélkül! Hasonlóan ők is csatlakozhatnak azt asztalodhoz az engedélyed nélkül!",
  favorite_of_tooltip: "Akinek <b>megerősített</b> kedvence vagy bármikor hozzáadhat az asztalához a hozzájárulásod nélkül! Hasonlóan te is jóváhagyás nélkül csatlakozhatsz az asztalukhoz!",

  couldNotFindCustomer: "Nem létezik felhasználó a megadott emailhez!",
  subprofileAlreadyUsedAsTableMate: "{0} már részese volt egy eseménynek! Biztosan törölni akarod?",

  favorite_approved_tooltip: "Jóváhagyva",
  favorite_pending_tooltip: "Jáváhagyásra vár",
  placeholder_add_an_email: "Adjon meg egy email címet...",
  placeholder_add_a_name: "Adjon meg egy nevet...",
  subprofileAlreadyExists: "Alprofil már létezik!",
  subprofile: "Alprofil",
  name: "Név",
  do_you_really_want_to_delete_your_favoriteOf: "<b>{0}</b> Önt kedvencének jelölte! Tényleg törölni szeretné?",
  do_you_really_want_to_delete_your_favorite: "Tényleg törölni szeretné a kedvenceid közül <b>{0}</b> nevű ismerősét?",
  do_you_really_want_to_delete_your_subprofile: "Tényleg törölni szeretni az <b>{0}</b> nevű alprofilját?",
  do_you_really_want_to_unjoin_your_subprofile: "Tényleg törölni szeretni az <b>{0}</b> a résztvevők közül?",

  thisEmailIsAlreadyBoundWithAnotherAccount: "Ez az email cím már egy más profilhoz van rendelve!",

  people_tab: "Asztaltársak",
  tables_tab: "Asztalaim",
  table_tab: "Az asztalom",
  settings_tab: "Beállítások",

  paying: "Fizető",
  wants_to_pay: "Fizetni akar",
  i_want_to_pay: "Én fizetem",
  i_do_not_pay: "Nem én fizetem",
  i_could_pay: "Én is fizethetem",
  i_will_pay: "Én fogok fizetni",
  i_am_invited: "Meghívott vagyok",

  paid_for: "Asztaltársaim",
  i_pay_for: "Én állom a fogyasztásukat",
  others: "Mindenki más",
  tableMates: "Még eldöntendő ki fizeti a fogyasztásukat",
  joiningTableMates: "Csatlakozni szeretnének",
  your_joined_tables: "Az asztal amelyhez csatlakoztam",
  your_tables: "Az asztalaim",

  add_sub_profile: "Alprofil hozzáadása",
  add_participant: "Új résztvevő(k) hozzáadása",
  close: "Bezár",
  scan_to_join_table: "{0}. asztal. Szkenneld, hogy csatlakozz!",
  add_additional_participants_as_subprofiles: "Alprofilok",
  add_additional_participants_without_table: "Asztal nélküli résztvevők",
  add_mate: "Hozzáadom",

  table_picker_title: "Válassz egy asztalt!",
  my_table_picker: "{0}. asztal (itt ülök)",
  table_picker: "{0}. asztal",
  my_mate_picker: "{0} (én)",
  mate_picker: "{0}",
  everybody: "Mindenki ({0})",
  allTables: "Összes asztal",
  i_pay_for2: "Én fizetem ({0})",

  order: "Rendelek",
  orderMeals: "Ételek",
  orderDrinks: "Italok",
  unknown: "???",
  byCustomer: "Személyenként",
  byDrinkOrMeal: "Étel/ital szerint",
  byDrink: "Ital szerint",
  byMeal: "Étel szerint",

  do_you_really_want_to_delete_all_new_orders: "Tényleg ki szeretné törölni a kosarat?",
  do_you_really_want_to_delete_all_orders: "Tényleg ki szeretné törölni az összes kiválasztott még nem visszaigazolt tételt?",

  please_choose_mate_for_this_order: "{0} {1} {2} - Kiknek rendeli?",
  select: "Kiválaszt",

  order_items: "Darabszám:",
  people_count: "Személyek:",

  do_you_really_want_to_submit_all_new_orders: "Tényleg megrendeli az összes kiválasztott tételt?",

  show_table_qr: "Asztal társaság szervezése",

  seats_wanted: "Hány személyre:",

  placeholder: "Helyfoglalás",
  my_profile: "Profilom",

  unlocking_not_possible_there_are_pending_orders: "Nem szabadíthatja fel az asztalt míg fizetetlen rendelések vannak!",
  unjoining_somebody_not_possible_there_are_pending_orders: "Nem hagyhatja el az asztalt míg futó rendelései vannak, amit ő fizet!",
  unjoining_yourself_not_possible_there_are_pending_orders: "Nem hagyhatja el az asztalt míg futó rendelései vannak, amit te fizetsz!",
  unjoining_somebody_not_possible_paying_for_others: "Nem hagyhatja el az asztalt míg ő fizet mások helyett is!",

  comment: "Megjegyzés",
  enter_comment: "Írjon megjegyzést (opcionális)",
  this_item_was_originally_ordered_by: "Ezt a tételt eredetileg {0} rendelte meg!",
  this_item_was_ordered_by: "Ezt a tételt {0} választotta ki meg!",
  cancelled: "Törölt",
  selected: "Kiválasztott",
  ordered: "Megrendelt",
  accepted: "Visszaigazolt",
  producing: "Folyamatban",
  delivering: "Kiszállítás",
  delivered: "Kiszállítva",
  paid: "Fizetve",

  rejection_reason: ["Hibás felütés", "Nem lehetséges", "Elfogyott", "Nincs a kinálatban", "Nem készült el", "Túl későn készült el"],

  timing: "Időzítés",
  timing_inherit: "Örököl",
  timing_asap: "Előre",
  timing_appetizer: "Előétel-Leves",
  timing_maincourse: "Köztes",
  timing_desert: "Főétel",
  timing_last: "Desszert",

  for_whom: "Kinek",

  unknown_person: "{0}. személy",

  additions: "További összetevők, feltétek",
  enter_additions: "Válasszon további összetevőket vagy feltéteket és variálja italát, ételét",
  inherited_additions: "Örökölt összetevők, opciók",
  noResultsMessageText: 'Nincs találat a "%s"-ra',
  options: "Opciók",

  reload_page: "Frissítés",
  timeout: "Időtúllépés",
  server_unavailable: "Kommunikációs hiba a szerverrel! Elképzelhető, hogy a szerver újraindul. Ebben az esetben a legjobb, ha vár egy-két percet, ugyanis a kapcsolat általában magától helyreáll!",
  error_code_500: "Server hiba",
  error_code_400: "Hibás művelet",
  continue: "Hagyja figyelmen kívül és folytassa",
  modify: "Módosítás",
  delete: "Töröl",
  order_cannot_be_deleted: "A rendelés már nem törölhető",
  orders_only_in_selected_or_ordered_state_can_be_deleted: "Csak a 'kiválasztott' vagy 'megrendelt' állapotú megrendelések törölhetőek!",
  left_the_table: "elhagyta azt asztalt",
  deleted_or_not_found: "Törölt vagy hiányzó",

  qr_code: "QR kód",
  person: "Személy",

  do_you_really_want_to_quit_pending_orders: "Tényleg ki akar lépni? Függő rendelések vannak!",
  quantity: "Mennyiség",
  paying_online: "Online fizet...",

  feedback: {
    label: "Visszajelzés",
    header: "Visszajelzés",
    nextLabel: "Tovább",
    reviewLabel: "Véglegesítés",
    sendLabel: "Küldés",
    closeLabel: "Bezár",
    messageSuccess: "Az Ön visszajelzését sikeresen elküldtük!",
    messageError: "A visszejelzés küldése közben hiba történt!",
    pleaseDescribe: "Kérlem írja le a problémáját",
    issue: "A probléma",
    blackout: "Kitakar",
    highlight: "Kiemel",
    highlight_or_blackout: "Emelje ki vagy takarja ki a fontos részleteket"
  },

  help: {
    next: "Következő",
    back: "Vissza",
    done: "Kész",
    skip: "Kihagy"
  },

  nameformat: "{0} {1}",
  service_fee: "Felszolgálási díj",

  daysOfWeek: ["Va", "Hé", "Ke", "Sze", "Csü", "Pén", "Szo"],
  monthNames: ["Jan", "Feb", "Már", "Ápr", "Máj", "Jun", "Júl", "Aug", "Sze", "Okt", "Nov", "Dec"],
  server_restart: "A szerver frissítés alatt van! Kérjük várjon türelemmel, míg a szerver újraindul és a kapcsolat magától helyreáll",
  email_not_associated_with_an_account: "A megadott email-hez nem tartozik fiók!",
  the_chosen_restauarant_online_services_seems_to_be_unavailable: "{0} jelenleg nem elérhető!",
  phone: "Telefonszám",
  address: "Cím",
  town: "Helyiség",
  country: "Ország",
  postcode: "Irányítószám",
  allDay: "egész nap",
  download_progress: "Frissítés letöltése ({0}%)",
  installing_update: "Frissítés telepítése",
  new_version_is_available: "Az applikáció új verziója érhető el!",
  applelogin: "Belépés Apple-lel",
  facebooklogin: "Bejelentkezés Facebook-kal",
  door: "Emelet/Ajtó",
  consent_successfull: "Hozzájárulását köszönjük!",
  update_successfull: "Adatait sikeresen frissítettük",
  consent_withdrawal_successfull: "Ön visszavonta hozzájárulását adatai kezeléséhez! Személyes adatait töröltük a rendszereinkből!",
  switch_back_to_live: "Az fő szerver újra elérhető! Átkapcsolunk a fő szerverre!",
  switch_to_backup: "Az fő szerver jelenleg nem elérhető! Átkapcsolunk a tartalék szerverre!",
  access_denied: "Az belépés sikeres, azonban jelenleg nincs hozzáférése egy környezethez sem. Kérjük írjon az support@eatwithme.online email címre, ha úgy gondolja, hogy hiba történt.",
  pay_later: "Mégsem",
  activation_code: "Aktivációs kód",
  bad_activation_code: "Lejárt vagy rossz aktivációs kód",
  activate: "Aktivál",
  activation: "Aktiválás",
  save_update_address: "Mentés és cím frissítése",
  no_internet: "Nincs internet kapcsolat!",
  request: "Kérés",
  please_perform_ntak_registration: "Kérem végezze el az NTAK adminisztrációt!",
};
export default local_hu;
