/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
/* eslint-disable no-useless-escape */
import $ from "jquery";
import orderListUtil, { playClickSound, processOrders, tmplparams } from "./order-list-util";
import admin, { getMenuCategory, updatePrices } from "./admin";
import { I18n } from "react-redux-i18n";
import menuitemutil, { populateEditCategory, populateEditMenuItem } from "./admin/menuitem";
import { updateLeftTabs } from "./tables-overview";
import { select, getLocale, touchStart, featureEnabled } from "./auth";
import moment from "moment";

/*
if (typeof menuitemutil != "undefined" && typeof menuitemutil.getLanguage=="undefined")
  menuitemutil.getLanguage = function() {return undefined}
*/

// eslint-disable-next-line no-unused-vars
export const onsave = () => {
  admin.getActiveMenuItems(function (cats) {
    admin.categories = cats;
    updatePrices(admin.categories);
    processOrders(orderListUtil.ordersCache);
    var visibilityMealStatus = $("#meal_menu .selectable");
    var visibilityDrinkStatus = $("#drink_menu .selectable");
    $("#drink_menu .selectable").remove();
    $("#meal_menu .selectable").remove();
    $("#timingSelector").replaceWith($("#timingSelectorTemplate").tmpl(tmplparams()));
    admin.categories.activeMenuCategories.forEach(function (category) {
      if (category.entityType == "DrinkCategory" && category.isTop)
        $("#categoryTemplate")
          .tmpl({ ...category, ...tmplparams() })
          .appendTo("#drink_menu");
    });
    admin.categories.activeMenuCategories.forEach(function (category) {
      if (category.entityType == "MealCategory" && category.isTop)
        $("#categoryTemplate")
          .tmpl({ ...category, ...tmplparams() })
          .appendTo("#meal_menu");
    });
    $(".menuItemHead").each((ind, e) => e.addEventListener("touchstart", touchStart, {
      capture: true,
      passive: false
    }));
    $(".list-group-item.defaultQuantity").each((ind, e) => e.addEventListener("touchstart", touchStart, {
      capture: true,
      passive: false
    }));

    visibilityMealStatus.each(function (ind, e) {
      var id = $(e).attr("id");
      var clazz = $(e).attr("class");
      $("#" + id).attr("class", clazz);
    });
    chooseMenuItems.attacheContextMenus();
  }, localStorage.language);
};

const chooseMenuItems = {
  contextmenu: false,
  attacheContextMenus: function () {
    $.contextMenu({
      selector: ".menuItemCategory",
      events: {
        show: function (options) {
          chooseMenuItems.contextmenu = true;
        },
        hide: function (options) {
          chooseMenuItems.contextmenu = false;
        }
      },
      build: function ($trigger, e) {
        var items = {};
        items["edit"] = {
          name: I18n.t("admin_local.edit"),
          icon: "edit"
        };
        return {
          callback: function (key, options) {
            chooseMenuItems.createTemplates();

            switch (key) {
              case "edit":
                var modal = $("#editMenuCategory").modal("show");
                var id = $trigger.attr("id").split("-");
                id = id[id.length - 1];
                admin.getMenuCategoryById(id, function (data) {
                  orderListUtil.entityType = undefined;
                  orderListUtil.entitytype = undefined;
                  populateEditCategory(modal, "edit", data, data.menuCategoryId);
                });
                break;
            }
          },
          items: items
        };
      }
    });
    $.contextMenu({
      selector: ".menuItem",
      events: {
        show: function (options) {
          chooseMenuItems.contextmenu = true;
        },
        hide: function (options) {
          chooseMenuItems.contextmenu = false;
        }
      },
      build: function ($trigger, e) {
        var items = {};
        items["edit"] = {
          name: I18n.t("admin_local.edit"),
          icon: "edit"
        };
        return {
          callback: function (key, options) {
            chooseMenuItems.createTemplates();

            switch (key) {
              case "edit":
                var modal = $("#editMenuItem").modal("show");
                var id = $trigger.attr("menuitem");
                admin.getMenuItemById(id, function (data) {
                  orderListUtil.entityType = undefined;
                  orderListUtil.entitytype = undefined;
                  populateEditMenuItem(modal, "edit", data, data.menuCategoryId);
                });
                break;
            }
          },
          items: items
        };
      }
    });
  },

  createTemplates: function () {
    var menu = $("#right_pane .tab-pane.menu");
    console.log("chooseMenuItem.js");
    if (menu.attr("id") == "drink_menu_tab") {
      //utils = drinkutils;
      orderListUtil.entityType = "Drink";
      orderListUtil.entitytype = "drink";
    } else {
      //utils = mealutils;
      orderListUtil.entityType = "Meal";
      orderListUtil.entitytype = "meal";
    }
    $("div#editMenuItem").remove();
    $("div#editMenuCategory").remove();
    if ($("div#editMenuCategory").length == 0) {
      $("script#editMenuCategoryTemplate")
        .tmpl({
          onsave: "onsave",
          webshopEnabled: featureEnabled('ordering/enabled') && ((featureEnabled('pickup/enabled') && featureEnabled('pickup/webshop')) || (featureEnabled('homedelivery/enabled') && featureEnabled('homedelivery/webshop'))),
          entityType: orderListUtil.entityType,
          ...tmplparams()
        })
        .appendTo("body");
      $("script#editMenuItemTemplate")
        .tmpl({
          onsave: "onsave",
          webshopEnabled: featureEnabled('ordering/enabled') && ((featureEnabled('pickup/enabled') && featureEnabled('pickup/webshop')) || (featureEnabled('homedelivery/enabled') && featureEnabled('homedelivery/webshop'))),
          entityType: orderListUtil.entityType,
          ...tmplparams()
        })
        .appendTo("body");
      $('#editMenuItem').on('hidden.bs.modal', () => {
        $("div#editMenuItem").remove();
        $("div#editMenuCategory").remove();
      });
      $('#editMenuCategory').on('hidden.bs.modal', () => {
        $("div#editMenuItem").remove();
        $("div#editMenuCategory").remove();
      });
      var options = {
        placeholder: I18n.t("local.enter_additions"),
        tokensAllowCustom: true,
        searchMinLength: 0,
        dataSource: function (term, object) {
          object.trigger("tokenize:dropdown:fill", [menuitemutil.getMenuItems(term)]);
        },
        displayNoResultsMessage: true,
        noResultsMessageText: I18n.t("local.noResultsMessageText"),
        dropdownMaxItems: 7,
        searchFromStart: false
      };
      $("div#editMenuItem #item_additions_mandatory").tokenize2(options);
      $("div#editMenuItem #item_additions_included").tokenize2(options);
      $("div#editMenuItem #item_additions_optional").tokenize2(options);

      $("div#editMenuCategory #category_additions_mandatory").tokenize2(options);
      $("div#editMenuCategory #category_additions_included").tokenize2(options);
      $("div#editMenuCategory #category_additions_optional").tokenize2(options);
      menuitemutil.hooks();
    }
  },

  status: "normal",

  maximize: function (item) {
    if ($(item).hasClass("icon-maximize")) {
      chooseMenuItems.status = "maximized";
      $("#left_pane").hide();
      $(item).removeClass("icon-maximize");
      $(item).addClass("icon-minimize");
      $("#right_pane").css("height", "100%");
      $("#right_pane").css("width", "100%");
    } else {
      chooseMenuItems.status = "normal";
      $("#left_pane").css("display", "");
      updateLeftTabs();
      $(item).removeClass("icon-minimize");
      $(item).addClass("icon-maximize");

      var orientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      if (orientation == "landscape") {
        if (typeof localStorage.table_overview_right_pane != "undefined") {
          $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
          $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
        } else {
          localStorage.table_overview_right_pane = 60;
          $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
          $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
        }
        $("#left_pane").css("height", null);
        $("#right_pane").css("height", null);
      } else {
        if (typeof localStorage.table_overview_right_pane_height != "undefined") {
          $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
          $("#left_pane").css("height", "calc(100% - " + localStorage.table_overview_right_pane_height + "%)");
        } else {
          localStorage.table_overview_right_pane = 60;
          $("#left_pane").css("height", "calc(100% - " + localStorage.table_overview_right_pane_height + "%)");
          $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
        }
        $("#left_pane").css("width", null);
        $("#right_pane").css("width", null);
      }
    }
  },

  minimize: function (item) {
    if ($(item).hasClass("icon-maximize")) {
      chooseMenuItems.status = "minimized";
      $("#right_pane").hide();
      $(item).removeClass("icon-maximize");
      $(item).addClass("icon-minimize");
      $("#left_pane").css("height", "100%");
      $("#left_pane").css("width", "100%");
    } else {
      chooseMenuItems.status = "normal";
      $("#right_pane").css("display", "");
      updateLeftTabs();
      $(item).removeClass("icon-minimize");
      $(item).addClass("icon-maximize");

      var orientation = window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      if (orientation == "landscape") {
        if (typeof localStorage.table_overview_right_pane != "undefined") {
          $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
          $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
        } else {
          localStorage.table_overview_right_pane = 60;
          $("#left_pane").css("width", "calc(100% - " + localStorage.table_overview_right_pane + "%)");
          $("#right_pane").css("width", localStorage.table_overview_right_pane + "%");
        }
        $("#left_pane").css("height", null);
        $("#right_pane").css("height", null);
      } else {
        if (typeof localStorage.table_overview_right_pane_height != "undefined") {
          $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
          $("#left_pane").css("height", "calc(100% - " + localStorage.table_overview_right_pane_height + "%)");
        } else {
          localStorage.table_overview_right_pane = 60;
          $("#left_pane").css("height", "calc(100% - " + localStorage.table_overview_right_pane_height + "%)");
          $("#right_pane").css("height", localStorage.table_overview_right_pane_height + "%");
        }
        $("#left_pane").css("width", null);
        $("#right_pane").css("width", null);
      }
    }
  }
};

var searchActive = false;
var lastSearch = "";
export const showMenuItems = search => {
  if (typeof search === "undefined" || typeof search !== "string") {
    search = $("#editTable #right_pane input#search:visible").val();
  }
  var search = search;
  var menu = $("#right_pane .tab-pane.menu");
  if (lastSearch == search) return;
  lastSearch = search
  if (search == "") {
    if (searchActive) {
      $("#right_pane ul").show();
      menu.removeClass("active");

      var tab_content = $("#right_pane .tab-content");
      tab_content.removeClass("selectable");
      tab_content.removeClass("selectedTop");
      tab_content.removeClass("multiple");
      tab_content.removeClass("single");
      menu.attr("level", "0");
      chooseMenuItems.menuTabVisible.addClass("active");
      chooseMenuItems.menuTabVisible.addClass("selectedTop");
      searchActive = false;
      $(menu.find(".menuItemCategory")).addClass("hidden");
      $(menu.find(".menuItem")).addClass("hidden");
      $(menu.find(".menuItemVariation")).addClass("hidden");
      chooseMenuItems.menuItemCategoriesVisible.removeClass("hidden");
      chooseMenuItems.menuItemsVisible.removeClass("hidden");
      //chooseMenuItems.removeClass("hidden");
      // $(menu.find('.tab-pane.menu
      // .fakeMenuItemCategory')).addClass('hidden');
    }
    return;
  }
  if (!searchActive) {
    chooseMenuItems.menuItemCategoriesVisible = $(menu.find(".menuItemCategory:not('.hidden')"));
    chooseMenuItems.menuItemsVisible = $(menu.find(".menuItem:not('.hidden')"));
    chooseMenuItems.menuItemVariationsVisible = $(menu.find(".menuItemVariation:not('.hidden')"));
    chooseMenuItems.menuTabVisible = $("#right_pane .tab-pane.menu.active");
    var tab_content = $("#right_pane .tab-content");
    tab_content.addClass("selectable");
    tab_content.addClass("selectedTop");
    menu.attr("level", "1");
    menu.removeClass("selectedTop");
    $(menu.find(".menuItemCategory")).addClass("hidden");
    // $(menu.find('.fakeMenuItemCategory')).removeClass('hidden');
  }
  searchActive = true;
  $(menu.find(".menuItem")).addClass("hidden");
  var c1, c2, c3, c4;
  search.split(" ").forEach((search, ind) => {
    var search2 = search.replace(/ö/g, '0').replace("`", "0");
    if (ind == 0) {
      c1 = $(menu.find(".menuItem[entityType='Drink'] span:contains('" + search + "')")).parents(".menuItem");
      c3 = $(menu.find(".menuItem[entityType='Drink'] div.barcode[value = '" + search2 + "']")).parents(".menuItem");
      c2 = $(menu.find(".menuItem[entityType='Meal'] span:contains('" + search + "')")).parents(".menuItem");
      c4 = $(menu.find(".menuItem[entityType='Meal'] div.barcode[value = '" + search2 + "']")).parents(".menuItem");
    } else {
      c1 = $(c1.find("span:contains('" + search + "')")).parents(".menuItem");
      c3 = $(c3.find("div.barcode[value = '" + search2 + "']")).parents(".menuItem");
      c2 = $(c2.find("span:contains('" + search + "')")).parents(".menuItem");
      c4 = $(c3.find("div.barcode[value = '" + search2 + "']")).parents(".menuItem");
    }
  })

  $(c1).removeClass("hidden");
  $(c2).removeClass("hidden");
  $(c3).removeClass("hidden");
  $(c4).removeClass("hidden");
  if (c1.length + c2.length + c3.length + c4.length == 1) {
    //initiate order menu as there is only one choice
    if (c1.length == 1) c1.find('.menuItemHead').click();
    else if (c2.length == 1) c2.find('.menuItemHead').click();
    else if (c3.length == 1) c3.find('.menuItemHead').click();
    else c4.find('.menuItemHead').click();
    return;
  }
  menu.removeClass("active");
  var tab_content = $("#right_pane .tab-content");
  if (c1.length + c3.length == 0 || c2.length + c4.length == 0) {
    $("#right_pane ul").hide();
    tab_content.removeClass("multiple");
    tab_content.addClass("single");
    if (c1.lenght + c3.lenght > 0) $("#right_pane .tab-pane.drink").addClass("active");
    else $("#right_pane .tab-pane.meal").addClass("active");
  } else {
    $("#right_pane ul").show();
    tab_content.addClass("multiple");
    tab_content.removeClass("single");
  }
  var tabpane = $(menu.find('.menuItem:not(".hidden")')).parents(".tab-pane");
  tabpane.addClass("active");
  if (c1.length > 0) select(c1[c1.length - 1]);
  else if (c3.length > 0) select(c3[c3.length - 1]);
  else if (c2.length > 0) select(c2[c2.length - 1]);
  else select(c4[c4.length - 1]);
};

export const gotoTop = () => {
  if (searchActive == true) return;

  $("#editTable").removeClass("calendar-view");
  $("#editTable .day").addClass("hidden");
  $("#editTable .week").remove();

  $(".search").val("");
  var menu = $(".tab-pane.menu.active");
  menu.removeClass("categorySelected");
  $(menu.find(".menuItemCategory")).removeClass("selected2");
  $(menu.find(".menuItemCategory")).removeClass("selected");
  $(menu.find(".menuItemCategory")).addClass("hidden");
  $(menu.find(".menuItem")).addClass("hidden");
  $(menu.find(".menuItemCategory[level='1']")).removeClass("hidden");
};

export const showMenuItemCategory = id => {
  //categoryShown = id;

  $("#editTable").removeClass("calendar-view");
  $("#editTable .day").addClass("hidden");
  $("#editTable .week").remove();

  const cid = id.split("-").slice(-1).pop();

  const menuCategory = getMenuCategory(cid);

  const containsCalendarItems = (menuCategory.activeMenuItems ? menuCategory.activeMenuItems : menuCategory.children).filter(m => m.defaultFromDate).length > 0;

  var menu = $(".tab-pane.menu.active");
  if ($(menu.find("#" + id + ".menuItemCategory")).hasClass("selected2")) {
    $(menu.find("#" + id + ".menuItemCategory")).removeClass("selected2");
    $(menu.find(".menuItemCategory")).addClass("hidden");
    $(menu.find(".parentCategory-" + id)).addClass("hidden");
    //$(menu.find("#" + id + ".menuItemCategory")).find('img').show();
    id = id.substring(0, id.lastIndexOf("-"));
    $(menu.find(".parentCategory-" + id)).removeClass("hidden");
    $(menu.find("#" + id + ".menuItemCategory")).removeClass("hidden");
  } else {
    $(menu.find(".menuItemCategory")).addClass("hidden");
    $(menu.find(".menuItem")).addClass("hidden");
    $(menu.find(".menuItem.parentCategory-" + id)).removeClass("hidden");
    $(menu.find(".menuItemCategory.parentCategory-" + id)).removeClass("hidden");
    $(menu.find("#" + id + ".menuItemCategory")).addClass("selected2");
    $(menu.find("#" + id + ".menuItemCategory")).removeClass("hidden");

    if (containsCalendarItems) {
      $("#editTable").addClass("calendar-view");
    }


    const week = [];

    if (containsCalendarItems)
      $("#editTable .day").removeClass("hidden");

    const weeks = [];
    $(".menuItem:not(.hidden)").each((ind, item) => {
      const i = $(item).attr("column");
      const w = $(item).attr("week");
      const weekm = moment(Number($(item).attr("date"))).startOf("week").week();
      if (weeks.indexOf(weekm) === -1) weeks.push(weekm);
    });

    weeks.sort((a, b) => a - b);

    var shift = 0;
    for (let weekm in weeks) {
      var maxind = 0;
      for (let i = 1; i < 8; i++) {
        const w = weeks[weekm];
        const week2 = [];

        $(".menuItem[column='" + i + "'][week = '" + w + "']:not(.hidden)").each((ind, item) => {
          if (week.indexOf(w) == -1) {
            const ww = $("<div class='week'>" + moment(Number($(item).attr("date"))).startOf("week").format("MMMM-DD") + "</div>");
            ww.insertBefore($(item));
            week.push(w);
            week2.push(w);
            ww.css("grid-row-start", "" + (shift + ind + 3));
            ww.css("grid-row-end", "" + (shift + ind + 4));
            shift++;
          } else if (week2.indexOf(w) == -1) {
            week2.push(w);
            //shift++;
          }

          $(item).css("grid-row-start", "" + (shift + ind + 3));
          $(item).css("grid-row-end", "" + (shift + ind + 4));
          $(item).css("grid-column-start", "" + (i));
          $(item).css("grid-column-end", "" + (i + 1));
          maxind = Math.max(maxind, ind);
        })
      }
      shift += maxind + 1;
    }

  }
  if (menu.find(".menuItemCategory.selected2").length > 0) menu.addClass("categorySelected");
  else menu.removeClass("categorySelected");
};

export const getAvailableQuantityLabel = (menuItem, q) => {
  var quantityType = /*o.quantityType ? o.quantityType : */ menuItem.quantityType;
  if (quantityType == "undefined" || quantityType == "" || quantityType == "none") return "";
  // if (o.quantity == 1 && quantityType == 'pcs')
  // return '';
  var quantity = q.quantity;
  var quantityLabel = getLocale(q?.quantityType?.name, menuitemutil.getLanguage());
  if (quantityLabel == "N/A") quantityLabel = "";
  if (quantityLabel != "" && menuItem.entityType == "Meal") {
    return quantityLabel;
  } else {
    var v = quantityLabel != "" ? quantityLabel + " " : "";
    v += Math.abs(quantity) == 1 && quantityType == "pcs" ? "" : "(" + (quantity + (Math.abs(quantity) == 1 && quantityType == "pcs" ? "" : quantityType == "" ? "" : " " + I18n.t("local." + quantityType))) + ")";
    return v;
  }
};

export default chooseMenuItems;
