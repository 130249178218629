import React, { Component } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getEntitiesContaining } from "../js/superadmin/superadmin";

class RestaurantAutoComplete extends Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: [],
    filterBy: []
  };

  render() {
    return (
      <AsyncTypeahead
        {...this.state}
        id="RestaurantAutoComplete"
        labelKey="name"
        emptyLabel="No matches found"
        minLength={3}
        onSearch={this._handleSearch}
        placeholder="Search for a restaurant..."
        onChange={this.props.onChange}
        renderMenuItemChildren={(option, props) => {
          console.log(option);
          return <span key={Number(option.id)}>{option.name}</span>;
        }}
      />
    );
  }

  _handleSearch = query => {
    this.setState({ isLoading: true });
    const t = this;
    getEntitiesContaining("Restaurant", query, options => {
      options.forEach(o => (o.id = "" + o.id));
      t.setState({
        isLoading: false,
        options: options
      });
    });
  };
}

export default RestaurantAutoComplete;
