import React from "react";
import { booleanToCheckboxReadonly } from "../../renderers/tablecellrenderers";
import { getAllRestaurants } from "../../js/superadmin/superadmin";
import { Translate, I18n } from "react-redux-i18n";
import Actions from "../../actions/EatWithMeActions";
import { flattenObject } from "../../components/utils";
import { restaurantGridStateKey } from "../../reducers/rootReducer";
import EatWithMeActions from "../../actions/EatWithMeActions";
import auth from "../../js/auth";
import { featureCategories } from "../../js/restaurant_settings_schema.js";

export const stateKey = restaurantGridStateKey;

const stateful = false;

function getColumns(admin_local) {
  return [
    {
      name: <Translate value="admin_local.status" />,
      dataIndex: "registrationTimestamp",
      sortable: true,

      width: "40px",
      className: "additional-class",
      expandable: true,
      renderer: props => {
        var online = new Date().getTime() - props.value < 800000;
        return online ? <i style={{ color: "green" }} className='icon-ok-circled' /> : <i style={{ color: "red" }} className='icon-cancel-circled' />;
      }
    },
    {
      name: <Translate value="admin_local.active" />,
      dataIndex: "isActive",
      sortable: true,

      readOnly: true,
      renderer: booleanToCheckboxReadonly,
      width: "40px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.test" />,
      dataIndex: "test",
      sortable: true,

      readOnly: true,
      renderer: booleanToCheckboxReadonly,
      width: "40px",
      className: "additional-class",
      expandable: true
    },
    {
      name: "K8s.live",
      dataIndex: "kubernetesLive",
      sortable: true,

      readOnly: true,
      renderer: booleanToCheckboxReadonly,
      width: "40px",
      className: "additional-class",
      expandable: true
    },
    {
      name: "K8s.backup",
      dataIndex: "kubernetesBackup",
      sortable: true,

      readOnly: true,
      renderer: booleanToCheckboxReadonly,
      width: "40px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.image" />,
      dataIndex: "id",
      sortable: true,

      width: "40px",
      className: "additional-class",
      expandable: true,
      renderer: props => {
        return null;
      }
    },
    {
      name: <Translate value="admin_local.name" />,
      dataIndex: "name",
      sortable: true,

      width: "150px",
      "minColumnWidth": "100px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.subscription" />,
      dataIndex: "subscription",
      sortable: true,

      width: "150px",
      "minColumnWidth": "100px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.address" />,
      dataIndex: "address",
      sortable: true,

      width: "60%",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.town" />,
      dataIndex: "town",
      sortable: true,

      width: "100px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.country" />,
      dataIndex: "country",
      sortable: true,

      width: "100px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.helm_tag" />,
      dataIndex: "helmtag",
      sortable: true,

      width: "100px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.helm_set" />,
      dataIndex: "helmset",
      sortable: true,

      width: "100px",
      className: "additional-class",
      expandable: true
    },
    /*
    {
      name: <Translate value="admin_local.languages" />,
      dataIndex: "languages",
      sortable: true,

      width: "40px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.currency" />,
      dataIndex: "baseCurrency.name",
      sortable: true,

      width: "40px",
      className: "additional-class",
      expandable: true
    },*/
    ...getFeatures(),
    {
      name: <Translate value="admin_local.server_url" />,
      dataIndex: "serverUrl",
      sortable: true,

      width: "400px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.backup_url" />,
      dataIndex: "backupUrl",
      sortable: true,

      width: "400px",
      className: "additional-class",
      expandable: true
    },
  ];
}

function getFeatures() {
  const columns = [];
  Object.keys(featureCategories([], auth.myStatus)).forEach((feature, ind) => {
    //console.log(feature);
    columns.push({
      name: feature,
      dataIndex: "settings.enabled-features." + feature + ".enabled",
      sortable: true,

      width: "40px",
      className: "additional-class",
      expandable: true,
      readOnly: true,
      renderer: booleanToCheckboxReadonly,
    })
  })
  //console.log(columns)
  return columns;
}

const restaurantConfig = (props, store) => {
  const { admin_local } = props;
  return {
    store: store,
    reducerKeys: "nested",

    gridType: "grid",
    pageSize: 20,

    stateKey: stateKey,

    stateful: stateful,

    columns: getColumns(admin_local),

    height: false,

    dataSource: function getData({ pageIndex, pageSize }) {
      return new Promise(resolve => {
        getAllRestaurants(data => {
          data.restaurants.forEach(d => (d.key = d.id));
          data.restaurants.push(EatWithMeActions.addNewRestaurant({ stateKey }).row);
          const d = data.restaurants.map(r => {
            var t = flattenObject(r);
            t.originalRecord = r;
            return t;
          });
          //console.log(d);
          resolve({ data: d });
        });
      });
    },

    events: {
      HANDLE_ROW_CLICK: (row, reactEvent, id, browserEvent) => {
        //const r = { ...unflattenObject(row.row) };
        const r = row.row.originalRecord;
        if (r.baseCurrency) r.baseCurrency = r.baseCurrency.name;
        store.dispatch(Actions.restaurantSelected(r));
      }
    },

    plugins: {
      COLUMN_MANAGER: {
        resizable: true,
        moveable: true,
        //minColumnWidth: 100,
        /*
      sortable: {
        enabled: true,
        method: "local",
        sortingSource: "http://react-redux-grid.herokuapp.com/getfakeData"
      }*/
      },
      STICKY_HEADER: {
        enabled: true
      },
      STICKY_FOOTER: {
        enabled: true
      },
      PAGER: {
        enabled: true,
        pagingType: "remote",
        recordType: I18n.t("admin_local.restaurant"),
        toolbarRenderer: function toolbarRenderer(pageIndex, pageSize, total, currentRecords, recordType) {
          if (!currentRecords) {
            return <Translate value="admin_local.Grid.Pager.Description.no_records" recordType={recordType} />;
          }

          var firstIndex = pageIndex * pageSize + 1;
          var lastIndex = pageIndex * pageSize + currentRecords;
          return <Translate value="admin_local.Grid.Pager.Description.records_displayed" firstIndex={firstIndex} lastIndex={lastIndex} total={total} recordType={recordType} />;
        }
      },
      LOADER: {
        enabled: false
      },
      SELECTION_MODEL: {
        mode: "checkbox-multi",
        enabled: false,
        allowDeselect: true,
        activeCls: "active",
        selectionEvent: "singleclick",
        editEvent: "none"
      },
      ERROR_HANDLER: {
        defaultErrorMessage: "AN ERROR OCURRED",
        enabled: true
      },
      BULK_ACTIONS: {
        enabled: false,
        actions: [
          {
            text: "Move",
            EVENT_HANDLER: () => { }
          },
          {
            text: "Add",
            EVENT_HANDLER: () => { }
          }
        ]
      },
      GRID_ACTIONS: {
        iconCls: "action-icon",
        menu: [
          {
            text: I18n.t("admin_local.delete"),
            key: "delete",
            EVENT_HANDLER: ({ metaData }) => {
              store.dispatch(EatWithMeActions.deleteRow({ row: metaData.rowData, rowIndex: metaData.rowIndex, stateKey: stateKey }));
            }
          },
          {
            text: I18n.t("admin_local.delete_order_history"),
            key: "delete_orders",
            EVENT_HANDLER: ({ metaData }) => {
              store.dispatch(EatWithMeActions.deleteOrderHistory({ row: metaData.rowData, rowIndex: metaData.rowIndex, stateKey: stateKey }));
            }
          },
        ]
      }
    }
  };
};
export default restaurantConfig;
