import $ from "jquery";
import admin, { clearSelect2Cache } from "../admin";
import { startpoll, confirmDialog } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

class TablesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveTable', 'deleteTable', 'activateTable', 'saveRoom', 'deleteRoom', 'activateRoom']);
	}

	ready = () => {

		clearSelect2Cache();

		$('#main').replaceWith($('#tablesMainTemplate').tmpl({
			...tmplparams()
		}));

		this.loadTables();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();

		admin.initializeSelect2($('select#forStore.select2'));

		$('input#extensions').keyup(event => {

		})

	}

	reload = () => {
		this.loadTables();
	}

	loadTables = () => {
		$('div#editTableDetails').modal('hide');

		admin.getTables(tables => {
			admin.getRooms(rooms => {
				this.processTables(rooms, tables);
			});
		});
	}

	serverSideMessageHandler = (data) => {
	}


	tables = null;
	processTables = (rooms, roomtables) => {

		this.roomtables = roomtables;
		this.rooms = rooms;

		const instance = this;
		var root = [];

		var room = {};
		room.t = "room";
		//room.folder=true;
		room.children = roomtables.restaurantTables.filter(t => !t.room);
		room.key = "room-0";
		room.id = "room-0";
		room.title = "";
		room.name = "---";
		room.children.forEach(table => {
			table._type = table.type;
			table.key = "table-" + table.id;
			table.id = "table-" + table.id;
			table.t = "table";
			table.title = "";
		});
		root.push(room)
		/*
		roomtables.restaurantTables.forEach(function (v, i) {
			v._type = v.type;
			v.title = "";
			//v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
			v.t = "table";
			v.id = "table-" + v.id;
			v.key = "table-" + v.id;
			if (!v.room && !v.roomId)
				root.push(v);
		});
		*/
		rooms.sort((a, b) => a.order - b.order);
		rooms.forEach(room => {
			room.t = "room";
			//room.folder=true;
			room.children = room.tables;
			room.key = "room-" + room.id;
			room.id = "room-" + room.id;
			room.title = "";
			room.tables.forEach(table => {
				table._type = table.type;
				table.key = "table-" + table.id;
				table.id = "table-" + table.id;
				table.t = "table";
				table.title = "";
			});
			root.push(room)
		});

		if (this.tables === null) {
			this.tables = createTree("table#tables", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: root,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "dnd", "persist"],
				selectMode: 3,
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 0
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				persist: {
					cookiePrefix: 'fancytree-tables',
					expandLazy: false,
					overrideSource: false,
					store: "auto"
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					if (data.node.data.id === "room-0") {
						$('#deleteTable').addClass('hidden');
						$('#deleteRoom').addClass('hidden');
						$('#editRoom').addClass('hidden');
						$('#edit').addClass('hidden');
						$('#activateTable').addClass('hidden');
						$('#activateRoom').addClass('hidden');
						$('#deactivateTable').addClass('hidden');
						$('#deactivateRoom').addClass('hidden');
					} else if (node.data.tables) {
						$('#edit').addClass('hidden');
						$('#editRoom').removeClass('hidden');
						$('#deleteTable').addClass('hidden');
						$('#deleteRoom').removeClass('hidden');
						$('#activateTable').addClass('hidden');
						$('#activateRoom').removeClass('hidden');
						$('#deactivateTable').addClass('hidden');
						$('#deactivateRoom').removeClass('hidden');
						$('#editRoom').removeAttr('disabled');
						$('#deleteRoom').removeAttr('disabled');
						if (node.data.isActive) {
							$('#deactivateRoom').removeAttr('disabled');
							$('#activateRoom').prop('disabled', 'true');
						} else {
							$('#deactivateRoom').prop('disabled', 'true');
							$('#activateRoom').removeAttr('disabled');
						}

					} else {
						$('#editRoom').addClass('hidden');
						$('#edit').removeClass('hidden');
						$('#edit').removeAttr('disabled');
						$('#deleteTable').removeClass('hidden');
						$('#deleteRoom').addClass('hidden');
						$('#activateTable').removeClass('hidden');
						$('#activateRoom').addClass('hidden');
						$('#deactivateTable').removeClass('hidden');
						$('#deactivateRoom').addClass('hidden');
						$('#deleteTable').removeAttr('disabled');
						if (node.data.isActive) {
							$('#deactivateTable').removeAttr('disabled');
							$('#activateTable').prop('disabled', 'true');
						} else {
							$('#deactivateTable').prop('disabled', 'true');
							$('#activateTable').removeAttr('disabled');
						}
					}

				},
				dnd: {
					preventVoidMoves: true, // Prevent dropping nodes
					// 'before self', etc.
					preventRecursiveMoves: true, // Prevent dropping
					// nodes on own
					// descendants
					autoExpandMS: 2000,
					autoScroll: true,
					draggable: {
						// zIndex: 1000,
						// appendTo: "body",
						// helper: "clone",
						scroll: true,
						revert: "invalid"
					},
					dragStart: function (node, data, event) {
						return true;
					},
					dragEnter: function (node, data) {
						if (node.data.t === "room") {
							if (data.otherNode.data.t === "room")
								return ["before", "after"];
							return ["before", "over", "after"];
						}
						return []
					},
					dragDrop: (node, data) => {
						if (data.otherNode.data.t === "room") {
							data.otherNode.moveTo(node, data.hitMode);
							var rooms = [];
							node.parent.children.forEach(function (d) {
								if (d.data.t === 'room' && d.data.name !== "---")
									rooms.push({ id: d.data.id.split('-')[1] })
							});
							admin.updateRoomOrder(rooms, rooms => {
								this.processTables = (rooms, this.roomtables)
							});
							return;
						}
						data.otherNode.moveTo(node, data.hitMode);
						var room = data.hitMode === "over" ? { id: data.node.data.id.split('-')[1] } : null;
						data = data.otherNode.data;
						admin.modifyTable(data.id.split("-")[1], room, data.number, data.name ? data.name : null, data.seats, data.shareable, data.visibleOnline, data.bookOnline, data.isActive, data.type, data.freeSeats, data.pos, data.forStore ? data.forStore : null, data.extensions, instance.loadTables);
					}
				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					// (Column #0 is rendered by fancytree by adding the checkbox)

					// Column #1 should contain the index as plain text, e.g.
					// '2.7.1'
					// $tdList.eq(1)
					// .text(node.getIndexHier())
					// .addClass("alignRight");

					// (Column #2 is rendered by fancytree)

					// ...otherwise render remaining columns
					var c = 1;
					if (node.data.t === "room") {
						$tdList.eq(c).html(node.data.name);
					}
					c++;
					if (node.data.t === "table")
						$tdList.eq(c).text(node.data.number);
					c++;
					if (node.data.t === "table" && node.data.name)
						$tdList.eq(c).html(node.data.name);
					else
						$tdList.eq(c).html("");
					c++;
					$tdList.eq(c).text(node.data.seats);
					c++;
					if (node.data.isActive !== undefined) {
						$tdList.eq(c).find('input').prop('checked', node.data.isActive);
						$tdList.eq(c).find('input').attr('id', 'a_' + node.data.id);
						$tdList.eq(c).find('label').attr('for', 'a_' + node.data.id);
						$tdList.eq(c).find('input').addClass('checkbox isActive');
					}
					c++;
					if (node.data._type) {
						$tdList.eq(c).html(node.data._type);
					}
					c++;
					if (node.data.pos !== undefined) {
						$tdList.eq(c).find('input').prop('checked', node.data.pos);
						$tdList.eq(c).find('input').attr('id', 'p_' + node.data.id);
						$tdList.eq(c).find('label').attr('for', 'p_' + node.data.id);
						$tdList.eq(c).find('input').addClass('checkbox pos');
					} else {
						$tdList.eq(c).html("")
					}
					c++;
					if (node.data.t === "table") {
						$tdList.eq(c).find('select.select2').attr('id', 'store' + node.data.id)
						admin.initializeSelect2($tdList.eq(c).find('select.select2')).done(() => {
							if (node.data.forStore) {
								var select = $tdList.eq(c).find('select.select2').select2();
								select.val(node.data.forStore.id);
								select.trigger('change');
							}
							$tdList.eq(c).find('select').on('select2:select', function (data) {
								if (!this.tables)
									return;
								var storeId = $(this).select2('val');
								data = this.tables.getActiveNode().data;
								admin.modifyTable(data.id.split('-')[1], data.number, data.name, data.seats, data.shareable, data.visibleOnline, data.bookOnline, data.isActive, data.type, data.freeSeats, data.pos, { id: storeId }, this.loadTables);

							});
						});
					} else {
						$tdList.eq(c).html("")
					}
					c++;
					if (node.data.shareable !== undefined) {
						$tdList.eq(c).find('input').prop('checked', node.data.shareable);
						$tdList.eq(c).find('input').attr('id', 's_' + node.data.id);
						$tdList.eq(c).find('label').attr('for', 's_' + node.data.id);
						$tdList.eq(c).find('input').addClass('checkbox shareable');
					} else {
						$tdList.eq(c).html("")
					}
					c++;
					if (node.data.visibleOnline !== undefined) {
						$tdList.eq(c).find('input').prop('checked', node.data.visibleOnline);
						$tdList.eq(c).find('input').attr('id', 'o_' + node.data.id);
						$tdList.eq(c).find('label').attr('for', 'o_' + node.data.id);
						$tdList.eq(c).find('input').addClass('checkbox visibleOnline');
					}
					c++;
					if (node.data.bookOnline !== undefined) {
						$tdList.eq(c).find('input').prop('checked', node.data.bookOnline);
						$tdList.eq(c).find('input').attr('id', 'b_' + node.data.id);
						$tdList.eq(c).find('label').attr('for', 'b_' + node.data.id);
						$tdList.eq(c).find('input').addClass('checkbox bookOnline');
					}
					c++;
					$tdList.eq(c).text(node.data.freeSeats);
					c++;
					$tdList.eq(c).text(node.data.extensions);

					if (node.data.id === "room-0") {
						$tdList.eq(4).html("");
						$tdList.eq(5).html("");
						$tdList.eq(10).html("");
						$tdList.eq(11).html("");
					}



					// $tdList.eq(1)
				}
			});
			this.checkBoxHooks();

			var node = this.tables.getRootNode();
			node.sortChildren(function (a, b) {
				if (a.data.t === 'room' && b.data.t === 'room')
					return a.data.order - b.data.order;
				if (a.data.t === 'room' && b.data.t !== 'room')
					return -1;
				if (a.data.t !== 'room' && b.data.t === 'room')
					return 1;
				var left = a.data.name ? a.data.name : a.data.number;
				var right = b.data.name ? b.data.name : b.data.number;
				if (isNaN(left) || isNaN(right))
					return ("" + left).localeCompare("" + right);
				else
					return left - right;
			}, true);

		} else {
			this.tables.reload(root);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
			this.checkBoxHooks();
			node = this.tables.getRootNode();
			node.sortChildren(function (a, b) {
				if (a.data.t === 'room' && b.data.t === 'room')
					return a.data.order - b.data.order;
				if (a.data.t === 'room' && b.data.t !== 'room')
					return -1;
				if (a.data.t !== 'room' && b.data.t === 'room')
					return 1;
				var left = a.data.name ? a.data.name : a.data.number;
				var right = b.data.name ? b.data.name : b.data.number;
				if (isNaN(left) || isNaN(right))
					return ("" + left).localeCompare("" + right);
				else
					return left - right;
			}, true);
		}
	}

	getRoom = ({ room, roomId }) => {
		return room ? room : roomId ? { id: roomId } : undefined;
	}

	checkBoxHooks = () => {
		const instance = this;
		$('input.checkbox.isActive').change((data) => {
			var t = data.currentTarget;
			data = instance.tables.getActiveNode().data;
			if (data.t === "table")
				admin.modifyTable(data.id.split("-")[1], this.getRoom(data), data.number, data.name, data.seats, data.shareable, data.visibleOnline, data.bookOnline, $(t).prop('checked'), data.type, data.freeSeats, data.pos, data.forStore, data.extensions, instance.loadTables);
			else
				admin.modifyRoom(data.id.split("-")[1], data.name, data.visibleOnline, data.bookOnline, $(t).prop('checked'), instance.loadTables);
		});
		$('input.checkbox.pos').change((data) => {
			var t = data.currentTarget;
			data = instance.tables.getActiveNode().data;
			if (data.t === "table")
				admin.modifyTable(data.id.split("-")[1], this.getRoom(data), data.number, data.name, data.seats, data.shareable, data.visibleOnline, data.bookOnline, data.isActive, data.type, data.freeSeats, $(t).prop('checked'), data.forStore, data.extensions, instance.loadTables);
		});
		$('input.checkbox.shareable').change((data) => {
			var t = data.currentTarget;
			data = instance.tables.getActiveNode().data;
			if (data.t === "table")
				admin.modifyTable(data.id.split("-")[1], this.getRoom(data), data.number, data.name, data.seats, $(t).prop('checked'), data.visibleOnline, data.bookOnline, data.active, data.type, data.freeSeats, data.pos, data.forStore, data.extensions, instance.loadTables);
		});
		$('input.checkbox.visibleOnline').change((data) => {
			var t = data.currentTarget;
			data = instance.tables.getActiveNode().data;
			if (data.t === "table")
				admin.modifyTable(data.id.split("-")[1], this.getRoom(data), data.number, data.name, data.seats, data.shareable, $(t).prop('checked'), data.bookOnline, data.active, data.type, data.freeSeats, data.pos, data.forStore, data.extensions, instance.loadTables);
			else
				admin.modifyRoom(data.id.split("-")[1], data.name, $(t).prop('checked'), data.bookOnline, data.active, instance.loadTables);
		});
		$('input.checkbox.bookOnline').change((data) => {
			var t = data.currentTarget;
			data = instance.tables.getActiveNode().data;
			if (data.t === "table")
				admin.modifyTable(data.id.split("-")[1], this.getRoom(data), data.number, data.name, data.seats, data.shareable, data.visibleOnline, $(t).prop('checked'), data.active, data.type, data.freeSeats, data.pos, data.forStore, data.extensions, instance.loadTables);
			else
				admin.modifyRoom(data.id.split("-")[1], data.name, data.visibleOnline, $(t).prop('checked'), data.active, instance.loadTables);
		});
	}


	updateHooks = () => {
		const instance = this;
		$('div#editTableDetails').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			instance.edit(modal, recipient);
		})
		$('div#editRoom').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			instance.editRoom(modal, recipient);
		})
	}

	edit = (modal, recipient) => {
		if (recipient === 'edit') {
			var data = this.tables.getActiveNode().data;
			modal.find('#id').val(data.id.split("-")[1]);
			modal.find('#number').val(data.number);
			modal.find('#name').val(data.name);
			modal.find('#seats').val(data.seats);
			modal.find('#type').val(data._type);
			if (data.shareable === true)
				modal.find('#shareable').prop("checked", 'true');
			else
				modal.find('#shareable').removeAttr("checked");
			if (data.visibleOnline === true)
				modal.find('#visibleOnline').prop("checked", 'true');
			else
				modal.find('#visibleOnline').removeAttr("checked");
			if (data.bookOnline === true)
				modal.find('#bookOnline').prop("checked", 'true');
			else
				modal.find('#bookOnline').removeAttr("checked");
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
			else
				modal.find('#active').removeAttr("checked");
			if (data.pos === true)
				modal.find('#pos').prop("checked", 'true');
			else
				modal.find('#pos').removeAttr("checked");
			if (data.forStore) {
				var select = modal.find('select.select2').select2();
				select.val(data.forStore.id);
				select.trigger('change');
			}
			modal.find('#freeSeats').val(data.freeSeats);
			modal.find('input#extensions').val(data.extensions);
			$('#freeSeatsFormGroup').show();
		} else {
			modal.find('#id').val("");
			modal.find('#number').val("");
			modal.find('#name').val("");
			modal.find('#seats').val("");
			modal.find('#type').val("");
			modal.find('#visibleOnline').prop("checked", 'true');
			modal.find('#bookOnline').prop("checked", 'true');
			modal.find('#shareable').removeAttr("checked");
			modal.find('#active').prop("checked", 'true');
			modal.find('#pos').removeAttr("checked");
			modal.find('#freeSeats').val("");
			select = modal.find('select.select2').select2();
			select.val(null);
			select.trigger('change');
			$('#freeSeatsFormGroup').hide();
			modal.find('#extentions').val("");

		}
	}

	editRoom = (modal, recipient) => {
		if (recipient === 'edit') {
			var data = this.tables.getActiveNode().data;
			modal.find('#roomid').val(data.id.split("-")[1]);
			modal.find('#roomname').val(data.name);
			if (data.visibleOnline === true)
				modal.find('#roomvisibleOnline').prop("checked", 'true');
			else
				modal.find('#roomvisibleOnline').removeAttr("checked");
			if (data.bookOnline === true)
				modal.find('#roombookOnline').prop("checked", 'true');
			else
				modal.find('#roombookOnline').removeAttr("checked");
			if (data.isActive === true)
				modal.find('#roomactive').prop("checked", 'true');
			else
				modal.find('#roomactive').removeAttr("checked");
		} else {
			modal.find('#roomid').val("");
			modal.find('#roomname').val("");
			modal.find('#roomvisibleOnline').prop("checked", 'true');
			modal.find('#roombookOnline').prop("checked", 'true');
			modal.find('#active').prop("checked", 'true');

		}
	}

	saveTable = () => {
		var modal = $('div#editTableDetails');
		var id = modal.find('#id').val();
		var number = modal.find('#number').val();
		var name = modal.find('#name').val();
		var seats = modal.find('#seats').val();
		var type = modal.find('#type').val();
		var shareable = modal.find('#shareable').prop('checked');
		var visibleOnline = modal.find('#visibleOnline').prop('checked');
		var bookOnline = modal.find('#bookOnline').prop('checked');
		var active = modal.find('#active').prop('checked');
		var pos = modal.find('#pos').prop('checked');
		var freeSeats = modal.find('#freeSeats').val();
		var extensions = modal.find('input#extensions').val();
		var forStore = { id: modal.find('select#forStore.select2').select2('val') };
		var room = null;
		if (id !== '') {
			var data = this.tables.getActiveNode().data;
			admin.modifyTable(id, { id: data.roomId }, number, name, seats, shareable, visibleOnline, bookOnline, active, type, freeSeats, pos, forStore, extensions, this.loadTables);
		} else {
			admin.addTable(room, number, name, seats, shareable, visibleOnline, bookOnline, active, type, pos, forStore, extensions, this.loadTables);
		}
	}

	activateTable = (active) => {
		var data = this.tables.getActiveNode().data;
		admin.modifyTable(data.id.split("-")[1], data.number, data.name, data.seats, data.shareable, data.visibleOnline, data.bookOnline, active, data.type, data.freeSeats, data.pos, data.forStore, data.extensions, this.loadTables);
	}

	deleteTable = (active) => {
		var data = this.tables.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_table'), data.number)).done(function () {
			admin.deleteTable(data.id.split("-")[1], instance.loadTables);
		});
	}

	saveRoom = () => {
		var modal = $('div#editRoom');
		var id = modal.find('#roomid').val();
		var name = modal.find('#roomname').val();
		var visibleOnline = modal.find('#roomvisibleOnline').prop('checked');
		var bookOnline = modal.find('#roombookOnline').prop('checked');
		var active = modal.find('#roomactive').prop('checked');
		if (id !== '') {
			admin.modifyRoom(id, name, visibleOnline, bookOnline, active, this.loadTables);
		} else {
			admin.addRoom(name, visibleOnline, bookOnline, active, this.loadTables);
		}
	}

	activateRoom = (active) => {
		var data = this.tables.getActiveNode().data;
		admin.modifyRoom(data.id.split("-")[1], data.name, data.visibleOnline, data.bookOnline, active, this.loadTables);
	}

	deleteRoom = (active) => {
		const instance = this;
		var data = this.tables.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_room'), data.name)).done(function () {
			admin.deleteRoom(data.id.split("-")[1], instance.loadTables);
		});
	}


}

export default TablesCode;