import React, { Component } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getUsersContaining } from "../js/superadmin/superadmin";
import { getGlobalUsersContaining } from "../js/admin";
import { get, getGlobal } from "../js/auth";
import { locals } from "../langs/languages";

class CustomerEmailAutoComplete extends Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: [],
    filterBy: [],
  };

  render() {
    return (
      <AsyncTypeahead
        {...this.state}
        className={this.props.className}
        id="CustomerEmailAutoComplete"
        labelKey={this.props.id}
        delay={500}
        emptyLabel="No matches found"
        dropup={this.props.dropup}
        selected={this.props.value ? [this.props.value] : []}
        //minLength={3}
        onSearch={this._handleSearch}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        renderMenuItemChildren={(option, props) => (
          <span key={option.id}>
            {option.email} - {option.name} {option.surname} {option.phone}
          </span>
        )}
      />
    );
  }

  onChange = values => {
    if (values.length > 0)
      this.props.onSelectionChange(values);
  }

  onInputChange = input => {
    if (this.props.onChange) {
      const event = {
        target: {
          id: this.props.id,
          value: input
        },
      }
      this.props.onChange(event);
    }
    this.query = input;
  }

  getUsersContaining = (property, contains, handler, errorHandler) => {
    get("adminService/getUsersContaining?" + property + "=" + contains, localStorage.id).done(handler);
  };
  getGlobalUsersContaining = (property, contains, handler, errorHandler) => {
    getGlobal("adminService/getUsersContaining?" + property + "=" + contains, localStorage.id).done(handler);
  };

  _handleSearch = query => {
    //if (this.props.onSelected)
    //this.props.onChange([{ email: query }]);
    if (query.length < 3)
      return;
    this.setState({ isLoading: true });
    const t = this;
    this.getUsersContaining(this.props.id, query, options => {
      //this.getGlobalUsersContaining(this.props.id, query, options2 => {
      /*
      options2.forEach(customer => {
        if (options.filter(c => { return c.id === customer.id }).length === 0) {
          options.push(customer);
        }
      })
      */
      options.forEach(element => {
        if (element.email && element.email.indexOf("@") === -1)
          element.email = "";
      });
      t.setState({
        isLoading: false,
        options: options
      });
      //});
    });
  };
}

export default CustomerEmailAutoComplete;
