import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { getPaymentMethodsByRestaurant } from "../../js/superadmin/superadmin";
import { CheckBoxEditor, DropDownEditor } from "../../renderers/tablecelleditors";
import { flattenObject } from "../../components/utils";
import { paymentMethodsGridStateKey } from "../../reducers/rootReducer";
import EatWithMeActions from "../../actions/EatWithMeActions";

export const stateKey = paymentMethodsGridStateKey;

const stateful = false;

function getColumns({ myStatus, onChange }, store) {
  return [
    { name: "id", dataIndex: "id", width: "0", renderer: <div></div>, editor: props => <div></div> },
    {
      name: <Translate value="admin_local.active" />,
      dataIndex: "isActive",
      sortable: true,
      renderer: props => <CheckBoxEditor {...props} stateKey={stateKey} onChange={props.onChange} />,
      readOnly: false,
      width: "30px",
      className: "",
      expandable: true,
      onChange: event => {
        console.log(event);
      }
    },
    {
      name: <Translate value="admin_local.payment_method" />,
      dataIndex: "method.id",
      sortable: true,
      renderer: props => (
        <DropDownEditor
          {...props}
          value={props.value}
          onChange={props.onChange}
          stateKey={stateKey}
          options={
            myStatus.payment_methods
              ? myStatus.payment_methods.map(m => {
                return { id: m.id, name: m.name };
              })
              : undefined
          }
        />
      ),
      width: "200px",
      className: "",
      expandable: true,
      onChange: event => {
        console.log(event);
      }
    }
  ];
}

const paymentMethodsConfig = (props, store) => {
  return {
    store: store,
    reducerKeys: "nested",

    gridType: "grid",
    pageSize: 20,

    stateKey: stateKey,

    stateful: stateful,

    columns: getColumns(props, store),

    restaurant_id: null,

    height: false,

    dataSource: function getData({ pageIndex, pageSize }) {
      return new Promise(resolve => {
        if (store.getState().rootReducer.restaurant && store.getState().rootReducer.restaurant.id) {
          getPaymentMethodsByRestaurant(store.getState().rootReducer.restaurant.id, data => {
            data.forEach(d => {
              d.key = d.id;
            });
            data.push({ isActive: false, method: {} });
            const d = data.map(r => flattenObject(r));
            resolve({ data: d });
          });
        } else resolve({ data: [] });
      });
    },

    events: {
      HANDLE_CELL_CLICK: (cell, reactEvent, id, browserEvent) => {
        console.log("On Cell Click Event");
      },
      HANDLE_CELL_DOUBLE_CLICK: (cell, reactEvent, id, browserEvent) => {
        console.log("On Cell Double Click Event");
      },
      HANDLE_ROW_CLICK: (row, reactEvent, id, browserEvent, t) => {
        console.log("On Row Click Event");
        console.log(row, t);
        //store.dispatch(Actions.EditorActions.editRow({ stateKey: stateKey, rowId: row.rowId, row: row, values: row, columns: getColumns(props) }));
      },
      HANDLE_ROW_DOUBLE_CLICK: (row, reactEvent, id, browserEvent) => {
        console.log("On Row Double Click Event");
      },
      HANDLE_BEFORE_SELECTION: () => {
        console.log("On Before Selection");
      },
      HANDLE_AFTER_SELECTION: () => {
        console.log("On After Selection");
      },
      HANDLE_AFTER_INLINE_EDITOR_SAVE: (row, reactEvent) => {
        console.log(row);
        console.log("On After Save Inline Editor Event");
      },
      HANDLE_BEFORE_BULKACTION_SHOW: () => {
        console.log("On Before Bulk Action Show");
      },
      HANDLE_AFTER_BULKACTION_SHOW: () => {
        console.log("On After Bulk Action Show");
      },
      HANDLE_BEFORE_SORT: () => {
        console.log("before sort event");
      },
      HANDLE_AFTER_SELECT_ALL: () => {
        console.log("Handle after select all");
      },
      HANDLE_AFTER_DESELECT_ALL: () => {
        console.log("Handle after deselect all");
      },
      HANDLE_AFTER_ROW_DROP: ({ treeData, row }) => {
        console.log("After drag and drop of row event");
      },
      HANDLE_EDITOR_FOCUS: a => {
        console.log(a);
        console.log("Handle editor focus");
      },
      HANDLE_EDITOR_BLUR: () => {
        console.log("Handle editor blur");
      }
    },

    plugins: {
      COLUMN_MANAGER: {
        resizable: true,
        moveable: true
        /*
      sortable: {
        enabled: true,
        method: "local",
        sortingSource: "http://react-redux-grid.herokuapp.com/getfakeData"
      }*/
      },
      STICKY_HEADER: {
        enabled: true
      },
      STICKY_FOOTER: {
        enabled: true
      },
      LOADER: {
        enabled: false
      },
      SELECTION_MODEL: {
        mode: "checkbox-multi",
        enabled: false,
        allowDeselect: true,
        activeCls: "active",
        selectionEvent: "singleclick",
        editEvent: "none"
      },
      ERROR_HANDLER: {
        defaultErrorMessage: "AN ERROR OCURRED",
        enabled: true
      },
      BULK_ACTIONS: {
        enabled: false,
        actions: [
          {
            text: "Move",
            EVENT_HANDLER: () => { }
          },
          {
            text: "Add",
            EVENT_HANDLER: () => { }
          }
        ]
      },
      GRID_ACTIONS: {
        iconCls: "action-icon",
        menu: [
          {
            text: I18n.t("admin_local.delete"),
            key: "delete",
            EVENT_HANDLER: ({ metaData }) => {
              store.dispatch(EatWithMeActions.deleteRow({ row: metaData.rowData, rowIndex: metaData.rowIndex, stateKey: stateKey }));
            }
          }
        ]
      }
    }
  };
};
export default paymentMethodsConfig;
