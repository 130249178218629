import { Component } from "react";

class AbstractMenuItem extends Component {
  pushMenu() {
    return this.props.pushMenu();
  }
  back() {
    this.props.back();
  }
  navigate(url) {
    // eslint-disable-next-line no-restricted-globals
    location.href = url;
  }
  setParentState(state) {
    this.props.setParentState(state);
  }
}

export default AbstractMenuItem;
