import React, { Component } from "react";
import EatWithMeText from "../../components/EatWIthMeText";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, hideEditTable, stopIntervals, updateFooterButtons } from "../../js/point-of-sale";
import orderListUtil, { getTableName, toggleSound } from "../../js/order-list-util";
import AICommandBox from "../../js/ai/AICommandBox";
import admin, { hasRole, selectTableNumberSelected, settings } from "../../js/admin";
import BackButton from "../../components/menus/BackButton";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import { printDailyClosing, userChanged } from "../../js/tables-overview";
import { ToggleButton } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import "./pointOfSale.css"
import auth, { ajaxCallEnd, ajaxCallStart, decreaseZoom, featureEnabled, get, getGlobal, increaseZoom, messageDialogTemp } from "../../js/auth";
import { reprintReceipts } from "../../js/tableMatePaid";
import moment from "moment";
import OpeningDay from "../../components/OpeningDay";
import { Help } from "../../js/help";
import { help } from "../../help/tables-overview";

class PointOfSale extends Component {
  state = {
    initialized: false,
    customerScreen: null,
    showCustomerScreen: localStorage.showCustomerScreen ? localStorage.showCustomerScreen === "true" : false
  };

  checkDayOpen = (first = false) => {
    if (featureEnabled("ntak")) {
      const h = this.props.myStatus.restaurant_settings["business-day-starts-at"];
      if (this.state.open === undefined || ((!first || !this.state.doneOnce) && this.state.date !== moment().add(-h, "hour").startOf("day").valueOf())) {
        //this.setState({ open: false })
        ajaxCallStart("findByRestaurantAndDate");
        get("api/businessDays/search/findByRestaurantAndDate?restaurant=" + this.props.myStatus.restaurant_id + "&date=" + moment().add(-h, "hour").startOf("day").format("yyyy-MM-DD HH:mm:ss ZZ").replace("+", "%2B")).done(d => {
          ajaxCallEnd("findByRestaurantAndDate");
          this.setState({ doneOnce: true, open: d._embedded.businessDays.length > 0, date: d._embedded.businessDays.length > 0 ? moment().startOf("day").valueOf() : null })
        })
      }
    } else {
      this.setState({ open: true })
    }
  }

  openDayChecker = null;

  componentWillUnmount = () => {
    if (navigator?.presentation?.requestSession)
      this.session = navigator.presentation.requestSession("https://console.eatwithme.online/img/EwM-customer-logo.svg");
  }

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    selectTableNumberSelected(this.props.match.params.tableNumber);

    if (myStatus && myStatus.restaurant_id) {
      if (this.state.open || this.state.continue) {
        ready({ ...this.props, updateCustomerScreen: this.updateCustomerScreen }, this);
        this.resetCustomerScreen();
        this.setState({ initialized: true });
        this.checkDayOpen();
        this.updateDeliveryUsers();
      }
      admin.userChanged = userChanged;
      admin.hideEditTable = hideEditTable;
      if (this.openDayChecker == null)
        this.openDayChecker = setInterval(this.checkDayOpen, 30000);
      this.checkDayOpen(true);
    }
    admin.userChanged = userChanged;
    admin.hideEditTable = hideEditTable;
    delete localStorage._editTable;
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;
    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      if (this.state.open || this.state.continue) {
        ready({ ...this.props, updateCustomerScreen: this.updateCustomerScreen }, this);
        this.resetCustomerScreen();
        this.setState({ initialized: true });
        this.checkDayOpen();
      }
      admin.userChanged = userChanged;
      admin.hideEditTable = hideEditTable;
      if (this.openDayChecker == null)
        this.openDayChecker = setInterval(this.checkDayOpen, 30000);
      this.checkDayOpen(true);
    }


    if (this.state.warningShown || !this.props.myStatus.subscription || myStatus.roles.filter(r => r.role === "superadmin").length)
      return;

    this.setState({ warningShown: true });

    var dueSince = myStatus.invoiceDueSince;
    if (dueSince > 0) {
      const instance = this;
      var duration = dueSince;
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
      function updateMessage() {
        duration -= 1;
        if (instance.dialog)
          instance.dialog.resolve();
        if (duration > 0) {
          instance.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_was_due_in"), dueSince) + "<br/>" + String.format(I18n.t('admin_local.continue_in'), duration));
          setTimeout(updateMessage, 60000);
        }
      }
      setTimeout(updateMessage, 60000);
      this.dialog.done(() => {
        delete this.dialog;
      });
    } else if (dueSince >= -3) {
      /*
      this.dialog = messageDialogTemp(I18n.t('local.warning'), String.format(I18n.t("admin_local.payment_due_in"), -dueSince), false);
      setTimeout(this.dialog.resolve, (5 + dueSince) * 1000);
      this.dialog.done(() => {
        delete this.dialog;
      });
      */
    }

  }

  resetCustomerScreen = () => {
    if ((auth.device.platform === "Android" && navigator.presentation && !this.state.session) || (featureEnabled("pos/customerScreenButton") && this.state.showCustomerScreen && !this.state.customerScreen)) {
      this.showCustomerScreen();
      updateFooterButtons();
    }
    if (!this.state.showCustomerScreen && (this.state.customerScreen || this.state.session)) {
      if (auth.device.platform === "Android" && navigator.presentation && this.state.session) {
        //this.state.session.postMessage(JSON.stringify({ source: this.props.match.params.tableNumber, data: [] }));
      }
      else {
        this.state.customerScreen.postMessage({ source: this.props.match.params.tableNumber, data: [] }, window.location.href);
      }
      this.setState({ customerScreen: null });
    }

  }

  componentWillUnmount() {
    stopIntervals();
    this.setState({ initialized: false });
    delete admin.userChange;
    delete admin.hideEditTable;
    clearInterval(this.openDayChecker);
  }

  render() {
    setTimeout(this.resetCustomerScreen, 100);
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      return (
        <div id="pointOfSale" style={{ display: "flex", flexFlow: "column", flexGrow: 1, overflow: "hidden" }}>
          <div
            className="menuItemHeader"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              height: "50px",
              alignItems: "center",
              fontSize: "18px",
              backgroundColor: "#FAF8EA",
              color: "#837B6C",
              paddingRight: "30px",
            }}
          >
            <EatWithMeText />
            <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
            <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
            <BackButton {...this.props} />
            <FlexGrowSpace />
            <FlexGrowSpace />
            <div id="zoom" style={{ display: "flex", alignItems: "center", "color": "black" }}>
              <div onClick={decreaseZoom} className="zoom icon-zoom-out-outline" />
              <h1 id="zoomlevel2" style={{ fontSize: "20px" }}></h1>
              <div onClick={increaseZoom} className="zoom icon-zoom-in-outline" />
            </div>
            <FlexGrowSpace />
            <span className="myTitle" style={{ fontSize: "24px", marginRight: "20px" }}>
              {getTableName(this.props.match.params.tableNumber)} {<Translate value="admin_local.point_of_sale" />}
            </span>
            <FlexGrowSpace />
            <span onClick={reprintReceipts} id="reprint-receipt" style={{ top: 0, position: "relative", fontSize: "28px", lineHeight: "28px", padding: "5px" }} data-toggle="button" aria-pressed="false" className="icon i_cash_register">
              <input type="radio" style={{ display: "none" }} />
            </span>
            <FlexGrowSpace />
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>

              <div style={{ marginLeft: "10px", display: "flex", justifyContent: "space-between" }}>
                <span data-toggle="toggle" onClick={toggleSound} style={{ lineHeight: "30px", padding: "5px" }} id="sound" className="btn icon icon-volume-off" />
                <span data-toggle="toggle" onClick={printDailyClosing} style={{ lineHeight: "30px", padding: "5px" }} id="printDailyClosing" className="icon icon-gauge" />
                {hasRole(myStatus.roles, ['superadmin', 'admin']) ?
                  <span data-toggle="toggle" style={{ lineHeight: "35px", padding: "5px", marginLeft: "4px", marginRight: "4px" }} id="settings" onClick={() => settings('editTable')} data-dismiss="modal" class="icon icon-cog-outline"></span>
                  : null}

                <div class="dropdown" style={{ alignSelf: "center", top: "4px", marginRight: "10px", marginLeft: "4px", padding: 0, height: "40px" }}>
                  <span onClick={orderListUtil.refreshPrinters} className="btn icon icon-print-1" style={{ backgroundColor: "transparent", top: "0px", padding: 0 }} id="printers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </span>
                  <div className="dropdown-menu" id="printers" style={{ minWidth: "unset", zIndex: "1003", fontSize: "1rem" }}>
                  </div>
                </div>
                <ButtonGroup toggle className="browser-only">
                  {auth.device.platform === "browser" && featureEnabled("pos/customerScreenButton") ?
                    <ToggleButton id="customerScreenButton" type="checkbox" checked={this.state.showCustomerScreen} onChange={(e) => { localStorage.showCustomerScreen = e.currentTarget.checked; this.setState({ showCustomerScreen: e.currentTarget.checked }); }} className="icon-monitor" variant="secondary" />
                    : null}
                </ButtonGroup>
              </div>
            </div>
          </div>
          {featureEnabled("ntak") && this.state.open === false && !this.state.continue ?
            <OpeningDay {...this.props} onContinue={(open) => this.setState({ open: open, continue: true })} /> : null}
          <div id="main" className={((featureEnabled("ntak") && this.state.open === false && !this.state.continue) ? "hidden" : "")}>
            <div className="bar"></div>
            <div id="editMenuItemDialog" />
            <div id="editDiscountDialog" />
            <div id="markTableMateForPaymentDialog" />
            <div id="tableMatePaidDialog" />
          </div>
          <AICommandBox />
        </div >
      );
    } else return null;
  }

  showCustomerScreen = () => {
    const t = this;
    //eslint-disable-next-line no-restricted-globals
    const path = location.protocol + "//" + location.host + location.pathname;
    if (auth.device.platform === "Android" && navigator.presentation) {

      var presentation = navigator.presentation;
      if (t.sessionState !== "inProgress") {
        t.sessionState = 'inProgress';
        presentation.onavailablechange = function (availableChangeEvent) {
          if (availableChangeEvent.available) {
            try {
              getGlobal("login/getLoginToken")
                .done(token => {

                  const url = "https://console.eatwithme.online/#/customerScreen/" + t.props.match.params.tableNumber + "?loginToken=" + token.id + "&server_url=" + auth.server + "&restaurant_id=" + auth.myStatus.restaurant_global_id + "&instance=" + localStorage.instance;
                  //const url = "file:///android_asset/www/index.html/#/customerScreen/" + t.props.match.params.tableNumber + "?loginToken=" + token.id + "&server_url=" + auth.server + "&restaurant_id=" + auth.myStatus.restaurant_global_id + "&instance=" + localStorage.instance;
                  console.error(url);
                  t.session = navigator.presentation.requestSession(url);

                  t.session.onstatechange = function () {
                    console.error("state:" + t.session.state);
                    switch (t.session.state) {
                      case 'connected':
                        break;
                      case 'disconnected':
                        break;
                    }
                  };
                  t.sessionState = 'done';
                  t.setState({ session: t.session });
                }).fail(() => {
                  t.sessionState = 'failed';
                });
            } catch (ex) {
              console.error(ex);
            }
          } else {
            console.error("External display not available.");
          }
        };
      }
    } else
      this.setState({ customerScreen: window.open(path + "#customerScreen/" + this.props.match.params.tableNumber, "customerScreen", '') });
  }

  updateCustomerScreen = (data) => {
    if (this.state.session || this.state.customerScreen) {
      const tableOccupation = (data && data.length > 0) ? data[0].tableOccupation : 0;
      get("adminService/" + localStorage.restaurantSelected + "/advertisements/getByTableOccupation/" + tableOccupation).done(images => {
        if (this.state.session) {
          if (data.length > 0) {
            this.state.session.postMessage(JSON.stringify({ source: this.props.match.params.tableNumber, data: data, images: images }));
            //this.state.session.postMessage(false, JSON.stringify({ source: this.props.match.params.tableNumber, data: data, images: images }));
          }
        } else {
          this.state.customerScreen.postMessage({ source: this.props.match.params.tableNumber, data: data, images: images }, window.location.href);
        }
      })
    }
  }
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      tables: props.rootReducer.tables,
      deliveryUsers: []
    };
  }
  return {};
};

export default connect(mapStateToProps)(PointOfSale);
