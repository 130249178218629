import React from "react";
import Form from "react-jsonschema-form";
import { restaurant_settings_schema, restaurant_settings_schema_ui_schema } from "../../js/restaurant_settings_schema";
import AbstractRestraurantEditor from "./AbstractRestaurantEditor";
import Buttons from "./Buttons";

import { CustomObjectFieldTemplate, CustomFieldTemplate } from "../../components/utils";



class RestaurantSettings extends AbstractRestraurantEditor {

  setupCKEditor = (id, lang) => {
    const lid = "root_" + id.replace(/\//g, "_") + "_" + lang;
    if (document.getElementById(lid) && !window.CKEDITOR.instances[lid]) {
      window.CKEDITOR.replace(lid);
      if (this.state.restaurant.settings[id] && this.state.restaurant.settings[id][lang]) {
        window.CKEDITOR.instances[lid].setData(this.state.restaurant.settings[id][lang]);
      }
      window.CKEDITOR.instances[lid].on('change', () => {
        const newState = { restaurant: { ...this.state.restaurant }, changed: true };
        var obj = newState.restaurant.settings;
        id.split("/").forEach(id => {
          const obj2 = obj[id];
          if (obj2 === undefined) {
            obj[id] = {};
            obj = obj[id]
            return obj;
          }
          obj = obj2;
        })
        newState.restaurant.settings[lid] = "";
        obj[lang] = window.CKEDITOR.instances[lid].getData();
        this.setState(newState);
      });
    }
  }

  componentDidUpdate = () => {
    this.setupCKEditor("description", "hu");
    this.setupCKEditor("description", "en");
    this.setupCKEditor("description_header", "hu");
    this.setupCKEditor("description_header", "en");
    this.setupCKEditor("description_footer", "hu");
    this.setupCKEditor("description_footer", "en");
    this.setupCKEditor("enabled-features/homedelivery/terms-and-conditions", "hu");
    this.setupCKEditor("enabled-features/homedelivery/terms-and-conditions", "en");
    this.setupCKEditor("enabled-features/pickup/terms-and-conditions", "hu");
    this.setupCKEditor("enabled-features/pickup/terms-and-conditions", "en");
    this.setupCKEditor("enabled-features/ordering/privacy-policy", "hu");
    this.setupCKEditor("enabled-features/ordering/privacy-policy", "en");
  }

  componentWillUnmount() {
    Object.keys(window.CKEDITOR.instances).forEach(c => window.CKEDITOR.instances[c].destroy())
  }



  render() {
    if (this.state.restaurant) {
      return (
        <div style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}>
          <Form
            uiSchema={restaurant_settings_schema_ui_schema(this.state.restaurant.languages.split(","))}
            FieldTemplate={CustomFieldTemplate}
            ObjectFieldTemplate={CustomObjectFieldTemplate}
            onChange={this.onChange}
            schema={restaurant_settings_schema(this.state.restaurant.languages.split(","), this.state.myStatus)}
            formData={this.state.restaurant.settings}
          />
          <Buttons restaurant={this.state.restaurant} changed={this.state.changed} handleCancel={() => this.props.handleCancel(this.state.restaurant)} handleSave={() => this.props.handleSave(this.state.restaurant)} />
        </div>
      );
    }
    return null;
  }

  onChange = ({ formData }, e) => {
    this.setState({ restaurant: { ...this.state.restaurant, settings: formData }, changed: true });
  };

  updateValue() { }
}

export default RestaurantSettings;
