import $ from "jquery";
import admin, { resultHandler } from "../admin";
import { startpoll, confirmDialog, replaceContent, post, get } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

export const states = ["accepted", "producing", "delivering", "delivered"];

class ProductionLinesCode extends EntityPageCode {

	printers = [];
	producingRules = ["manualOnly", "afterPrint"];

	constructor() {
		super();
		super.publishFunctions(['saveProductionLine', 'deleteProductionLine', 'activateProductionLine']);
	}

	clear = () => {
		this.productionLines = null;
	}


	ready = () => {
		const instance = this;

		admin.getStores(function (data) {
			instance.stores = data;
			$('#main').replaceWith($('#productionLinesMainTemplate').tmpl({
				...tmplparams()
			}));


			admin.getPrinters(function (data) {
				instance.printers = data;
				$('#editProductionLineTemplate').tmpl({ printers: instance.printers, producingRules: instance.producingRules, states: states, ...tmplparams() }).appendTo('body');
				instance.loadProductionLines();

				startpoll(instance.serverSideMessageHandler);

			});

		});
	}

	reload = () => {
		this.loadProductionLines();
	}

	loadProductionLines = () => {
		admin.getProductionLines(this.processProductionLines);
	}

	serverSideMessageHandler = (data) => {
	}

	productionLines = null;
	processProductionLines = (data) => {

		const instance = this;
		instance.production_lines = data;

		data.forEach(function (v, i) {
			v.title = v.number;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});

		replaceContent($('#editProductionLine'), $('#editProductionLineTemplate').tmpl({ printers: this.printers, producingRules: this.producingRules, production_lines: this.production_lines, states: states, stores: this.stores, ...tmplparams() }));

		if (instance.productionLines == null) {
			this.productionLines = createTree("table#productionLines", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the
					// this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column
					// (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if
					// node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);

					var stores = "";
					node.data.inputStores.forEach(function (store) {
						stores += store.name + " ";
					});
					$tdList.eq(2).text(stores);
					stores = "";
					node.data.outputStores.forEach(function (store) {
						stores += store.name + " ";
					});
					$tdList.eq(3).text(stores);

					$tdList.eq(4).text(node.data.printer ? node.data.printer.name : "");
					$tdList.eq(5).text(node.data.printer ? node.data.printer.connectedPrinter : "");
					var collaboratingProductionLines = "";
					if (node.data.collaboratingProductionLineIds)
						node.data.collaboratingProductionLineIds.forEach(function (pl) {
							instance.production_lines.forEach(function (p) {
								if (Number(p.id) === Number(pl))
									collaboratingProductionLines += p.name + " ";
							});
						});
					$tdList.eq(6).text(collaboratingProductionLines);
				}
			});
			this.updateHooks();
		} else {
			this.productionLines.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}

	}

	updateHooks = () => {
		$('div#editProductionLine').off('show.bs.modal');
		$('div#editProductionLine').on('show.bs.modal', (event) => {

			var modal = $("div#editProductionLine")
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			modal.find('select#printer option').removeAttr('selected');
			modal.find('fieldset#input_stores input').prop('checked', false);
			modal.find('fieldset#output_stores input').prop('checked', false);
			modal.find('#producingRule input').prop('checked', false);
			modal.find('#posProducingRule input').prop('checked', false);
			// attributes
			if (recipient === 'edit') {
				console.log(this.productionLines.getActiveNode());
				var data = this.productionLines.getActiveNode().data;

				this.production_lines.forEach(function (v, i) {
					if (Number(v.id) !== Number(data.id)) {
						var checked = false;
						data.collaboratingProductionLineIds.forEach(function (id) {
							checked = checked || (Number(id) === Number(v.id));
						});
						$('#collaborating_production_lines input#pl_' + v.id).prop('disabled', null);
						$('#collaborating_production_lines input#pl_' + v.id).prop('checked', checked ? true : null);
						checked = false;
						data.controlledProductionLineIds.forEach(function (id) {
							checked = checked || (Number(id) === Number(v.id));
						});
						$('#controlled_production_lines input#cl_' + v.id).prop('disabled', null);
						$('#controlled_production_lines input#cl_' + v.id).prop('checked', checked ? true : null);
					} else {
						$('#collaborating_production_lines input#pl_' + v.id).prop('disabled', true);
						$('#collaborating_production_lines input#pl_' + v.id).prop('checked', null);
						$('#controlled_production_lines input#cl_' + v.id).prop('disabled', true);
						$('#controlled_production_lines input#cl_' + v.id).prop('checked', null);
					}
				});
				$('#input_stores input').prop('checked', null);
				$('#output_stores input').prop('checked', null);
				data.inputStores.forEach(function (store) {
					$('#input_stores input#i_' + store.id).prop('checked', true);
				});
				data.outputStores.forEach(function (store) {
					$('#output_stores input#o_' + store.id).prop('checked', true);
				});
				$('#producingRule input#p_' + data.printedState).prop('checked', true);
				$('#posProducingRule input#pos_' + data.posPrintedState).prop('checked', true);

				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				if (data.isActive === true)
					modal.find('#active').attr("checked", true);
				else
					modal.find('#active').attr("checked", false);
				if (data.qrcode === true)
					modal.find('#qrcode').attr("checked", true);
				else
					modal.find('#qrcode').attr("checked", false);
				if (data.printer) {
					modal.find('select#printer option[value="' + data.printer.id + '"]').prop('selected', true);
				}
			} else {

				$('fieldset#collaborating_production_lines').empty();
				this.production_lines.forEach(function (v, i) {
					$('<input type="checkbox" value="' + v.id + '">' + v.name + '</input>').appendTo('fieldset#collaborating_production_lines');
				});
				$('fieldset#controlled_production_lines').empty();
				this.production_lines.forEach(function (v, i) {
					$('<input type="checkbox" value="' + v.id + '">' + v.name + '</input>').appendTo('fieldset#controlled_production_lines');
				});
				$('#producingRule input#p_manualOnly').prop('checked', true);

				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#active').attr("checked", true);
				modal.find('#qrcode').attr("checked", true);
			}
		})
	}

	saveProductionLine = () => {
		var modal = $('div#editProductionLine');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var printer = Number(modal.find('select#printer option:selected').val());
		if (printer) {
			printer = {
				id: printer
			};
		} else {
			printer = null;
		}
		var collaboratingProductionLines = []
		$('div#collaborating_production_lines input:checked').each(function (ind, pl) {
			collaboratingProductionLines.push({
				id: $(pl).attr('id').substring(3)
			});
		});

		var controlledProductionLines = []
		$('div#controlled_production_lines input:checked').each(function (ind, pl) {
			controlledProductionLines.push({
				id: $(pl).attr('id').substring(3)
			});
		});
		var inputStores = [];
		$('#input_stores input:checked').each(function (ind, pl) {
			inputStores.push({
				id: $(pl).attr('id').substring(2)
			});
		});
		var outputStores = []
		$('#output_stores input:checked').each(function (ind, pl) {
			outputStores.push({
				id: $(pl).attr('id').substring(2)
			});
		});
		var processingRule = $('#producingRule input:checked').length === 1 ? $('#producingRule input:checked').attr('id').split('_')[1] : null;
		var posProcessingRule = $('#posProducingRule input:checked').length === 1 ? $('#posProducingRule input:checked').attr('id').split('_')[1] : null;
		var active = modal.find('#active').prop('checked');
		var qrcode = modal.find('#qrcode').prop('checked');
		if (id !== '') {
			this.modifyProductionLine({
				id: id,
				name: name,
				printer: printer,
				collaboratingProductionLines: collaboratingProductionLines,
				controlledProductionLines: controlledProductionLines,
				inputStores: inputStores,
				outputStores: outputStores,
				processingRule: processingRule ? "afterPrint" : "manualOnly",
				printedState: processingRule,
				posPrintedState: posProcessingRule,
				isActive: active,
				qrcode: qrcode
			}, this.loadProductionLines);
		} else {
			this.addProductionLine({
				id: id,
				name: name,
				printer: printer,
				collaboratingProductionLines: collaboratingProductionLines,
				controlledProductionLines: controlledProductionLines,
				inputStores: inputStores,
				outputStores: outputStores,
				processingRule: processingRule ? "afterPrint" : "manualOnly",
				printedState: processingRule,
				isActive: active,
				qrcode: qrcode
			}, this.loadProductionLines);
		}
	}

	activateProductionLine = (active) => {
		var data = this.productionLines.getActiveNode().data;
		data = Object.assign({}, data);
		data.active = active;
		data.printer = {
			id: data.printer.id
		};
		delete data.restaurantId;
		data.isActive = data.active;
		delete data.active;
		this.modifyProductionLine(data, this.loadProductionLines);
	}

	deleteProductionLine = () => {
		const instance = this;
		var data = this.productionLines.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_production_line'), data.name)).done(function () {
			instance.deleteProductionLine2(data.id, instance.loadProductionLines);
		});

	}

	modifyProductionLine = (productionLine, handler, errorHandler) => {
		post("adminService/" + localStorage.restaurantSelected + "/modifyProductionLine", productionLine).done(function (data) {
			resultHandler(data, handler, errorHandler);
		});
	}

	addProductionLine = (productionLine, handler, errorHandler) => {
		post("adminService/" + localStorage.restaurantSelected + "/addProductionLine", productionLine).done(function (data) {
			resultHandler(data, handler, errorHandler)
		});
	}

	deleteProductionLine2 = (id, handler, errorHandler) => {
		get("adminService/" + localStorage.restaurantSelected + "/deleteProductionLine/" + id).done(function (data) {
			resultHandler(data, handler, errorHandler)
		});
	}

}

export default ProductionLinesCode;
