import $ from "jquery";

export const _qrcode = (target, url, label, size) => {
  //if (ReactDOM.findDOMNode(target.get(0)) !== null) ReactDOM.unmountComponentAtNode(target.get(0));
  //ReactDOM.render(<ReactQRCode url={url} size={size ? size : target.get(0).getBoundingClientRect().height} />, target.get(0));

  $(target)
    .empty()
    .qrcode({
      // render method: 'canvas', 'image' or 'div'
      render: $(target).get(0).localName,

      // version range somewhere in 1 .. 40
      minVersion: 15,
      maxVersion: 20,

      // error correction level: 'L', 'M', 'Q' or 'H'
      ecLevel: "M",

      // offset in pixel if drawn onto existing canvas
      left: 0,
      top: 0,

      // size in pixel
      //size: 300,

      // code color or image element
      fill: "#000",
      size: size,

      // background color or image element, null for transparent background
      background: "#ffffff",

      // content
      text: url,

      // corner radius relative to module width: 0.0 .. 0.5
      radius: 0.1,

      // quiet zone in modules
      quiet: 0,

      // modes
      // 0: normal
      // 1: label strip
      // 2: label box
      // 3: image strip
      // 4: image box
      mode: 1,

      mSize: 0.06,
      mPosX: 0.5,
      mPosY: 0.7,

      label: label,
      fontname: "sans",
      fontcolor: "#000",

      image: null
    });
};
