import { unflattenObject, flattenObject } from "../components/utils";
import superadmin, { modifyRestaurant, addRestaurant, deleteRestaurant } from "../js/superadmin/superadmin";
import { updateRow, deleteRow, addNewRow } from "./rootReducer";
import { store } from "..";
import EatWithMeActions from "../actions/EatWithMeActions";
import { confirmDialog } from "../js/auth";
import { I18n } from "react-redux-i18n";

const restaurantReducer = (state, action) => {
  switch (action.type) {
    case "updateRow":
      {
        const data = action.row;
        const newRestaurant = { ...data };
        const baseCurrency = state.myStatus.currencies.find(c => {
          return c.name === newRestaurant.baseCurrency;
        });
        if (newRestaurant.baseCurrency && newRestaurant.baseCurrency !== undefined) {
          newRestaurant.baseCurrency = { id: baseCurrency.id };
        } else {
          newRestaurant.baseCurrency = null;
        }
        if (data.id) {
          modifyRestaurant(newRestaurant, restaurant => {
            restaurant._key = action.row._key;
            updateRow({ stateKey: action.stateKey, row: flattenObject(restaurant) });
            if (restaurant.baseCurrency) restaurant.baseCurrency = restaurant.baseCurrency.name;
            restaurant.timestamp = new Date().getTime();
            setTimeout(() => {
              store.dispatch(EatWithMeActions.restaurantSelected(restaurant));
            }, 100);
          });
        } else if (data.name && data.baseCurrency)
          addRestaurant(newRestaurant, restaurant => {
            restaurant._key = action.row._key;
            restaurant.key = action.row.id;
            updateRow({ stateKey: action.stateKey, row: flattenObject(restaurant) });
            if (restaurant.baseCurrency) restaurant.baseCurrency = restaurant.baseCurrency.name;
            restaurant.timestamp = new Date().getTime();
            setTimeout(() => {
              store.dispatch(EatWithMeActions.restaurantSelected(restaurant));
            }, 100);
            addNewRow(EatWithMeActions.addNewRestaurant({ stateKey: action.stateKey }));
          });
        else setTimeout(() => updateRow(action), 10);
      }
      break;
    case "deleteRow":
      {
        const data = unflattenObject(action.row);
        if (data.id) {
          deleteRestaurant(data.id, data => {
            deleteRow(action);
          });
        }
      }
      break;
    case "deleteOrderHistory":
      {
        const data = unflattenObject(action.row);
        if (data.id) {
          confirmDialog(I18n.t("local.confirmation"), I18n.t("Are you sure you want to delete ALL the table occupations and orders?")).done(function () {
            superadmin.deleteAllTableOccupations(data.id, function (data) {

            })
          });
        }
      }
      break;

    default:
  }
  return state;
};

export default restaurantReducer;
