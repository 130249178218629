import ean from "ean-check";
import { getPaymentMethod } from "../tableMatePaid";

export const generate_sento = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) => {
    var url = "";
    var totalAmount = 0;
    var services = {};
    var a = 0;

    const sentoGyujtok = {
        5: 5,
        18: 18,
        27: 27,
        s5: 6,
        s18: 19,
        s27: 28,
        null: 99,
        undefined: 99
    }

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount + category.service_amount).forEach(category => {
        if (category.amount) {
            url += ean.ean13.generate(200000000000 + sentoGyujtok[category.vat] * 1000000000 + Math.round(category.amount * category.count)) + "\n";
            totalAmount += category.amount * category.count;
            a += category.amount * category.count;
        }
        if (category.service_amount) {
            if (!services[category.vat])
                services[category.vat] = 0;
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });

    Object.keys(services).forEach(key => {
        a += services[key];
        url += ean.ean13.generate(200000000000 + sentoGyujtok["s" + key] * 1000000000 + services[key]) + "\n";
    })

    var paymentMethods = {
        "cash": "08",
        "card": "01",
        "hellopay": "01",
        "teya": "01",
        "online": "05",
        "bizpay": "01",
        "paynance": "01",
    }

    types.filter(t => t.amount > 0).forEach((t, ind) => {
        if (ind > 0)
            url += "|";
        const paymentMethod = getPaymentMethod(t.type);

        //var roundFunction = auth.myStatus.restaurant_base_currency["javaScriptRoundFunction"];
        var amount = t.amount;
        // eslint-disable-next-line no-eval
        //amount = eval(roundFunction.replace("amount", t.amount));

        if (t.currency === "EUR" && paymentMethod.method.type === "cash")
            url += "#b07" + ("" + (10000000000 + (Math.round(100 * t.amount) / 100))).substring(2) + "\n";
        else {
            var a = ("" + (10000000000 + amount));
            a = a.substring(2);
            url += "#b" + paymentMethods[paymentMethod.method.type] + a + "\n";
        }
        /*
      if ("fiscat3" === cashierType)
        url += "|";*/
    })

    console.log(url);
    def.resolve(url);
}  