import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import { Translate } from "react-redux-i18n";

class MessageDialog extends Component {
  state = { show: this.props.show };

  constructor(props) {
    super(props);
    this.state.show = this.props.show;

    this.props.def.done(() => {
      console.log("Unmount message")
      this.unmount();
    });
  }

  componentDidMount() {
    this.d.innerHTML = this.props.message;
  }

  unmount() {
    ReactDOM.unmountComponentAtNode(this.props.container);
    this.props.def.resolve();
  }

  render() {
    if (this.state.show === false) {
      return null;
    }
    return (
      <Modal style={{ zIndex: 20001 }} show={this.state.show} onHide={this.props.def.resolve} backdrop={false} centered>
        <Modal.Header className="eatwithme no-close">
          <h1>{this.props.title}</h1>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "1.8em" }}>
          <div ref={d => { this.d = d }}>{this.props.message}</div>
        </Modal.Body>
        {this.props.noOk ? null :
          <Modal.Footer>
            <Button
              onClick={() => {
                this.unmount();
                this.props.def.resolve();
              }}
            >
              <Translate value="local.ok" />
            </Button>
          </Modal.Footer>}
      </Modal>
    );
  }
}

export default MessageDialog;
