const local_en = {
  ml: "ml",
  dl: "dl",
  cl: "cl",
  l: "l",
  pcs: "pcs",
  g: "g",
  dkg: "dkg",
  kg: "kg",
  none: "N/A",
  day: "day",
  hour: "hour",
  min: "minute",

  sign_up: "Sign-up",
  login: "Login",
  provide_first_name: "Please provide your first name",
  surname: "Familiy name",
  provide_surname: "Please provide your family name",
  email: "Email address",
  provide_email: "Please provide your email address",
  quick_access_code: "Quick code",
  require_quick_access_code: "Compulsory",
  provide_password: "Please provide your password",
  forgot_password: "Forgot your password?",
  need_sign_up: "Need to sign up?",
  registration: "Registration",
  register: "Register",
  back_to_login: "Back to login",
  social_login: "or sign in with social account",
  email_already_in_use: "E-mail address already in use!",
  activation_email_sent: "An activation email was sent to {0}. Please check your inbox including the SPAM folder!",
  activation_email_sent2: "An activation email was sent to {0}. Please check your inbox including the SPAM folder!",
  password_reset_email_sent: "The password reset email was sent successfully to {0}!",
  password_reset_email_sent2: "The password reset email was sent successfully to %{email}!",
  confirm_account_deletion: "Are you sure you want to delete your account?",
  account_deleted_successfully: "Your account was deleted successfully!",
  bad_password_request_token: "Bad or expired password reset token! Please request a new one!",
  password_reset_successfully: "New password set successfully!",
  missing_role: "Access denied! Required role not assigned to your user!",
  password_forgotten_text: "Please provide your email address. We will send you a mail with a link to set up your new password.",
  register_first: "Please register first before using any of the third party login service!",

  login_failed: "Login failed! Bad email or password!",
  communication_error: "Communication error with the server (error code: {0})!",

  join: "Join",
  occupy: "Occupy",
  ok: "Ok",
  cancel: "Cancel",
  apply: "Apply",

  get_a_table: "Get a table",
  locked_get_a_table_one_table: "Table no. {0}\n{2} people",
  locked_get_a_table_more_tables: "{0} tables\n{2} people",
  joined_get_a_table: "Joined\ntable\nno. {0}",
  joined_not_authorized_get_a_table: "Joined\nbut not\napproved",
  let_other_to_join_your_table: "Let others join your table",

  the_lead_customer_cannot_be_unapproved: "You cannot change the joining status of the organizer!",

  confirmation: "Confirmation",
  warning: "Warning",
  do_you_want_to_be_the_organizer: "Do you really want to be the organizer?",
  approvedBy: "Approved by {0}",
  paidBy: "Paid by {0}",
  waiting_for_approval_from_somebody: "Waiting for approval from somebody!",
  your_mate_is_not_intending_to_pay_his_bill: "Your mate is not intending to pay his bill!",
  your_mate_intends_to_pay_his_bill: "Your mate intends to pay his bill but also allows you to invite him!",
  your_mate_wants_to_pay: "Your mate wants to pay his bill and does not allow anybody else to do so!",
  shared_table_tooltip: "The remaining free seats at this table can be occupied by somebody else!",
  request_from: "Request from {0}",
  message_from: "Message from {0}",
  error_message: "Error message",
  warning_message: "Warning message",
  question: "Question",
  suggestion: "Suggestion",
  information: "Information",
  success_message: "Success",
  organizer_request_denied: "I do not agree for you to become the organizer!",
  organizer_request: "Please allow me to take of the organizer role from you!",
  organizer_confirmation: "You became the organizer!",
  let_me_pay: "Let me pay instead",
  approve_joining: "Approve joining",
  reject_joining: "Reject joining",
  reject_paying: "I don't want to pay",
  i_am_paying: "I want to pay",
  does_not_agree_invite: "{0} does not agree with you to pay!",
  do_you_want_to_leave: "Do you really want to leave this table?",
  lock_another_table: "Occupy another table",
  unlock_table: "Leave the table",
  scanToAddTable: "Occupy another table",
  scanToChangeTable: "I change to this table",
  somebody: "Somebody",
  sending: "Send password reset e-mail",
  password_reset: "Set new password",
  ask_password_reset: "Reset password",
  password_forgotten: "Password forgotten",

  message_already_joined: "You already joined this table!",
  message_nobody_to_join: "There is nobody to join at this table!",
  message_nobody_allows_joining: "Currently nobody allows joining at this table!",
  message_do_you_want_to_switch_table: "You already joined table {0}! Do you want to switch to table {1}?",
  message_do_you_want_to_join_table_with_approval: "The table is already occupied and people can join his table after getting approval! Do you want to join?",
  message_do_you_want_to_join_table_without_approval: "The table is already occupied and is allowed to join without further approval! Do you want to join?",
  massage_you_do_not_want_to_pay: "In the settings you stated that you don't want to pay! Change the setting if you still intend to pay!",
  message_you_can_join_only_one_company: "You already joined a table and you are only allowed to switch to a different table of the same party!",
  choose_between_joining_or_occupying_a_table: "Would you like to occupy the table or join an existing party instead?",
  this_table_is_occupied: "This table is occupied and currently nobody allows joining at this table!",
  this_table_is_occupied_by_multiple_parties_join_directly:
    "There are multiple parties at this table you could join! Please ask an already joined participant to provide you with a QR code within the EatWithMe application in their phone! This way it won't be ambiguous which party do you want to join!",
  would_you_create_a_picture_as_well: "Add a photo as well! It will make easier identification later!",
  message_do_you_really_want_to_leave_the_table: "Do you really want to leave the table?",
  message_do_you_really_want_to_unlock_table_not_yourself: "Does he really leave the table?",
  message_do_you_really_want_to_unlock_table: "Do you really want to leave the table?",
  are_you_sure_you_want_to_pay_for_yourself: "Are you sure you would like to pay your consumption instead {0}?",
  are_you_sure_you_want_to_pay_instead: "Are you sure you would like to pay for {1} instead {0}?",
  are_you_sure_you_want_to_pay_instead_and_more: "Are you sure you would like to pay for {1} and {2} more people instead {0}?",
  reject_invite: "{0} does not allow for you to pay her/his consumption!",

  qr_code_generation_failed: "Failed to read the QR code! Want to try again?",

  leave_table: "Leave table",
  leave_the_table: "Leave the table",
  message_table_join_cancelled: "You left the table successfully!",
  message_subprofile_join_cancelled: "{0} removed from the participants!",
  profileUpdatedSuccessfully: "Your profile was successfully updated",

  first_name: "Name",
  family_name: "Family&nbsp;name",
  password: "Password",

  new_subprofile: "Add new subprofile (e.g. for your child)",
  profile_picture: "Profile&nbsp;picture",
  my_favorites: "My favourites",
  favorite_of: "Favourite of",
  subprofiles: "Sub-profiles",
  subprofile_tooltip:
    "You may create sub-profiles for people (like your children or parents) who do not have email account or do not have access to them currently!<br/><br/>This way you can order later for them separately without them having an account of their own!",
  my_favorites_tooltip: "After their <b>confirmation</b> you can add your favourites to your table without asking their permission!<br/><br/>Also they can join your table without your approval!",
  favorite_of_tooltip: "After your <b>confirmation</b> you can be added by them to their table without asking your permission!<br/><br/>Also you can join their table without their further approval!",

  couldNotFindCustomer: "Could not find customer with the given email address!",
  subprofileAlreadyUsedAsTableMate: "{0} was already part of an event! Are you sure you want to delete {0}!",

  favorite_approved_tooltip: "Approved",
  favorite_pending_tooltip: "Pending approval",
  placeholder_add_an_email: "Type an email address...",
  placeholder_add_a_name: "Type an name...",
  subprofileAlreadyExists: "Sub-profile already exists!",
  subprofile: "Sub-profile",
  name: "Name",
  do_you_really_want_to_delete_your_favoriteOf: "Do you really want to delete the favourite request from <b>{0}</b>?",
  do_you_really_want_to_delete_your_favorite: "Do you really want to delete from your favourites <b>{0}</b>?",
  do_you_really_want_to_delete_your_subprofile: "Do you really want to delete the sub-profile <b>{0}</b>?",
  do_you_really_want_to_unjoin_your_subprofile: "Do you really want to remove <b>{0}</b> from the participants?",
  message_do_you_really_want_to_change_table: "Do you really want to move {0} to table {1}?",

  thisEmailIsAlreadyBoundWithAnotherAccount: "This email address is already bound with another account!",

  people_tab: "Table mates",
  tables_tab: "My tables",
  table_tab: "My table",
  settings_tab: "Settings",

  paying: "Paying",
  wants_to_pay: "Wants to pay",
  i_do_not_pay: "I won't pay",
  i_could_pay: "I don't mind paying",
  i_want_to_pay: "I want to pay",
  i_will_pay: "I will pay",
  i_am_invited: "I am a guest of somebody",

  paid_for: "Table mates",
  i_pay_for: "I cover for them",
  tableMates: "Undecided who pays for them",
  joiningTableMates: "Wanting to join in",
  your_joined_tables: "My joined table",
  your_tables: "My tables",

  add_sub_profile: "Add sub-profile",
  add_participant: "Add new participant(s)",
  close: "Close",
  scan_to_join_table: "Scan to join table {0}",
  add_additional_participants_as_subprofiles: "Subprofiles",
  add_additional_participants_without_table: "Participants without a table",
  add_mate: "Add",

  table_picker_title: "Choose a table",
  table_picker: "Table no. {0}",
  my_table_picker: "Table no. {0} (here I sit)",
  my_mate_picker: "{0} (myself)",
  mate_picker: "{0}",
  everybody: "All {0}",
  allTables: "All tables",
  i_pay_for2: "All {0} I pay for",

  order: "Order",
  orderMeals: "Meals",
  orderDrinks: "Drinks",
  unknown: "???",
  byCustomer: "By person",
  byDrinkOrMeal: "By meal or drink",
  byDrink: "By drink",
  byMeal: "By meal",

  do_you_really_want_to_delete_all_new_orders: "Do you really want to empty the cart?",
  do_you_really_want_to_delete_all_orders: "Do you really want to delete all new not confirmed orders?",
  please_choose_mate_for_this_order: "{0} {1} {2} - For whom do you order?",
  select: "Select",

  order_items: "Item count:",
  people_count: "People count:",

  do_you_really_want_to_submit_all_new_orders: "Do you really want to sumbit all new orders?",

  show_table_qr: "Organize your tables",

  seats_wanted: "For how many person:",

  placeholder: "Seat occupation",
  my_profile: "My profile",

  unlocking_not_possible_there_are_pending_orders: "The person cannot leave the table. There are pending orders!",
  unjoining_somebody_not_possible_there_are_pending_orders: "Cannot leave the table until you have pending orders paid by s/he!",
  unjoining_yourself_not_possible_there_are_pending_orders: "You cannot leave the table until you have pending orders paid by yourself!",
  unjoining_somebody_not_possible_paying_for_others: "The person cannot leave the table. S/he is paying for other people!",

  comment: "Comment",
  enter_comment: "Enter comment (optional)",
  this_item_was_originally_ordered_by: "This item was originally ordered by {0}!",
  this_item_was_ordered_by: "This item was selected by {0}!",
  cancelled: "Cancelled",
  selected: "Selected",
  ordered: "Ordered",
  accepted: "Accepted",
  producing: "Producing",
  delivering: "Delivering",
  delivered: "Delivered",
  paid: "Paid",

  rejection_reason: ["Input by mistake", "Not possible", "Out of stock", "Not available", "Was not prepared", "Was prepared to late"],

  timing: "Timing",
  timing_inherit: "Inherit",
  timing_asap: "Asap",
  timing_appetizer: "Appetizer-Soup",
  timing_maincourse: "Inbetween",
  timing_desert: "Main couse",
  timing_last: "Dessert",

  for_whom: "For whom",

  unknown_person: "Person {0}",

  additions: "Additional ingredients or toppings",
  enter_additions: "Add additional ingredients or toppings to customize your drink or meal",
  inherited_additions: "Inherited ingredients or options",
  noResultsMessageText: 'No results mached "%s"',
  options: "Options",

  reload_page: "Refresh",
  timeout: "Timeout",
  server_unavailable: "Failed to communicate with the server!",
  error_code_500: "Internal server error",
  error_code_400: "Bad request",
  continue: "Ignore and continue",
  modify: "Modify",
  delete: "Delete",
  order_cannot_be_deleted: "Order cannot be deleted anymore",
  orders_only_in_selected_or_ordered_state_can_be_deleted: "Orders only in 'selected' or 'ordered' state can be deleted!",
  left_the_table: "left",
  deleted_or_not_found: "Deleted or not found",

  qr_code: "QR code",
  person: "Person",

  do_you_really_want_to_quit_pending_orders: "Do you really want to quit? There are pending orders!",
  quantity: "Quantity",
  paying_online: "Paying online...",

  feedback: {
    label: "Send Feedback",
    header: "Send Feedback",
    nextLabel: "Continue",
    reviewLabel: "Review",
    sendLabel: "Send",
    closeLabel: "Close",
    messageSuccess: "Your feedback was sent succesfully.",
    messageError: "There was an error sending your feedback to the server.",
    pleaseDescribe: "Please describe the issue you are experiencing",
    issue: "The issue",
    blackout: "Blackout",
    highlight: "Highlight",
    highlight_or_blackout: "Highlight or blackout important information"
  },

  help: {
    next: "Next",
    back: "Back",
    done: "Done",
    skip: "Skip"
  },

  nameformat: "{1} {0}",
  service_fee: "Service fee",

  daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  server_restart: "The server is being upgraded. Please wait patiently until the server restarts and the connection is restored!",
  email_not_associated_with_an_account: "Email not associated with an account",
  the_chosen_restauarant_online_services_seems_to_be_unavailable: "{0} is currently not available!",
  phone: "Phone",
  address: "Address",
  town: "Town",
  country: "Country",
  postcode: "Zip code",
  allDay: "all day",
  download_progress: "Upgrade in progress ({0}%)",
  installing_update: "Installing update",
  new_version_is_available: "A new version of the application is available!",
  applelogin: "Login with Apple",
  facebooklogin: "Login with Facebook",
  door: "Floor/door",
  consent_successfull: "Thanks for your consent",
  update_successfull: "Update successful",
  consent_withdrawal_successfull: "You withdrew your consent to handle your personal data! Your personal details has been deleted from our system!",
  switch_back_to_live: "The main server is available again! Switching back to it!",
  switch_to_backup: "The main server currently is not available! Switching to backup server!",
  access_denied: "A login was successful, however, it seems that you do not have access to any of the environments. Please contact us on support@eatwithme.online if you think this is an error on our side.",
  pay_later: "Pay later",
  activation_code: "Aktivation code",
  bad_activation_code: "Wrong or expired activation code",
  activate: "Activate",
  activation: "Activation",
  no_internet: "No internet connection",
  request: "Request",
  please_perform_ntak_registration: "Please perform the NTAK administration!",
};
export default local_en;
