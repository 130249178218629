import React from "react";
import { TileLayer, Marker, Popup, Map as MapContainer, useLeaflet as useMapEvents } from "react-leaflet";
import AbstractRestaurantEditor from "./AbstractRestaurantEditor";
import Buttons from "./Buttons";

function MyComponent({ t }) {
  useMapEvents({
    click(event) {
      t.setState({ restaurant: { ...t.state.restaurant, longitude: event.latlng.lng, latitude: event.latlng.lat }, changed: true });
    },
    locationfound: (location) => {
      console.log('location found:', location)
    },
  })
  return null
}

class RestaurantSettings extends AbstractRestaurantEditor {
  handleClick = event => {
    this.setState({ restaurant: { ...this.state.restaurant, longitude: event.latlng.lng, latitude: event.latlng.lat }, changed: true });
  };

  render() {
    if (this.state.restaurant) {
      const { restaurant } = this.state;
      const position = [restaurant.latitude ? restaurant.latitude : 0, restaurant.longitude ? restaurant.longitude : 0];
      return (
        <React.Fragment>
          <MapContainer style={{ height: this.props.height + "px" }} center={position} zoom="13" attributionControl={false} onClick={this.handleClick}>
            <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
            <Marker position={position}>
              <Popup>{restaurant.name}</Popup>
            </Marker>
            <MyComponent t={this} />
          </MapContainer>
          <Buttons restaurant={this.state.restaurant} changed={this.state.changed} handleCancel={this.props.handleCancel} handleSave={this.props.handleSave} />
        </React.Fragment>
      );
    }
    return null;
  }
}


export default RestaurantSettings;
