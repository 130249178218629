import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import DailyClosingCode from "../js/admin/DailyClosingCode";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import LoginButton from "../components/icon-buttons/LoginButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { getMyImageUrl } from "../js/auth";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import "./dailyClosing.css";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import "daterangepicker/daterangepicker.css";
import moment from "moment";
import TimeRangeSlider from "react-time-range-slider";
import BackButton from "../components/menus/BackButton";
import FlexGrowSpace from "../widgets/FlexGrowSpace";
import RestaurantSelector from "../components/RestaurantSelector";
import EntityPage from "../components/EntityPage";

class DailyClosing extends EntityPage {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.tableOccupations_fromDate)),
        endDate: moment(Number(localStorage.tableOccupations_toDate)),
    };

    constructor() {
        super('admin_local.menus.daily_closing');
        this.code = new DailyClosingCode()
    }


    static getDerivedStateFromProps(props, state) {
        if (props.maxTime)
            return {
                timeValue: {
                    start: { hours: props.shift, minutes: 0 },
                    end: { hours: props.maxTime, minutes: 0 }
                },
                ...state,
            }
        else
            return state;
    }

    /*
    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            ready(this.props).done(() => {
                reload(this.state)
            });
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props).done(() => {
                reload(this.state)
            });
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        stopIntervals();
        this.setState({ initialized: false });
    }

    */

    render() {
        const ranges = {}
        ranges[I18n.t("admin_local.today")] = [moment(), moment()];
        ranges[I18n.t("admin_local.yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[I18n.t("admin_local.last_7_days")] = [moment().subtract(6, 'days'), moment()];
        ranges[I18n.t("admin_local.last_30_days")] = [moment().subtract(29, 'days'), moment()];
        ranges[I18n.t("admin_local.last_3_months")] = [moment().subtract(2, 'month').startOf('month'), moment()];
        ranges[I18n.t("admin_local.this_month")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[I18n.t("admin_local.last_month")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            var start = moment().startOf("day");
            var end = moment().startOf("day");

            var start2 = moment().startOf("day");
            var end2 = moment().startOf("day");

            start.add(this.state.timeValue.start.hours - this.props.shift, 'hours');
            start.add(this.state.timeValue.start.minutes, 'minutes');
            end.add(this.state.timeValue.end.hours - this.props.shift, 'hours');
            end.add(this.state.timeValue.end.minutes, 'minutes');

            start2.add(this.state.timeValue.start.hours, 'hours');
            end2.add(this.state.timeValue.end.hours, 'hours');
            start2.add(this.state.timeValue.start.minutes, 'minutes');
            end2.add(this.state.timeValue.end.minutes, 'minutes');

            return (
                <div id="dailyClosing" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <BackButton {...this.props} />
                            <FlexGrowSpace />
                            <RestaurantSelector {...this.props} onReload={() => { this.code.fullreload(this.props, this.state); }} />
                            <FlexGrowSpace />
                            <span className="myTitle" style={{ fontSize: "24px", marginRight: "20px" }}>
                                {<Translate value="admin_local.daily_closing" />}
                            </span>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
                                    <LoginButton query="keep=true&redirect=/tablesOverview" />
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="range-bar" style={{ flexShrink: 0, display: "flex", flexWrap: "wrap" }}>
                        <div style={{ display: "flex", flexFlow: "column", justifyItems: "center", alignItems: "center", paddingLeft: "2rem", paddingRight: "2rem", flexGrow: 1 }}>
                            <div style={{ display: "flex", flexWrap: "nowrap" }}>
                                <DateRangePicker startDate={{ ...this.state.startDate }} endDate={{ ...this.state.endDate }} onApply={this.dateRangeChanged} ranges={ranges}>
                                    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}><i className="fa fa-calendar" ></i><span>{this.state.startDate.format("LL")} - {this.state.endDate.format("LL")}</span></div>
                                </DateRangePicker>
                                <span>{start2.format("HH:mm")}</span> - <span>{end2.format("HH:mm")}</span>
                            </div>
                            <div style={{ height: "2rem", width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <div style={{ width: "100%", height: "1rem", top: "50%", position: "relative", transform: "translateY(-50%)" }}>
                                    <TimeRangeSlider
                                        disabled={false}
                                        format={24}
                                        minValue={"00:00"}
                                        maxValue={((this.props.maxTime - this.props.shift) + ":00")}
                                        name={"time_range"}
                                        onChangeComplete={this.changeCompleteHandler}
                                        onChange={this.timeChangeHandler}
                                        step={5}
                                        value={{ start: start.format("HH:mm"), end: end.format("HH:mm") }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="main" className="incomingStocks" ></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    changeStartHandler(time) {
        console.log("Start Handler Called", time);
    }

    timeChangeHandler = (time) => {
        const start = { hours: Number(time.start.split(":")[0]), minutes: Number(time.start.split(":")[1]) };
        const end = { hours: Number(time.end.split(":")[0]), minutes: Number(time.end.split(":")[1]) };
        start.hours += Number(this.props.shift);
        end.hours += Number(this.props.shift);

        this.setState({
            timeValue: {
                start: start,
                end: end,
            }
        });
    }

    changeCompleteHandler = (time) => {
        this.code.reload(this.state)
    }

    dateRangeChanged = (event, values) => {
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.tableOccupations_fromDate = values.startDate._d.getTime();
        localStorage.tableOccupations_toDate = values.endDate._d.getTime();
        this.code.reload(this.state);
    }
}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
            shift: props.rootReducer.myStatus.restaurant_settings['business-day-starts-at'],
            maxTime: (((props.rootReducer.myStatus.restaurant_settings['business-day-ends-at'] < props.rootReducer.myStatus.restaurant_settings['business-day-starts-at'] ? props.rootReducer.myStatus.restaurant_settings['business-day-ends-at'] + 24 : props.rootReducer.myStatus.restaurant_settings['business-day-ends-at'])))
        };
    }
    return {};
};

export default connect(mapStateToProps)(DailyClosing);
