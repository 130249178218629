import React, { Component } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { I18n } from "react-redux-i18n";

class TableOccupationAutoComplete extends Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: [],
    filterBy: []
  };

  render() {
    return (
      <AsyncTypeahead
        {...this.state}
        {...this.props}
        id="TableOccupationAutoComplete"
        labelKey="label"
        emptyLabel={I18n.t('admin_local.no_matches_found')}
        minLength={1}
        onSearch={this._handleSearch}
        placeholder={I18n.t('admin_local.order_no')}
        onChange={this.props.onChange}
      />
    );
  }

  _handleSearch = query => {
    const t = this;
    const options2 = this.props.tableOccupations;
    const options = [];
    options2.forEach(occupation => {
      if (occupation.bookedProperties)
        if (options.filter(c => { return c.occupation.id === occupation.id }).length === 0) {
          const label = "" + occupation.id + " - " + occupation.bookedProperties.name;
          options.push({ label: label, occupation });
        }
    })
    t.setState({
      isLoading: false,
      options: options
    });
  }
}

export default TableOccupationAutoComplete;
