import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import auth, { getMyImageUrl, getString, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import { withRouter } from "react-router-dom";


class ShoppingList extends Component {
    state = {
        initialized: false,
        html: ""
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            getString("adminService/" + localStorage.restaurantSelected + "/stockHtml?lang=" + localStorage.language).done(html => {
                this.setState({ html: html })
            })
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            getString("adminService/" + localStorage.restaurantSelected + "/stockHtml?lang=" + localStorage.language).done(html => {
                this.setState({ html: html })
            })
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        this.setState({ initialized: false });
    }

    render() {
        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="Suppliers" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.shoppingList" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "30px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div id="shoppingList" dangerouslySetInnerHTML={{ __html: this.state.html }} style={{ overflow: "auto" }}></div>
                </div >
            );
        } else return null;
    }

}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(ShoppingList));
