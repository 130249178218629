import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class HelpButton extends Component {
  state = {};
  render() {
    return <a className="icon-help browser-only" href={this.props.href} style={{ height: "25px", transform: "scale(1.2)" }}> </a>;
  }
}

export default withRouter(HelpButton);
