import React, { Component } from "react";

class ControledInput extends Component {
  state = {
    value: ""
  };

  static getDerivedStateFromProps(props, state) {
    const s = {
      ...state,
    };
    if (!s.quickChange)
      s.value = props.value;
    else
      delete s.quickChange
    return s;
  }

  render() {
    return <input className={this.props.className} id={this.props.id} disabled={this.props.disabled} onChange={this.onChange} value={this.state.value} placeholder={this.props.placeholder}></input>;
  }

  onChange = event => {
    this.quickChange = true;
    const state = { value: event.target.value, quickChange: true };
    this.setState(state);
    const id = event.target.id;
    const value = event.target.value;
    const instance = this;
    if (this.props.onChange) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.timeout = null;
        instance.setState({
          value: null
        })
        instance.props.onChange({ target: { id: id, value: value } });
      }, 400);
    }
  };
}

export default ControledInput;
