
class EntityPageCode {

    globalFunctions = [];

    publishFunctions(globalFunctionss) {
        this.globalFunctions = globalFunctionss;
        this.globalFunctions.forEach(f => {
            window[f] = this[f];
        })
    }

    clear() {
        this.globalFunctions.forEach(f => {
            delete window[f];
        })
    }

}

export default EntityPageCode;