import React, { Component } from "react";
import orderListUtil, { updateTableMates, update, updateOrders, deleteOrders, getTableName2 as getTableName, simpleChangeStateByTableAndMenuItem as simpleChangeStateByTableAndMenuItem2, playNewAcceptedOrderSound, initSound, getTableOccupation, getTable } from "../../js/order-list-util";
import { I18n, Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import LoginButton from "../../components/icon-buttons/LoginButton";
import auth, { booleanSetting, featureEnabled, getMyImageUrl, startpoll, stopPool } from "../../js/auth";
import EatWithMeText from "../../components/EatWIthMeText";
import MenuItemHeader from "../../components/MenuItemHeader";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import admin, { getTableOccupations } from "../../js/admin";
import "./GuestScreen.css";
import tableLayout from "../../js/table-layout";
import $ from "jquery";

let svgNS = "http://www.w3.org/2000/svg";

let totalWidth = 1920;
let totalHeight = 1000;
let initialRadius = 40;  // Start with a large circle radius

let circles = [];

let circleR = [80, 50, 40, 30, 20];
let layerR = [0, 150, 240, 320, 400];

var [fromX, fromY, fromWidth, fromHeight] = [1824.8106689453125, - 190, 216.1893310546875, 201.8935546875];


class GuestScreen extends Component {
  state = {
    initialized: false,
    soundon: "true" === localStorage.soundon,
    playSound: false,
    delivering: [],
    newDelivering: []
  };

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id) {
      this.setState({ initialized: true });
      orderListUtil.show = () => true;
      this.initialize();
    }
    window.simpleChangeStateByTableAndMenuItem = this.simpleChangeStateByTableAndMenuItem;
    if (this.state.soundon) {
      initSound();
    }

  }

  simpleChangeStateByTableAndMenuItem = (t, previousState, state, timing) => {
    const def = $.Deferred();
    simpleChangeStateByTableAndMenuItem2(t, previousState, state, timing, def);
    def.promise().done(() => {
      orderListUtil.processOrders(orderListUtil.ordersCache, false);
      this.setState({
        orders: orderListUtil.orders
      })
    });
  }

  recover = () => {
    admin.getUnprocessedOrders([], orders => {
      orderListUtil.ordersCache = orders;
      orderListUtil.processOrders(orders);
      this.setState({
        orders: orderListUtil.orders,
        newDelivering: this.getDelivering(orderListUtil.orders)
      })
    })
  }

  toShow = []

  s1 = () => {
    const text = this.toShow.pop();
    this.insertNewCircle(text);
    this.setState({ delivering: [...this.state.delivering, text] });
    setTimeout(() => {
      if (this.toShow.length) {
        this.toShowTask = setTimeout(this.s1, 0);
      } else if (this.toHide.length) {
        this.toShowTask = setTimeout(this.h1, 0);
      } else
        this.toShowTask = undefined;
    }, 6000)
  }
  h1 = () => {
    const text = this.toHide.pop();
    this.deleteNewCircle(text);
    this.setState({ delivering: this.state.delivering.filter(t => t != text) });
    setTimeout(() => {
      if (this.toShow.length) {
        this.toShowTask = setTimeout(this.s1, 0);
      } else if (this.toHide.length) {
        this.toShowTask = setTimeout(this.h1, 0);
      } else
        this.toShowTask = undefined;
    }, 6000)
  }

  show = (t) => {
    if (this.toShow.indexOf(t) !== -1)
      return;
    this.toShow.push(t);
    if (!this.toShowTask) {
      this.toShowTask = setTimeout(this.s1, 0);
    }
  }

  toHide = []
  hide = (t) => {
    if (this.toHide.indexOf(t) !== -1)
      return;
    this.toHide.push(t);
    if (!this.toShowTask) {
      this.toShowTask = setTimeout(this.h1, 0);
    }
  }


  initialize = () => {
    orderListUtil.states = ["ordered", "delivering"];
    getTableOccupations((data) => {
      orderListUtil.tableOccupations = data;
      updateTableMates();
      admin.getActiveMenuItems((categs) => {
        admin.categories = categs;
        admin.getUnprocessedOrders([], orders => {
          orderListUtil.ordersCache = orders;
          orderListUtil.processOrders(orders);
          const showTableNames = this.props.myStatus.restaurant_tables.filter(t => t.pos).length > 3;
          this.setState({
            tableOccupations: data,
            categories: categs,
            orders: orderListUtil.orders,
            newDelivering: this.getDelivering(orderListUtil.orders, showTableNames),
            showTableNames
          })
        }, {
          show: function (order) {
            return order.type === "meal";
          },
          done: function () {
          }
        });
      }, localStorage.language);
    });
    startpoll(this.serverSideMessageHandler, [
      {
        Restaurant: localStorage.restaurantSelected,
        Order: "*"
      },
      {
        Restaurant: localStorage.restaurantSelected,
        Menu: "*"
      },
      {
        Restaurant: localStorage.restaurantSelected,
        TableOccupation: "*"
      }
    ], undefined, this.recover);
    this.refreshCycle = setInterval(() => {
      this.setState({ timestamp: new Date().getTime() });
    }, 10000);
  }

  serverSideMessageHandler = (message) => {
    try {
      if (message.type === "timeout" && message.message === "true") return;
      if (message.type === "refresh") {
        if (message.message === "order changed" || message.message === "order state changed" || message.message === "new order" || message.message === "new order selected") {
          if (message.data) update(message.data);
          else admin.getOrder(message.TableOccupation, message.Order, updateOrders);
          this.refresh();
        } else if (message.message == "table changed" || message.message == "waiter called") {
          if (message.data) {
            update(message.data);
            this.refresh();
          } else admin.getTableOccupation(message.TableOccupation, this.refresh);
        } else if (message.message === "order cancelled") {
          if (message.data) {
            orderListUtil.deleteOrders(message.Order, true);
            update(message.data);
            this.refresh();
          } else {
            deleteOrders(message.Order);
            this.refresh();
          }
        }
        this.setState({
          orders: orderListUtil.orders
        })
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      this.setState({ initialized: true });
      orderListUtil.show = () => true;
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.setState({ initialized: false });
    clearInterval(this.refreshCycle);
    stopPool();
    clearInterval(this.playSoundInterval);
  }

  getDelivering(orders, showTableNames = this.state.showTableNames) {
    const { myStatus } = this.props;
    const showPart = booleanSetting("tables-overview/notify-waiter-when-part-ready");
    var delivering = [];
    if (myStatus && myStatus.restaurant_id && orders) {
      if (showPart) {
        delivering = orders.tables.filter(t => t.stateMap.delivering.length > 0).sort((a, b) => b.inStateDurations.delivering - a.inStateDurations.delivering);
      } else {
        delivering = orders.ordersByTiming.map(t => t.customers.filter(tt => tt.state === "delivering")).filter(t => t.length).reduce((a, b) => [...a, ...b], []).sort((a, b) => b.inStateDurations.delivering - a.inStateDurations.delivering);
      }
    }
    return delivering.map(t => !getTable(t.tableNumber).pos || showTableNames ? getTableName(t.tableNumber) : "#" + getTableOccupation(t.tableOccupation).seqnr);
  }

  render() {
    const { myStatus } = this.props;
    const { delivering, newDelivering } = this.state;
    if (myStatus && myStatus.restaurant_id) {
      const newOnes = newDelivering.filter(t => delivering.indexOf(t) === -1);
      const deletedOnes = delivering.filter(t => newDelivering.indexOf(t) === -1);
      if (newOnes.length) {
        this.show(newOnes.pop());
      } else if (deletedOnes.length) {
        this.hide(deletedOnes.pop());
      }
      return (
        <div id="guestScreen" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
          <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
          <div id="main" >
            <div class="delivering">
              <div class="title">{I18n.t("admin_local.pickupyourorder")}</div>
              <svg width="" height="" id="svgCanvas" viewBox="1824.8106689453125 -190 216.1893310546875 201.8935546875"
                style={{ "backgroundRepeat": "no-repeat", background: "rgb(2,0,36)", background: "radial-gradient(circle farthest-corner at right top, rgba(2,0,36,1), rgba(0,212,255,1))" }}>
                <defs>
                  <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                    <feDropShadow dx="0" dy="4" stdDeviation="5" flood-color="black" flood-opacity="0.6" />
                  </filter>
                </defs>
                <rect x="2020" y="-170" width="1" height="1" />
              </svg>
            </div>
          </div >
        </div >
      );

    } else return (<div id="waiterScreen" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
      {!auth.isIncludedInFrame ? (
        <div
          className="menuItemHeader"
          style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
        >
          <EatWithMeText />
          <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.guestScreen" />} />
          <button data-toggle="toggle" onClick={this.toggleSound} id="sound" className={"btn icon " + (this.state.soundon ? 'icon-volume-up' : 'icon-volume-off')}>
          </button>

          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <ChangeLanguage />
            <UserImageButton src={getMyImageUrl()} />
            <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
              <LoginButton query="keep=true&redirect=/tablesOverview" />
              <LogoutButton query="keep=true&redirect=/tablesOverview" />
            </div>
          </div>
        </div>
      ) : (
        ""
      )
      }
      <audio id="front-desk-bells" src="https://console.eatwithme.online/audio/front-desk-bells.wav" preload="auto"></audio>
      <audio id="silent" src="https://console.eatwithme.online/audio/silent.wav" preload="auto"></audio>
      <div id="main" >
      </div >
    </div >);
  }

  tableDelivered = (e) => {
    tableLayout.tableDelivered(e).done(this.refresh);
  }

  acceptOrders = (e) => {
    const tableNumber = $(e.target).attr("tableNumber");
    tableLayout.showSimpleOrderTimeout = setTimeout(() => tableLayout.showSimpleOrder(tableNumber, 'ordered', undefined, true), 400);
    tableLayout.tableAccepted(e, tableNumber).done(this.refresh);
  }

  refresh = () => {
    orderListUtil.processOrders(orderListUtil.ordersCache, false);
    this.setState({
      orders: orderListUtil.orders,
      newDelivering: this.getDelivering(orderListUtil.orders)
    })
  }

  toggleSound = () => {
    this.setState({ soundon: !this.state.soundon });
    localStorage.soundon = !this.state.soundon;
    if (!this.state.soundon) {
      playNewAcceptedOrderSound();
    }
  }

  waiterCalled = (e) => {
    const tableNumber = $(e.target).attr("tableNumber");
    const tableOccupation = $(e.target).attr("tableOccupation")
    tableLayout.waiterCalled(tableNumber, tableOccupation).done(() => {
      orderListUtil.processOrders(orderListUtil.ordersCache, false);
      this.setState({
        orders: orderListUtil.orders
      })
    });
  }

  insertAtTheEnd = (index) => {
    let svg = document.getElementById("svgCanvas");

    let layer2 = index == 0 ? 0 : Math.floor(Math.log2(index));
    let numCircles = Math.pow(2, layer2);
    let i = index - Math.floor(Math.pow(2, layer2));
    let inverse = (Math.floor(layer2 / 2) * 2 === layer2);
    if (inverse)
      i = numCircles - i;
    // Decrease the size of the circles for each layer
    let circleRadius = circleR[layer2];

    // Adjust spacing as we go outward: larger gaps initially, smaller later
    let layerRadius = layerR[layer2];

    // Restrict angles between 0 and π/2 (1st quadrant)
    let angleStep = numCircles > 1 ? (Math.PI / 1.6) / (numCircles - 1) : 1;

    //console.log(index, layer2, i, inverse);

    let angle = (i + (inverse ? -1 : 0)) * angleStep - .2;

    // Calculate the position of the circle in polar coordinates (1st quadrant)
    let { x, y } = this.polarToCartesian(layerRadius - 2 * initialRadius, angle, totalWidth, 0);

    // Skip circles that go out of bounds (lower than the canvas height)
    if (y > totalHeight || x < 0) {
      return;
    }

    let circle = document.createElementNS(svgNS, "circle");

    circle.style = "filter: url(#shadow)";
    circle.setAttribute("cx", -0);
    circle.setAttribute("cy", -0);
    circle.setAttribute("r", circleRadius);  // Decreasing radius
    circle.setAttribute("fill", `rgba(${255 - layer2 * 40}, ${layer2 * 40}, ${i * 30}, 0.8)`);

    // Create text element for the index number, centered in the circle
    let text = document.createElementNS(svgNS, "text");

    // Store the circle for later animation reference
    let g = document.createElementNS(svgNS, "g");

    text.setAttribute("x", 0);
    text.setAttribute("y", 0 + (circleRadius / 7));
    text.setAttribute("stroke", "white");
    text.style = "font-size:" + circleRadius + "px";
    text.textContent = index++;

    if (layer2 == 0) {
      x += -1.9 * circleRadius + 0;
      y -= 1.3 * circleRadius - 10;
      text.style = "font-size:80px";
    }


    g.x = x;
    g.y = y;
    g.setAttribute("transform", "translate(" + (x) + "," + (y) + ")");
    svg.appendChild(g);
    g.appendChild(circle);
    g.appendChild(text);

    circles.push({ circle, text, g });
  }

  // Convert polar coordinates to Cartesian, restrict to 1/4 circle
  polarToCartesian = (radius, angle, cx, cy) => {
    return {
      x: cx - radius * 2 * Math.cos(angle), // Flip X since we're in the top-right corner
      y: cy + radius * Math.sin(angle)
    };
  }

  animateViewBox = (svgElement, fromViewBox, toViewBox, duration) => {
    const [fromX, fromY, fromWidth, fromHeight] = fromViewBox;
    const [toX, toY, toWidth, toHeight] = toViewBox;

    let startTime = null;

    function animate(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1); // Limit progress to 1 (100%)

      // Interpolate between from and to values
      const currentX = fromX + (toX - fromX) * progress;
      const currentY = fromY + (toY - fromY) * progress;
      const currentWidth = fromWidth + (toWidth - fromWidth) * progress;
      const currentHeight = fromHeight + (toHeight - fromHeight) * progress;

      // Set the new viewBox
      svgElement.setAttribute("viewBox", `${currentX} ${currentY} ${currentWidth} ${currentHeight}`);

      // Continue animation as long as the progress is less than 1
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    // Start the animation
    requestAnimationFrame(animate);
  }

  adjustViewBox = ([fromX, fromY, fromWidth, fromHeight]) => {
    let svg = document.getElementById("svgCanvas");
    let bbox = svg.getBBox();  // Get the bounding box of all SVG content
    let padding = 20;  // Add some padding around the content
    //if (!svg.getAttribute("viewBox"))
    // Set the viewBox to zoom into the actual content

    let [toX, toY, toWidth, toHeight] = [bbox.x - padding, bbox.y - padding, bbox.width + 2 * padding, bbox.height + 2 * padding];

    this.animateViewBox(svg, [fromX, fromY, fromWidth, fromHeight], [toX, toY, toWidth, toHeight], 2000);
    svg.setAttribute(
      "viewBox",
      `${toX} ${toY} ${toWidth} ${toHeight}`
    );
    return ([toX, toY, toWidth, toHeight])
  }
  insertNewCircle = (text) => {
    let svg = document.getElementById("svgCanvas");

    //if (circles.length === 0) return; // Ensure there are circles to modify

    this.insertAtTheEnd(circles.length + 1);

    // Step 1: Save the position and radius of the first circle
    let firstCircle = circles[0].circle;
    let firstG = circles[0].g;
    var originalCx = firstG.x;
    var originalCy = firstG.y;
    var originalR = parseFloat(firstCircle.r.baseVal.value);
    var originalColor = firstCircle.getAttribute("fill");
    var originalStyle = circles[0].text.getAttribute("style");

    let prevG = circles[circles.length - 1].g;
    let prevCircle = circles[circles.length - 1].circle;
    let newCx = parseFloat(prevG.x);
    let newCy = parseFloat(prevG.y);
    let newR = parseFloat(prevCircle.r.baseVal.value);
    let newColor = prevCircle.getAttribute("fill");
    let newStyle = circles[circles.length - 1].text.getAttribute("style");

    circles[circles.length - 1].g.remove();
    circles.pop();

    // Step 2: Modify existing circles to move them outward (shift position/radius)
    for (let i = circles.length; i > 0; i--) {

      let newCircle = circles[i - 1].circle;
      let newG = circles[i - 1].g;

      let oldCx = parseFloat(newG.x);
      let oldCy = parseFloat(newG.y);
      let oldR = parseFloat(newCircle.r.baseVal.value);
      let oldColor = newCircle.getAttribute("fill");
      let oldStyle = circles[i - 1].text.getAttribute("style");


      circles[i - 1].g.setAttribute("transform", "translate(" + newCx + "," + newCy + ")");
      circles[i - 1].circle.setAttribute("r", newR);
      circles[i - 1].circle.setAttribute("fill", newColor);
      circles[i - 1].text.setAttribute("style", newStyle);
      circles[i - 1].text.setAttribute("y", newR / 7);
      circles[i - 1].g.x = newCx;
      circles[i - 1].g.y = newCy;

      newCx = oldCx;
      newCy = oldCy;
      newR = oldR;
      newColor = oldColor;
      newStyle = oldStyle;


      //circles[i].text.textContent = circles[i - 1].text.textContent;
    }

    // Step 3: Insert a new circle at the position of the original first circle
    let newCircle = document.createElementNS(svgNS, "circle");
    newCircle.setAttribute("cx", 0);
    newCircle.setAttribute("cy", 0);
    newCircle.setAttribute("fill", "rgba(0, 150, 255, 0.8)");
    newCircle.style = "filter: url(#shadow)";

    let newText = document.createElementNS(svgNS, "text");
    newText.setAttribute("x", 0);
    newText.setAttribute("stroke", "white");
    newText.textContent = text;  // New text inside the circle
    newText.onclick = this.deleteNewCircle;
    newText.textAnchor = "middle";
    newText.setAttribute("text-anchor", "middle");
    newText.setAttribute("dominant-baseline", "middle");

    // Add the new circle and text to the SVG
    let g = document.createElementNS(svgNS, "g");
    g.x = originalCx;
    g.y = originalCy;
    //g.setAttribute("transform", "translate(" + (originalCx + originalR) + "," + (originalCy + originalR) + ")");
    if (circles.length == 0)
      g.setAttribute("transform", "translate(" + originalCx + "," + originalCy + ")");
    else
      g.setAttribute("transform", "translate(" + (1240 + 860 / 2) + "," + (-170 + 530 / 2) + ")");
    newCircle.setAttribute("r", 230);
    newText.style = "font-size:" + 240;
    newText.setAttribute("y", 240 / 7);

    g.appendChild(newCircle);
    g.appendChild(newText);
    // Add the new circle and text to the array for future references
    circles.unshift({ circle: newCircle, text: newText, g });
    //adjustViewBox();

    setTimeout(() => {

      svg.appendChild(g);
      setTimeout(() => {
        g.setAttribute("transform", "translate(" + originalCx + "," + originalCy + ")");
        newCircle.setAttribute("r", originalR);
        newText.style = newStyle;
        newText.setAttribute("y", (30 / 7));
        /*
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 200)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 400)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 600)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 800)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 1000)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 1200)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 1400)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 1600)
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 1800)*/
        setTimeout(() => [fromX, fromY, fromWidth, fromHeight] = this.adjustViewBox([fromX, fromY, fromWidth, fromHeight]), 2100)
      }, 100)
      //adjustViewBox();
    }, 100);

  }

  deleteNewCircle = (text) => {
    if (typeof text !== "string" && typeof text !== "number") {
      text = text.currentTarget.textContent;
    }
    //if (circles.length === 0) return; // Ensure there are circles to modify

    //insertAtTheEnd(circles.length + 1);
    let ii;
    for (ii = 0; ii < circles.length; ii++) {
      if (circles[ii].text.textContent == text)
        break;
    }

    var c = circles[ii];

    var newCx = c.g.x;
    var newCy = c.g.y;
    var newR = parseFloat(c.circle.r.baseVal.value);
    var newColor = c.circle.getAttribute("fill");
    var newStyle = c.text.getAttribute("style");

    c.circle.setAttribute("r", 0);
    c.text.setAttribute("style", "font-size:0");

    c.g.remove();

    setTimeout(() => {

      for (let i = ii + 1; i < circles.length; i++) {

        var n = circles[i];

        let oldCx = parseFloat(n.g.x);
        let oldCy = parseFloat(n.g.y);
        let oldR = parseFloat(n.circle.r.baseVal.value);
        let oldColor = n.circle.getAttribute("fill");
        let oldStyle = n.text.getAttribute("style");

        n.g.setAttribute("transform", "translate(" + newCx + "," + newCy + ")");
        n.circle.setAttribute("r", newR);
        n.circle.setAttribute("fill", newColor);
        n.text.setAttribute("style", newStyle);
        n.text.setAttribute("y", newR / 7);
        n.g.x = newCx;
        n.g.y = newCy;

        newCx = oldCx;
        newCy = oldCy;
        newR = oldR;
        newColor = oldColor;
        newStyle = oldStyle;



        //circles[i].text.textContent = circles[i - 1].text.textContent;
      }

      circles.splice(ii, 1);
    }, 200);


  }


}

function formatSeconds(seconds) {
  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format remaining seconds to always have two digits
  const formattedSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

  // Combine minutes and formatted seconds
  return minutes + ':' + formattedSeconds;
}


const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable
    };
  }
  return {};
};

export default connect(mapStateToProps)(GuestScreen);
