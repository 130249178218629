import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import "../css/myDateRangePicker.css";

class MyDateRangePicker extends Component {
    render() {
        moment.locale(localStorage.language);
        const locale = {
            customRangeLabel: I18n.t("admin_local.custom_range"),
            cancelLabel: I18n.t("local.cancel"),
            applyLabel: I18n.t("local.apply"),
        }
        const ranges = {}
        ranges[I18n.t("admin_local.today")] = [moment(), moment()];
        ranges[I18n.t("admin_local.yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[I18n.t("admin_local.last_7_days")] = [moment().subtract(6, 'days'), moment()];
        ranges[I18n.t("admin_local.last_30_days")] = [moment().subtract(29, 'days'), moment()];
        ranges[I18n.t("admin_local.last_3_months")] = [moment().subtract(2, 'month').startOf('month'), moment()];
        ranges[I18n.t("admin_local.this_month")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[I18n.t("admin_local.last_month")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        return (<DateRangePicker locale={locale} linkedCalendars={false} startDate={this.props.startDate} endDate={{ ...this.props.endDate }} onApply={this.props.onApply} ranges={ranges}>
            <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}><i className="fa fa-calendar" ></i><span>{this.props.startDate.locale(localStorage.language).format("LL")} - {this.props.endDate.locale(localStorage.language).format("LL")}</span></div>
        </DateRangePicker>
        );
    }
}

export default MyDateRangePicker;