/* eslint-disable */
import $ from "jquery";
import admin, { getMyStatus } from "../../js/admin";
import { startpoll, confirmDialog } from "../../js/auth";
import languages from "../../langs/languages";
import superadmin from "./superadmin";
import { getUsersContaining, getEntitiesContaining, modifyCustomer, addCustomer } from "./superadmin";
import SortedSet from "sortedset";
import "../../tweaked/tokenize2.js";
import "../../tweaked/tokenize2.min.css";
import { createTree } from "jquery.fancytree";


const { local, admin_local } = languages;
var r = null;

var settings;
var labels;
export const ready = () => {
  r = null;
  updateHooks();
  if (typeof localStorage.superadmin_users_filters != "undefined") {
    settings = JSON.parse(localStorage.superadmin_users_filters);
    Object.keys(settings).forEach(key => {
      $("select#" + key + ".filter option").prop("selected", false);
      var t2 = $("select#" + key + ".filter option[value = '" + settings[key] + "']");
      $(t2).prop("selected", true);
    });
  }

  getUsers();

  admin.getLabels(function (data) {
    labels = data;
  });
  var options = {
    placeholder: "",
    tokensAllowCustom: true,
    searchMinLength: 0,
    dataSource: function (term, object) {
      object.trigger("tokenize:dropdown:fill", [getLabels(term)]);
    },
    displayNoResultsMessage: true,
    noResultsMessageText: local.noResultsMessageText,
    dropdownMaxItems: 7,
    searchFromStart: false
  };

  $("#labels").tokenize2(options);

  startpoll(serverSideMessageHandler);
};

function serverSideMessageHandler(data) { }

function getUsers() {
  var nameFilter = $("#nameFilter").val();
  var onlineFilter = $("#onlineFilter").val();
  var roleFilter = $("#roleFilter").val();
  var activeFilter = $("#activeFilter").val() == "" ? null : $("#activeFilter").val();
  var entityTypeFilter = $("#entityTypeFilter").val();
  var entityFilter = $("#entityFilter").val();
  var settings = {
    nameFilter: nameFilter,
    onlineFilter: onlineFilter,
    roleFilter: roleFilter,
    activeFilter: activeFilter,
    entityTypeFilter: entityTypeFilter,
    entityFilter: entityFilter
  };
  localStorage.superadmin_users_filters = JSON.stringify(settings);
  if (entityFilter == "") entityFilter = "*";
  superadmin.getUsersByFilter(nameFilter, onlineFilter, activeFilter, roleFilter, entityTypeFilter, entityFilter, processUsers);
}

function processUsers(users) {
  users.forEach(function (v) {
    v.title = v.name + (v.surname != null ? " " + v.surname : "") + " (" + v.email + ")";
    v.subprofiles = v.children.length;
    if (!v.role) {
      v.key = "user:" + v.id;
      //v.expanded = true;
    }
    if (!v.isActive) v.extraClasses = "itemDeactivated";
    v.children.forEach(function (v1, index) {
      v1.title = v1.role;
      v1.key = "role:" + v.id;
      //v1.expanded = false;
      if (v1.role == "eatwithme") v.eatwithme = true;
      if (v1.role == "admin") v.admin = true;
    });
    v.children.forEach(function (v1, index) {
      if (v1.role == "eatwithme") v.children.splice(index, 1);
    });
  });
  if (r == null) {
    r = createTree("table#users", {
      checkbox: false,
      titlesTabbable: true, // Add all node titles to TAB chain
      source: users,
      zindex: 1000,
      extensions: ["persist", "table", "gridnav"],
      icon: false,
      autoScroll: true,
      table: {
        checkboxColumnIdx: 0 // render the checkboxes into the this
        // indentation : 16, // indent every node level by 16px
        // nodeColumnIdx : 2
      },
      gridnav: {
        autofocusInput: false, // Focus first embedded input if node
        // gets
        // activated
        handleCursorKeys: true
        // Allow UP/DOWN in inputs to move to prev/next node
      },
      activate: function (event, data) {
        // A node was activated: display its title:
        var node = data.node;
        if (node.data.role) {
          $("#editUser").prop("disabled", "true");
          $("#addRole").prop("disabled", "true");
        } else {
          $("#editUser").removeAttr("disabled");
          $("#addRole").removeAttr("disabled");
          if (node.data.isActive) {
            $("#deactivateUser").removeAttr("disabled");
            $("#activateUser").prop("disabled", "true");
          } else {
            $("#deactivateUser").prop("disabled", "true");
            $("#activateUser").removeAttr("disabled");
          }
        }
        $("#delete").removeAttr("disabled");
      },
      // renderStatusColumns: false, // false: default renderer
      // true: generate renderColumns events, even for status nodes
      // function: specific callback for status nodes

      renderColumns: function (event, data) {
        var node = data.node,
          $tdList = $(node.tr).find(">td");

        // (Column #0 is rendered by fancytree by adding the checkbox)

        // Column #1 should contain the index as plain text, e.g.
        // '2.7.1'
        // $tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

        // (Column #2 is rendered by fancytree)

        // ...otherwise render remaining columns
        // $tdList.eq(1).text(node.data.name)
        $tdList
          .eq(2)
          .prop("colspan", 3)
          .nextAll()
          .remove();
        if (node.data.entityType) {
          if (node.data.entityType == "Restaurant" && node.data.entity) {
            $tdList.eq(2).text(node.data.entityType + " " + node.data.entity.name);
          }
        } else {
          $tdList.eq(1).text(node.data.isOnline);
          $tdList.eq(2).text(node.data.isActive);
          var roles = "";
          if (node.children)
            node.children.forEach(function (v) {
              if (roles != "") roles += ",";
              roles = roles + v.data.role;
            });
          $tdList.eq(3).text(roles);
        }

        // $tdList.eq(1)
      }
    });
  } else {
    r.reload(users);
    $("#editUser").prop("disabled", "true");
    $("#addRole").prop("disabled", "true");
    $("#activateUser").prop("disabled", "true");
    $("#deactivateUser").prop("disabled", "true");
    $("#delete").prop("disabled", "true");
  }
}

var _restoreModal = false;
function updateHooks() {
  $(".filter").on("input", function (e) {
    // r.reactivate(false);
    getUsers();
  });
  /*
  $("button#activateUser").click(() => activateUser(true));
  $("button#deactivateUser").click(() => activateUser(false));
  $("button#deleteUser").click(deleteItem);

  $("div#editUser button#save").click(saveUser);
  $("div#editRole button#save").click(saveRole);
  */
  $("div#editUser").on("show.bs.modal", function (event) {
    var modal = $(this);
    clearTokens(modal.find("#labels").tokenize2());
    var button = $(event.relatedTarget); // Button that triggered the modal
    var recipient = button.data("whatever"); // Extract info from data-*
    modal.find("#password").val("");
    // attributes
    if (!_restoreModal) {
      if (recipient == "edit") {
        var data = r.getActiveNode().data;
        modal.find("#id").val(data.id);
        modal.find("#name").val(data.name);
        modal.find("#surname").val(data.surname);
        modal.find("#email").val(data.email);
        modal.find("#gitlabSecureToken").val(data.gitlabSecureToken);
        modal.find("#active").prop("checked", data.isActive);
        data.labels.forEach(function (label) {
          modal.find("#labels").trigger("tokenize:tokens:add", ["label;" + label.id + ";", label.name]);
        });
      } else {
        modal.find("#id").val("");
        modal.find("#name").val("");
        modal.find("#surname").val("");
        modal.find("#email").val("");
        modal.find("#active").prop("checked", true);
        modal.find("#gitlabSecureToken").val("");
      }
    }
    // If necessary, you could initiate an AJAX request here (and then do the
    // updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a
    // data binding library or other methods instead.
  });

  $("div#editRole").on("show.bs.modal", function (event) {
    var modal = $(this);
    var button = $(event.relatedTarget); // Button that triggered the
    // modal
    var recipient = button.data("whatever"); // Extract info from data-*
    // attributes
    modal.find("#users").tokenize2({
      placeholder: local.placeholder_add_an_email,
      tokensAllowCustom: false,
      searchMinLength: 3,
      dataSource: function (a, b) {
        return getUsersContaining(a, function (data) {
          var $items = [];
          $.each(data, function (k, v) {
            $items.push({
              value: v.email,
              text: v.name + (v.surname != null ? " " + v.surname : "") + " (" + v.email + ")"
            });
          });
          modal.find("#users").trigger("tokenize:dropdown:fill", [$items]);
        });
      }
    });
    modal.find("#entity").tokenize2({
      placeholder: local.placeholder_add_a_name,
      tokensAllowCustom: false,
      searchMinLength: 3,
      dataSource: function (a, b) {
        if (modal.find("#entityType").val() != "")
          return getEntitiesContaining(modal.find("#entityType").val(), a, function (data) {
            var $items = [];
            $.each(data, function (k, v) {
              $items.push({
                value: v.id,
                text: v.name + " (" + v.address + ")"
              });
            });
            modal.find("#entity").trigger("tokenize:dropdown:fill", [$items]);
          });
        else modal.find("#entity").trigger("tokenize:dropdown:fill", [[]]);
      }
    });
    if (!_restoreModal) {
      var data = r.getActiveNode().data;
      modal.find("#name").val("");
      modal
        .find("#users")
        .tokenize2()
        .clear();
      modal
        .find("#users")
        .tokenize2()
        .trigger("tokenize:tokens:add", [data.email, data.name + (data.surname != null ? " " + data.surname : "") + " (" + data.email + ")", true, ""]);
    }

    // If necessary, you could initiate an AJAX request here (and then
    // do the
    // updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could
    // use a
    // data binding library or other methods instead.
  });
}

var roles = null;

export const saveUser = () => {
  var modal = $("div#editUser");
  var id = modal.find("#id").val();
  var name = modal.find("#name").val();
  var surname = modal.find("#surname").val();
  var email = modal.find("#email").val();
  var password = modal.find("#password").val();
  var gitlabSecureToken = modal.find("#gitlabSecureToken").val();
  var active = modal.find("#active").prop("checked");
  var labels = [];
  var a = modal.find("#labels");
  a.tokenize2()
    .toArray()
    .forEach(function (addition) {
      var id = addition.split(";");
      labels.push({
        id: id[1]
      });
    });
  if (id != "") {
    modifyCustomer(id, name, surname, email, password, gitlabSecureToken, labels, active, getUsers);
  } else {
    addCustomer(name, surname, email, password, gitlabSecureToken, labels, active, getUsers, restoreModal);
  }
}

export const saveRole = () => {
  var modal = $("div#editRole");
  modal
    .find("#users").tokenize2().toArray()
    .forEach(function (user) {
      var entityType = modal.find("#entityType").val();
      if (modal.find("#role").val() == "eatwithme" || entityType == "") {
        superadmin.addNewRole(user, modal.find("#role").val(), "", 0, getUsers, restoreModal);
      } else {
        modal
          .find("#entity")
          .data("tokenize2")
          .toArray()
          .forEach(function (restaurant) {
            superadmin.addNewRole(user, modal.find("#role").val(), modal.find("#entityType").val(), restaurant, getUsers, restoreModal);
          });
      }
    });
}

function restoreModal() {
  restoreModal = true;
  $("div#editUser").modal("show");
  restoreModal = false;
}

export const deleteItem = () => {
  var data = r.getActiveNode().data;
  if (data.role) {
    var user = r.getActiveNode().parent.data;
    confirmDialog(
      local.confirmation,
      String.format(admin_local.confirm_role_deletion, user.name + (user.surname != null ? " " + user.surname : "") + " (" + user.email + ")", data.role, data.entity ? (data.entityType + " " + data.entity.name) : "")
    ).done(function () {
      superadmin.deleteRole(data.id, getUsers);
    });
  } else {
    confirmDialog(local.confirmation, String.format(admin_local.confirm_customer_deletion, data.name + (data.surname != null ? " " + data.surname : "") + " (" + data.email + ")")).done(function () {
      superadmin.deleteCustomer(data.id, getUsers);
    });
  }
}

export const activateUser = (active) => {
  var data = r.getActiveNode().data;
  modifyCustomer(data.id, data.name, data.surname, data.email, null, null, data.labels, active, getUsers);
}

function clearTokens(tokenizer) {
  tokenizer.toArray().forEach(function (addition) {
    tokenizer.trigger("tokenize:tokens:remove", addition);
  });
}

let getLabels = function (search) {
  //let s = search;
  var set = new SortedSet({
    comparator: function (a, b) {
      if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase())) return -1;
      if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase())) return 1;
      return a.text.localeCompare(b.text);
    }
  });
  var menuItem = _getLabels(search, set, labels);
  if (menuItem == "") {
    return null;
  }
  var result = [];
  Array.from(set).forEach(function (e) {
    result[result.length] = e;
  });
  return result;
};

let _getLabels = function (s, set, labels) {
  labels.forEach(function (item) {
    var regexp = new RegExp(s, "ig");
    var name = item.name;
    var r = regexp.test(name);
    if (r) {
      set.add({
        value: "label;" + item.id + ";",
        text: name
      });
    }
  });
};
