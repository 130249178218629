import $ from "jquery";
import introJs from "../tweaked/intro.js";
import { isVisible, getLocale } from "./auth";
import languages from "../langs/languages";

const { admin_local } = languages;

export class Help {

  help = null;

  on = false;

  timeoutCount = 0;


  constructor(help) {
    this.help = help;
  }

  initHelp() {
    $("*[intro]").each(function (ind, item) {
      item = $(item);
      item.removeAttr("data-intro");
      item.removeAttr("data-step");
      item.removeAttr("data-position");
      item.removeAttr("data-scrollTo");
      item.removeAttr("intro");
    });
    //if (!this.help)
    var step = 1;
    this.help.basic.forEach(function (helpItem) {
      var item = $($(helpItem.selector)[0]);
      console.log(helpItem.selector, item.length);
      item.each(function (ind, item) {
        var rect = item.getBoundingClientRect();
        var e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
        if (!item.isSameNode(e)) {
          while (e != null) {
            e = e.parentNode;
            if (item.isSameNode(e)) break;
          }
          if (e === null) return;
        }
        item = $(item);
        if (isVisible(item)) {
          item.attr("intro", "true");
          item.attr("data-step", step);
          item.attr("data-intro", getLocale(helpItem["data-intro"]));
          item.attr("data-position", helpItem["data-position"]);
          item.attr("data-scrollTo", helpItem["data-scrollTo"]);
          step++;
        }
      });
    });
    return step !== 1; // step == 1 means that there is not help intro configured
  }

  helpDummyMouseHandlers(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  clickForHelpInitialized = null;
  helpItemClickHandler(e) {
    this.timeoutCount++;
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    var step = 1;
    if (this.clickForHelpInitialized === null) {
      this.help.basic.forEach(function (helpItem) {
        var item = $($(helpItem.selector));
        item.each(function (ind, item) {
          var rect = item.getBoundingClientRect();
          var e = document.elementFromPoint(rect.x + rect.width / 2, rect.y + rect.height / 2);
          if (!item.isSameNode(e)) {
            while (e != null) {
              e = e.parentNode;
              if (item.isSameNode(e)) break;
            }
            if (e == null) return;
          }
          item = $(item);
          if (item.attr("intro")) return;
          item.attr("intro", "true");
          item.attr("data-step", step);
          item.attr("data-intro", getLocale(helpItem["data-intro"]));
          item.attr("data-position", helpItem["data-position"]);
          item.attr("data-scrollTo", helpItem["data-scrollTo"]);
          step++;
        });
      });
      this.clickForHelpInitialized = introJs().start();
      this.clickForHelpInitialized.onexit(this.resetHelpClick);
      this.clickForHelpInitialized.onskip(this.resetHelpClick);
    } else {
      var item = $(e.currentTarget);
      if (!item.attr("intro")) {
        while (item.lenght > 0) {
          item = item.parent();
          if (item.attr("intro")) {
            break;
          }
        }
      }
      if (item.length === 0) return;
      if (item.attr("intro")) {
        step = item.attr("data-step");
        this.clickForHelpInitialized.goToStep(step);
      } else {
        item = item.parents("[intro]");
        step = item.attr("data-step");
        this.clickForHelpInitialized.goToStep(step);
      }
      return;
    }
    item = $(e.currentTarget);
    if (item.attr("intro")) {
      step = item.attr("data-step");
      this.clickForHelpInitialized.goToStep(step);
    } else {
      item = item.parents("[intro]");
      step = item.attr("data-step");
      this.clickForHelpInitialized.goToStep(step);
    }
  }

  resetHelpClick = () => {
    this.timeoutCount++;
    const timeoutCount2 = this.timeoutCount;
    const instance = this;
    setTimeout(function () {
      if (instance.timeoutCount !== timeoutCount2) return;
      $("#pre-help").removeClass("active");
      instance.resetHelpPreSelection();
    }, 3000);
  }

  deselectedHelpClass = "fadedabit";
  resetHelpPreSelection = () => {
    this.clickForHelpInitialized = null;
    var items = $(".fadedabit");
    items.removeClass(this.deselectedHelpClass);
    $("*[intro]").each(function (ind, item) {
      item = $(item);
      item.removeAttr("data-intro");
      item.removeAttr("data-step");
      item.removeAttr("data-position");
      item.removeAttr("data-scrollTo");
      item.removeAttr("intro");
    });
    $("*").unbind("click", this.helpItemClickHandler);
    $("body *[onclick_bck]").each(function (ind, o) {
      $(o).attr("onclick", $(o).attr("onclick_bck"));
      $(o).removeAttr("onclick_bck");
    });
    $("body *[onmousedown_bck]").each(function (ind, o) {
      $(o).attr("onmousedown", $(o).attr("onmousedown_bck"));
      $(o).removeAttr("onclick_bck");
    });
    $("body *[onmouseup_bck]").each(function (ind, o) {
      $(o).attr("onmouseup", $(o).attr("onmouseup_bck"));
      $(o).removeAttr("onclick_bck");
    });
    this.unbindHelpDummyHandler($("body"));
    this.unbindHelpDummyHandler($("body *"));
    this.on = false;
  }
  initHelpButton() {
    if (typeof help == "undefined") return;
    var helpButton = $("button#pre-help");
    if (helpButton.length === 0) {
      this.setTimeout(this.initHelpButton, 100);
      return;
    }
  }

  helpButtonClickHandler(event) {
    console.log("help");
    this.timeoutCount++;
    var timeoutCount2 = this.timeoutCount;
    if ($(event.currentTarget).hasClass("active")) {
      this.resetHelpPreSelection();
      return;
    }
    setTimeout(function () {
      if (this.timeoutCount !== timeoutCount2) return;
      $("#pre-help").removeClass("active");
      this.resetHelpPreSelection();
      this.showHelp();
    }, 3000);
    var items = $("*").filter(function () {
      return $(this).css("opacity") > 0.8;
    });
    //var deselectedClass = "fadedabit";
    items.addClass(this.deselectedHelpClass);

    this.help.basic.forEach(function (helpItem) {
      var item = $(helpItem.selector);
      item.click(this.helpItemClickHandler);
      $(item.find("*")).click(this.helpItemClickHandler);
      item.find("*").removeClass(this.deselectedHelpClass);
      while (item.length !== 0) {
        item.removeClass(this.deselectedHelpClass);
        item = item.parent();
      }
    });

    this.bindHelpDummyHandler($("body"));
    this.bindHelpDummyHandler($("body *"));

    $("body *[onclick]").each(function (ind, o) {
      $(o).attr("onclick_bck", $(o).attr("onclick"));
      $(o).removeAttr("onclick");
    });
    $("body *[onmousedown]").each(function (ind, o) {
      $(o).attr("onmousedown_bck", $(o).attr("onmousedown"));
      $(o).removeAttr("onclick");
    });
    $("body *[onmouseup]").each(function (ind, o) {
      $(o).attr("onmouseup_bck", $(o).attr("onmouseup"));
      $(o).removeAttr("onclick");
    });
  };

  showHelp() {
    if (!this.initHelp()) return;
    this.clickForHelpInitialized = introJs().setOptions("showStepNumbers", true).setOption("overlayOpacity", .3).setOption("nextLabel", admin_local.next).setOption("prevLabel", admin_local.back).setOption("skipLabel", admin_local.close).start();
    this.clickForHelpInitialized.onexit(this.resetHelpClick);
    this.clickForHelpInitialized.onskip(this.resetHelpClick);
    this.bindHelpDummyHandler($("body"));
    this.bindHelpDummyHandler($("body *"));
    this.on = true;
  }

  bindHelpDummyHandler(items) {
    items.click(this.helpDummyMouseHandlers);
    items.mousedown(this.helpDummyMouseHandlers);
    items.mouseup(this.helpDummyMouseHandlers);
    items.keydown(this.helpDummyMouseHandlers);
    items.keyup(this.helpDummyMouseHandlers);
  }

  unbindHelpDummyHandler(items) {
    items.unbind("click", this.helpDummyMouseHandlers);
    items.unbind("mousedown", this.helpDummyMouseHandlers);
    items.unbind("mouseup", this.helpDummyMouseHandlers);
    items.unbind("keydown", this.helpDummyMouseHandlers);
    items.unbind("keyup", this.helpDummyMouseHandlers);
  }
}