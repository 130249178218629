const local_it = {
  "ml": "ml",
  "dl": "dl",
  "cl": "cl",
  "l": "l",
  "pcs": "pezzi",
  "g": "g",
  "dkg": "dkg",
  "kg": "kg",
  "none": "N/D",
  "day": "giorno",
  "hour": "ora",
  "min": "minuto",
  "sign_up": "Registrati",
  "login": "Accedi",
  "provide_first_name": "Inserisci il tuo nome",
  "surname": "Cognome",
  "provide_surname": "Inserisci il tuo cognome",
  "email": "Indirizzo email",
  "provide_email": "Inserisci il tuo indirizzo email",
  "quick_access_code": "Codice rapido",
  "require_quick_access_code": "Obbligatorio",
  "provide_password": "Inserisci la tua password",
  "forgot_password": "Hai dimenticato la password?",
  "need_sign_up": "Hai bisogno di registrarti?",
  "registration": "Registrazione",
  "register": "Registrati",
  "back_to_login": "Torna al login",
  "social_login": "oppure accedi con un account social",
  "email_already_in_use": "Indirizzo email già in uso!",
  "activation_email_sent": "Una email di attivazione è stata inviata a {0}. Controlla la tua casella di posta, compresa la cartella SPAM!",
  "activation_email_sent2": "Una email di attivazione è stata inviata a {0}. Controlla la tua casella di posta, compresa la cartella SPAM!",
  "password_reset_email_sent": "L'email per il reset della password è stata inviata con successo a {0}!",
  "password_reset_email_sent2": "L'email per il reset della password è stata inviata con successo a {email}!",
  "confirm_account_deletion": "Sei sicuro di voler cancellare il tuo account?",
  "account_deleted_successfully": "Il tuo account è stato cancellato con successo!",
  "bad_password_request_token": "Token per il reset della password non valido o scaduto! Richiedi un nuovo token!",
  "password_reset_successfully": "Nuova password impostata con successo!",
  "missing_role": "Accesso negato! Ruolo richiesto non assegnato al tuo utente!",
  "password_forgotten_text": "Inserisci il tuo indirizzo email. Ti invieremo una email con un link per impostare la tua nuova password.",
  "register_first": "Per favore, registrati prima di utilizzare qualsiasi servizio di accesso di terze parti!",
  "login_failed": "Accesso fallito! Email o password errati!",
  "communication_error": "Errore di comunicazione con il server (codice errore: {0})!",
  "join": "Unisciti",
  "occupy": "Occupare",
  "ok": "Ok",
  "cancel": "Annulla",
  "apply": "Applica",
  "get_a_table": "Prenota un tavolo",
  "locked_get_a_table_one_table": "Tavolo n. {0}\n{2} persone",
  "locked_get_a_table_more_tables": "{0} tavoli\n{2} persone",
  "joined_get_a_table": "Unisciti\nal tavolo\nn. {0}",
  "joined_not_authorized_get_a_table": "Unisciti\nma non\napprovato",
  "let_other_to_join_your_table": "Lascia che altri si uniscano al tuo tavolo",
  "the_lead_customer_cannot_be_unapproved": "Non puoi cambiare lo stato di adesione dell'organizzatore!",
  "confirmation": "Conferma",
  "warning": "Avviso",
  "do_you_want_to_be_the_organizer": "Vuoi davvero essere l'organizzatore?",
  "approvedBy": "Approvato da {0}",
  "paidBy": "Pagato da {0}",
  "waiting_for_approval_from_somebody": "In attesa di approvazione da qualcuno!",
  "your_mate_is_not_intending_to_pay_his_bill": "Il tuo compagno non ha intenzione di pagare il suo conto!",
  "your_mate_intends_to_pay_his_bill": "Il tuo compagno intende pagare il suo conto ma ti consente anche di invitarlo!",
  "your_mate_wants_to_pay": "Il tuo compagno vuole pagare il suo conto e non consente a nessun altro di farlo!",
  "shared_table_tooltip": "I posti liberi rimanenti a questo tavolo possono essere occupati da qualcun altro!",
  "request_from": "Richiesta da {0}",
  "message_from": "Messaggio da {0}",
  "error_message": "Messaggio di errore",
  "question": "Domanda",
  "suggestion": "Suggerimento",
  "information": "Informazione",
  "success_message": "Successo",
  "organizer_request_denied": "Non sono d'accordo che tu diventi l'organizzatore!",
  "organizer_request": "Per favore, permettimi di diventare l'organizzatore al posto tuo!",
  "organizer_confirmation": "Sei diventato l'organizzatore!",
  "let_me_pay": "Lascia che paghi io",
  "approve_joining": "Approva l'adesione",
  "reject_joining": "Rifiuta l'adesione",
  "reject_paying": "Non voglio pagare",
  "i_am_paying": "Voglio pagare",
  "does_not_agree_invite": "{0} non è d'accordo con te per pagare!",
  "do_you_want_to_leave": "Vuoi davvero lasciare questo tavolo?",
  "lock_another_table": "Occupare un altro tavolo",
  "unlock_table": "Lasciare il tavolo",
  "scanToAddTable": "Occupare un altro tavolo",
  "scanToChangeTable": "Cambio a questo tavolo",
  "somebody": "Qualcuno",
  "sending": "Invio email di reset password",
  "password_reset": "Imposta una nuova password",
  "ask_password_reset": "Reimposta la password",
  "password_forgotten": "Password dimenticata",
  "message_already_joined": "Sei già entrato in questo tavolo!",
  "message_nobody_to_join": "Non c'è nessuno da unire a questo tavolo!",
  "message_nobody_allows_joining": "Attualmente nessuno permette l'adesione a questo tavolo!",
  "message_do_you_want_to_switch_table": "Sei già nel tavolo {0}! Vuoi passare al tavolo {1}?",
  "message_do_you_want_to_join_table_with_approval": "Il tavolo è già occupato e le persone possono unirsi dopo aver ottenuto l'approvazione! Vuoi unirti?",
  "message_do_you_want_to_join_table_without_approval": "Il tavolo è già occupato e puoi unirti senza ulteriore approvazione! Vuoi unirti?",
  "massage_you_do_not_want_to_pay": "Nelle impostazioni hai indicato che non vuoi pagare! Cambia l'impostazione se intendi comunque pagare!",
  "message_you_can_join_only_one_company": "Hai già aderito a un tavolo e puoi solo passare a un tavolo diverso dello stesso gruppo!",
  "choose_between_joining_or_occupying_a_table": "Preferisci occupare il tavolo o unirti a un gruppo esistente invece?",
  "this_table_is_occupied": "Questo tavolo è occupato e al momento nessuno consente l'adesione a questo tavolo!",
  "this_table_is_occupied_by_multiple_parties_join_directly": "Ci sono più gruppi a questo tavolo a cui potresti unirti! Chiedi a un partecipante già presente di fornirti un codice QR tramite l'app EatWithMe sul suo telefono! In questo modo non ci saranno ambiguità su quale gruppo desideri unirti!",
  "would_you_create_a_picture_as_well": "Aggiungi anche una foto! Faciliterà l'identificazione successiva!",
  "message_do_you_really_want_to_leave_the_table": "Vuoi davvero lasciare il tavolo?",
  "message_do_you_really_want_to_unlock_table_not_yourself": "Vuoi davvero lasciare il tavolo?",
  "message_do_you_really_want_to_unlock_table": "Vuoi davvero lasciare il tavolo?",
  "are_you_sure_you_want_to_pay_for_yourself": "Sei sicuro di voler pagare il tuo consumo invece di {0}?",
  "are_you_sure_you_want_to_pay_instead": "Sei sicuro di voler pagare al posto di {1} invece di {0}?",
  "are_you_sure_you_want_to_pay_instead_and_more": "Sei sicuro di voler pagare per {1} e altre {2} persone invece di {0}?",
  "reject_invite": "{0} non permette a te di pagare il suo consumo!",
  "qr_code_generation_failed": "Impossibile leggere il codice QR! Vuoi riprovare?",
  "leave_table": "Lascia il tavolo",
  "leave_the_table": "Lascia il tavolo",
  "message_table_join_cancelled": "Hai lasciato il tavolo con successo!",
  "message_subprofile_join_cancelled": "{0} rimosso dai partecipanti!",
  "profileUpdatedSuccessfully": "Il tuo profilo è stato aggiornato con successo",
  "first_name": "Nome",
  "family_name": "Cognome",
  "password": "Password",
  "new_subprofile": "Aggiungi nuovo sottoprofilo (ad esempio per tuo figlio)",
  "profile_picture": "Foto del profilo",
  "my_favorites": "I miei preferiti",
  "favorite_of": "Preferito di",
  "subprofiles": "Sottoprofili",
  "subprofile_tooltip": "Puoi creare sottoprofili per le persone (come i tuoi figli o genitori) che non hanno un account email o non hanno accesso attualmente! In questo modo puoi ordinare successivamente per loro separatamente senza che abbiano un proprio account!",
  "my_favorites_tooltip": "Dopo la loro conferma, puoi aggiungere i tuoi preferiti al tuo tavolo senza chiedere il loro permesso! Inoltre, possono unirsi al tuo tavolo senza la tua approvazione!",
  "favorite_of_tooltip": "Dopo la tua conferma, puoi essere aggiunto da loro al loro tavolo senza chiedere il tuo permesso! Inoltre, puoi unirti al loro tavolo senza ulteriore approvazione!",
  "couldNotFindCustomer": "Impossibile trovare il cliente con l'indirizzo email fornito!",
  "subprofileAlreadyUsedAsTableMate": "{0} faceva già parte di un evento! Sei sicuro di volerlo cancellare {0}!",
  "favorite_approved_tooltip": "Approvato",
  "favorite_pending_tooltip": "In attesa di approvazione",
  "placeholder_add_an_email": "Inserisci un indirizzo email...",
  "placeholder_add_a_name": "Inserisci un nome...",
  "subprofileAlreadyExists": "Il sottoprofilo esiste già!",
  "subprofile": "Sottoprofilo",
  "name": "Nome",
  "do_you_really_want_to_delete_your_favoriteOf": "Vuoi davvero eliminare la richiesta di preferito da <b>{0}</b>?",
  "do_you_really_want_to_delete_your_favorite": "Vuoi davvero eliminare dai tuoi preferiti <b>{0}</b>?",
  "do_you_really_want_to_delete_your_subprofile": "Vuoi davvero eliminare il sottoprofilo <b>{0}</b>?",
  "do_you_really_want_to_unjoin_your_subprofile": "Vuoi davvero rimuovere <b>{0}</b> dai partecipanti?",
  "message_do_you_really_want_to_change_table": "Vuoi davvero spostare {0} al tavolo {1}?",
  "thisEmailIsAlreadyBoundWithAnotherAccount": "Questo indirizzo email è già associato a un altro account!",
  "people_tab": "Compagni di tavolo",
  "tables_tab": "I miei tavoli",
  "table_tab": "Il mio tavolo",
  "settings_tab": "Impostazioni",
  "paying": "Pagamento in corso",
  "wants_to_pay": "Vuole pagare",
  "i_do_not_pay": "Non voglio pagare",
  "i_could_pay": "Non mi dispiace pagare",
  "i_want_to_pay": "Voglio pagare",
  "i_will_pay": "Pagherò",
  "i_am_invited": "Sono un ospite di qualcuno",
  "paid_for": "Compagni di tavolo",
  "i_pay_for2": "Tutti {0} pagati da me",
  "tableMates": "Non deciso chi paga per loro",
  "joiningTableMates": "Desidera unirsi",
  "your_joined_tables": "Il mio tavolo unito",
  "your_tables": "I miei tavoli",
  "add_sub_profile": "Aggiungi sottoprofilo",
  "add_participant": "Aggiungi nuovi partecipanti",
  "close": "Chiudi",
  "scan_to_join_table": "Scansiona per unirti al tavolo {0}",
  "add_additional_participants_as_subprofiles": "Sottoprofili",
  "add_additional_participants_without_table": "Partecipanti senza tavolo",
  "add_mate": "Aggiungi",
  "table_picker_title": "Scegli un tavolo",
  "table_picker": "Tavolo n. {0}",
  "my_table_picker": "Tavolo n. {0} (qui sono seduto)",
  "my_mate_picker": "{0} (io stesso)",
  "mate_picker": "{0}",
  "everybody": "Tutti {0}",
  "allTables": "Tutti i tavoli",
  "order": "Ordina",
  "orderMeals": "Pasti",
  "orderDrinks": "Bevande",
  "unknown": "???",
  "byCustomer": "Per persona",
  "byDrinkOrMeal": "Per pasto o bevanda",
  "byDrink": "Per bevanda",
  "byMeal": "Per pasto",
  "do_you_really_want_to_delete_all_new_orders": "Vuoi davvero svuotare il carrello?",
  "do_you_really_want_to_delete_all_orders": "Vuoi davvero eliminare tutti gli ordini nuovi non confermati?",
  "please_choose_mate_for_this_order": "{0} {1} {2} - Per chi ordini?",
  "select": "Seleziona",
  "order_items": "Numero di elementi:",
  "people_count": "Numero di persone:",
  "do_you_really_want_to_submit_all_new_orders": "Vuoi davvero inviare tutti gli ordini nuovi?",
  "show_table_qr": "Organizza i tuoi tavoli",
  "seats_wanted": "Per quante persone:",
  "placeholder": "Occupazione dei posti",
  "my_profile": "Il mio profilo",
  "unlocking_not_possible_there_are_pending_orders": "La persona non può lasciare il tavolo. Ci sono ordini in sospeso!",
  "unjoining_somebody_not_possible_there_are_pending_orders": "Non puoi lasciare il tavolo fino a quando non hai pagato gli ordini in sospeso di lui/lei!",
  "unjoining_yourself_not_possible_there_are_pending_orders": "Non puoi lasciare il tavolo fino a quando non hai pagato gli ordini in sospeso di te stesso!",
  "unjoining_somebody_not_possible_paying_for_others": "La persona non può lasciare il tavolo. Sta pagando per altre persone!",
  "comment": "Commento",
  "enter_comment": "Inserisci un commento (facoltativo)",
  "this_item_was_originally_ordered_by": "Questo articolo è stato originariamente ordinato da {0}!",
  "this_item_was_ordered_by": "Questo articolo è stato selezionato da {0}!",
  "cancelled": "Annullato",
  "selected": "Selezionato",
  "ordered": "Ordinato",
  "accepted": "Accettato",
  "producing": "In produzione",
  "delivering": "In consegna",
  "delivered": "Consegnato",
  "paid": "Pagato",
  "rejection_reason": [
    "Input per errore",
    "Non possibile",
    "Esaurito",
    "Non disponibile",
    "Non era pronto",
    "Era pronto troppo tardi"
  ],
  "timing": "Tempo",
  "timing_inherit": "Ereditato",
  "timing_asap": "Il prima possibile",
  "timing_appetizer": "Antipasto-Zuppa",
  "timing_maincourse": "Piatto principale",
  "timing_desert": "Dolce",
  "timing_last": "Ultimo",
  "for_whom": "Per chi",
  "unknown_person": "Persona {0}",
  "additions": "Ingredienti o condimenti aggiuntivi",
  "enter_additions": "Aggiungi ingredienti o condimenti aggiuntivi per personalizzare la tua bevanda o il tuo pasto",
  "inherited_additions": "Ingredienti o opzioni ereditate",
  "noResultsMessageText": 'Nessun risultato corrisponde a "{s}"',
  "options": "Opzioni",
  "reload_page": "Aggiorna",
  "timeout": "Tempo scaduto",
  "server_unavailable": "Impossibile comunicare con il server",
  "error_code_500": "Errore interno del server",
  "error_code_400": "Richiesta non valida",
  "continue": "Ignora e continua",
  "modify": "Modifica",
  "delete": "Elimina",
  "order_cannot_be_deleted": "L'ordine non può essere eliminato",
  "orders_only_in_selected_or_ordered_state_can_be_deleted": "Gli ordini solo nello stato 'selezionato' o 'ordinato' possono essere eliminati",
  "left_the_table": "ha lasciato il tavolo",
  "deleted_or_not_found": "Cancellato o non trovato",

  "qr_code": "Codice QR",
  "person": "Persona",

  "do_you_really_want_to_quit_pending_orders": "Vuoi davvero uscire? Ci sono ordini in sospeso!",
  "quantity": "Quantità",
  "paying_online": "Pagamento online...",

  "feedback": {
    "label": "Invia Feedback",
    "header": "Invia Feedback",
    "nextLabel": "Continua",
    "reviewLabel": "Revisione",
    "sendLabel": "Invia",
    "closeLabel": "Chiudi",
    "messageSuccess": "Il tuo feedback è stato inviato con successo.",
    "messageError": "Si è verificato un errore nell'invio del tuo feedback al server.",
    "pleaseDescribe": "Descrivi il problema che stai riscontrando",
    "issue": "Problema",
    "blackout": "Blocco",
    "highlight": "Evidenzia",
    "highlight_or_blackout": "Evidenzia o blocca le informazioni importanti"
  },

  "help": {
    "next": "Avanti",
    "back": "Indietro",
    "done": "Fatto",
    "skip": "Salta"
  },

  "nameformat": "{1} {0}",
  "service_fee": "Commissione di servizio",

  "daysOfWeek": ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  "monthNames": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
  "server_restart": "Il server è in fase di aggiornamento. Attendi pazientemente finché il server si riavvia e la connessione viene ripristinata!",
  "email_not_associated_with_an_account": "Email non associata a un account",
  "the_chosen_restauarant_online_services_seems_to_be_unavailable": "{0} al momento non è disponibile!",
  "phone": "Telefono",
  "address": "Indirizzo",
  "town": "Città",
  "country": "Paese",
  "postcode": "Codice postale",
  "allDay": "tutto il giorno",
  "download_progress": "Aggiornamento in corso ({0}%)",
  "installing_update": "Installazione dell'aggiornamento",
  "new_version_is_available": "È disponibile una nuova versione dell'applicazione!",
  "applelogin": "Accedi con Apple",
  "facebooklogin": "Accedi con Facebook",
  "door": "Piano/Porta",
  "consent_successfull": "Grazie per il tuo consenso",
  "update_successfull": "Aggiornamento riuscito",
  "consent_withdrawal_successfull": "Hai revocato il tuo consenso per gestire i tuoi dati personali! I tuoi dettagli personali sono stati cancellati dal nostro sistema!",
  "switch_back_to_live": "Il server principale è di nuovo disponibile! Torniamo al server principale!",
  "switch_to_backup": "Il server principale al momento non è disponibile! Passaggio al server di backup!",
  "access_denied": "Il login è riuscito, ma sembra che non hai accesso a nessuno degli ambienti. Contattaci su support@eatwithme.online se pensi che si tratti di un errore da parte nostra.",
  "pay_later": "Paga in seguito",
  "activation_code": "Codice di attivazione",
  "bad_activation_code": "Codice di attivazione errato o scaduto",
  "activate": "Attiva",
  "activation": "Attivazione",
  "no_internet": "Nessuna connessione internet",
  request: "Domanda"
};
export default local_it;
