import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import auth, { getParameterByName, post } from "../../js/auth";
import LabeledInput from "../../widgets/LabeledInput";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import ChangeLanguage from "../icon-buttons/ChangeLanguage";
import { I18n } from "react-redux-i18n";

class PasswordReset extends Component {
  render() {
    return (
      <Modal backdrop="static" keyboard={false} show={true} centered>
        <Modal.Header>
          <Modal.Title>{I18n.t("local.password_reset")}</Modal.Title>
          <ChangeLanguage />
        </Modal.Header>

        <Modal.Body>
          <div
            className=""
            style={{
              left: "0px",
              width: "100%",
              zIndex: "5",
              overflow: "hidden",
              paddingLeft: "20px",
              paddingRight: "20px",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div>
              <form onSubmit={this.login} className="input-group" style={{ width: "100%", background: "unset" }}>
                <LabeledInput id="password" label={I18n.t("local.password")} placeHolder={I18n.t("local.provide_password")} type="password" ref={node => (this.passwordNode = node)} />
                <input
                  type="submit"
                  style={{
                    position: "absolute",
                    left: "-9999px",
                    width: "1px",
                    height: "1px"
                  }}
                  tabIndex="-1"
                />
              </form>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button style={{ margin: "0px" }} id="login" onClick={() => this.props.history.push("login")} className="btn btn-info icon">
            {I18n.t("local.back_to_login")}
          </Button>
          <FlexGrowSpace />
          <Button
            style={{ margin: "0px" }}
            id="reset"
            onClick={() => {
              this.passwordReset();
            }}
            className="btn btn-primary icon icon-register"
          >
            {I18n.t("local.password_reset")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  passwordReset = () => {
    const t = this;
    var password = this.passwordNode.inputNode.value;
    var email = getParameterByName("email");
    if (password === "") return;
    //post(auth.globalserver + "/eatwithme.server/authenticationService/resetPassword", { email: email, password: password, activationToken: getParameterByName("token") }).done(function (data) {
    post(auth.globalserver + "/eatwithme.server/authenticationService/resetPassword", { email: email, password: password, activationToken: getParameterByName("token") }).done(function (data) {
      if (data == null) {
        alert("Failed to set new password!");
      } else if (data.error && data.error === "local.bad_password_request_token") {
        auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error)).done(function () {
          t.props.history.push("/passwordForgotten");
        });
      } else if (data.error) {
        auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error));
      } else if (data.success) {
        auth.messageDialog(I18n.t("local.success_message"), I18n.t(data.success)).done(function () {
          t.props.history.push("/login");
        });
      } else {
        auth.messageDialog(I18n.t("local.error_message"), I18n.t(data.error)).done(function () { });
      }
    });
  };
}

export default PasswordReset;
