import React, { Component } from "react";
import ImageButton from "./ImageButton";
import icon from "../../img/login@2x.png";
import { withRouter } from "react-router-dom";
import { unlock } from "../../js/admin.js";

class LoginButton extends Component {
  state = {};
  render() {
    return <ImageButton {...this.props} id="login" src={icon} handleClick={this.login} style={{ width: "24px", height: "24px", marginLeft: "9px" }}></ImageButton>;
  }

  login = () => {
    //logout(false).done(() => this.props.history.push("login" + (this.props.query ? "?" + this.props.query : "")));
    //this.props.history.push("login" + (this.props.query ? "?" + this.props.query : ""));
    unlock("new");
  };
}

export default withRouter(LoginButton);
