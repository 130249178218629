import React, { Component } from "react";

class ImageButton extends Component {
  render() {
    if (this.props.src) return <img id={this.props.id} alt="" className={this.props.clazz} onClick={this.props.handleClick} src={this.props.src} style={this.props.style}></img>;
    else return <div id={this.props.id} alt="" className={this.props.clazz} onClick={this.props.handleClick} style={this.props.style}></div>;
  }
}

export default ImageButton;
