import React from "react";

export const booleanToCheckbox = ({ value }) => {
  const checked = value === true ? { defaultChecked: true } : {};
  return <input type="checkbox" {...checked}></input>;
};

export const booleanToCheckboxReadonly = props => {
  const { value } = props;
  const checked = value === true ? { checked: true } : {};
  return <input type="checkbox" {...checked} readOnly={true}></input>;
};
