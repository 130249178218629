import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate } from "react-redux-i18n";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { getMyImageUrl, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";

class EntityPage extends Component {

    state = {
        initialized: false,
    }

    constructor(title) {
        super();
        this.state.title = title;
    }

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            this.code.ready(this.props, this.state);
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            this.code.ready(this.props, this.state);
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        this.code.clear();
        this.setState({ initialized: false });
    }

    render() {

        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, overflow: "hidden", maxHeight: "100%", height: "100%" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value={this.state.title} />} />
                            <div style={{
                                display: "flex", justifyContent: "flex-end", alignItems: "center"
                            }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "30px", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div id="main" ></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }


}

export default EntityPage;