import React, { Component } from "react";
import _ from "lodash";

class AbstractRestaurantEditor extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.restaurant && state.restaurant && state.restaurant.id === props.restaurant.id && props.cancel !== true && state.restaurant.timestamp === props.restaurant.timestamp) {
      if (JSON.stringify((state.restaurant) !== JSON.stringify(props.restaurant)))
        return {
          restaurant: props.restaurant,
          ...state,
          changed: true
        };
    }
    return {
      restaurant: props.restaurant,
      myStatus: props.myStatus,
      changed: false
    };
  }

  render() {
    return <div>{this.props.children};</div>;
  }

}

export default AbstractRestaurantEditor;
