import ean from "ean-check";

export const generate_flexy = (def, amounts, cashierType, payable, types, amountPaidExchangeRate, serviceVat) => {
    var url = "";
    var totalAmount = 0;
    var services = {};
    var a = 0;

    Object.values(amounts).sort((a, b) => a.entryOrder - b.entryOrder).filter(category => category.amount + category.service_amount).forEach(category => {
        if (category.amount) {
            url += ean.ean13.generate(240000000000 + (category.vat === null || category.vat === undefined ? 99 : category.vat) * 1000000 + Math.round(category.amount * category.count)) + "\r\n";
            totalAmount += category.amount * category.count;
            a += category.amount * category.count;
        }
        if (category.service_amount) {
            if (!services[category.vat])
                services[category.vat] = 0;
            services[category.vat] += category.service_amount * category.count;
            totalAmount += category.service_amount * category.count;
        }
    });

    Object.keys(services).forEach(key => {
        a += services[key];
        url += ean.ean13.generate(241000000000 + key * 1000000 + services[key]) + "\r\n";
    })
    console.log(url);
    def.resolve(url);
}  