import React, { Component } from "react";
import { connect } from "react-redux";

const DefaultTheme = React.lazy(() => import('../theme/Default'));
const FlatTheme = React.lazy(() => import('../theme/Flat'));
const FancyTheme = React.lazy(() => import('../theme/Fancy'));
const NightTheme = React.lazy(() => import('../theme/Night'));
const OldTheme = React.lazy(() => import('../theme/Old'));

class ThemeSelector extends Component {

    render = () => {
        const CHOSEN_THEME = this.props.daynight ? 'night' : localStorage.getItem('skin') || 'default';
        //eslint-disable-next-line no-undef
        if (typeof StatusBar !== "undefined") {
            if (CHOSEN_THEME === "night") {
                //eslint-disable-next-line no-undef
                StatusBar.backgroundColorByName("black");
                /*
                if (typeof NavigationBar !== "undefined") {
                    //eslint-disable-next-line no-undef
                    NavigationBar.hide();
                    try {
                        //eslint-disable-next-line no-undef
                        NavigationBar.backgroundColorByName("black", false);
                    } catch (ex) { }
                }
                */
            } else {
                //eslint-disable-next-line no-undef
                StatusBar.backgroundColorByName("white");
                /*
                if (typeof NavigationBar !== "undefined") {
                    try {
                        //eslint-disable-next-line no-undef
                        NavigationBar.hide();
                    } catch (ex) { }
                    //eslint-disable-next-line no-undef
                    NavigationBar.backgroundColorByName("white", true);
                }
                */
            }
        }
        return (
            <>
                <React.Suspense fallback={<></>}>
                    {(CHOSEN_THEME === 'flat') && <FlatTheme />}
                    {(CHOSEN_THEME === 'default') && <DefaultTheme />}
                    {(CHOSEN_THEME === 'fancy') && <FancyTheme />}
                    {(CHOSEN_THEME === 'night') && <NightTheme />}
                    {(CHOSEN_THEME === 'old') && <OldTheme />}
                </React.Suspense>
                {this.props.children}
            </>
        )
    }

}

const mapStateToProps = (props, state) => {
    return {
        daynight: props.rootReducer.daynight,
    };
};

export default connect(mapStateToProps)(ThemeSelector);