import { unflattenObject } from "../components/utils";
import { modifyRole, addNewRole, deleteRole } from "../js/superadmin/superadmin";
import { updateRow, deleteRow, addNewRow } from "./rootReducer";

const roleReducer = (state, action) => {
  switch (action.type) {
    case "updateRow":
      {
        const data = unflattenObject(action.row);
        if (data.role.id)
          modifyRole(data.role.id, data.customer.id, data.role.role, data.role.entityType, data.entity.id, data => {
            updateRow(action);
          });
        else if (data.customer && data.entity && data.role.role)
          addNewRole(data.customer.email, data.role.role, data.role.entityType, data.entity.id, data => {
            action.row["role.id"] = data.id;
            addNewRow({ stateKey: action.stateKey, data: {} });
            updateRow(action);
          });
        else setTimeout(() => updateRow(action), 10);
      }
      break;
    case "deleteRow":
      {
        const data = unflattenObject(action.row);
        if (data.role.id) {
          deleteRole(data.role.id, data => {
            deleteRow(action);
          });
        }
      }
      break;
    default:
  }
  return state;
};
export default roleReducer;
