import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, clearData, reload, toggleChart, recalculateConsumption, filterChanged } from "../js/admin/stocklist";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { ajaxErrorEnd, featureEnabled, getMyImageUrl, getQuantityLabel, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import moment from "moment";
import { withRouter } from "react-router-dom";
import menuitemutil from "../js/admin/menuitem";
import { additionOptionIsChecked, includedInCategoryOption, orderMenuItem } from "../js/orderMenuItem";
import { activeMenuCategories, activeMenuItems, getMenuCategoryOptions } from "../js/tables-overview";
import { getMenuCategory } from "../js/admin";
import { capturePhotoWithData } from "../js/camera-util";

class StockList extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
    };
    //hello

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            ready(this.props);
            this.setState({ initialized: true });
        }
        auth.minfontsize = 8;

        window.toggleChart = toggleChart;
        window.recalculateConsumption = recalculateConsumption;
        window.menuitemutil = menuitemutil;
        window.reload = reload;
        window.ajaxErrorEnd = ajaxErrorEnd;
        window.featureEnabled = featureEnabled;
        window.orderMenuItem = orderMenuItem;
        window.getQuantityLabel = getQuantityLabel;
        window.includedInCategoryOption = includedInCategoryOption;
        window.getActiveMenuItems = activeMenuItems;
        window.getActiveMenuCategories = activeMenuCategories;
        window.getMenuCategory = getMenuCategory;
        window.getMenuCategoryOptions = getMenuCategoryOptions;
        window.additionOptionIsChecked = additionOptionIsChecked;
        window.filterChanged = filterChanged;
        window.capturePhotoWithData = capturePhotoWithData;
        setRem();
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props);
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        clearData();
        this.setState({ initialized: false });
        delete window.toggleChart;
        delete window.recalculateConsumption;
        delete window.menuitemutil;
        delete window.reload;
        delete window.ajaxErrorEnd;
        delete window.featureEnabled;
        delete window.orderMenuItem;
        delete window.getQuantityLabel;
        delete window.includedInCategoryOption;
        delete window.getActiveMenuItems;
        delete window.getActiveMenuCategories;
        delete window.getMenuCategory;
        delete window.getMenuCategoryOptions;
        delete window.additionOptionIsChecked;
        delete window.filterChanged;
        delete window.capturePhotoWithData;
    }

    render() {
        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="StockList" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "100%" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.stockList" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div id="main" className="" style={{ flexGrow: "1" }}></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                    <div id="editMenuItemDialog" />
                </div >
            );
        } else return null;
    }

    dateRangeChanged = (event, values) => {
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        reload(this.state);
    }

}
const mapStateToProps = (props, state) => {
    if (typeof localStorage.admin_stocklist_start === "undefined") {
        localStorage.admin_stocklist_start = new Date().getTime();
        localStorage.admin_stocklist_end = new Date().getTime();
    }

    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(StockList));
