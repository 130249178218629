
import React, { Component } from "react";
import $ from "jquery";
import auth, { appRoot, getString, switchServer } from "../js/auth";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { getMyStatus, hasRole } from "../js/admin";
import { rest } from "lodash";
import moment from "moment";

var get = auth.get;

if (typeof localStorage.restaurantNameSelected == "undefined") {
  localStorage.restaurantNameSelected = "Please select a restaurant to administer";
}

//<img alt="" style={{ height: "24px", width: "24px" }} src={auth.globalserver + "/eatwithme.server/adminService/" + restaurant.globalId + "/getRestaurantImage"} />

class RestaurantSelector extends Component {
  render() {
    const { restaurants } = this.props;

    const getRestaurant2 = (restaurant) => {
      if (!restaurant.instance) restaurant.instance = "eatwithme";
      const ntaknotok = !restaurant.test && restaurant?.ntakstatus?.enabled && moment(restaurant?.ntakstatus?.startdate).isBefore(moment()) && restaurant?.ntakstatus?.status && Object.keys(restaurant?.ntakstatus?.status).filter(key => !restaurant?.ntakstatus?.status[key]).length;
      return <Dropdown.Item
        style={{ color: "#857D6D", fontSize: "20px", paddingRight: hasRole(auth.myStatus.roles, ["superadmin"]) ? "3rem" : "0", paddingLeft: hasRole(auth.myStatus.roles, ["superadmin"]) ? "0rem" : "0", backgroundColor: ntaknotok ? "yellow" : restaurant.subscription || restaurant.test ? "" : "blue" }}
        key={restaurant.globalId}
        onClick={() => {
          this.pickRestaurant(restaurant.globalId, restaurant.name, restaurant.languages, restaurant.serverUrl, restaurant.backupUrl, restaurant.instance);
        }}
        className={"dropdown-item " + (restaurant.test ? "test" : "")}
      >
        {(restaurant.test ? "Test - " : "") + restaurant.name}
        {restaurant.subscription && hasRole(auth.myStatus.roles, ["superadmin"]) ? (<div style={{ position: "absolute", fontSize: "10px", right: "0", bottom: "0" }}>{restaurant.subscription}</div>) : null}
        {(!hasRole(auth.myStatus.roles, ["superadmin"]) || !ntaknotok) ? null : <div class="icon-attention" style={{ position: "absolute", fontSize: "8px", right: "0", top: "0", "color": "red" }}>NTAK</div>}
      </Dropdown.Item>
    }
    return (
      <React.Fragment>
        <Dropdown key={this.props.restaurantSelected} style={{ fontFamily: "Mohave" }}>
          <DropdownToggle className="nocaret" variant="none" id="dropdownRestaurantButton" style={{ fontSize: "min(24px, 7vw)", color: "#857D6D", }}>
            {this.props.myStatus.restaurant_name ? this.props.myStatus.restaurant_name : I18n.t("admin_local.please_select_a_restaurant")}
          </DropdownToggle>
          <Dropdown.Menu style={{ maxHeight: "85vh", overflowY: "auto" }}>
            {restaurants.filter(restaurant => restaurant.isActive).sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => (a.test ? 1 : 0) - (b.test ? 1 : 0)).map(restaurant => (
              getRestaurant2(restaurant)
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment >
    );
  }

  pickRestaurant(restaurantId, name, languages, url, backupUrl, instance) {
    const originalServer = auth.server;
    const t = this;
    switchServer({ globalId: restaurantId, serverUrl: url, backupUrl: backupUrl, name: name, instance: instance }).done(() => {
      getMyStatus(() => {
        // eslint-disable-next-line no-restricted-globals
        if (window.device.platform === "browser" && Number(location.port) !== 3000 && location.href.indexOf("https://hotbackup.eatwithme.online") === -1) {

          get("login/setRestaurant/" + localStorage.restaurantSelected).done(() => {
            t.props.onReload();
          });
        } else {
          localStorage.server = auth.server;
          get("login/setRestaurant/" + localStorage.restaurantSelected)
            .done(() => {
              // eslint-disable-next-line no-restricted-globals
              t.props.onReload();
            })
            .fail(() => {
              auth.ajaxError = false;
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            });
        }
      });
    });
  }

  refreshRestaurantHeader() {
    $("#dropdownRestaurantButton").html(localStorage.restaurantNameSelected + "<span class='caret'></span>");
  }
}

const mapStateToProps = state => {
  return {
    restaurants: state.rootReducer.restaurants ? state.rootReducer.restaurants : [],
    restaurantSelected: state.rootReducer.restaurantSelected,
  };
};

export default withRouter(connect(mapStateToProps)(RestaurantSelector));
