import $ from "jquery";
import admin, { getRolesByRestaurants } from "../admin";
import auth, { confirmDialog, del, get, getInputDialog, getInputDialog2, messageDialog, post, postt, put, startpoll } from "../auth";
import { I18n } from "react-redux-i18n";
import { onPhotoDataSuccess, crop } from "../camera-util";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import { saveAs } from "file-saver";
import { restaurantSelected } from "../../actions/EatWithMeActions";


var paymentMethodsData;
var currenciesData;
var activeRestaurant;
var properties;
//var printersData;

export const clearData = () => {
	r = null;
	roles = null;
	paymentMethods = null;
	currencies = null;
	accessTokens = null;
	if (window.CKEDITOR)
		Object.keys(window.CKEDITOR.instances).forEach(c => window.CKEDITOR.instances[c].destroy());
}

export const reload = () => {

}



export const ready = (props) => {
	properties = props;
	activeRestaurant = null;
	admin.getAllRestaurants(function (data) {
		auth.myStatus.restaurants = data.restaurants;
	})
	admin.getAllPaymentMethods(localStorage.language, function (pm) {
		paymentMethodsData = pm;
		admin.getAllCurrencies(localStorage.language, function (c) {
			currenciesData = c;
			$('#main').replaceWith($('#restaurantMainTemplate').tmpl({
				paymentMethods: paymentMethodsData,
				properties: props,
				currencies: currenciesData,
				...tmplparams()
			}));


			updateHooks();
			admin.getAllRestaurants2(processRestaurants);

			startpoll(serverSideMessageHandler);
			// updateHooks();

			$("body").on("paste", function (event) {

				var items = (event.clipboardData || event.originalEvent.clipboardData).items;
				for (let index in items) {
					var item = items[index];
					if (item.kind === 'file') {
						var blob = item.getAsFile();
						var reader = new FileReader();
						reader.onload = function (event) {
							onPhotoDataSuccess(event.target.result);
						}; // data url!
						reader.readAsDataURL(blob);
					}
				}

			})
		});
	});
}

function serverSideMessageHandler(data) {

}

var r = null;
function processRestaurants(restaurants) {
	restaurants.restaurants.forEach(function (v, i) {
		v.title = v.name;
		v.folder = false;
		v.key = v.id;
	});
	if (r == null)
		r = createTree("table#restaurants", {
			checkbox: false,
			source: restaurants.restaurants,
			zindex: 1000,
			extensions: ["persist", "table", "gridnav"],
			icon: false,
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 2
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets
				// activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				$('#addAccessToken').prop('disabled', false);
				var node = data.node;
				activeRestaurant = data.node.data;

				$("#ntak_id").val(activeRestaurant.ntakid);
				$("#ntak_tax_id").val(activeRestaurant.ntaktax);
				$("#cert_request").val(activeRestaurant.ntakcsr);
				$("#cert").val(activeRestaurant.ntakcer);
				$("#key").val(activeRestaurant.ntakkey);
				$('#editRestaurant').removeAttr('disabled');
				$('#deleteRestaurant').removeAttr('disabled');

				if (node.data.ntakid && node.data.ntaktax) {
					$('#downloadCSR').removeAttr('disabled');
					$('#uploadCER').removeAttr('disabled');
					$('#test').attr('disabled');
				} else {
					$('#downloadCSR').attr('disabled', true);
					$('#uploadCER').attr('disabled', true);
					$('#test').attr('disabled', true);
				}

				if (node.data.isActive) {
					$('#deactivateRestaurant').removeAttr('disabled');
					$('#activateRestaurant').prop('disabled', 'true');
				} else {
					$('#deactivateRestaurant').prop('disabled', 'true');
					$('#activateRestaurant').removeAttr('disabled');
				}
				admin.getRolesByRestaurants(data.node.data.id, processRoles);
				admin.getCurrenciesByRestaurant(data.node.data.id, processCurrencies);
				processConnectedRestaurants(data.node.data.copies);
				admin.getPaymentMethodsByRestaurant(data.node.data.id, processPaymentMethods);
				admin.getAccessTokensByRestaurant(data.node.data.id, processAccessTokens);
				$('#addRole').removeAttr('disabled');
				$('#addUser').removeAttr('disabled');

				admin.getPrinters(function (printers) {
					$('#editRestaurant.modal').remove();
					$('#editRestaurantTemplate').tmpl({
						paymentMethods: paymentMethodsData,
						currencies: currenciesData,
						printersData: printers,
						possiblePrinterActions: node.data.possiblePrinterActions,
						...tmplparams()
					}).appendTo('body');
					updateEditRestaurantHook();
				}, data.node.data.id);

			},

			// renderStatusColumns: false, // false: default renderer
			// true: generate renderColumns events, even for status nodes
			// function: specific callback for status nodes

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = true;

				// (Column #0 is rendered by fancytree by adding the checkbox)

				// Column #1 should contain the index as plain text, e.g.
				// '2.7.1'
				$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

				// (Column #2 is rendered by fancytree)

				// ...otherwise render remaining columns
				$tdList.eq(2).text(node.data.name)
				$tdList.eq(3).text(node.data.address)
				$tdList.eq(4).text(node.data.town)
				$tdList.eq(5).text(node.data.country)
				$tdList.eq(6).text(node.data.languages)
				if (!node.data.isActive)
					$tdList.addClass('itemDeactivated');

				// $tdList.eq(1)
			}
		});
	else {
		r.reload(restaurants.restaurants);
	}
}

var roles = null;

function processRoles(data) {
	data.roles.forEach(function (v, i) {
		if (v.customer)
			v.title = v.customer.name + " " + v.customer.surname + " (" + v.customer.email + ")";
		else
			v.title = v.role.email;
		v.folder = false;
		v.key = v.id;
	});
	if (roles == null)
		roles = createTree("table#roles", {
			checkbox: false,
			source: data.roles,
			zindex: 1000,
			extensions: ["table", "gridnav"],
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 2
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},

			// renderStatusColumns: false, // false: default renderer
			// true: generate renderColumns events, even for status nodes
			// function: specific callback for status nodes
			activate: function (event, data) {
				// A node was activated: display its title:
				$('#deleteRole').removeAttr('disabled');
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				// Make the title cell span the remaining columns if it's a
				// folder:
				/*
				 * if( typeof node.data != 'undefined' ) { $tdList.eq(2)
				 * .prop("colspan", 4) .nextAll().remove(); //return; }
				 */

				node.expanded = false;

				// (Column #0 is rendered by fancytree by adding the checkbox)

				// Column #1 should contain the index as plain text, e.g.
				// '2.7.1'
				$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

				// (Column #2 is rendered by fancytree)
				// ...otherwise render remaining columns
				// $tdList.eq(3).text(node.data.email);
				$tdList.eq(3).text(I18n.t('admin_local.role_names.' + node.data.role.role));
				$tdList.eq(4).text(node.data.role.entityType);
				$tdList.eq(5).text(node.data.entity.name);
				// $tdList.eq(1)
			},

			edit: {
				// Available options with their default:
				adjustWidthOfs: 4, // null: don't adjust input size to content
				inputCss: {
					minWidth: "3em"
				},
				triggerStart: ["clickActive", "f2", "dblclick", "shift+click", "mac+enter"],
				beforeEdit: function (event, node) {
					return true;
				},
				// $.noop, // Return false to prevent edit mode
				edit: function (d) {
					// $.noop, // Editor was opened (available as data.input)
				},
				beforeClose: $.noop, // Return false to prevent cancel/save
				// (data.input is available)
				save: $.noop, // Save data.input.val() or return false to keep
				// editor open
				close: $.noop, // Editor was removed
			},

		});
	else
		roles.reload(data.roles);
}

export const saveRestaurant = () => {
	var modal = $('div#editRestaurant');
	var id = modal.find('#id').val();
	var globalId = modal.find('#globalId').val();
	var name = modal.find('#name').val();
	var address = modal.find('#address').val();
	var company = modal.find('#company').val();
	var postcode = modal.find('#postcode').val();
	var phone = modal.find('#phone').val();
	var town = modal.find('#town').val();
	var country = modal.find('#country').val();
	var languages = modal.find('#languages').val();
	var longitude = modal.find('#longitude').val();
	var latitude = modal.find('#latitude').val();
	var css = modal.find('#css').val();
	var invoiceHeading = window.CKEDITOR.instances.invoiceHeading.getData();
	var invoiceFooter = window.CKEDITOR.instances.invoiceFooter.getData();
	Object.keys(window.CKEDITOR.instances).forEach(c => window.CKEDITOR.instances[c].destroy());
	crop();
	var image = $(modal.find('#smallImage')).attr('src');
	var printerActions = {};
	$('.printerAction').each(function (ind, p) {
		printerActions[$(p).attr('id')] = {
			id: $(p).val() ? $(p).val() : null
		};
	});
	var active = modal.find('#active').prop('checked');
	admin.modifyRestaurant({ globalId, id, name, address, town, country, phone, postcode, company, languages, longitude, latitude, css, invoiceHeading, invoiceFooter, printerActions, isActive: active, image }, function () {
		admin.getAllRestaurants2(processRestaurants);
	});
}

export const _deleteRole = () => {
	var data = roles.getActiveNode().data;
	admin.deleteRole(data.role.id, function () {
		var data = r.getActiveNode().data;
		admin.getRolesByRestaurants(data.id, processRoles);
	});
}

export const saveRole = () => {
	var modal = $('div#editRole');
	modal.find("#users").tokenize2().pressedDelimiter();
	modal.find('#entity').data('tokenize2').toArray().forEach(function (restaurant) {
		modal.find('#users').data('tokenize2').toArray().forEach(function (user) {
			admin.addNewRole(user, modal.find('#role').val(), modal.find('#entityType').val(), restaurant, function () {
				var data = r.getActiveNode().data;
				getRolesByRestaurants(data.id, processRoles);
			})
		});
	});
}
export const saveUser = () => {
	var modal = $('div#editUser');
	modal.find('#entity').data('tokenize2').toArray().forEach(function (restaurant) {
		admin.addNewUser(modal.find('#first_name').val(), modal.find('#surname').val(), modal.find('#email').val(), modal.find('#password').val(), modal.find('#role').val(), modal.find('#entityType').val(), restaurant, function () {
			var data = r.getActiveNode().data;
			getRolesByRestaurants(data.id, processRoles);
		})
	});
}

function updateEditRestaurantHook() {


	$('div#editRestaurant').on('shown.bs.modal', function (event) {

		const CKEDITOR = window.CKEDITOR;

		if (!CKEDITOR.instances.invoiceHeading) {
			var wysiwygareaAvailable = isWysiwygareaAvailable(),
				isBBCodeBuiltIn = !!CKEDITOR.plugins.get('bbcode');

			var editorElement = CKEDITOR.document.getById('editor');

			// :(((
			if (isBBCodeBuiltIn) {
				editorElement.setHtml(
					'Hello world!\n\n' +
					'I\'m an instance of [url=https://ckeditor.com]CKEditor[/url].'
				);
			}

			// Depending on the wysiwygarea plugin availability initialize classic or inline editor.
			if (wysiwygareaAvailable) {
				CKEDITOR.replace('invoiceHeading');
			} else {
				editorElement.setAttribute('contenteditable', 'true');
				CKEDITOR.inline('invoiceHeading');

				// TODO we can consider displaying some info box that
				// without wysiwygarea the classic editor may not work.
			}

			function isWysiwygareaAvailable() {
				// If in development mode, then the wysiwygarea must be available.
				// Split REV into two strings so builder does not replace it :D.
				if (CKEDITOR.revision == ('%RE' + 'V%')) {
					return true;
				}

				return !!CKEDITOR.plugins.get('wysiwygarea');
			}

		}
		if (!CKEDITOR.instances.invoiceFooter) {
			var wysiwygareaAvailable = isWysiwygareaAvailable(),
				isBBCodeBuiltIn = !!CKEDITOR.plugins.get('bbcode');

			var editorElement = CKEDITOR.document.getById('editor');

			// :(((
			if (isBBCodeBuiltIn) {
				editorElement.setHtml(
					'Hello world!\n\n' +
					'I\'m an instance of [url=https://ckeditor.com]CKEditor[/url].'
				);
			}

			// Depending on the wysiwygarea plugin availability initialize classic or inline editor.
			if (wysiwygareaAvailable) {
				CKEDITOR.replace('invoiceFooter');
			} else {
				editorElement.setAttribute('contenteditable', 'true');
				CKEDITOR.inline('invoiceFooter');

				// TODO we can consider displaying some info box that
				// without wysiwygarea the classic editor may not work.
			}

			function isWysiwygareaAvailable() {
				// If in development mode, then the wysiwygarea must be available.
				// Split REV into two strings so builder does not replace it :D.
				if (CKEDITOR.revision == ('%RE' + 'V%')) {
					return true;
				}

				return !!CKEDITOR.plugins.get('wysiwygarea');
			}

		}


		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = r.getActiveNode().data;

			window.CKEDITOR.instances.invoiceHeading.setData(data.invoiceHeading);
			window.CKEDITOR.instances.invoiceFooter.setData(data.invoiceFooter);
		} else {
			window.CKEDITOR.instances.invoiceHeading.setData("");
			window.CKEDITOR.instances.invoiceFooter.setData("");
		}

	});

	$('div#editRestaurant').on('show.bs.modal', function (event) {
		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = r.getActiveNode().data;
			modal.find('#id').val(data.id);
			modal.find('#globalId').val(data.globalId);
			if (data.image && data.image !== "") {
				modal.find('#smallImage').attr('src', data.image);
				$(modal.find('#smallImageDiv')).removeClass('hidden');
			} else {
				modal.find('#smallImage').attr('src', "");
				$(modal.find('#smallImageDiv')).addClass('hidden');
			}
			modal.find('#name').val(data.name);
			modal.find('#address').val(data.address);
			modal.find('#phone').val(data.phone);
			modal.find('#company').val(data.company);
			modal.find('#postcode').val(data.postcode);
			modal.find('#town').val(data.town);
			modal.find('#country').val(data.country);
			modal.find('#languages').val(data.languages);
			modal.find('#longitude').val(data.longitude);
			modal.find('#latitude').val(data.latitude);
			modal.find('#currency').val(data.baseCurrency != null ? data.baseCurrency.id : "");
			modal.find('#css').val(data.css);
			//			modal.find('#invoiceHeading').html(data.invoiceHeading);

			Object.keys(data.printerActions).forEach(function (p) {
				var v = data.printerActions[p].id;
				var t = $(modal.find("#" + p + ".printerAction option[value=" + (v ? v : '') + "]"));
				t.prop('selected', true);
			});
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
		} else {
			modal.find('#id').val("");
			modal.find('#smallImage').attr('src', "");
			$(modal.find('#smallImageDiv')).addClass('hidden');
			modal.find('#name').val("");
			modal.find('#address').val("");
			modal.find('#town').val("");
			modal.find('#country').val("");
			modal.find('#languages').val("");
			modal.find('#longitude').val("");
			modal.find('#latitude').val("");
			modal.find('#css').val("");
			//modal.find('#invoiceHeading').html("");
			modal.find('#currency').val("");
			modal.find('#active').prop("checked", 'true');
		}
	})
}

function updateHooks() {

	$('div#editUser').on('show.bs.modal', function (event) {
		var modal = $(this);
		modal.find('#entity').tokenize2({
			placeholder: I18n.t('local.placeholder_add_a_name'),
			tokensAllowCustom: false,
			searchMinLength: 3,
			dataSource: function (a, b) {
				return admin.getEntitiesContaining(modal.find('#entityType').val(), a, function (data) {
					var $items = [];
					$.each(data, function (k, v) {
						switch (modal.find('#entityType').val()) {
							case "Restaurant":
								$items.push({
									value: v.id,
									text: v.name + " (" + v.address + ")"
								});
								break;
							case "ProductionLine":
								$items.push({
									value: v.id,
									text: getRestaurant(v.restaurantId).name + " - " + v.name
								});
								break;
							case "Store":
								$items.push({
									value: v.id,
									text: getRestaurant(v.restaurantId).name + " - " + v.name
								});
								break;
							default:
						}
					});
					modal.find('#entity').trigger('tokenize:dropdown:fill', [$items]);
				});
			}
		});
		var restaurant = r.getActiveNode().data;
		modal.find('#entity').tokenize2().trigger('tokenize:tokens:add', [restaurant.id, restaurant.name, true, ""]);

		modal.find('#entityType').change(function () {
			var tokenizer = modal.find('#entity').tokenize2();
			tokenizer.toArray().forEach(function (addition) {
				tokenizer.trigger('tokenize:tokens:remove', addition);
			});
		});
		// modal.find('#restaurants').tokenize2().trigger('tokenize:tokens:add',
		// [''+restaurant.id, favorite.parent.name, true, (favorite.approved ?
		// 'approved ' : '')+ 'favoriteOf'+favorite.id, tooltip]);

	})
	$('div#editRole').on('show.bs.modal', function (event) {
		var modal = $(this);
		modal.find('#users').tokenize2({
			placeholder: I18n.t('local.placeholder_add_an_email'),
			tokensAllowCustom: true,
			searchMinLength: 3,
			dataSource: function (a, b) {
				return admin.getUsersContaining(a, undefined, undefined, undefined, function (data) {
					var $items = [];
					$.each(data, function (k, v) {
						$items.push({
							value: v.email,
							text: v.name + (v.surname != null ? " " + v.surname : "") + " (" + v.email + ")"
						});
					});
					modal.find('#users').trigger('tokenize:dropdown:fill', [$items]);
				});
			}
		});
		modal.find('#entity').tokenize2({
			placeholder: I18n.t('local.placeholder_add_a_name'),
			tokensAllowCustom: false,
			searchMinLength: 3,
			dataSource: function (a, b) {
				return admin.getEntitiesContaining(modal.find('#entityType').val(), a, function (data) {
					var $items = [];
					$.each(data, function (k, v) {
						switch (modal.find('#entityType').val()) {
							case "Restaurant":
								$items.push({
									value: v.id,
									text: v.name + " (" + v.address + ")"
								});
								break;
							case "ProductionLine":
								$items.push({
									value: v.id,
									text: getRestaurant(v.restaurantId).name + " - " + v.name
								});
								break;
							case "Store":
								$items.push({
									value: v.id,
									text: getRestaurant(v.restaurantId).name + " - " + v.name
								});
								break;
							default:
						}
					});
					modal.find('#entity').trigger('tokenize:dropdown:fill', [$items]);
				});
			}
		});
		var restaurant = r.getActiveNode().data;
		modal.find('#entity').tokenize2().trigger('tokenize:tokens:add', [restaurant.id, restaurant.name, true, ""]);

		modal.find('#entityType').change(function () {
			var tokenizer = modal.find('#entity').tokenize2();
			tokenizer.toArray().forEach(function (addition) {
				tokenizer.trigger('tokenize:tokens:remove', addition);
			});
		});
		// modal.find('#restaurants').tokenize2().trigger('tokenize:tokens:add',
		// [''+restaurant.id, favorite.parent.name, true, (favorite.approved ?
		// 'approved ' : '')+ 'favoriteOf'+favorite.id, tooltip]);

	})

	$('div#editPaymentMethod').on('show.bs.modal', function (event) {
		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = paymentMethods.getActiveNode().data;
			modal.find('#id').val(data.id);
			if (data.image && data.image !== "") {
				modal.find('#smallImage').attr('src', data.image);
				$(modal.find('#smallImageDiv')).removeClass('hidden');
			} else {
				modal.find('#smallImage').attr('src', "");
				$(modal.find('#smallImageDiv')).addClass('hidden');
			}
			modal.find('#paymentMethod').val(data.method.id);
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
		} else {
			modal.find('#id').val("");
			modal.find('#smallImage').attr('src', "");
			$(modal.find('#smallImageDiv')).addClass('hidden');
			modal.find('#paymentMethod').val("");
			modal.find('#active').prop("checked", 'true');
		}
	});

	$('div#editCurrency').on('show.bs.modal', function (event) {
		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = currencies.getActiveNode().data;
			modal.find('#id').val(data.id);
			modal.find('#currency').val(data.currency.id);
			modal.find('#exchange_rate').val(data.exchangeRate);
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
		} else {
			modal.find('#id').val("");
			modal.find('#currency').val("");
			modal.find('#exchange_rate').val("");
		}
	});

}

var paymentMethods = null;

function processPaymentMethods(data) {
	data.forEach(function (v, i) {
		v.title = v.method.name;
		v.folder = false;
	});
	$('#deletePaymentMethod').prop('disabled', 'true');
	$('#editPaymentMethod').prop('disabled', 'true');
	$('#activatePaymentMethod').prop('disabled', 'true');
	$('#deactivatePaymentMethod').prop('disabled', 'true');
	if (paymentMethods == null)
		paymentMethods = createTree("table#paymentMethods", {
			checkbox: false,
			source: data,
			zindex: 1000,
			extensions: ["table", "gridnav", "persist", "dnd"],
			table: {
				checkboxColumnIdx: 0,
				indentation: 16,
				nodeColumnIdx: 2
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				handleCursorKeys: true
			},

			activate: function (event, data) {
				var node = data.node;
				$('#deletePaymentMethod').removeAttr('disabled');
				$('#editPaymentMethod').removeAttr('disabled');
				if (node.data.isActive === true) {
					$('#activatePaymentMethod').prop('disabled', 'true');
					$('#deactivatePaymentMethod').removeAttr('disabled');
				} else {
					$('#activatePaymentMethod').removeAttr('disabled');
					$('#deactivatePaymentMethod').prop('disabled', 'true');
				}
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = false;
				//$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");
				//$tdList.eq(2).text(node.data.method.name);
				if (!node.data.isActive)
					$tdList.addClass('itemDeactivated');
			},

			edit: {
				adjustWidthOfs: 4, // null: don't adjust input size to content
				inputCss: {
					minWidth: "3em"
				},
				triggerStart: ["clickActive", "f2", "dblclick", "shift+click", "mac+enter"],
				beforeEdit: function (event, node) {
					return true;
				},
				edit: function (d) {
				},
				beforeClose: $.noop, // Return false to prevent cancel/save
				save: $.noop, // Save data.input.val() or return false to keep
				close: $.noop, // Editor was removed
			},

			dnd: {
				preventVoidMoves: true, // Prevent dropping nodes
				// 'before self', etc.
				preventRecursions: false, // Prevent dropping
				// nodes on own
				// descendants
				autoExpandMS: 2000,
				autoScroll: true,
				draggable: {
					// zIndex: 1000,
					// appendTo: "body",
					// helper: "clone",
					scroll: true,
					revert: "invalid"
				},
				dragStart: function (node, data, event) {
					return "move";
				},
				dragEnter: function (node, data) {
					return ["before", "after"];
				},
				dragDrop: function (node, data) {
					data.otherNode.moveTo(node, data.hitMode);
					var ids = node.parent.children.map(d => d.data);
					ids.forEach((i, ind) => i.seqnr = ind);
					post("adminService/" + localStorage.restaurantSelected + "/updatePaymentMethodOrder", ids);
				}
			},


		});
	else
		paymentMethods.reload(data);
}

var accessTokens = null;

var accessTokensData = null;

function processAccessTokens(data = accessTokensData) {
	if (data._embedded)
		data = data._embedded.accessTokens;
	accessTokensData = data;
	data = data.map(v => {
		return {
			title: v.name,
			token: v.token,
			folder: false,
			_links: v._links
		}
	});
	$('#deleteAccessToken').prop('disabled', 'true');
	if (accessTokens == null)
		accessTokens = createTree("table#accessTokens", {
			checkbox: false,
			source: data,
			zindex: 1000,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0,
				indentation: 16,
				nodeColumnIdx: 0
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				handleCursorKeys: true
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = false;
				if (node.data.token) {
					$tdList.eq(1).find('input').val(node.data.token);
					$tdList.eq(1).find('input').removeClass("hidden");
				}
			},

			activate: function (event, data) {
				$('#deleteAccessToken').removeAttr('disabled');
			},

		});
	else
		accessTokens.reload(data);
}

var currencies = null;

function processCurrencies(data) {
	data.forEach(function (v, i) {
		v.title = v.currency.name;
		v.folder = false;
	});
	$('#addCurrency').removeAttr('disabled');
	$('#deleteCurrency').prop('disabled', 'true');
	$('#editCurrency').prop('disabled', 'true');
	$('#activateCurrency').prop('disabled', 'true');
	$('#deactivateCurrency').prop('disabled', 'true');
	if (currencies == null)
		currencies = createTree("table#currencies", {
			checkbox: false,
			source: data,
			zindex: 1000,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0,
				indentation: 16,
				nodeColumnIdx: 2
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				handleCursorKeys: true
			},

			activate: function (event, data) {
				var node = data.node;
				$('#deleteCurrency').removeAttr('disabled');
				$('#editCurrency').removeAttr('disabled');
				if (node.data.isActive === true) {
					$('#activateCurrency').prop('disabled', 'true');
					$('#deactivateCurrency').removeAttr('disabled');
				} else {
					$('#activateCurrency').removeAttr('disabled');
					$('#deactivateCurrency').prop('disabled', 'true');
				}
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = false;
				$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");
				$tdList.eq(2).text(node.data.currency.name);
				$tdList.eq(3).text(node.data.exchangeRate);
				if (!node.data.isActive)
					$tdList.addClass('itemDeactivated');
			},

			edit: {
				adjustWidthOfs: 4, // null: don't adjust input size to content
				inputCss: {
					minWidth: "3em"
				},
				triggerStart: ["clickActive", "f2", "dblclick", "shift+click", "mac+enter"],
				beforeEdit: function (event, node) {
					return true;
				},
				edit: function (d) {
				},
				beforeClose: $.noop, // Return false to prevent cancel/save
				save: $.noop, // Save data.input.val() or return false to keep
				close: $.noop, // Editor was removed
			},

		});
	else
		currencies.reload(data);
}

var connectedRestaurants = null;

function processConnectedRestaurants(data) {
	if (data == null) data = [];
	data = data.map(r => properties.restaurants.find(rr => rr.globalId == r));
	data.forEach(function (v, i) {
		v.title = v.name;
		v.folder = false;
	});
	$('button#addConnectedRestaurant').removeAttr('disabled');
	$('button#deleteConnectedRestaurant').prop('disabled', 'true');
	if (connectedRestaurants == null)
		connectedRestaurants = createTree("table#connectedRestaurants", {
			checkbox: false,
			source: data,
			zindex: 1000,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0,
				indentation: 16,
				nodeColumnIdx: 2
			},
			icon: false,
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				handleCursorKeys: true
			},

			activate: function (event, data) {
				$('#deleteConnectedRestaurant').removeAttr('disabled');
			},

			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");
				node.expanded = false;
				//$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");
			},

			edit: {
				adjustWidthOfs: 4, // null: don't adjust input size to content
				inputCss: {
					minWidth: "3em"
				},
				triggerStart: ["clickActive", "f2", "dblclick", "shift+click", "mac+enter"],
				beforeEdit: function (event, node) {
					return true;
				},
				edit: function (d) {
				},
				beforeClose: $.noop, // Return false to prevent cancel/save
				save: $.noop, // Save data.input.val() or return false to keep
				close: $.noop, // Editor was removed
			},

		});
	else
		connectedRestaurants.reload(data);
}

export const activateRestaurantPaymentMethod = (active) => {
	var restaurant = r.getActiveNode().data;
	var data = paymentMethods.getActiveNode().data;
	admin.activateRestaurantPaymentMethod(restaurant.id, data.id, active, function () {
		admin.getPaymentMethodsByRestaurant(restaurant.id, processPaymentMethods);
	});
}

export const saveCurrency = () => {
	var restaurant = r.getActiveNode().data;
	var modal = $('div#editCurrency');
	var id = modal.find('#id').val();
	var active = modal.find('#active').prop('checked');
	var currency = modal.find('#currency').val();
	var exchange_rate = modal.find('#exchange_rate').val();
	if (id !== '') {
		admin.modifyRestaurantCurrency(restaurant.id, id, currency, exchange_rate, active, function () {
			admin.getCurrenciesByRestaurant(restaurant.id, processCurrencies);
		})
	} else {
		admin.addRestaurantCurrency(restaurant.id, currency, exchange_rate, active, function () {
			admin.getCurrenciesByRestaurant(restaurant.id, processCurrencies);
		})
	}
}

export const activateCurrency = (active) => {
	var restaurant = r.getActiveNode().data;
	var data = currencies.getActiveNode().data;
	admin.modifyRestaurantCurrency(restaurant.id, data.id, data.currency.id, data.exchangeRate, active, function () {
		admin.getCurrenciesByRestaurant(restaurant.id, processCurrencies);
	});
}

export const deleteCurrency = () => {
	var restaurant = r.getActiveNode().data;
	var data = currencies.getActiveNode().data;
	admin.deleteRestaurantCurrency(restaurant.id, data.id, function () {
		admin.getCurrenciesByRestaurant(restaurant.id, processCurrencies);
	});
}

export const saveConnectedRestaurant = () => {
	var restaurant = r.getActiveNode().data;
	var modal = $('div#editConnectedRestaurant');
	var restaurant2 = modal.find('#restaurant').val();
	admin.addConnectedRestaurant(restaurant.id, restaurant2, processConnectedRestaurants);
}

export const deleteConnectedRestaurant = () => {
	var restaurant = r.getActiveNode().data;
	var data = connectedRestaurants.getActiveNode().data;
	admin.deleteConnectedRestaurant(restaurant.id, data.id, processConnectedRestaurants);
}


function getRestaurant(id) {
	var restaurant;
	auth.myStatus.restaurants.forEach(function (r) {
		if (Number(r.id) === Number(id))
			restaurant = r;
	})
	return restaurant;
}


export const deleteAccessToken = () => {
	var restaurant = r.getActiveNode().data;
	var data = accessTokens.getActiveNode().data;
	del(data._links.accessToken.href).done(() => {
		admin.getAccessTokensByRestaurant(restaurant.id, processAccessTokens);
	});
}

export const addAccessToken = () => {
	if ($("span#accessTokenName").hasClass("hidden")) {
		$("span#accessTokenName").removeClass("hidden");
		return;
	}
	if ($("input#accessTokenName").val()) {
		var restaurant = r.getActiveNode().data;
		get("adminService/" + localStorage.restaurantSelected + "/generateAccessToken?name=" + $("input#accessTokenName").val() + "&role=generic").done(data => {
			$("span#accessTokenName").addClass("hidden");
			$("input#accessTokenName").val("")
			//$('#accessToken').val(data.token);
			admin.getAccessTokensByRestaurant(restaurant.id, tokens => {
				tokens._embedded.accessTokens.forEach(t => {
					if (t._links.self.href.split("/").slice(-1).pop() == data.id) {
						t.token = data.token;
					}
				})
				processAccessTokens(tokens)
			});
		});
	}
}

export const uploadCER = () => {
	var oldfileInput = document.getElementById('cer_file');
	if (oldfileInput)
		document.body.removeChild(oldfileInput);
	var fileInput = document.createElement('input');

	fileInput.setAttribute('type', "file");
	fileInput.setAttribute('id', "cer_file");
	fileInput.style.display = 'none';
	document.body.appendChild(fileInput);
	fileInput.addEventListener('change', (event) => {
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.readAsText(file, 'UTF-8');

		reader.onload = (event) => {
			var text = btoa(event.target.result);
			$("#cert").val(text);
			document.body.removeChild(fileInput);
			admin.modifyRestaurant({ ...activeRestaurant, ntakcer: text }, (data) => {
				activeRestaurant = data;
			});
		}
	});
	fileInput.click();
}

export const downloadCSR = () => {
	if (activeRestaurant.ntakid) {
		var element = document.createElement('a');
		element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent($("#cert_request").val()));
		element.setAttribute('download', "tanusitvany_kerelem_" + activeRestaurant.ntakid + ".csr");
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}
}

export const saveNTAKID = () => {
	var ntakId = $("#ntak_id").val();
	var ntaktax = $("#ntak_tax_id").val();
	if (activeRestaurant && !activeRestaurant.ntakId && ntakId && ntaktax && ntakId.length === 10 && ntaktax.length === 11)
		admin.modifyRestaurant({ ...activeRestaurant, ntakid: ntakId, ntaktax: ntaktax }, (data) => {
			activeRestaurant = data;
			$("#cert_request").val(data.ntakcsr);
			$("#key").val(data.ntakkey);

			if (activeRestaurant.ntakid && activeRestaurant.ntaktax) {
				$('#downloadCSR').removeAttr('disabled');
				$('#uploadCER').removeAttr('disabled');
				$('#test').attr('disabled');
			} else {
				$('#downloadCSR').attr('disabled', true);
				$('#uploadCER').attr('disabled', true);
				$('#test').attr('disabled', true);
			}

		});
}

export const testNtak = () => {
	get("ntak/ellenorzes/" + localStorage.restaurantSelected).done(result => {
		$("#testresult").removeClass("icon-help");
		$("#testresult").removeClass("icon-ok");
		$("#testresult").removeClass("icon-cancel");
		if (result.success) {
			$("#testresult").addClass("icon-ok");
			$("#testresult").css("color", "green");
		} else {
			$("#testresult").addClass("icon-cancel");
			$("#testresult").css("color", "red");
		}
	}).fail(() => {
		$("#testresult").removeClass("icon-help");
		$("#testresult").removeClass("icon-ok");
		$("#testresult").addClass("icon-cancel");
		$("#testresult").css("color", "red");
	});

}

export const deleteTraffic = () => {
	const until = getInputDialog(I18n.t("local.question"), I18n.t("admin_local.until_when_delete_the_traffic"), "", "\d\d\d\d-\d\d-\d\d").done((i, until) => {
		if (until.match(/\d\d\d\d-\d\d-\d\d/)) {
			confirmDialog(I18n.t("local.confirmation"), String.format(I18n.t("admin_local.are_you_sure_you_want_to_delete_traffic_until"), until)).done(() => {
				get("adminService/" + localStorage.restaurantSelected + "/deleteAllTableOccupations/" + until, undefined, undefined, 300000);
			})
		}
	})
}