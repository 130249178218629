import { unflattenObject } from "../components/utils";
import { updateRow, addNewRow, deleteRow } from "./rootReducer";
import { modifyRestaurantPaymentMethod, addRestaurantPaymentMethod, deleteRestaurantPaymentMethod } from "../js/superadmin/superadmin";

const paymentMethodReducer = (state, action) => {
  switch (action.type) {
    case "updateRow":
      {
        const data = unflattenObject(action.row);
        if (data.id)
          modifyRestaurantPaymentMethod(state.myStatus.restaurant_id, data.id, data.method.id, data.isActive, data => {
            updateRow(action);
          });
        else
          addRestaurantPaymentMethod(state.myStatus.restaurant_id, data.method.id, data.isActive, data => {
            action.row.id = data.id;
            addNewRow({ stateKey: action.stateKey, row: {} });
            updateRow(action);
          });
      }
      break;
    case "deleteRow":
      {
        const data = unflattenObject(action.row);
        if (data.id) {
          deleteRestaurantPaymentMethod(state.myStatus.restaurant_id, data.id, data => {
            deleteRow(action);
          });
        }
      }
      break;
    default:
  }
  return state;
};
export default paymentMethodReducer;
