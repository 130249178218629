import $ from "jquery";
import { tmplparams } from "../../js/order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import superadmin from "../../js/superadmin/superadmin";
import { capturePhotoWithData, onPhotoDataSuccess } from "../../js/camera-util";

export const paymentTypes = ['cash', 'card', 'online', 'transfer', 'thirdparty', 'hellopay', 'teya', 'bizpay', 'paynance'];

class PaymentMethodsCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['savePaymentMethod', '_deletePaymentMethod', 'activatePaymentMethod', 'getPhoto', 'capturePhotoWithData']);
	}

	clearData = () => {

	}

	getPhoto = () => {
		capturePhotoWithData();
	}

	ready = () => {

		$('#main').replaceWith($('#editPaymentMethodTemplate').tmpl({
			...tmplparams(), paymentTypes: ['cash', 'card', 'online', 'transfer', 'thirdparty', 'hellopay', "teya", "bizpay", "paynance"]
		}));

		this.paymentMethodUpdateHooks();
		superadmin.getAllPaymentMethods("", this.processPaymentMethods);

		//startpoll(serverSideMessageHandler);
		// updateHooks();

		$("body").on("paste", function (event) {

			var items = (event.clipboardData || event.originalEvent.clipboardData).items;
			for (let index in items) {
				var item = items[index];
				if (item.kind === 'file') {
					var blob = item.getAsFile();
					var reader = new FileReader();
					reader.onload = function (event) {
						onPhotoDataSuccess(event.target.result);
					}; // data url!
					reader.readAsDataURL(blob);
				}
			}

		})

	}

	reload = () => {
		superadmin.getAllPaymentMethods("", this.processPaymentMethods);
	}

	r = null;
	processPaymentMethods = (paymentMethods) => {
		paymentMethods.forEach(function (v, i) {
			v.title = v.name;
			v.folder = false;
			v.type2 = v.type
		});
		if (this.r == null) {
			this.r = createTree("table#paymentMethods", {
				checkbox: false,
				source: paymentMethods,
				zindex: 1000,
				extensions: ["persist", "table", "gridnav"],
				icon: false,
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets
					// activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},
				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#editPaymentMethod').removeAttr('disabled');
					$('#deletePaymentMethod').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivatePaymentMethod').removeAttr('disabled');
						$('#activatePaymentMethod').prop('disabled', 'true');
					} else {
						$('#deactivatePaymentMethod').prop('disabled', 'true');
						$('#activatePaymentMethod').removeAttr('disabled');
					}
				},

				// renderStatusColumns: false, // false: default renderer
				// true: generate renderColumns events, even for status nodes
				// function: specific callback for status nodes

				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					// (Column #0 is rendered by fancytree by adding the checkbox)

					// Column #1 should contain the index as plain text, e.g.
					// '2.7.1'
					$tdList.eq(1).text(node.getIndexHier()).addClass("alignRight");

					// (Column #2 is rendered by fancytree)

					// ...otherwise render remaining columns
					$tdList.eq(2).text(node.data.name)
					$tdList.eq(3).text(node.data.type2)
					if (!node.data.isActive)
						$tdList.addClass('itemDeactivated');

					// $tdList.eq(1)
				}
			});
		} else {
			this.r.reload(paymentMethods);
		}
	}

	paymentMethodUpdateHooks = () => {
		const instance = this;
		$('div#editPaymentMethod').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			modal.find('.type_option').prop("checked", null);
			modal.find(".type_option").prop("checked", false);
			modal.find('label.type_option').removeClass("active");
			if (recipient === 'edit') {
				var data = instance.r.getActiveNode().data;
				modal.find('#id').val(data.id);
				if (data.image && data.image !== "") {
					modal.find('#smallImage').attr('src', data.image);
					$(modal.find('#smallImageDiv')).removeClass('hidden');
				} else {
					modal.find('#smallImage').attr('src', "");
					$(modal.find('#smallImageDiv')).addClass('hidden');
				}
				modal.find('#name').val(data.name);
				modal.find('#settings').val(JSON.stringify(data.settings));
				modal.find('#active').prop("checked", data.isActive === true);
				modal.find('#type_' + data.type2 + ".type_option").prop("checked", 'true');
				modal.find('label#type_' + data.type2).addClass("active");
			} else {
				modal.find('#id').val("");
				modal.find('#smallImage').attr('src', "");
				$(modal.find('#smallImageDiv')).addClass('hidden');
				modal.find('#name').val("");
				modal.find('#settings').val("{}");
				modal.find('#active').prop("checked", true);
			}
			// If necessary, you could initiate an AJAX request here (and then do
			// the
			// updating in a callback).
			// Update the modal's content. We'll use jQuery here, but you could use
			// a
			// data binding library or other methods instead.
		});
	}

	savePaymentMethod = () => {
		const instance = this;
		var modal = $('div#editPaymentMethod');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var settings = JSON.parse(modal.find('#settings').val());
		var image = $(modal.find('#smallImage')).attr('src');

		var active = modal.find('#active').prop('checked');
		var type = modal.find('.type_option.active input').attr('id');
		if (id !== '') {
			superadmin.modifyPaymentMethod(id, name, type, active, image, settings, function () {
				superadmin.getAllPaymentMethods("", instance.processPaymentMethods);
			});
		} else {
			superadmin.addPaymentMethod(name, type, active, image, settings, function () {
				superadmin.getAllPaymentMethods("", instance.processPaymentMethods);
			});
		}
	}

	_deletePaymentMethod = () => {
		const instance = this;

		var data = this.r.getActiveNode().data;
		superadmin.deletePaymentMethod(data.id, function () {
			superadmin.getAllPaymentMethods("", instance.processPaymentMethods);
		});
	}

	activatePaymentMethod = (active) => {
		const instance = this;

		var data = this.r.getActiveNode().data;
		superadmin.modifyPaymentMethod(data.id, data.name, data.type, active, data.image, data.settings, function () {
			superadmin.getAllPaymentMethods("", instance.processPaymentMethods);
		});
	}

}

export default PaymentMethodsCode;