import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { Route } from "react-router-dom";
import SuperRestaurantAdmin from "../../superadmin/superRestaurantAdmin/SuperRestaurantAdmin";
import { connect } from "react-redux";
import SuperUserAdmin from "../../superadmin/superUserAdmin/SuperUserAdmin";
import auth, { get, getGlobal } from "../../js/auth";
import PaymentMethods from "../../superadmin/paymentMethods/PaymentMethods";
import admin from "../../js/admin";

class SuperAdminMenus extends AbstractMenu {

  componentWillUnmount = () => {
    admin.getMyStatus(() => { });
  }

  render() {
    const { admin_local } = this.props;
    const superAdminBackground = "red";
    if (admin_local)
      return (
        <React.Fragment>
          <Route
            exact
            path={"/superAdminMenus"}
            render={props => (
              <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
                <SubmenuHeader {...this.props} header={admin_local.menus.superAdminMenu} />
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                  <MenuButton href={"superAdminMenus/superRestaurantAdmin"} id="restaurants" background={superAdminBackground} name={admin_local.menus.restaurants} myStatus={this.props.myStatus} />
                  <MenuButton href={"superAdminMenus/superUserAdmin"} id="users" background={superAdminBackground} name={admin_local.menus.users} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => this.navigate("superadmin_currencies.html")} id="currencies" background={superAdminBackground} name={admin_local.menus.currencies} myStatus={this.props.myStatus} />
                  <MenuButton href={"superAdminMenus/paymentMethods"} id="paymentMethods" background={superAdminBackground} name={admin_local.menus.paymentMethods} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => this.navigate("superadmin_reports.html")} id="reports" background={superAdminBackground} name={admin_local.menus.manage_reports} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => this.navigate(auth.server + "/eatwithme.server/monitoring")} id="monitoring" background={superAdminBackground} name={admin_local.menus.monitoring} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => get("superadminService/sendResellerFees")} id="sendResellerFees" background={superAdminBackground} name={admin_local.menus.send_reseller_fees} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => get("superadminService/sendResellerFeesToMe")} id="sendResellerFees" background={superAdminBackground} name={admin_local.menus.send_reseller_fees_to_me} myStatus={this.props.myStatus} />
                  <MenuButton onClick={() => get("superadminService/checkSubscriptions")} id="sendResellerFees" background={superAdminBackground} name={admin_local.menus.check_subscriptions} myStatus={this.props.myStatus} />
                </div>
              </div>
            )}
          />
          <Route path={"/superAdminMenus/superRestaurantAdmin"} render={props => <SuperRestaurantAdmin {...props} />} />
          <Route path={"/superAdminMenus/superUserAdmin"} render={props => <SuperUserAdmin {...props} />} />
          <Route path={"/superAdminMenus/paymentMethods"} render={props => <PaymentMethods {...props} />} />
        </React.Fragment>
      );
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local
  };
};

export default connect(mapStateToProps)(SuperAdminMenus);
