import React, { Component } from "react";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import admin from "../../js/admin";
import { I18n } from "react-redux-i18n";

class UpdateSettingButton extends Component {

  state = {
    update_versions: typeof localStorage.update_versions !== "undefined" ? localStorage.update_versions : "stable"
  };
  render() {
    const { myStatus } = this.props;
    if (this.props.roles || localStorage.restaurantSelected === "0") {
      if (!admin.hasRole(myStatus.roles, this.props.roles)) return null;
    }

    const style = {
      ...this.props.style,
      backgroundImage: "url('" + this.props.backgroundImage + "')"
    };
    const style2 = {
      background: this.props.background
    };
    const iClass = "menuicon " + (this.props.i ? this.props.i : "hidden");
    const diva = (
      <div className="menuButtonImage" style={style} >
        <div className="menuButtonBackground" style={style2}>
          <Button variant="none" id={this.props.id} className="menuButton" data-step={this.props["data-step"]} data-intro={this.props["data-intro"]}>
            <i className={iClass}></i>
            <span>{this.props.name}</span>
            <ButtonGroup style={{ display: "flex", flexFlow: "column" }} toggle>
              <ToggleButton variant="secondary" key="stable" type="radio" name="update" value="stable" checked={"stable" === this.state.update_versions} onChange={(e) => this.setRadioValue("stable")}>{I18n.t("admin_local.stable_versions")}</ToggleButton>
              <ToggleButton variant="secondary" key="test" type="radio" name="update" value="test" checked={"test" === this.state.update_versions} onChange={(e) => this.setRadioValue("test")}>{I18n.t("admin_local.test_versions")}</ToggleButton>
            </ButtonGroup>
          </Button>
        </div>
      </div>
    );
    return diva;
  }

  setRadioValue(value) {
    localStorage.update_versions = value;
    this.setState({ update_versions: value })
  }
}

export default UpdateSettingButton;
