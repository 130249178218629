import React from 'react';

const fontSize = 8;
const radius = 5;

const Node = ({ node }) => {
    // sizes
    const sizes = {
        radius: radius,
        textSize: fontSize,
        textX: radius * 1.5,
        textY: radius / 2,
    };
    const sizesImg = {
        radius: 10,
        textSize: fontSize,
        textX: 30 * 1.5,
        textY: 30 / 2,
    };

    return (
        <>
            {
                node.img
                    ? (
                        <image
                            href={node.img}
                            x="0"
                            y="0"
                            height={sizesImg.radius * 2}
                            width={sizesImg.radius * 2}
                            style={{
                                transform: `translate(-${sizesImg.radius}px, -${sizesImg.radius}px)`,
                            }}
                        />
                    )
                    : (
                        <circle
                            fill={node.color}
                            stroke={node.color}
                            r={node.size}
                        />
                    )
            }
            <g style={{ fontSize: sizes.textSize + 'px', transform: 'translate(' + (node.img ? node.size / 2 + 7 : node.size / 2 + 7) + 'px ,' + (node.img ? (node.size / 2) - sizesImg.textSize : node.size / 2) + 'px)' }} >
                <text

                >
                    {node.label.split("/").map((l, ind) => <tspan x="0" dy="1em" font-size={(ind === node.label.split("/").length - 1 ? sizes.textSize : sizes.textSize / 3 * 2) + 'px'} >{l}</tspan>)}
                </text>
            </g>
        </>
    );
};

export default Node;