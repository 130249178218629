import React from "react";
import EatWithMeText from "../../components/EatWIthMeText";
import MenuItemHeader from "../../components/MenuItemHeader";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import { Translate } from "react-redux-i18n";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import AbstractMenuItem from "../../components/AbstractMenuItem";
import { connect } from "react-redux";
import $ from "jquery";
import "../../js/superadmin/users";
import { ready, saveUser, saveRole, activateUser, deleteItem } from "../../js/superadmin/users";
import orderListUtil, { tmplparams } from "../../js/order-list-util";
import { get } from "../../js/auth";

class SuperUserAdmin extends AbstractMenuItem {
  state = {};

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;
    if (myStatus && myStatus.restaurant_id) {
      const body = $("#superAdminUserTemplateBody").tmpl(tmplparams());
      body.appendTo("#main");
      ready();
    }
    window.saveUser = saveUser;
    window.deleteItem = deleteItem;
    window.saveRole = saveRole;
    window.activateUser = activateUser;
    window.get = get;
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      const bodyTmpl = $("#superAdminUserTemplateBody");
      const body = bodyTmpl.tmpl(tmplparams());
      body.appendTo("#main");
      ready();
    }
  }

  componentWillUnmount() {
    delete window.saveUser;
    delete window.deleteItem;
    delete window.saveRole;
    delete window.activateUser;
  }

  render() {
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      return (
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1 }}>
          <div
            className="menuItemHeader"
            style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
          >
            <EatWithMeText />
            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.users" />} />
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <ChangeLanguage />
              <UserImageButton />
            </div>
          </div>
          <div id="main" style={{ flexGrow: 1 }}></div>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      local: props.rootReducer.local,
      admin_local: props.rootReducer.admin_local
    };
  }
  return {};
};

export default connect(mapStateToProps)(SuperUserAdmin);
