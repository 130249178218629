import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import AI from "../../admin/AI/AI";
import { Route, Switch } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import Meals from "../../admin/meals/Meals";
import Drinks from "../../admin/drinks/Drinks";
import Restaurants from "../../admin/Restaurants";
import Printers from "../../admin/Printers";
import ProductionLines from "../../admin/ProductionLines";
import Tables from "../../admin/Tables";
import VatCategories from "../../admin/VatCategories";
import QuantityTypes from "../../admin/QuantityTypes";
import DiscountTypes from "../../admin/DiscountTypes";
import Labels from "../../admin/Labels";
import CashRegisters from "../../admin/CashRegisters";
import Scales from "../../admin/Scales";
import TableLayouts from "../../admin/TableLayouts";
import Wolt from "../../admin/Wolt";
import auth, { featureEnabled, messageDialog } from "../../js/auth";
import UpdateSettingButton from "../../components/menus/UpdateSettingButton";
import Advertisements from "../../admin/Advertisements";
import FoodPanda from "../../admin/FoodPanda";
import admin from "../../js/admin";
import PlansMenu from "./PlansMenu";
import BankTerminals from "../../admin/BankTerminals";
import Falatozz from "../../admin/Falatozz";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class AdminMenu extends AbstractMenu {
  state = { warningShown: false };

  componentWillUnmount = () => {
    admin.getMyStatus(() => { });
  }

  ntakCheck = () => {
    if (!featureEnabled("ntak"))
      return;
    if (this.props.myStatus.roles && !this.state.warningShown) {
      const ntakConnectionOK = this.props.myStatus?.ntakstatus?.status?.connectionOK;
      this.setState({ warningShown: true });
      admin.getActiveMenuItems(data => {
        function checkNtak(category) {
          if (category.activeMenuItems.find(m => m.isActive && m.isTop && (!m.defaultMainCategoryId || !m.defaultSubCategoryId))) {
            return true;
          }
          if (category.activeMenuCategories.filter(m => m.isActive && m.isTop).find(checkNtak)) return true;
        }
        const drinkproblem = data.activeMenuCategories.filter(m => m.isActive && m.isTop && m.entityType === "DrinkCategory").find(checkNtak);
        const mealproblem = data.activeMenuCategories.filter(m => m.isActive && m.isTop && m.entityType === "MealCategory").find(checkNtak);
        this.setState({ ntakProblem: !ntakConnectionOK, mealNtakProblem: mealproblem, drinkNtakProblem: drinkproblem });
      })
    }
  }

  componentDidMount = () => {
    this.ntakCheck();
  }

  componentDidUpdate = () => {
    this.ntakCheck();

  }


  render() {
    const background = "#847556";
    if (this.props.myStatus.restaurant_id) {
      return (
        <Switch>
          <Route
            path={"/adminMenu"}
            render={props => (
              <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
                <SubmenuHeader {...this.props} header={I18n.t("admin_local.menus.administration")} />
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                  <MenuButton error={this.state.ntakProblem} href="adminMenu/restaurants" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_system_parameter" background={background} name={I18n.t("admin_local.menus.restaurants")} >
                    {!this.state.ntakProblem ? null : <i className="icon-attention" style={{ color: "yellow", animation: "opacity 3s infinite steps(10)" }} />}
                  </MenuButton>
                  <MenuButton error={this.state.drinkNtakProblem} href="adminMenu/drinks" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_wine" background={background} name={I18n.t("admin_local.menus.drinks")} >
                    {!this.state.drinkNtakProblem ? null : <i className="icon-attention" style={{ color: "yellow", animation: "opacity 3s infinite steps(10)" }} >ntak</i>}
                  </MenuButton>
                  <MenuButton error={this.state.mealNtakProblem} href="adminMenu/meals" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_food" background={background} name={I18n.t("admin_local.menus.meals")}>
                    {!this.state.mealNtakProblem ? null : <i className="icon-attention" style={{ color: "yellow", animation: "opacity 3s infinite steps(10)" }} >ntak</i>}
                  </MenuButton>
                  <MenuButton href="adminMenu/tables" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_table" background={background} name={I18n.t("admin_local.menus.tables")} />
                  <MenuButton href="adminMenu/production-lines" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_production" background={background} name={I18n.t("admin_local.menus.productionLines")} />
                  <MenuButton href="adminMenu/vatCategories" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_system_parameter" background={background} name={I18n.t("admin_local.menus.VATCategories")} />
                  <MenuButton href="adminMenu/printers" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-printer" background={background} name={I18n.t("admin_local.menus.printers")} />
                  <MenuButton href="adminMenu/cashRegisters" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_cash_register" background={background} name={I18n.t("admin_local.menus.cashRegisters")} />
                  <MenuButton href="adminMenu/bankTerminals" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_cash_register" background={background} name={I18n.t("admin_local.menus.bankTerminals")} />
                  <MenuButton href="adminMenu/scales" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_scale" background={background} name={I18n.t("admin_local.menus.scales")} />
                  <MenuButton href="adminMenu/servings" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_system_parameter" background={background} name={I18n.t("admin_local.menus.servings")} />
                  <MenuButton href="adminMenu/discounts" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-percent" background={background} name={I18n.t("admin_local.menus.discounts")} />
                  <MenuButton href="adminMenu/advertisements" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-picture" background={background} name={I18n.t("admin_local.menus.advertisements")} />
                  <MenuButton href="adminMenu/labels" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="i_label" background={background} name={I18n.t("admin_local.menus.labels")} />
                  <MenuButton features={["wolt"]} href="adminMenu/wolt" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-wolt" background={background} name={I18n.t("admin_local.menus.wolt")} />
                  <MenuButton features={["foodpanda"]} href="adminMenu/foodpanda" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-foodpanda" background={background} name={I18n.t("admin_local.menus.foodpanda")} />
                  <MenuButton features={["falatozz"]} href="adminMenu/falatozz" roles={["superadmin", "admin"]} myStatus={this.props.myStatus} i="icon-falatozz" background={background} name={I18n.t("admin_local.menus.falatozz")} />
                  {auth.device.platform !== "browser" ?
                    <UpdateSettingButton href="/adminMenu/AI" roles={["superadmin", "admin", "waiter", "cook", "store", "finance"]} myStatus={this.props.myStatus} background={background} name={I18n.t("admin_local.menus.updateSettings")} />
                    : null}

                </div>
              </div>
            )}
          />
        </Switch>
      );
    }
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local,
    myStatus: state.rootReducer.myStatus,
  };
};

export default connect(mapStateToProps)(withRouter(AdminMenu));
