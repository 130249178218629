
import $ from "jquery";
import admin from "../admin";
import { confirmDialog } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import { getTable } from "../../reducers/rootReducer";

class TableLayoutsCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveTable', 'deleteTable', 'activateTable', 'saveRoom', 'deleteRoom', 'activateRoom', 'saveRestaurantFloorplan', 'deleteRestaurantFloorplan', 'activateRestaurantFloorplan', 'updateRestaurantFloorplan']);
	}

	canvas = null;
	activeObject = null;
	editPoints = null;
	editMode = false;

	ready = (props) => {

		const instance = this;

		$('script#tableLayoutsTemplate').tmpl({
			...tmplparams()
		}).appendTo('div#main');

		$('#download').click(function (e) {
			admin.downloadFloorPlan(function (layout) {
				var blob = new Blob([layout]);
				window.saveAs(blob, props.myStatus.restaurant_name + '_layout.svg');
			});
		});

		$('#download-only').click(function (e) {
			admin.downloadFloorPlan(function (layout) {
				var svg = $(layout);
				var e = svg.find('g.eatwithme-room').parent();
				e.remove();
				e = svg.find('g.eatwithme-table').parent().parent();
				e.remove();
				var blob = new Blob([svg.prop('outerHTML')]);
				window.saveAs(blob, props.myStatus.restaurant_name + '_layout.svg');
			});
		});

		admin.getMyStatus(function (data) {
		});

		admin.getRestaurantLayout(function (data) {
			instance.processRestaurant(data);
			admin.getRestaurantFloorplans(instance.processRestaurantFloorPlans);
		});

		// canvas = new fabric.Canvas('c');
		// canvas.selection = true; // disable group selection

		$('#upload').click(function () {
			$("#file").replaceWith($("#file").val('').clone(true));
			$('#file').attr('files', '');
			$('#file').click();
		});

		$('#upload-only').click(function () {
			$("#file2").replaceWith($("#file2").val('').clone(true));
			$('#file2').attr('files', '');
			$('#file2').click();
		});

		$('#update').click(function () {
			instance.updateTablesFromFloorplan();
		});

		$('#file').change(function () {
			if (document.getElementById('file').files[0] === "")
				return;
			var reader = new FileReader();

			reader.addEventListener("load", function () {
				try {
					var tables = [];
					$('#c svg > g > g').each((ind, t) => tables.push(t));
					$('#c').empty();
					$('#c').html(reader.result);
					var g = $($('#c').find('svg > g'));
					if (g.find('.eatwithme-table').length > 0)
						tables.reverse().forEach(t => {
							if ($(t).find('.eatwithme-table').length === 0) {
								$(t).remove();
								$(t).prependTo(g);
							}
						})
					$('#c > svg').css('width', '100%');
					$('#c > svg').css('height', '100%');

					var position = $('#c svg').get(0).getBoundingClientRect();

					$('#c svg').attr('viewBox', position.left + " " + position.top + " " + position.width + " " + position.height);
					var r = $('#c > svg').prop('outerHTML');
					instance.preprocessLayout(r, function (floorplan) {
						instance.updateRestaurantFloorplan(floorplan);
					});

				} catch (ex) {
					console.log(ex);
				}
			}, false);

			//if (instance.file) {
			reader.readAsText(document.getElementById('file').files[0]);
			//}
		});

		$('#file2').change(function () {
			if (document.getElementById('file2').files[0] === "")
				return;
			var reader = new FileReader();
			console.log("Uploaded")

			reader.addEventListener("load", function () {
				console.log("Uploaded")

				try {
					$('#c').empty();
					$('#c').html(reader.result);
					$('#c svg svg').each((ind, t) => {
						$(t).replaceWith('<g>' + $(t).html() + '</g>')
					})
					var g = $($('#c').find('svg > g'));
					var rootg = $("<g/>");
					rootg.appendTo($("#c svg"));
					g.appendTo(rootg);
					/*
					var xmin = 10000000;
					var ymin = 10000000;
					$('#c svg > g > g').each((ind, g) => {
						const t = $(g).attr('transform');
						const re = /translate\(([\d.]+),([\d.]+)\)/
						const found = t.match(re);
						if (found) {
							xmin = Math.min(xmin, Number(found[1]));
							ymin = Math.min(ymin, Number(found[2]))
						}
					})
					$('#c svg > g > g').each((ind, g) => {
						const t = $(g).attr('transform');
						const re = /(.*translate\()([\d.]+),([\d.]+)(\).*)/
						const found = t.match(re);
						if (found) {
							$(g).attr('transform', found[1] + (Number(found[2]) - xmin) + "," + (Number(found[3]) - ymin) + found[4]);
						}
					})
					const box = $('#c svg').attr('viewBox').split(" ");
					$('#c svg').attr('viewBox', (Number(box[0]) - xmin) + " " + (Number(box[1]) - ymin) + " " + (Number(box[2]) - xmin) + " " + (Number(box[3]) - ymin));
					/*
					$('#c svg').attr('viewBox', '')
					$('#c svg').attr('style', '');
					$('#c svg').attr('width', '');
					$('#c svg').attr('height', '');
					*/
					/*
					var g = $($('#c').find('svg > g'));
					var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
					var layout = data.floorplan;
					//admin.downloadFloorPlan(function(layout) {
					var svg = $(layout);
					var e = svg.find('g.eatwithme-room').parent();
					g.append(e);
					e = svg.find('g.eatwithme-table').parent().parent();
					g.append(e);

					if (g.find('.eatwithme-table').length === 0)
						tables.each(function (ind, t) {
							if ($(t).find('.eatwithme-table').length > 0) {
								$(t).remove();
								$(t).appendTo(g);
							}
						})

						*/

					$('#c > svg').css('width', '100%');
					$('#c > svg').css('height', '100%');
					var r = $('#c > svg').prop('outerHTML');
					instance.preprocessLayout(r, function (floorplan) {
						instance.updateRestaurantFloorplan(floorplan, instance.reload);
					});
					//});

				} catch (ex) {
					console.log(ex);
				}
			}, false);

			//if (instance.file) {
			reader.readAsText(document.getElementById('file2').files[0]);
			//}
		});


		$.contextMenu({
			selector: ".eatwithme-table,.eatwithme-tableNumber",
			build: function ($trigger, e) {
				if ($trigger.hasClass('eatwithme-tableNumber')) {
					$trigger = $($('#' + $trigger.attr('id').split('-')[0] + ".eatwithme-table").get(0));
				}
				var table = instance.tableMap.get(Number($trigger.attr('id')));
				if (table === null) {
					return;
				}
				var items = {};
				if (table.isActive === true)
					items["deactivate"] = { name: I18n.t('admin_local.deactivate'), icon: "edit" };
				else
					items["activate"] = { name: I18n.t('admin_local.activate'), icon: "edit" };
				if (table.shareable === true)
					items["makeNonShareable"] = { name: I18n.t('admin_local.make_unshareable'), icon: "edit" };
				else
					items["makeShareable"] = { name: I18n.t('admin_local.make_shareable'), icon: "edit" };
				items["delete"] = { name: I18n.t('admin_local.delete'), icon: "delete" };
				return {
					callback: function (key, options) {
						switch (key) {
							case 'deactivate': admin.modifyTable(table.id, table.number, table.seats, table.shareable, false, table.freeSeats, instance.reload); break;
							case 'activate': admin.modifyTable(table.id, table.number, table.seats, table.shareable, true, table.freeSeats, instance.reload); break;
							case 'makeShareable': admin.modifyTable(table.id, table.number, table.seats, true, table.isActive, table.freeSeats, instance.reload); break;
							case 'makeNonShareable': admin.modifyTable(table.id, table.number, table.seats, false, table.isActive, table.freeSeats, instance.reload); break;
							case 'delete': admin.deleteTable(table.id, instance.reload); break;
							default:
						}
					},
					items: items
				}
			}
		});

		$('#c').on('contextmenu', function (e) { e.preventDefault(); });

	}

	reload = () => {
		admin.getRestaurantLayout(this.processRestaurant);
		admin.getRestaurantFloorplans(this.processRestaurantFloorPlans);
	}

	floorPlans = null;
	processRestaurantFloorPlans = (data) => {
		const instance = this;
		data.forEach((d) => {
			if (instance.activeRestaurantLayout !== null && Number(d.id) === Number(instance.activeRestaurantLayout.id))
				d.selected = true;
		});
		if (instance.floorPlans === null)
			instance.floorPlans = createTree("table#floorplans",
				{
					checkbox: "radio",
					titlesTabbable: false, // Add all node titles to
					// TAB chain
					source: data,
					selectMode: 1,
					zindex: 1000,
					keyboard: false,
					extensions: ["table", "gridnav", "dnd"],
					dnd: {
						preventVoidMoves: true, // Prevent dropping
						// nodes 'before
						// self', etc.
						preventRecursiveMoves: true, // Prevent
						// dropping
						// nodes on own
						// descendants
						autoExpandMS: 400,
						draggable: {
							// zIndex: 1000,
							// appendTo: "body",
							// helper: "clone",
							scroll: false,
							revert: "invalid"
						},
						dragStart: function (node, data) {
							return true;
						},
						dragEnter: function (node, data) {
							// Prevent dropping a parent below another
							// parent (only sort
							// nodes under the same parent)
							return ["before", "after"];
						},
						dragDrop: function (node, data) {
							data.otherNode.moveTo(node, data.hitMode);
						}
					},
					icon: false,
					table: {
						checkboxColumnIdx: 0, // render the checkboxes
						// into the this
						// column index (default: nodeColumnIdx)
						indentation: 16, // indent every node level
						// by 16px
						nodeColumnIdx: 0,
						// render node expander, icon, and title to this
						// column (default:
						// #0)
					},
					gridnav: {
						autofocusInput: true, // Focus first embedded
						// input if node
						// gets activated
						handleCursorKeys: true
						// Allow UP/DOWN in inputs to move to prev/next node
					},

					activate: function (event, data) {
						// A node was activated: display its title:
						var node = data.node;
						$('#delete').removeAttr('disabled');
						if (node.data.isActive) {
							$('#deactivate').removeAttr('disabled');
							$('#activate').prop('disabled', 'true');
						} else {
							$('#deactivate').prop('disabled', 'true');
							$('#activate').removeAttr('disabled');
						}

						instance.processRestaurant(node.data);

					},
					previousNode: null,
					click: function (event, data) {
						if (event.ctrlKey !== true)
							return;
						if (instance.floorPlans === null)
							return;
						if (data.node === instance.menuItems.previousNode) {
							instance.floorPlans.activateKey("");
							event.preventDefault();
							instance.menuItems.previousNode = null;
						} else {
							instance.menuItems.previousNode = data.node;
						}
					},
					renderColumns: function (event, data) {
						var node = data.node, $tdList = $(node.tr).find(">td");

						var c = 0;
						$tdList.eq(++c).text(node.data.name);
					}
				});
		else {
			instance.floorPlans.reload(data);
		}

	}


	tableMap = null;
	processRestaurant = (data) => {
		const instance = this;
		this.activeRestaurantLayout = data;
		if (data === null)
			return;
		var $c = $('#c');
		$c.css('zoom', '1');
		$c.empty();
		var x = $c.innerWidth();
		var y = $c.innerHeight();
		var floorplan = $(data.floorplan);
		$c.append(floorplan);
		var width = $('svg').attr('width');
		var height = $('svg').attr('height');
		var zoom = Math.min(x / width, y / height);
		// $('#c').css('zoom',zoom);
		$('#c > svg').css('width', width * zoom);
		$('#c > svg').css('height', height * zoom);
		// $c.css('zoom',zoom);
		if (instance.tableMap === null) {
			instance.tableMap = new Map();
			admin.getTables(function (data2) {
				data2.restaurantTables.forEach(function (table) {
					instance.tableMap.set(Number(table.number), table);
				});
				floorplan.find('.eatwithme-tableNumber').each(function (ind, t) {
					instance.tableNumber = $(t).attr('id').split('-')[0];
					instance.seatNumber = $(t).attr('seatNumber');
					var table = $('#' + instance.tableNumber + ".eatwithme-table");
					if (table != null) {
						var table2 = instance.tableMap.get(Number(instance.tableNumber));
						if (table2) {
							if (instance.tableMap.has(Number(instance.tableNumber))) {
								if (Number(table2.seats) === Number(instance.seatNumber)) {
									$(table).addClass('unchanged');
								} else {
									$(table).addClass('changed');
								}
							} else {
								admin.addTable(instance.tableNumber, instance.seatNumber, false, false, function (data) {
									$(table).addClass('new');
								});
							}
							if (!table2.isActive === true) {
								$(table).addClass('inactive');
							}
							if (table2.shareable === true) {
								$(table).addClass('shareable');
							}
						} else {
							$(table).addClass('new');
						}
					}
					var seats = $('#' + instance.tableNumber + ".eatwithme-seat");
					$(seats).addClass('unchanged');
				});

			});
		} else {
			floorplan.find('.eatwithme-tableNumber').each(function (ind, t) {
				instance.tableNumber = $(t).attr('id').split('-')[0];
				instance.seatNumber = $(t).attr('seatNumber');
				var table = $('#' + instance.tableNumber + ".eatwithme-table");
				if (table != null) {
					var table2 = instance.tableMap.get(Number(instance.tableNumber));
					if (table2) {
						if (instance.tableMap.has(Number(instance.tableNumber))) {
							if (Number(table2.seats) === Number(instance.seatNumber)) {
								$(table).addClass('unchanged');
							} else {
								$(table).addClass('changed');
							}
						} else {
							admin.addTable(instance.tableNumber, instance.seatNumber, false, false, function (data) {
								$(table).addClass('new');
							});
						}
						if (!table2.isActive === true) {
							$(table).addClass('inactive');
						}
						if (table2.shareable === true) {
							$(table).addClass('shareable');
						}
					} else {
						$(table).addClass('new');
					}
				}
				var seats = $('#' + instance.tableNumber + ".eatwithme-seat");
				$(seats).addClass('unchanged');
			});
		}
	}

	preprocessLayout = (data, handler) => {
		const instance = this;
		instance.tableMap = new Map();
		var floorplan = $(data);
		admin.getTables(function (data2) {
			data2.restaurantTables.forEach(function (table) {
				instance.tableMap.set(Number(table.number), table);
			});
			floorplan.find('g[transform]').each(function (ind, item) {
				var transform = $(item).attr('transform');
				var match = transform.match('matrix\\(([^\\)]*)\\)');
				if (match) {
					var m = match[1];
					var t = instance.decomposeMatrix(m.split(','));
					var tr = "";
					tr += 'scale(' + t.scale[0] + ',' + t.scale[1] + ') ';
					tr += 'translate(' + t.translate[0] + ',' + t.translate[1] + ') ';
					if (t.skew !== 0)
						tr += 'skew(' + t.skew + ') ';
					if (t.rotate !== 0)
						tr += 'rotate(' + t.rotate + ') ';
					tr = instance.decomposed_toString(t);
					//eslint-disable-next-line no-useless-escape
					var newTransform = transform.replace(/matrix\([^\)]*\)/, tr);
					console.log(newTransform);
					$(item).attr('transform', newTransform);
				}
			});
			floorplan.find('tspan').each(function (ind, t) {
				var tableNumber = $(t).attr('id').split("-")[0];
				if ($(t).hasClass('eatwithme-tableNumber')) {
					var match = true;
					tableNumber = tableNumber + "-" + $(t).attr('seatnumber');
				}
				match = tableNumber.match('(\\d+)-(\\d+)');
				if (match) {
					tableNumber = match[1];
					instance.seatNumber = match[2];
					$(t).attr('id', tableNumber);
					$(t).addClass('eatwithme-tableNumber');
					t.removeAttribute('textLength');
					const table2 = getTable(tableNumber);
					$(t).html(table2 && table2.name ? table2.name : tableNumber);
					var p = $(t).parent();
					var table = null;
					for (let i = 0; i < 10; i++) {
						var tables = $(p).find('ellipse');
						if (tables.length === 1) {
							table = tables[0];
							break;
						}
						tables = $(p).find('rect');
						if (tables.length === 1) {
							table = tables[0];
							break;
						}
						p = $(p).parent();
					}
					if (table != null) {
						$(table).addClass('eatwithme-table');
						$(table).attr('id', tableNumber);
					}
					table = $(t).parent().parent().parent();
					if (table != null) {
						$(table).addClass('eatwithme-table');
						$(table).attr('id', tableNumber);
					}
					var tableTop = $(table).parent();
					$(tableTop.find('> g')).each(function (ind, tableTopChildren) {
						var seatsGroup = $(tableTopChildren).find('> g');
						if (seatsGroup.length === 1) {
							var seats = $(seatsGroup).find('> g');
							$(seats).each(function (ind, seat) {
								$(seat).addClass('eatwithme-seat');
								$(seat).attr('id', tableNumber);
								$(seat).attr('eatwithme-seat-number', (ind + 1));
							});
							// $(t).attr('seatNumber', seats.length);
						}
					});
				}
			});
			var t = null;
			$(floorplan).each(function (ind, f) {
				if (f.localName === 'svg')
					t = f;
			})
			console.log($(t));
			handler($(t).prop('outerHTML'));
		});
	}

	updateTablesFromFloorplan = () => {
		const instance = this;
		var floorplan = instance.tableLayout.SVG;
		admin.getTables(function (data2) {
			var tableMap = new Map();
			data2.restaurantTables.forEach(function (table) {
				tableMap.set(Number(table.number), table);
			});
			floorplan.find('.eatwithme-tableNumber').each(function (ind, t) {
				if ($(t).hasClass('unchanged'))
					return;
				instance.tableNumber = $(t).attr('id').split('-')[0];

				var seats = $(t).parents('.eatwithme-table').parent().find('.eatwithme-seat');
				if (seats.length > 0) {
					var seatNumber = seats.length;
					$(t).attr('seatNumber', seatNumber);
				} else
					seatNumber = $(t).attr('seatNumber');
				$(t).html(instance.tableNumber);
			});
			floorplan.find('.changed').removeClass('changed');
			floorplan.find('.unchanged').removeClass('unchanged');
			floorplan.find('.new').removeClass('new');
			//updateFloorPlan(floorplan.prop('outerHTML'), reload);
			floorplan.find('.eatwithme-tableNumber').each(function (ind, t) {
				if ($(t).hasClass('unchanged'))
					return;
				instance.tableNumber = $(t).attr('id').split('-')[0];
				var seatNumber = $(t).attr('seatNumber');

				var table = $('#' + instance.tableNumber + '.eatwithme-table');
				if (tableMap.has(Number(instance.tableNumber))) {
					if (Number(tableMap.get(Number(instance.tableNumber)).seats) === Number(seatNumber)) {
						$(table).addClass('unchanged');
					} else {
						var table2 = tableMap.get(Number(instance.tableNumber));
						admin.modifyTable(table2.id, table2.number, seatNumber, table2.shareable, table2.active, table2.freeSeats, function (data) {
							$(table).removeClass('changed');
							$(table).addClass('unchanged');
						});
					}
				} else {
					admin.addTable(instance.tableNumber, seatNumber, false, false, function (data) {
						$(table).removeClass('new');
						$(table).addClass('unchanged');
					});
				}
			});
			admin.getRestaurantLayout(instance.processRestaurant);
		});
	}

	resetEditedObject = () => {
		const instance = this;
		var points = [];
		var matrix = instance.activeObject.calcTransformMatrix();
		var left = 100000000, right = -1000000000, top = 1000000000, bottom = -1000000000;
		instance.activeObject.points.forEach(function (point, index) {
			left = Math.min(point.x, left);
			top = Math.min(point.y, top);
			right = Math.max(point.x, right);
			bottom = Math.max(point.y, bottom);
		});
		instance.activeObject.points.forEach(function (point, index) {
			var x = point.x - left - (right - left) / 2;
			var y = point.y - top - (bottom - top) / 2;
			var p = window.fabric.util.transformPoint({
				x: x,
				y: y
			}, matrix);
			points[points.length] = p;
		});
		instance.canvas.remove(instance.activeObject);
		instance.activeObject = new window.fabric.Polygon(points, {
			selectable: false,
			objectCaching: false
		});
		instance.canvas.add(instance.activeObject);
		instance.activeObject.on('mousedblclick', instance.mousedblclick);
		instance.canvas.renderAll();
	}

	mousedblclick = () => {
		if (this.editMode) {
			this.quitEdit();
		} else {
			this.edit();
		}
	}

	quitEdit = () => {
		const instance = this;
		this.editMode = false;
		this.editPoints.forEach(function (editPoint) {
			instance.canvas.remove(editPoint);
		});
		instance.canvas.getObjects().forEach(function (obj) {
			obj.selectable = true;
		})
		instance.canvas.remove(instance.activeObject);
		instance.canvas.renderAll();
		instance.activeObject = new window.fabric.Polygon(instance.activeObject.points, {
			selectable: true,
			objectCaching: true
		});
		instance.activeObject.on('mousedblclick', this.mousedblclick);
		instance.canvas.add(instance.activeObject);
		instance.canvas.renderAll();
		// canvas.setActiveObject(activeObject);
	}

	edit = () => {
		this.activeObject = this.canvas.getActiveObject();

		if (!(this.activeObject instanceof window.fabric.Polygon))
			return;

		this.canvas.discardActiveObject().renderAll();
		// canvas.remove(activeObject);
		// canvas.renderAll();
		this.editPoints = [];

		this.resetEditedObject();

		this.canvas.getObjects().forEach(function (obj) {
			obj.selectable = false;
		})

		this.activeObject.points.forEach(function (point, index) {
			var circle = new window.fabric.Circle({
				radius: 5,
				fill: 'green',
				left: point.x,
				top: point.y,
				originX: 'center',
				originY: 'center',
				hasBorders: false,
				hasControls: false,
				name: index,
				lockScalingX: true,
				lockScalingY: true,
			});

			this.canvas.add(circle);
			circle.editPoint = true;
			// point.x = p.x;
			// point.y = p.y;
			// points[points.length] = {x:p.x, y:p.y};
			this.editPoints.push(circle);
		});
		// updateHooks();
		this.editMode = true;

	}

	circleMoved = (options) => {

		if (!this.editMode)
			return;

		if (options.target._objects) {
			options.target._objects.forEach(function (p) {
				var matrix = p.calcTransformMatrix();
				var p2 = window.fabric.util.transformPoint({
					x: 0,
					y: 0
				}, matrix);
				this.activeObject.points[p.name] = {
					x: p2.x,
					y: p2.y
				};
			});
			this.canvas.remove(this.activeObject);
			this.activeObject = new window.fabric.Polygon(this.activeObject.points, {
				selectable: false,
				objectCaching: false
			});
			this.canvas.add(this.activeObject);
			this.canvas.renderAll();
			this.canvas.sendToBack(this.activeObject);
			this.activeObject.on('mousedblclick', this.mousedblclick);
		} else {
			if (options.target.editPoint) {
				var p = options.target;
				this.activeObject.points[p.name] = {
					x: p.getCenterPoint().x,
					y: p.getCenterPoint().y
				};

				// UNCOMMENT LINES 39 - 42 TO HAVE A WORKAROUND

				this.canvas.remove(this.activeObject);
				this.activeObject = new window.fabric.Polygon(this.activeObject.points, {
					selectable: false,
					objectCaching: false
				});
				this.canvas.add(this.activeObject);
				this.canvas.renderAll();
				this.canvas.sendToBack(this.activeObject);
				this.activeObject.on('mousedblclick', this.mousedblclick);
			}
		}

	}

	duplicateEditPoint = (editPoint) => {
		var points = [];
		this.quitEdit();
		if (this.activeObject instanceof window.fabric.Polygon) {
			this.activeObject.points.forEach(function (point, index) {
				points[points.length] = point;
				if (point.x === editPoint.left && point.y === editPoint.top) {
					points[points.length] = point;
				}
			});
			this.canvas.remove(this.activeObject);
			this.activeObject = new window.fabric.Polygon(points, {
				selectable: false,
				objectCaching: false
			});
			this.canvas.add(this.activeObject);
			this.anvas.setActiveObject(this.activeObject);
			this.ctiveObject.on('mousedblclick', this.mousedblclick);
		}
		this.canvas.renderAll();
		this.edit();
	}

	deleteEditPoint = (editPoint) => {
		var points = [];
		this.quitEdit();
		if (this.activeObject instanceof window.fabric.Polygon) {
			this.activeObject.points.forEach(function (point, index) {
				if (point.x === editPoint.left && point.y === editPoint.top) {
					return;
				}
				points[points.length] = point;
			});
			this.canvas.remove(this.activeObject);
			this.activeObject = new window.fabric.Polygon(points, {
				selectable: false,
				objectCaching: false
			});
			this.canvas.add(this.activeObject);
			this.activeObject.on('mousedblclick', this.mousedblclick);
		}
		this.canvas.renderAll();
		this.edit();
	}


	updateHooks = () => {
		const instance = this;
		$('div#editRestaurantFloorplan').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			instance.edit(modal, recipient);
		})
	}

	edit = (modal, recipient) => {
		if (recipient === 'edit') {
			var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
			modal.find('#id').val(data.id);
			modal.find('#name').val(data.name);
			if (data.isActive === true)
				modal.find('#active').prop("checked", 'true');
			else
				modal.find('#active').removeAttr("checked");
		} else {
			modal.find('#id').val("");
			modal.find('#name').val("");
			modal.find('#active').prop("checked", 'true');

		}
	}

	saveRestaurantFloorplan = () => {
		var modal = $('div#editRestaurantFloorplan');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var active = modal.find('#active').prop('checked');
		if (id !== undefined && id !== '') {
			var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
			admin.modifyRestaurantFloorplan(id, name, data.floorplan, active, this.reload);
		} else {
			admin.addRestaurantFloorplan(name, "", active, this.reload);
		}
	}

	updateRestaurantFloorplan = (floorplan) => {
		var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
		admin.modifyRestaurantFloorplan(data.id, data.name, floorplan, data.active, this.reload);
	}

	activateRestaurantFloorplan = (active) => {
		var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
		admin.modifyRestaurantFloorplan(data.id, data.name, data.floorplan, active, this.reload);
	}

	deleteRestaurantFloorplan = (active) => {
		const instance = this;
		var data = $("table#floorplans").fancytree('getTree').getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_floorplan'), data.name)).done(function () {
			admin.deleteRestaurantFloorplan(data.id, instance.reload);
		});

	}


}

export default TableLayoutsCode;