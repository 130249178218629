import React from "react";
import { Translate } from "react-redux-i18n";
import { getCurrenciesByRestaurant } from "../../js/superadmin/superadmin";
import { flattenObject } from "../../components/utils";
import { DropDownEditor } from "../../renderers/tablecelleditors";

export const stateKey = "currency-grid-stateKey";

const stateful = false;

function getColumns({ myStatus, onChange }, store) {
  return [
    {
      name: <Translate value="admin_local.currency" />,
      dataIndex: "currency.id",
      sortable: true,
      renderer: props => (
        <DropDownEditor
          {...props}
          value={props.value}
          onChange={props.onChange}
          stateKey={stateKey}
          options={myStatus.currencies.map(m => {
            return { id: m.id, name: m.name };
          })}
        />
      ),
      width: "200px",
      className: "additional-class",
      expandable: true
    },
    {
      name: <Translate value="admin_local.exchange_rate" />,
      dataIndex: "exchangeRate",
      sortable: true,

      readOnly: true,
      width: "200px",
      className: "additional-class",
      expandable: true
    }
  ];
}

const currenciesConfig = (props, store) => {
  return {
    store: store,
    reducerKeys: "nested",

    gridType: "grid",
    pageSize: 20,

    stateKey: stateKey,

    stateful: stateful,

    columns: getColumns(props, store),

    restaurant_id: null,

    height: false,

    dataSource: function getData({ pageIndex, pageSize }) {
      return new Promise(resolve => {
        if (store.getState().rootReducer.restaurant && store.getState().rootReducer.restaurant.id) {
          getCurrenciesByRestaurant(store.getState().rootReducer.restaurant.id, data => {
            data.push({});
            const d = data.map(r => flattenObject(r));
            resolve({ data: d });
          });
        } else resolve({ data: [] });
      });
    },

    events: {},

    plugins: {
      COLUMN_MANAGER: {
        resizable: true,
        moveable: true
        /*
      sortable: {
        enabled: true,
        method: "local",
        sortingSource: "http://react-redux-grid.herokuapp.com/getfakeData"
      }*/
      },
      STICKY_HEADER: {
        enabled: true
      },
      STICKY_FOOTER: {
        enabled: true
      },
      LOADER: {
        enabled: false
      },
      SELECTION_MODEL: {
        mode: "checkbox-multi",
        enabled: false,
        allowDeselect: true,
        activeCls: "active",
        selectionEvent: "singleclick",
        editEvent: "none"
      },
      ERROR_HANDLER: {
        defaultErrorMessage: "AN ERROR OCURRED",
        enabled: true
      },
      BULK_ACTIONS: {
        enabled: false,
        actions: [
          {
            text: "Move",
            EVENT_HANDLER: () => { }
          },
          {
            text: "Add",
            EVENT_HANDLER: () => { }
          }
        ]
      },
      GRID_ACTIONS: {
        iconCls: "action-icon",
        menu: [
          {
            text: "Delete",
            key: "delete",
            EVENT_HANDLER: ({ metaData }) => {
              /*
            const rowIndex = metaData.rowIndex;
            store.dispatch(
              Actions.EditorActions.removeRow({
                stateKey,
                rowIndex
              })
            );
            */
            }
          }
        ]
      }
    }
  };
};
export default currenciesConfig;
