import React, { Component } from "react";
import { withRouter } from "react-router-dom";

const userImg = {
  borderRadius: "100%",
  height: "26px",
  width: "26px"
};

class UserImageButton extends Component {
  render() {
    return (
      <div className="icon pull-left hide-print">
        <img
          id="user_img"
          alt=""
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            this.props.history.push("/profile");
          }}
          src={this.props.src}
          style={userImg}
        />
      </div>
    );
  }
}

export default withRouter(UserImageButton);
