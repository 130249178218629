import React, { Component } from 'react';
import { Map as MapContainer, TileLayer, Marker, useLeaflet as useMapEvents } from 'react-leaflet';
import { I18n } from 'react-redux-i18n';
import moment from "moment";
import { getHomeDeliveryTableNumber, getLocalPrinterSettings, getPickupTableNumber, getTableMate } from '../js/admin';
import { DivIcon } from 'leaflet';
import orderListUtil, { getAddresses, getTableOccupation, update } from '../js/order-list-util';
import TimeRangeSlider from "react-time-range-slider";
import $ from "jquery";
import { getAmountToPay, selectTableOccupation, showMap } from '../js/tables-overview';
import { get, post } from '../js/auth';
import { getPaymentMethod } from '../js/tableMatePaid';
import { ContactSupportOutlined } from '@material-ui/icons';

function clickEvent(t, event) {
    const date = moment().startOf("day").valueOf();
    var target = event.target;
    var marker = $(target).parents('.leaflet-marker-icon').find('>div');
    if ($(target).hasClass('icon-level-down') || $(target).hasClass('icon-qrcode')) {

        let arr = [];
        var text = "";
        $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
        arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));

        var ok = false;

        //var path = t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;
        var path = encodeURIComponent(t.props.myStatus.restaurant_address);

        arr.forEach(i => {
            const t = $(i).parents('.leaflet-marker-icon').find('>div');
            //path += "/" + t.attr('longitude') + ',' + t.attr('latitude');
            path += "/" + encodeURIComponent(t.attr('address').replace(/\//g, " ").replace(/,/g, " "));
            //console.log(t.attr('id'), marker.attr('id'))
            if (t.attr('id') === marker.attr('id'))
                ok = true;

            text += getAddresses(t.attr('id'), date);
        });

        if (!ok) {
            text = "";
            path += t.props.myStatus.restaurant_address;
            //path = t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;
            //path += "/" + marker.attr('longitude') + ',' + marker.attr('latitude');
            path += "/" + marker.attr('address');
            text += getAddresses(marker.attr('id'), date);
        }


        path += "/" + encodeURIComponent(t.props.myStatus.restaurant_address);
        //path += "/" + t.props.myStatus.restaurant_latitude + ',' + t.props.myStatus.restaurant_longitude;

        const url = 'https://www.google.com/maps/dir/' + path + '/@' + marker.attr('longitude') + ',' + marker.attr('latitude') + ',14z';
        if ($(target).hasClass('icon-qrcode')) {
            const printerSettings = getLocalPrinterSettings();
            console.log(printerSettings);
            var printer = t.props.myStatus.restaurant_printer_actions.printTableSlip.id;
            if (printerSettings.printerActions.pa_printTableSlip)
                printer = printerSettings.printerActions.pa_printTableSlip;

            const html = "<html><body><div>!ESCqrcode: " + url + "!LF!LF</div><div>" + text + "</div></body></html>";

            post("printerServerClient/" + localStorage.restaurantSelected + "/printerMessage/" + printer, { text: html });
        } else {
            window.open(url, "_blank")
        }
        return;
    }

    if ($(target).hasClass('order')) {
        if (!$(target).prop('checked')) {
            $(target).attr('order', 0);
            marker.find('span.seqnr').html("");
            marker.find('span.seqnr').addClass("hidden");
        }
        var arr = [];
        $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
        if ($(target).prop('checked')) {

            var currentOrder = arr.map(i => $(i).attr('order')).reduce((a, b) => Math.max(a, b), 0);
            $(target).attr('order', currentOrder + 1);
        }
        arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));
        arr.forEach((i, ind) => {
            $(i).attr('order', ind + 1);
            $(i).parents('.leaflet-marker-icon').find('span.seqnr').html(ind + 1);
            $(i).parents('.leaflet-marker-icon').find('span.seqnr').removeClass('hidden');
        });
        return;
    }

    if (marker.length > 0) {
        showMap(false);
        selectTableOccupation(marker.attr('id'))
    }
    //t.setState({ longitude: event.latlng.lat, latitude: event.latlng.lng });
}

//var map;
function MyComponent({ t }) {
    const date = moment().startOf("day").valueOf();

    useMapEvents({
        click: (event) => clickEvent(t, date, event)
    })
    const restaurantPosition = [t.props.myStatus.restaurant_latitude, t.props.myStatus.restaurant_longitude];


    const deliveryUsers = t.props.deliveryUsers;

    return (
        <React.Fragment>
            {t.props.tableOccupations.filter(o => o.bookedProperties && o.bookedProperties.longitude).map(occupation => {
                const position = [occupation.bookedProperties.latitude, occupation.bookedProperties.longitude];
                const startTime = moment(occupation.bookedOccupationStart);

                if (startTime.isAfter(t.state.endDate))
                    return null;

                if (t.state.startDate.isAfter(moment().startOf('day')) && startTime.isBefore(t.state.startDate))
                    return null;

                var text = "";

                const o = orderListUtil.allOrders.tables.find(o => o.tableOccupation === occupation.id && moment(o.date).startOf("day").valueOf() === date);

                if (!o || o?.stateMap?.delivered?.length)
                    return;


                if (o && o.deliveredById) {
                    var courier = deliveryUsers.find(u => u.id === o.courierId);
                    if (courier)
                        text += "<div class='courier' style='width:100%'><span style='color:" + courier.color + ';background-color:' + courier.backgroundColor + "'>" + o.deliveredByName + " (" + o.deliveredById + ")</span></div>";
                    else
                        text += "<div class='courier' style='width:100%'><span>" + o.deliveredByName + "</span></div>";
                }
                text += "<div style='width:100%;background:orange;color:white;display:flex;align-items:center'>";

                text += "<label class='checkbox-inline'><input onChange='setOrder(event)' order='0' class='order' type='checkbox' value=''></label>";
                if (localStorage.bookingMapZoom > 14) {
                    text += "<div class='icon-level-down'></div>";
                    text += '<div style="flex-grow:1"><b>#' + occupation.seqnr + '</b></div>';
                    text += "<div class='icon-qrcode'></div>";
                }
                text += "</div>";
                text += "<span class='seqnr hidden'></span><div>"
                if (startTime.date() === moment().add(-1, 'day').date()) {
                    text += I18n.t("admin_local.yesterday") + " ";
                } else if (startTime.date() === moment().date()) {
                    text += I18n.t("admin_local.today") + " ";
                } else if (startTime.clone().add(1, 'day').date() === moment().date()) {
                    text += I18n.t("admin_local.tomorrow") + " ";
                } else {
                    text += startTime.format("YYYY-MMM-DD") + "<br/>";
                }
                text +=
                    moment(occupation.bookedOccupationStart).format("HH:mm") +
                    (occupation.bookedOccupationEnd ? (
                        "-" +
                        moment(occupation.bookedOccupationEnd).format("HH:mm")) : "") +
                    "<br/>";
                text += "</div>";
                if (localStorage.bookingMapZoom > 14) {
                    text += "<div>";
                    text += occupation.bookedProperties.surname ? occupation.bookedProperties.surname + " " : "";
                    text += (occupation.bookedProperties.name ? occupation.bookedProperties.name : occupation.bookedProperties.email ? occupation.bookedProperties.name : "");
                    text += "</div>";
                    text += "<div class='address icon-address-card-o'>";
                    text += (occupation.bookedProperties.postcode ? occupation.bookedProperties.postcode + "&nbsp;" : "") + (occupation.bookedProperties.town !== t.props.myStatus.restaurant_town ? occupation.bookedProperties.town + "&nbsp;" : "") + (occupation.bookedProperties.address ? occupation.bookedProperties.address : "");
                    text += "</div>";

                    text += "<div class='icon-mobile'>" + (occupation.bookedProperties.phone ? occupation.bookedProperties.phone : "");
                }

                const restaurantTableOccupation = occupation.restaurantTables[0];

                var clazz = "i_takeaway " + (getTableMate(restaurantTableOccupation.tableMateIds[0]) ? getTableMate(restaurantTableOccupation.tableMateIds[0]).states.reduce((a, b) => a + " " + b.state, "") + " " : "");

                var stateMap = orderListUtil.allOrders.tables.find(t => t.tableOccupation === occupation.id);
                stateMap = stateMap && stateMap.stateMap;
                clazz += stateMap && Object.entries(stateMap).filter(e => e[1].length).reduce((a, b) => a + " " + b[0], "");

                var style = ""

                if (localStorage.bookingMapZoom < 14) {
                    style = "font-size:" + localStorage.bookingMapZoom + "px";
                }


                const customMarker = new DivIcon({
                    className: clazz,
                    id: occupation.id,
                    iconSize: [20, 20],
                    fontSize: "3px",
                    html: "<div style='" + style + "' id='" + occupation.id + "' address='" + occupation.bookedProperties.address + ", " + occupation.bookedProperties.town + (occupation.bookedProperties.postcode ? ", " + occupation.bookedProperties.postcode : "") + "' longitude='" + occupation.bookedProperties.longitude + "' latitude='" + occupation.bookedProperties.latitude + "'>" + text + "</div>",
                })


                return (<Marker key={localStorage.bookingMapZoom + "-" + occupation.id} position={position} icon={customMarker} >
                </Marker>)
            }
            )}
            <Marker position={restaurantPosition} />
        </React.Fragment >)
}


class BookingMap extends Component {
    state = {
        startDate: moment().startOf('day'),
        endDate: moment().add(2, 'hour'),
    }

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount = (props) => {
        window.setOrder = (event) => clickEvent(props, event);
        const map = this.mapRef.current.leafletElement;

        if (typeof localStorage.bookingMapZoom == "undefined")
            localStorage.bookingMapZoom = 13;

        // Attach the zoom change event listener
        map.on('zoomend', this.handleZoomChange);
    }

    componentWillUnmount() {
        delete window.clickEvent;
        const map = this?.mapRef?.current?.leafletElement;

        // Clean up the event listener when the component unmounts
        if (map)
            map.off('zoomend', this.handleZoomChange);
    }

    handleZoomChange = () => {
        // This function will be called when the zoom level changes
        const currentZoom = this.mapRef.current.leafletElement.getZoom();
        localStorage.bookingMapZoom = currentZoom;
        this.setState({ currentZoom })
    };


    render() {


        return (
            <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
                <div style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>{this.state.startDate.format('HH:mm') + " - " + this.state.endDate.format('HH:mm')}</div>
                <TimeRangeSlider
                    style={{ flexShrink: "0" }}
                    disabled={false}
                    format={24}
                    minValue={"00:00"}
                    maxValue={"24:00"}
                    name={"time_range"}
                    onChangeComplete={this.changeCompleteHandler}
                    onChange={this.timeChangeHandler}
                    step={15}
                    value={{ start: this.state.startDate.format("HH:mm"), end: this.state.endDate.format("HH:mm") }}
                />
                <MapContainer ref={this.mapRef} whenCreated={mapInstance => { this.mapRef.current = mapInstance; this.componentDidMount2() }} style={{ flexGrow: "1", flexShrink: "1", width: "100vw" }} center={[this.props.myStatus.restaurant_latitude ? this.props.myStatus.restaurant_latitude : 0, this.props.myStatus.restaurant_longitude ? this.props.myStatus.restaurant_longitude : 0]} zoom={Number(localStorage.bookingMapZoom)} scrollWheelZoom={true}>
                    <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" id="mapbox.streets" maxZoom="18" accessToken="pk.eyJ1IjoiY3N6YXN6IiwiYSI6ImNqeHhqMTY1NDAwM2EzbW83aTJqZGlkM3MifQ.QeTnxqdNsSXLkBtBHsgkBQ" />
                    <MyComponent key={localStorage.bookingMapZoom + "-" + this.key} t={this} />
                </MapContainer>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span>{I18n.t("admin_local.couriers")}</span>
                    {this.props.deliveryUsers.map(u => (
                        <div id={u.id} key={u.id} onClick={this.assignToCourier} class="courier"><span style={{ backgroundColor: u.backgroundColor, color: u.color }} >{u.name}</span></div>
                    )

                    )}
                </div>
            </div>);
    }

    assignToCourier = (event) => {
        const courier = event.currentTarget.id;
        let arr = [];
        $('.leaflet-marker-icon .order:checked').each((ind, i) => arr.push(i));
        arr = arr.sort((a, b) => $(a).attr('order') - $(b).attr('order'));
        const date = moment().startOf("day").valueOf();
        const occupations = [];
        arr.forEach(i => {
            const t = $(i).parents('.leaflet-marker-icon').find('>div');
            const occupationid = Number(t.attr('id'));
            orderListUtil.allOrders.tables.filter(o => o.tableOccupation === occupationid && moment(o.date).startOf("day").valueOf() === date).forEach(o => occupations.push(o))
        });
        var delivery = 0;
        const orderIds = [];
        const assign = (occupations) => {
            const occupation = occupations.shift();
            occupation.orderIds.forEach(id => orderIds.push(id));
            if (occupations.length) {
                assign(occupations);
                return;
            }

            if (orderIds) {
                get("adminService/" + this.props.myStatus.restaurant_id + "/assignCourier/" + delivery + "/" + occupation.tableOccupation + "?orderIds=" + orderIds + "&courier=" + courier).done((data) => {
                    update(data);
                    delivery = data.orders[0].deliveredById;
                    this.setState({ changed: moment() })
                })
            }

        }
        if (occupations.length)
            assign(occupations);

    }

    timeChangeHandler = (time) => {
        const start = moment().startOf('day').add(Number(time.start.split(":")[0]), "hour").add(Number(time.start.split(":")[1]), "minute");
        const end = moment().startOf('day').add(Number(time.end.split(":")[0]), "hour").add(Number(time.end.split(":")[1]), "minute");

        this.setState({
            startDate: start,
            endDate: end,
        });
    }

    changeCompleteHandler = (time) => {
        //this.code.reload(this.state)
    }
}

export default BookingMap;