import React from "react";
import { store } from "..";

export const CustomObjectFieldTemplate = props => {
  return (
    <React.Fragment>
      {props.properties.map(element => {
        return element.content.props.schema.type === "object" ? (
          <div key={element.name} className="property-wrapper">
            {element.content}
          </div>
        ) : (
            <div key={element.name} className="property">
              {element.content}
            </div>
          );
      })}
    </React.Fragment>
  );
};
export const CustomFieldTemplate = props => {
  const { id, classNames, label, help, required, description, errors, children } = props;
  return (
    <div className={classNames}>
      <label htmlFor={id}>
        {label}
        {required ? "*" : null}
      </label>
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
};

export const flattenObject = object => {
  if (object && typeof object === "object") {
    var res = {};
    Object.keys(object).forEach(key => {
      if (object[key] === null) {
        res[key] = object[key];
      } else if (typeof object[key] === "object") {
        //  Object.keys(object[key]).forEach(key2 => {
        const r = flattenObject(object[key]);
        //console.log(key, "=>", r);
        if (r != null)
          Object.keys(r).forEach(rkey => {
            //console.log(key + "." + rkey, "==>", r[rkey]);
            res[key + "." + rkey] = r[rkey];
          })
        else
          res[key] = null;
        //});
      } else {
        res[key] = object[key];
      }
    });
    return res;
  } else return object;
};

export const unflattenObject = object => {
  var res = {};
  Object.keys(object).forEach(key => {
    var prop = res;
    const t = key.split(".");
    t.forEach((k, ind) => {
      if (ind === t.length - 1) {
        console.log(key, prop);
        if (isNaN(k))
          prop[k] = object[key];
        else {
          if (!Array.isArray(prop))
            prop = [];
          prop.push(object[key]);
          console.log(key, prop);
        }
      } else if (prop[k]) {
        console.log(k, "found")
        prop = prop[k];
      } else {
        prop[k] = {};
        prop = prop[k];
      }
    });
  });
  return res;
};

export const getRowsFromStore = stateKey => {
  const c = store.getState().nested.dataSource.get(stateKey);
  const data = c.get("data");
  return data.toJS();
};

export const getRowFromStore = (stateKey, rowId) => {
  const c = store.getState().nested.dataSource.get(stateKey);
  const data = c.get("data");
  const json = data.toJS();
  const originalRow = json.find(r => {
    return r._key === rowId;
  });
  return originalRow;
};

export const getRowIndexFromStore = (stateKey, rowId) => {
  const c = store.getState().nested.dataSource.get(stateKey);
  const data = c.get("data");
  const json = data.toJS();
  var index = null;
  json.forEach((r, ind) => {
    if (r._key === rowId) index = ind;
  });
  return index;
};

export const getColumnsFromStore = stateKey => {
  console.log(store.getState().nested.grid.get(stateKey));
  return store.getState().nested.grid.get(stateKey).columns;
};
