/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable no-eval */
import $ from "jquery"

import auth, { post, select, updateCheckBoxesById, avoidMultipleClick, messageDialog, whatDecimalSeparator, choiceDialog, choiceDialog2, getLocale, ajaxErrorEnd, confirmDialog, replaceContent } from "./auth"
import { showMenuItems } from "./chooseMenuItem";
import { getMenuItem, getTableMate, isAdmin, cancelOrder, getMenuCategory, getPickupTableNumber, getHomeDeliveryTableNumber, lockTable, getPriceForQuantity, isPickup, isHomeDelivery } from "./admin";
import orderListUtil, { updateTableOccupations, _show, deleteOrders, getOrderById, mateInState, tmplparams, update, getOrdersPaidBy, getTableOccupation, getTable, isTakeawayTable, playClickSound, processAllOrders } from "./order-list-util";
import { getMenuCategoryOptions, clickHandler222, getMenuItemAdditions, filterAdditions, getOrdersToPay, processRestaurant, activeMenuItems } from "./tables-overview";
import { I18n } from "react-redux-i18n";
import admin, { unlockTable } from "./admin";
import "../css/orderMenuItem.css";
import Cleave from "cleave.js";
import { tableOccupations } from "../actions/EatWithMeActions";


export const orderMenuItem = (item, entityType, id, quantity, force, mate, timing, comment, additions = [], price, defaultProductionLines, defaultQuantityType, takeaway, updated = false, copy = false) => {
	const date = $($(item).parents("li").get(0)).attr("date");
	var numeralDecimalMark = whatDecimalSeparator();

	const table = getTable(localStorage.tableNumberSelected);
	const pickupTableNumber = getPickupTableNumber();
	const homedeliveryTableNumber = getHomeDeliveryTableNumber();


	if (item && (!localStorage.tableOccupationSelected || localStorage.tableOccupationSelected === "null" || !getTableOccupation(localStorage.tableOccupationSelected))) {
		if (table.pos && localStorage.tableNumberSelected != homedeliveryTableNumber && localStorage.tableNumberSelected != pickupTableNumber && typeof localStorage.tableNumberSelected !== "undefined") {
			lockTable(localStorage.tableNumberSelected, 1, (data) => {
				update(data, () => orderMenuItem(item, entityType, id, quantity, force, mate, timing, comment, additions, price, defaultProductionLines, defaultQuantityType));
			});
		}
		return;
	}
	const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
	if (table && table.pos && localStorage.tableNumberSelected != homedeliveryTableNumber && localStorage.tableNumberSelected != pickupTableNumber && item && localStorage.tableOccupationSelected) {
		if (tableOccupation) {
			const tableMate = tableOccupation.tableMates[0];
			if (tableMate && getOrdersToPay(tableMate.id).length === 0 && getOrdersPaidBy(tableMate.id).length > 0) {
				unlockTable(localStorage.tableOccupationSelected, localStorage.tableNumberSelected, false, data =>
					update(data, () =>
						lockTable(localStorage.tableNumberSelected, 1, (data) =>
							update(data, () => orderMenuItem(item, entityType, id, quantity, force, mate, timing, comment, additions, price, defaultProductionLines, defaultQuantityType)))
					)
				)
				return;
			}
		}
	}
	if (new Date().getTime() - auth.scrollEventTimestamp < 500)
		return;

	auth.vibrateOk();

	if (price == undefined)
		price = null;

	const state = orderListUtil.statesShown.find(a => a != 'cancelled' && a != 'ordered');

	$('#editTable #right_pane input#search:visible').val('');
	showMenuItems('');
	var menuItem = getMenuItem(id);

	timing = $('#timingSelector button.timing.active').attr('id') ? $('#timingSelector button.timing.active').attr('id') : timing ? timing : menuItem.defaultTiming;

	if (!timing || timing === 'inherit') {
		timing = Object.keys(auth.myStatus.restaurant_settings.timings)[0];
	}
	mate = typeof mate == 'undefined' ? $('#userSelector button.tablemate.active').attr('id') : mate;

	if (localStorage.tableNumberSelected == getPickupTableNumber() || localStorage.tableNumberSelected == getHomeDeliveryTableNumber()) {
		timing = Object.keys(auth.myStatus.restaurant_settings.timings)[0];
	}

	if (!orderMenuItem.kioskMode)
		$('#timingSelector button.timing.active').removeClass('active');

	var quantityLabel = "";

	var tableMateChoices = [];
	var productionLines = [];
	auth.myStatus.restaurant_production_lines.forEach(function (pl) {
		var pl = Object.assign({}, pl);
		pl.selected = false;
		productionLines.push(pl);
	});
	var mateCount = 0;

	admin.tableMates.forEach((tableMateId) => {
		var tableMate = getTableMate(tableMateId);
		if (tableMate.active == true && tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
			mateCount++;
		}
	});
	admin.tableMates.forEach(function (tableMateId) {
		var tableMate = getTableMate(tableMateId);
		if (tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
			tableMate.count = 1;
			const tmate = { ...tableMate };
			tableMateChoices.push(tmate);
			if (tmate.id == mate || (typeof mate == "undefined" && mateCount == 1)) {
				mate = tmate.id;
				tmate.selected = true;
			} else {
				tmate.selected = false;
			}
		}
	});

	if (!updated && timing && mate && quantity && additions && !menuItem.freeQuantity) {

		menuItem.availableQuantities.forEach(function (q) {
			if (q.quantity == quantity)
				quantityLabel = q.label;
		});

		const tempId = "" + new Date().getTime() + Math.round(Math.random() * 10000);

		var order = {
			menuItemId: id,
			tempId: (tempId + id),
			quantity: quantity,
			comment: (typeof comment == 'undefined' ? "" : comment),
			timing: timing,
			tableMateIds: [mate],
			takeaway: takeaway,
			additions: (typeof additions == 'undefined' ? [] : additions),
			price: price,
			productionLines: (typeof defaultProductionLines == 'undefined' ? [] : defaultProductionLines),
			quantityType: (typeof defaultQuantityType == 'undefined' ? undefined : defaultQuantityType),
			date: date
		}
		var newOrderIds = "";
		const postOrder = function () {
			var timestamp = new Date().getTime();
			order.tableMateIds.forEach((mate, ind) => {
				const maxOrderId = orderListUtil.newOrders.reduce((a, b) => Math.min(a, b.id), 0);
				var fullPrice = getPriceForQuantity(menuItem, quantity);
				const childOrders = order.additions.map(addition => {
					return {
						addition: addition.addition,
						menuItem: { id: addition.menuItemId, entityType: getMenuItem(addition.menuItemId).entityType },
						quantity: addition.quantity,
						fullPrice: getPriceForQuantity(getMenuItem(addition.menuItemId), addition.quantity),
						tempId: (tempId + ind + order.menuItemId + addition.menuItemId),
						parentOrderId: maxOrderId - 1,
						childOrders: []
					}
				})
				var cremovals = 0;
				var cadditions = 0;
				childOrders.forEach(a => {
					if (a.addition)
						cadditions += a.fullPrice;
					else
						cremovals += a.fullPrice;
				})
				if (cadditions > cremovals)
					fullPrice += cadditions - cremovals;
				else if (cadditions == 0 && cremovals > 0 && !menuItem.minimumPrice) {
					fullPrice += cadditions - cremovals;
				} else {
					childOrders.forEach(c => c.fullPrice = 0)
				}
				orderListUtil.newOrders.push({
					addition: true,
					childOrders: childOrders,
					comment: order.comment,
					created: timestamp,
					defaultProductionLinesIds: '',
					discount: 0,
					discountComment: '',
					forWhomId: mate,
					id: maxOrderId - 1,
					tempId: order.tempId + ind,
					inStateTimeStamp: timestamp,
					menuItem: { id: order.menuItemId },
					menuItemId: order.menuItemId,
					modificationTimeStamp: timestamp,
					orderSequenceNumber: orderListUtil.currentOrderSequenceNumber,
					orderedById: Number(localStorage.id),
					ordersInState: [],
					paid: false,
					price: price,
					fullPrice: fullPrice,
					printState: {},
					printed: false,
					quantity: order.quantity,
					recordState: 'ACTIVE',
					restaurantComment: '',
					state: state,
					statePerProductionLine: {},
					tableMateDiscount: 0,
					tableNumber: Number(localStorage.tableNumberSelected),
					tableOccupationId: localStorage.tableOccupationSelected,
					timing: order.timing,
					wasSelectedForPayment: false,
					menuItemId: id,
					quantity: quantity,
					comment: (typeof comment == 'undefined' ? "" : comment),
					tableMateIds: [mate],
					takeaway: order.takeaway,
					additions: order.additions,
					productionLines: (typeof defaultProductionLines == 'undefined' ? [] : defaultProductionLines),
					quantityType: (typeof defaultQuantityType == 'undefined' ? undefined : defaultQuantityType),
					date: date
				});
				newOrderIds += orderListUtil.newOrders[orderListUtil.newOrders.length - 1].id + ",";
			});
			const tableOccupation = getTableOccupation(localStorage.tableOccupationSelected);
			tableOccupation.modificationTimeStamp = tableOccupation.modificationTimeStamp + 1;
			updateTableOccupations([tableOccupation], orderListUtil.newOrders);
			const channel = isPickup() ? "console_pickup" : isHomeDelivery() ? "console_homedelivery" : "console";
			const sendit = () =>
				post("adminService/" + localStorage.restaurantSelected + "/new" + entityType + "Orders" + "?tableOccupation=" + localStorage.tableOccupationSelected + "&tableNumber="
					+ localStorage.tableNumberSelected + "&lang=" + localStorage.language + "&state=" + state + "&timestamp=" + timestamp + "&channel=" + channel, order, undefined, false, 2, true, 10000).done(function (data) {
						updateTableOccupations([data.tableOccupation], data.orders);
						var orderId = data.orders[0].id;
						var v = $('#editTable div.tab-pane#byCustomer');
						v = v.find('div.draggable.byCustomer[id*="-' + orderId + '"]');
						if (v.length > 0) {
							$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
							$(v).addClass('highlight-3')
						}
						var v = $('#editTable div.tab-pane#byTiming');
						v = v.find('div.draggable.byTiming[id*="-' + orderId + '"]');
						if (v.length > 0) {
							$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
							$(v).addClass('highlight-3')
						}
						var v = $('#editTable table.orderTable');
						v = v.find("tr[orderid*='" + orderId + "']");
						if (v.length > 0) {
							$(v.parents('div.freeze-orderTable').get(0)).scrollTo(v.get(0));
							_show($(v));
							$(v).addClass('highlight-3')
						}
					}).fail(function (data) {
						auth.vibrateOk(2000);
						choiceDialog2(I18n.t("local.error_message"), String.format(I18n.t("admin_local.failed_to_send_order"), getLocale(menuItem.name)), [I18n.t("admin_local.resend")]).done(() => {
							sendit();
						}).fail(() => {
							deleteOrders(newOrderIds, true);
							processRestaurant();
							//updateTableOccupations([], []);
						})
					});
			if (order.id > 0 || state !== "selected" || !auth.delayedOrder())
				sendit();
			else {
				var orderId = newOrderIds.split(",")[0];
				var v = $('#editTable div.tab-pane#byCustomer');
				v = v.find('div.draggable.byCustomer[id*="-' + orderId + '"]');
				if (v.length > 0) {
					$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
					$(v).addClass('highlight-3')
				}
				var v = $('#editTable div.tab-pane#byTiming');
				v = v.find('div.draggable.byTiming[id*="-' + orderId + '"]');
				if (v.length > 0) {
					$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
					$(v).addClass('highlight-3')
				}
				var v = $('#editTable table.orderTable');
				v = v.find("tr[orderid*='" + orderId + "']");
				if (v.length > 0) {
					$(v.parents('div.freeze-orderTable').get(0)).scrollTo(v.get(0));
					_show($(v));
					$(v).addClass('highlight-3')
				}
			}
		}
		const includedOptions = getMenuItemAdditions(menuItem, '', 'included');
		if (!copy && getMenuCategoryOptions(menuItem) && getMenuCategoryOptions(menuItem)
			.filter(option => option.optionType === "one" && activeMenuItems(getMenuCategory(option.additionId), menuItem, true, takeaway || tableOccupation.takeaway).length > 0 && getMenuCategory(option.additionId).activeMenuItems && getMenuCategory(option.additionId).activeMenuItems.filter(menuItem => includedOptions && includedOptions.filter(m => m.id === menuItem.id).length !== 0).length === 0).length > 0) {
			$('body > #chooseOption').remove();

			$('body > #editMenuItemDialog').empty();
			$('script#chooseOptionTemplate').tmpl({ isTakeaway: takeaway || tableOccupation.takeaway, menuItemChosen: menuItem, ...tmplparams(), additionsSelected: [] }).appendTo('body');
			$('body > #chooseOption').modal().show({ keyboard: false });

			const modal = $('body > #chooseOption');

			/*const modal = $('#editMenuItemDialog');
			console.log(modal);
			modal.empty();
			$('script#chooseOptionTemplate').tmpl({ isTakeaway: takeaway, menuItemChosen: menuItem, ...tmplparams(), additionsSelected: [] }).appendTo(modal);
			modal.find('#chooseOption').modal().show({ keyboard: false });
			*/
			$('body > #chooseOption').find('input').on('change', () => {
				var ok = true;
				order.additions = [];
				$('body > #chooseOption').find('.additionsBlock div.optionCategory').each(function (ind, optionCategory) {


					const optionType = $(optionCategory).attr('type');
					var o = false;
					$(optionCategory).find('input[type="checkbox"],input[type="radio"]').each(function (ind, a) {

						var addition = /*optionType === "separate" ? $(a).parent().attr('id') : */ $(a).attr('id').substring(7);

						var quantity = addition.split("-")[1];
						addition = addition.split("-")[0];
						if (optionType === "separate") {
							o = true;
							const checked = $(a).prop('checked');
							a = $(a).next('label');
							var originalQuantity = $(a).attr('originalQuantity');
							if ($(a).hasClass('included') && !checked) {
								order.additions.push({ menuItemId: addition, addition: false, quantity: quantity });
								o = true;
							} else if ($(a).hasClass('included') && checked && Number(originalQuantity) != Number(quantity)) {
								order.additions.push({ menuItemId: addition, addition: quantity > originalQuantity, quantity: Math.abs(quantity - originalQuantity) });
								o = true;
							} else if ($(a).hasClass('optional') && !$(a).hasClass('included') && checked) {
								order.additions.push({ menuItemId: addition, addition: true, quantity: quantity });
								o = true;
							}
						} else if ($(a).prop('checked') && !$(a).hasClass('included')) {
							order.additions.push({ menuItemId: addition.split("-")[0], addition: true, quantity: quantity });
							o = true;
						}
					});
					if (optionType !== "separate")
						$(optionCategory).find('input.included:not(:checked)').each(function (ind, a) {
							var addition = /*($(optionCategory).attr('type') === "separate" ? $(a).parent().attr('id') : */$(a).attr('id').substring(7);
							var quantity = addition.split("-")[1];
							order.additions.push({ menuItemId: addition.split("-")[0], addition: false, quantity: quantity });
							o = true;
						});
					$(optionCategory).find('input.included:checked').each(function (ind, a) {
						o = true;
					});
					if (optionType === "maxone") {
						o = true;
					}
					if (!o) {
						$(optionCategory).removeClass('highlight-3');
						setTimeout(function () {
							$(optionCategory).addClass('highlight-3');
						}, 100);
					}

					ok = ok && o;

				});
				if (ok) {
					$('body > #chooseOption').modal('hide');
					$('body > #chooseOption').remove();
					postOrder();
				}
			});

			function c(c) {
				c.preventDefault();
				c.stopPropagation();
				window.history.pushState('', null, '');
				window.history.back();
				window.history.pushState('', null, '');
				modal.find("#close").trigger('click');
			}
			modal.off('hidden.bs.modal');
			modal.on('hidden.bs.modal', function () {
				modal.empty();
				if (window.history.state === "orderMenuItem") {
					window.history.back();
				}
				$(window).off('popstate', c);

			});
			window.history.pushState('orderMenuItem', null, '');
			$(window).on('popstate', c);

		} else {
			postOrder();
		}
		return;
	}

	if (orderListUtil.drag && !force)
		return;


	if (quantity == undefined)
		menuItem.availableQuantities.forEach(function (q) {
			if (q.def && q.onMenu == true)
				quantity = q.quantity;
		});
	if (quantity == undefined && menuItem.availableQuantities.length > 0) {
		menuItem.availableQuantities.forEach(function (q) {
			if (quantity == undefined && q.onMenu == true)
				quantity = q.quantity;
		});
	}

	if (quantity == undefined)
		quantity = 1;

	var tk = false;
	try {
		tk = getTableOccupation(localStorage.tableOccupationSelected) && getTableOccupation(localStorage.tableOccupationSelected).takeaway || !!takeaway;
	} catch (ex) { }

	$('body > .chooseOption').remove();

	var modal = $('#editMenuItemDialog');
	var modal2 = updated ? $('<div/>') : modal;

	if (!updated) {
		modal.empty();
	}

	$('#chooseMates').tmpl({
		mode: updated ? 'edit' : 'new',
		tableMates: tableMateChoices,
		can_be_changed: true,
		can_be_deleted: false,
		menuItemChosen: menuItem,
		timingSelected: timing,
		quantitySelected: quantity,
		quantityLabel: quantityLabel,
		quantity: quantity,
		productionLines: productionLines,
		keyboard: false,
		backdrop: 'static',
		price: "",
		additionsSelected: additions,
		takeaway: !!takeaway,
		isTakeaway: tk,
		...tmplparams()
	}).appendTo(modal2);

	function additionChangedHandler2(e) {
		const $optionCategory = $(e.currentTarget).parents(".optionCategory");
		var optionCategoryMin = $optionCategory.attr("min");
		//optionCategoryMin = optionCategoryMin || 0;
		var optionCategoryMax = $optionCategory.attr("max");
		//optionCategoryMax = optionCategoryMax || 1000000;
		const optionCategoryType = $optionCategory.attr("type");

		if (optionCategoryType === "separate" && (optionCategoryMin || optionCategoryMax)) {
			var count = 0;
			$optionCategory.find("li.active input.addition-quantity").each((ind, a) => count += Number(a.value));
			$optionCategory.find("li:not(.active)").removeClass("disabled");
			modal.find("button#select").removeClass("disabled");
			if (optionCategoryMax && count >= optionCategoryMax) {
				$optionCategory.find("li:not(.active)").addClass("disabled");
			}
			if ((optionCategoryMin || optionCategoryMin == 0) && count < optionCategoryMin) {
				modal.find("button#select").addClass("disabled");
			}
		}
	}

	function additionChangedHandler(e) {
		var li = $($(e.currentTarget).parents('li')[0]);
		if (li.hasClass('active')) {
			var n = li.find('input#quantity');
			if (li.find('input#quantity').data('cleave')) {
				li.find('input#quantity').data('cleave').onChange();
			}
			var lis = $(li.parent().find('li[menuitem=' + li.attr('menuitem') + ']'));
			if (lis.length > 0)
				$(lis).each(function (ind, l) {
					if (l == li[0])
						return;
					$(l).hide();
					$(l).removeClass('active');
				});
			select(li);
			n.focus();
			n.select();
		} else {
			li.parent().find('li[menuitem=' + li.attr('menuitem') + ']').show();
			var quantity = li.attr('count');
			li.find('input#quantity').val(quantity);
			li.find('span.quantity').text(quantity);
			li.find('span.quantity').attr('quantity', quantity);
		}
	}

	function additionChangedHandler3(e) {
		var li = $($(e.currentTarget).parents('li')[0]);

		const menuitem = getMenuItem(li.attr("menuitem"));

		const adds = [];

		$(modal).find('.additionsBlock div.optionCategory').each(function (ind, optionCategory) {
			const optionType = $(optionCategory).attr('type');
			var o = false;
			$(optionCategory).find('input[type="checkbox"],input[type="radio"]').each(function (ind, a) {
				var addition = optionType === "separate" ? $(a).parent().attr('id') : $(a).attr('id').substring(7);
				var quantity = addition.split("-")[1];
				addition = addition.split("-")[0];
				if (optionType === "separate") {
					a = $(a).parent();
					quantity = $(a).find('input').val();
					if (quantity === "on")
						quantity = 1;
					var originalQuantity = $(a).attr('originalQuantity');
					if ($(a).hasClass('included') && !$(a).hasClass('active')) {
						adds.push(addition + "-" + quantity + "-" + false);
					} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
						adds.push(addition + "-" + quantity + "-" + false);
					} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
						adds.push(addition + "-" + quantity + "-" + true);
					}
				} else if ($(a).prop('checked') && !$(a).hasClass('included')) {
					adds.push(addition + "-" + quantity + "-" + true);
				}
			});
			$(optionCategory).find('input.included:not(:checked)').each(function (ind, a) {
				var addition = ($(optionCategory).attr('type') === "separate" ? $(a).parent().attr('id') : $(a).attr('id')).substring(7);
				var quantity = addition.split("-")[1];
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + true);
			});
		});
		$(modal.find('.additionsBlock > ul > li')).each(function (ind, a) {
			var addition = $(a).attr('id').split('-')[0];
			var quantity = $(a).find('input').val();
			var originalQuantity = $(a).attr('originalQuantity');
			if ($(a).hasClass('included') && !$(a).hasClass('active')) {
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + false);
			} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
				adds.push(addition.split("-")[0] + "-" + Math.abs(quantity - originalQuantity) + "-" + true);
			} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + true);
			}
		});

		timing = $(modal.find('label.timing.active')).attr('id') ? $(modal.find('label.timing.active')).attr('id').substring(7) : "asap";
		var quantity2 = menuItem.freeQuantity ? modal.find('input#freeQuantity').val() :
			$(modal.find('label.quantity.active input')).length == 0 ? Number(modal.find('input#quantity').val()) : $(modal.find('label.quantity.active input')).attr('id').substring(6);

		orderMenuItem(item, entityType, id, quantity2, force, mate, timing, comment, adds, price, defaultProductionLines, defaultQuantityType, takeaway, true);

	}

	if (updated) {
		replaceContent(modal.find("#additions"), modal2.find("#additions"));
	}


	modal.find('.selectable').unbind('click', clickHandler222);
	modal.find('.selectable').click(clickHandler222);

	modal.find('.selectable').focusin(clickHandler222);

	select(modal.find('.selectable[level=2]').get(0));

	modal.find('#additionSearch').unbind('keyup');
	modal.find('#additionSearch').keyup(function (e) {
		additionSearchChanged(e, menuItem);
	});

	$('#orderMenuItem.modal .optionCategory .list-group input').off('change');
	$('#orderMenuItem.modal .addition.list-group-item:not(.disabled) input').off("change");


	updateCheckBoxesById('#orderMenuItem.modal .mate.list-group-item:not(.disabled)', additionChangedHandler);
	updateCheckBoxesById('#orderMenuItem.modal .addition.list-group-item:not(.disabled)', additionChangedHandler);
	$('#orderMenuItem.modal .addition.list-group-item:not(.disabled) input').on("change", additionChangedHandler2);
	$('#orderMenuItem.modal .optionCategory .list-group input').on('change', additionChangedHandler3);

	if (updated) {
		return;
	}

	modal.find('.keyboard').hide();

	modal.find("button#takeaway").on("click", () => {
		//modal.hide();
		orderMenuItem(item, entityType, id, quantity, force, mate, timing, comment, additions, price, defaultProductionLines, defaultQuantityType, !takeaway);
	})

	modal.find('#comment').tokenize2({
		placeholder: I18n.t("local.enter_comment"),
		tokensAllowCustom: true,
		searchMinLength: 0
	});

	if (modal.find('#freeQuantity').length) {
		new Cleave(modal.find('#freeQuantity'), {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: true,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		});
		modal.find('#freeQuantity').val(quantity);
	}

	modal.data('quantity', quantity);

	var previousSelection = item;

	function c(c) {
		c.preventDefault();
		c.stopPropagation();
		window.history.pushState('', null, '');
		window.history.back();
		window.history.pushState('', null, '');
		$("#close").trigger('click');
	}
	modal.off('hidden.bs.modal');
	modal.on('hidden.bs.modal', function () {
		select(previousSelection);
		$('#editTable').find('.scrollable-y').removeClass('modal');
		$('#editTable').find('.scrollable-y').removeClass('modal-open');
		modal.empty();

		if (window.history.state === "orderMenuItem") {
			window.history.back();
		}
		$(window).off('popstate', c);

	});
	modal.off('shown.bs.modal');
	modal.on('shown.bs.modal', function () {
		$('#editTable').find('.scrollable-y').addClass('modal-open');

		window.history.pushState('orderMenuItem', null, '');
		$(window).on('popstate', c);

	});

	// updateToolTips();
	modal.modal('show', {
		keyboard: false
	});

	if (menuItem.freeQuantity) {
		modal.find("#freeQuantity").trigger('focus');
	} else {
		modal.find('#additionSearch').trigger('focus');
	}


	var clickHandler = function (e, f) {
		auth.vibrateOk();
		if ($(this).attr('disabled'))
			return;
		avoidMultipleClick(this, e);
		e.key = "Enter";
		e.preventDefault();
		additionSearchChanged(e, menuItem);
		var comment = "";
		const event = new Event('click');
		modal.find('#comment').tokenize2().pressedDelimiter(event);
		modal.find('#comment').tokenize2().toArray().forEach(function (com) {
			comment = comment != "" ? (comment + ";" + com) : com;
		});
		var timing = $(modal.find('label.timing.active')).attr('id') ? $(modal.find('label.timing.active')).attr('id').substring(7) : "asap";
		var quantity2 = menuItem.freeQuantity ? modal.find('input#freeQuantity').val() :
			$(modal.find('label.quantity.active input')).length == 0 ? Number(modal.find('input#quantity').val()) : $(modal.find('label.quantity.active input')).attr('id').substring(6);

		if (quantity2 == quantity)
			quantity = modal.data('quantity');
		else
			quantity = quantity2;

		if (quantity == 0 || isNaN(quantity)) {
			auth.vibrateOk(1000);
			modal.find('input#quantity').removeClass('highlight-3');
			setTimeout(function () {
				modal.find('input#quantity').addClass('highlight-3');
				modal.find('input#quantity').focus();
			})
			return;
		}
		var unit_price = modal.find('input#price').length == 1 ? Number(modal.find('input#price').val()) : undefined;
		if (unit_price != undefined && (unit_price == 0 || isNaN(unit_price))) {
			auth.vibrateOk(1000);
			modal.find('input#price').removeClass('highlight-3');
			setTimeout(function () {
				modal.find('input#price').addClass('highlight-3');
				modal.find('input#price').focus();
			})
			return;
		}

		if (tableMateChoices.length == 0) {
			console.error("Trying to order without table occupation: " + localStorage.tableOccupationSelected, "");
		}

		var ids = tableMateChoices.length == 0 ? [auth.myStatus.tableMate.id] : [];

		var productionLines = [];
		modal.find('#priceBlock .list-group-item.active').each(function (ind, t) {
			productionLines.push({ id: $(t).attr('id') })
		});

		var count = 0;
		if (tableMateChoices.length > 0) {
			modal.find('#matesBlock .list-group-item.active').each(function (ind, t) {
				var c = $(t).attr('count');
				if (c > 30 && !f) {
					messageDialog(I18n.t("local.confirmation"), String.format(I18n.t("admin_local.you_cannot_order_so_many"), c));
					return;
				}
				if (c > 10 && !f) {
					confirmDialog(I18n.t("local.information"), String.format(I18n.t("admin_local.do_you_realy_want_to_order_so_many"), c)).done(() => {
						clickHandler(e, true);
					});
					return;
				}
				if (c < 1)
					quantity = -quantity;
				for (let i = 0; i < Math.abs(c); i++) {
					ids[count] = $(t).attr('id');
					count = count + 1;
				}
			});
		} else {
			ids = [localStorage.id];
			count = 1;
		}

		const tempId = "" + new Date().getTime() + Math.round(Math.random() * 10000);

		var order = {
			menuItemId: id,
			tempId: tempId,
			quantity: quantity,
			comment: comment,
			timing: timing,
			tableMateIds: ids,
			additions: [],
			price: unit_price,
			productionLines: productionLines,
			takeaway: takeaway,
			date: date
		}



		var ok = true;
		$(modal).find('.additionsBlock div.optionCategory').each(function (ind, optionCategory) {
			const optionType = $(optionCategory).attr('type');
			var o = false;
			$(optionCategory).find('input[type="checkbox"],input[type="radio"]').each(function (ind, a) {

				var addition = optionType === "separate" ? $(a).parent().attr('id') : $(a).attr('id').substring(7);
				var quantity = addition.split("-")[1];
				addition = addition.split("-")[0];
				if (optionType === "separate") {
					o = true;
					a = $(a).parent();
					quantity = $(a).find('input').val();
					if (quantity === "on")
						quantity = 1;
					var originalQuantity = $(a).attr('originalQuantity');
					if ($(a).hasClass('included') && !$(a).hasClass('active')) {
						order.additions.push({ menuItemId: addition, addition: false, quantity: quantity });
						o = true;
					} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
						order.additions.push({ menuItemId: addition, addition: quantity > originalQuantity, quantity: Math.abs(quantity - originalQuantity) });
						o = true;
					} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
						order.additions.push({ menuItemId: addition, addition: true, quantity: quantity });
						o = true;
					}
				} else if ($(a).prop('checked') && !$(a).hasClass('included')) {
					order.additions.push({ menuItemId: addition.split("-")[0], addition: true, quantity: quantity });
					o = true;
				}
			});
			$(optionCategory).find('input.included:not(:checked)').each(function (ind, a) {
				var addition = ($(optionCategory).attr('type') === "separate" ? $(a).parent().attr('id') : $(a).attr('id')).substring(7);
				var quantity = addition.split("-")[1];
				order.additions.push({ menuItemId: addition.split("-")[0], addition: false, quantity: quantity });
				o = true;
			});
			$(optionCategory).find('input.included:checked').each(function (ind, a) {
				o = true;
			});
			if (optionType === "maxone") {
				o = true;
			}
			if (!o) {
				auth.vibrateOk(1000);
				$(optionCategory).removeClass('highlight-3');
				setTimeout(function () {
					$(optionCategory).addClass('highlight-3');
				}, 100);
			}

			ok = ok && o;
		});
		if (!ok) {
			return;
		}
		$(modal.find('.additionsBlock > ul > li')).each(function (ind, a) {
			var addition = $(a).attr('id').split('-')[0];
			var quantity = $(a).find('input').val();
			var originalQuantity = $(a).attr('originalQuantity');
			if ($(a).hasClass('included') && !$(a).hasClass('active'))
				order.additions.push({ menuItemId: addition, addition: false, quantity: quantity });
			else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity))
				order.additions.push({ menuItemId: addition, addition: quantity > originalQuantity, quantity: Math.abs(quantity - originalQuantity) });
			else if ($(a).hasClass('optional') && $(a).hasClass('active'))
				order.additions.push({ menuItemId: addition, addition: true, quantity: quantity });
		});
		var newOrderIds = "";
		if (count > 0) {
			var timestamp = new Date().getTime();
			order.tableMateIds.forEach((mate, ind) => {
				const maxOrderId = orderListUtil.newOrders.reduce((a, b) => Math.min(a, b.id), 0);
				var fullPrice = getPriceForQuantity(menuItem, quantity);
				const childOrders = order.additions.map(addition => {
					return {
						addition: addition.addition,
						menuItem: { id: addition.menuItemId, entityType: getMenuItem(addition.menuItemId).entityType },
						quantity: addition.quantity,
						fullPrice: getPriceForQuantity(getMenuItem(addition.menuItemId), addition.quantity),
						parentOrderId: maxOrderId - 1,
						tempId: (tempId + ind + order.menuItemId + addition.menuItemId),
						childOrders: []
					}
				})
				var cremovals = 0;
				var cadditions = 0;
				childOrders.forEach(a => {
					if (a.addition)
						cadditions += a.fullPrice;
					else
						cremovals += a.fullPrice;
				})
				if (cadditions > cremovals)
					fullPrice += cadditions - cremovals;
				else if (cadditions == 0 && cremovals > 0 && !menuItem.minimumPrice) {
					fullPrice += cadditions - cremovals;
				} else {
					childOrders.forEach(c => c.fullPrice = 0)
				}
				orderListUtil.newOrders.push({
					addition: true,
					childOrders: childOrders,
					comment: order.comment,
					created: timestamp,
					defaultProductionLinesIds: '',
					discount: 0,
					discountComment: '',
					forWhomId: Number(mate),
					id: maxOrderId - 1,
					tempId: order.tempId + ind,
					inStateTimeStamp: timestamp,
					menuItem: { id: order.menuItemId },
					modificationTimeStamp: timestamp,
					orderSequenceNumber: orderListUtil.currentOrderSequenceNumber,
					orderedById: Number(localStorage.id),
					ordersInState: [],
					paid: false,
					price: null,
					fullPrice: fullPrice,
					printState: {},
					printed: false,
					productionLines: [],
					quantity: order.quantity,
					recordState: 'ACTIVE',
					restaurantComment: '',
					state: state,
					statePerProductionLine: {},
					tableMateDiscount: 0,
					tableNumber: Number(localStorage.tableNumberSelected),
					tableOccupationId: localStorage.tableOccupationSelected,
					timing: order.timing,
					wasSelectedForPayment: false,
					takeaway: !!takeaway,
					tableMateIds: [mate],
					additions: order.additions,
					productionLines: (typeof defaultProductionLines == 'undefined' ? [] : defaultProductionLines),
					quantityType: (typeof defaultQuantityType == 'undefined' ? undefined : defaultQuantityType),
					menuItemId: id,
					tableMateIds: [mate],
					date: date
				});


				newOrderIds += orderListUtil.newOrders[orderListUtil.newOrders.length - 1].id + ",";
			});
			modal.modal('hide');
			$('.modal-backdrop').hide();
			modal.hide();
			updateTableOccupations([], orderListUtil.newOrders);

			const channel = isPickup() ? "console_pickup" : isHomeDelivery() ? "console_homedelivery" : "console";
			const sendit = () => post("adminService/" + localStorage.restaurantSelected + "/new" + entityType + "Orders" + "?tableOccupation=" + localStorage.tableOccupationSelected + "&tableNumber="
				+ localStorage.tableNumberSelected + "&lang=" + localStorage.language + "&state=" + state + "&timestamp=" + timestamp + "&channel=" + channel, order, undefined, false, 1, true, 10000).done(function (data) {
					updateTableOccupations([data.tableOccupation], data.orders).done(function () {
						// tableLayout.updateTableOccupations();
						// updateOrders(data.orders);
						// gotoTop();
						var orderId = data.orders[0].id;
						var v = $('#editTable div#byCustomer.tab-pane.container');
						v = v.find('div.draggable.byCustomer[id*="-' + orderId + '"]');
						if (v.length > 0) {
							$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
							$(v).addClass('highlight-3')
						}
						var v = $('#editTable div#byTiming.tab-pane.container');
						v = v.find('div.draggable.byTiming[id*="-' + orderId + '"]');
						if (v.length > 0) {
							$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
							$(v).addClass('highlight-3')
						}
						var v = $('#editTable table.orderTable');
						v = v.find("tr[orderid*='" + orderId + ",']");
						if (v.length > 0) {
							$(v.parents('div.freeze-orderTable').get(0)).scrollTo(v.get(0));
							_show($(v));
							$(v).addClass('highlight-3')
						}
					});
				}).fail((data) => {
					auth.ajaxError = false;
					auth.vibrateOk(2000);
					choiceDialog2(I18n.t("local.error_message"), String.format(I18n.t("admin_local.failed_to_send_order"), getLocale(menuItem.name)), [I18n.t("admin_local.resend")]).done(() => {
						sendit();
					}).fail(() => {
						deleteOrders(newOrderIds, true);
						updateTableOccupations([], []);
					})
				});
			if (order.id > 0 || state !== "selected" || !auth.delayedOrder())
				sendit();
			else {
				var orderId = newOrderIds.split(",")[0];
				var v = $('#editTable div#byCustomer.tab-pane.container');
				v = v.find('div.draggable.byCustomer[id*="-' + orderId + '"]');
				if (v.length > 0) {
					$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
					$(v).addClass('highlight-3')
				}
				var v = $('#editTable div#byTiming.tab-pane.container');
				v = v.find('div.draggable.byTiming[id*="-' + orderId + '"]');
				if (v.length > 0) {
					$(v.parents('div.scrollable-y').get(0)).scrollTo(v.get(0));
					$(v).addClass('highlight-3')
				}
				var v = $('#editTable table.orderTable');
				v = v.find("tr[orderid*='" + orderId + ",']");
				if (v.length > 0) {
					$(v.parents('div.freeze-orderTable').get(0)).scrollTo(v.get(0));
					_show($(v));
					$(v).addClass('highlight-3')
				}
			}
		} else {
		}
	}
	if (modal.find('button#select').length) {
		if (window.location.pathname.indexOf("/drinks") >= 0 || window.location.pathname.indexOf("/meals") >= 0)
			return;
		if (item) {
			modal.find('button#select').click(clickHandler);
			modal.find('button#select').get(0).addEventListener("touchend", clickHandler, true);
		}
	}

}

export const switchKeyboard = () => {
	if ($('#orderMenuItem').is(':visible')) {
		if ($('#orderMenuItem .keyboard').is(':visible')) {
			$('#orderMenuItem .keyboard').hide();
			$('#commentDiv input').prop('readonly', false);
			$('.numpad').show();
			$('.additionsBlock').removeClass('wide')
		} else {
			$('#orderMenuItem .keyboard').show();
			$('#commentDiv input').prop('readonly', true);
			$('.numpad').hide();
			$('.additionsBlock').addClass('wide');
		}
	} else if ($('#chooseCustomer').is(':visible')) {
		if ($('#chooseCustomer .keyboard').is(':visible')) {
			$('#chooseCustomer .keyboard').hide();
		} else {
			$('#chooseCustomer .keyboard').show();
		}
	}

}

export const orderAnother = (ids) => {
	auth.vibrateOk();
	var orderId = ids.split(',')[0];
	var order = getOrderById(orderId);
	var additions = [];
	$(order.childOrders).each(function (ind, a) {
		additions.push({ menuItemId: a.menuItem.id, addition: a.addition, quantity: a.quantity });
	});

	orderMenuItem(null, getMenuItem(order.menuItem.id).entityType, order.menuItem.id, order.quantity, true, order.forWhomId, order.timing, order.comment, additions, order.customPrice ? order.price / order.quantity : null, order.customPrice ? order.defaultProductionLines : undefined, order.customPrice ? order.quantityType : undefined, order.takeaway, false, true);
}

export const deleteOne = (orderIds) => {
	auth.vibrateOk();
	orderIds = orderIds.split(',')[0];

	if (orderIds < 0) {
		deleteOrders([orderIds]);
		return;
	}

	cancelOrder(orderIds, orderListUtil.statesShown, function (data) {
		if (data == null) {
			console.log("Failed to cancel orders");
		} else {
			deleteOrders(data.deletedOrders, true);
			updateTableOccupations([data.tableOccupation], data.orders);
			// tableLayout.updateTableOccupations();
			// updateOrders(data.orders);
		}
	});
}

export const editOrder = (item, event, _takeaway, additionsSelectedUpdated, updated = false) => {
	auth.vibrateOk();
	if (event.button && event.button != 0)
		return
	var v1 = $($(item).parents('tr').get(0));
	if (v1.length > 0)
		_show(v1);
	if (orderListUtil.dragged || orderListUtil.drag)
		return;
	orderListUtil.orderEditorVisible = true;
	if ($(item).hasClass('selectable') && !$(item).hasClass('selected'))
		return;
	var ids = $(item).attr('orderIds') ? $(item).attr('orderIds').split(',') : $(item).attr('orders') ? $(item).attr('orders').split(',') : $(item).attr('orderid').split(',');
	var newOrder = $(item).parents('tr').hasClass('newOrder');
	/*if (newOrder) {
		return;
	}*/
	var orders = [];
	var orderIds = [];
	var mateIds = new Map();
	var timing;
	var quantity;
	var quantityType;
	var menuItem;
	var price;
	var additionsSelected = new Set();
	var can_be_changed = true;
	var can_be_deleted = true;
	var quantityLabel = "";
	var comment = "";
	var productionLines = [];
	var orderPaid = false;
	var orderInPayingState = false;
	var orderWasInPayingState = false;
	var takeaway = typeof _takeaway !== "undefined" ? _takeaway : false;
	var isTakeaway = undefined;
	ids.forEach(function (id, ind) {
		if (id != '') {
			var order = orderListUtil.getOrderById(id);
			isTakeaway = _takeaway || (order && order.takeaway) || getTableOccupation(order.tableOccupationId).takeaway;
			if (typeof order !== undefined) {

				if (ind === 0)
					auth.myStatus.restaurant_production_lines.forEach(function (pl) {
						var pl = Object.assign({}, pl);
						pl.selected = false;
						if (order.defaultProductionLinesIds != null)
							order.defaultProductionLinesIds.split(",").forEach(function (p) {
								if (Number(p) === Number(pl.id))
									pl.selected = true;
							});
						productionLines.push(pl);
					});

				can_be_changed = can_be_changed && (order.state === 'selected' || order.state === 'ordered' || order.state === 'accepted') && !order.paid;
				var mate = getTableMate(getTableMate(order.forWhomId).payingMateId);

				orderPaid = orderPaid || order.paid;
				orderInPayingState = orderInPayingState || mateInState(mate, 'wantsToPay');
				orderWasInPayingState = orderWasInPayingState || order.wasSelectedForPayment;

				can_be_deleted = can_be_deleted && !order.paid && !mateInState(mate, 'wantsToPay') && (!order.wasSelectedForPayment || isAdmin());
				comment = order.comment;
				orderIds.push(Number(id));
				orders.push(order);
				if (mateIds.has(order.forWhomId))
					mateIds.set(order.forWhomId, Number(mateIds.get(order.forWhomId)) + 1);
				else
					mateIds.set(order.forWhomId, 1);
				timing = order.timing;
				quantity = order.quantity;
				if (typeof _takeaway === "undefined")
					takeaway |= order.takeaway;
				menuItem = getMenuItem(order.menuItem.id);
				price = menuItem.availableQuantities.length == 0 ? order.price / order.quantity : undefined;
				quantityType = order.quantityType;
				menuItem.availableQuantities.forEach(function (q) {
					if (q.quantity == quantity)
						quantityLabel = q.label;
				});
				var possibleAdditions = getMenuItemAdditions(menuItem, '', 'included', isTakeaway);

				order.childOrders.forEach(function (childOrder) {

					var q = Number(childOrder.quantity);

					possibleAdditions.forEach(function (addition) {
						if (addition.id == childOrder.menuItem.id && childOrder.addition) {
							q += Number(addition.value.split('-')[1]);
						}
					});

					additionsSelected.add(childOrder.menuItem.id + "-" + q + "-" + childOrder.addition);
				});
			}
		}
	});

	//var modal = $('#editMenuItemDialog');
	//modal.empty();
	var tableMateChoices = [];
	admin.tableMates.forEach(function (tableMateId) {
		var tableMate = getTableMate(tableMateId);
		if (tableMate.tableOccupationId == localStorage.tableOccupationSelected && tableMate.tableNumber == localStorage.tableNumberSelected) {
			const t = { ...tableMate, count: 1 }
			tableMateChoices.push(t);
			if (mateIds.has(tableMate.id)) {
				t.selected = true;
				t.count = mateIds.get(tableMate.id);
			} else {
				t.selected = false;
			}
		}
	});


	const additionsSelectedArray = additionsSelectedUpdated ? additionsSelectedUpdated : [];
	if (!additionsSelectedUpdated)
		additionsSelected.forEach(a => additionsSelectedArray.push(a));

	$('body > .chooseOption').remove();
	var modal = $('#editMenuItemDialog');
	var modal2 = updated ? $('<div/>') : modal;

	if (!updated) {
		modal.empty();
	}

	$('#chooseMates').tmpl({
		mode: 'edit',
		can_be_deleted: can_be_deleted && can_be_changed,
		can_be_changed: can_be_changed,
		tableMates: tableMateChoices,
		menuItemChosen: menuItem,
		timingSelected: timing,
		quantitySelected: quantity,
		quantityLabel: quantityLabel,
		quantity: Number(quantity),
		productionLines: productionLines,
		price: auth.round(price),
		keyboard: false,
		takeaway: takeaway, // the order is takeaway
		isTakeaway: isTakeaway, // the order or the tableOccupation is takeaway
		backdrop: 'static',
		additionsSelected: additionsSelectedArray,
		...tmplparams()
	}).appendTo(modal2);

	//if (quantity)
	//	$("#freeQuantity").val(quantity);

	function additionChangedHandler2(e) {
		const $optionCategory = $(e.currentTarget).parents(".optionCategory");
		var optionCategoryMin = $optionCategory.attr("min");
		//optionCategoryMin = optionCategoryMin || 0;
		var optionCategoryMax = $optionCategory.attr("max");
		//optionCategoryMax = optionCategoryMax || 1000000;
		const optionCategoryType = $optionCategory.attr("type");

		if (optionCategoryType === "separate" && (optionCategoryMin || optionCategoryMax)) {
			var count = 0;
			$optionCategory.find("li.active input.addition-quantity").each((ind, a) => count += Number(a.value));
			$optionCategory.find("li:not(.active)").removeClass("disabled");
			modal.find("button#select").removeClass("disabled");
			if (optionCategoryMax && count >= optionCategoryMax) {
				$optionCategory.find("li:not(.active)").addClass("disabled");
			}
			if ((optionCategoryMin || optionCategoryMin == 0) && count < optionCategoryMin) {
				modal.find("button#select").addClass("disabled");
			}
		}
	}

	function additionChangedHandler(e) {
		var li = $($(e.currentTarget).parents('li')[0]);
		if (li.hasClass('active')) {
			var n = li.find('input#quantity');
			if (li.find('input#quantity').data('cleave'))
				li.find('input#quantity').data('cleave').onChange();
			n.focus();
			n.select();
			const lll = li.parent().find('li[menuitem=' + li.attr('menuitem') + '][id != ' + li.attr("id") + ']');
			lll.hide();
			lll.removeClass('active');
			li.show();
		} else {
			li.parent().find('li[menuitem=' + li.attr('menuitem') + ']').show();
			var quantity = li.attr('count');
			li.attr('v', quantity);
			li.find('input#quantity').val(quantity);
			if (li.find('input#quantity').data('cleave'))
				li.find('input#quantity').data('cleave').onChange();
			li.find('span.quantity').text(quantity);
			li.find('span.quantity').attr('quantity', quantity);
		}
	}

	function additionChangedHandler3(e) {
		var li = $($(e.currentTarget).parents('li')[0]);

		const menuitem = getMenuItem(li.attr("menuitem"));

		const adds = [];

		$(modal).find('.additionsBlock div.optionCategory').each(function (ind, optionCategory) {
			const optionType = $(optionCategory).attr('type');
			var o = false;
			$(optionCategory).find('input[type="checkbox"],input[type="radio"]').each(function (ind, a) {
				var addition = optionType === "separate" ? $(a).parent().attr('id') : $(a).attr('id').substring(7);
				var quantity = addition.split("-")[1];
				addition = addition.split("-")[0];
				if (optionType === "separate") {
					a = $(a).parent();
					quantity = $(a).find('input').val();
					if (quantity === "on")
						quantity = 1;
					var originalQuantity = $(a).attr('originalQuantity');
					if ($(a).hasClass('included') && !$(a).hasClass('active')) {
						adds.push(addition + "-" + quantity + "-" + false);
					} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
						adds.push(addition + "-" + quantity + "-" + false);
					} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
						adds.push(addition + "-" + quantity + "-" + true);
					}
				} else if ($(a).prop('checked') && !$(a).hasClass('included')) {
					adds.push(addition + "-" + quantity + "-" + true);
				}
			});
			$(optionCategory).find('input.included:not(:checked)').each(function (ind, a) {
				var addition = ($(optionCategory).attr('type') === "separate" ? $(a).parent().attr('id') : $(a).attr('id')).substring(7);
				var quantity = addition.split("-")[1];
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + true);
			});
		});
		$(modal.find('.additionsBlock > ul > li')).each(function (ind, a) {
			var addition = $(a).attr('id').split('-')[0];
			var quantity = $(a).find('input').val();
			var originalQuantity = $(a).attr('originalQuantity');
			if ($(a).hasClass('included') && !$(a).hasClass('active')) {
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + false);
			} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
				adds.push(addition.split("-")[0] + "-" + Math.abs(quantity - originalQuantity) + "-" + true);
			} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
				adds.push(addition.split("-")[0] + "-" + quantity + "-" + true);
			}
		});

		timing = $(modal.find('label.timing.active')).attr('id') ? $(modal.find('label.timing.active')).attr('id').substring(7) : "asap";
		var quantity2 = menuItem.freeQuantity ? modal.find('input#freeQuantity').val() :
			$(modal.find('label.quantity.active input')).length == 0 ? Number(modal.find('input#quantity').val()) : $(modal.find('label.quantity.active input')).attr('id').substring(6);

		editOrder(item, event, _takeaway, adds, true)

	}

	if (updated) {
		replaceContent(modal.find("#additions"), modal2.find("#additions"));
	}

	modal.find('.selectable').unbind('click', clickHandler222);
	modal.find('.selectable').click(clickHandler222);

	modal.find('.selectable').focusin(clickHandler222);
	$('#orderMenuItem.modal .optionCategory .list-group input').off('change');
	$('#orderMenuItem.modal .addition.list-group-item:not(.disabled) input').off("change");

	select(modal.find('.selectable[level=2]').get(0));

	modal.find('#additionSearch').unbind('keyup');
	modal.find('#additionSearch').keyup(function (e) {
		additionSearchChanged(e, menuItem);
	});

	updateCheckBoxesById('#orderMenuItem.modal .mate.list-group-item:not(.disabled)', additionChangedHandler);
	updateCheckBoxesById('#orderMenuItem.modal .addition.list-group-item:not(.disabled)', additionChangedHandler);
	$('#orderMenuItem.modal .addition.list-group-item:not(.disabled) input').on("change", additionChangedHandler2);
	$('#orderMenuItem.modal .optionCategory .list-group input').on('change', additionChangedHandler3);

	if (updated) {
		return;
	}

	modal.find('.keyboard').hide();

	modal.find("button#takeaway").on("click", () => {
		modal.hide();
		editOrder(item, event, !takeaway);
	});

	modal.find('#comment').tokenize2({
		placeholder: I18n.t("local.enter_comment"),
		tokensAllowCustom: true,
		searchMinLength: 0
	});
	comment.split(';').forEach(function (comment) {
		modal.find('#comment').trigger('tokenize:tokens:add',
			[comment, comment]);

	});

	var numeralDecimalMark = whatDecimalSeparator();

	if (modal.find('#freeQuantity').length) {
		new Cleave(modal.find('#freeQuantity'), {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: true,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		});
		modal.find('#freeQuantity').val(quantity);
	}


	modal.data('quantity', quantity);

	var previousSelection = item;

	function c(c) {
		c.preventDefault();
		c.stopPropagation();
		window.history.pushState('', null, '');
		window.history.back();
		window.history.pushState('', null, '');
		$("#close").trigger('click');
	}
	modal.off('hidden.bs.modal');
	modal.on('hidden.bs.modal', function () {
		select(previousSelection);
		$('#editTable').find('.scrollable-y').removeClass('modal');
		$('#editTable').find('.scrollable-y').removeClass('modal-open');
		modal.empty();
		if (window.history.state === "orderMenuItem") {
			window.history.back();
		}
		$(window).off('popstate', c);
	});
	modal.off('shown.bs.modal');
	modal.on('shown.bs.modal', function () {
		$('#editTable').find('.scrollable-y').addClass('modal-open');
		window.history.pushState('orderMenuItem', null, '');
		$(window).on('popstate', c);
	});

	// updateToolTips();
	modal.modal('show', {
		keyboard: false
	});
	modal.find('button#delete').click(
		function () {
			if (!can_be_deleted) {
				if (orderPaid) {
					messageDialog(I18n.t("admin_local.deletion_not_allowed"), I18n.t("admin_local.paid_order_cannot_be_deleted"));
				} else if (orderInPayingState) {
					messageDialog(I18n.t("admin_local.deletion_not_allowed"), I18n.t("admin_local.order_cannot_be_deleted_while_mate_is_paying"));
				} else if (orderWasInPayingState) {
					messageDialog(I18n.t("admin_local.deletion_not_allowed"), I18n.t("admin_local.order_cannot_be_deleted_while_order_was_already_selected_for_payment_one"));
				}
				return;
			}
			var comment = "";
			modal.modal('hide');
			if (orderIds[0] < 0 || $(item).parents('tr').hasClass('newOrder')) {
				deleteOrders(orderIds, false);
				updateTableOccupations([], []);
			} else {
				cancelOrder(orderIds, orderListUtil.statesShown, function (data) {
					if (data == null) {
						console.log("Failed to cancel orders");
					} else {
						deleteOrders(data.deletedOrders, true);
						updateTableOccupations([data.tableOccupation], data.orders);
						// tableLayout.updateTableOccupations();
						// updateOrders(data.orders);
					}
				});
			}
		}
	);
	var v = modal.find('button#select');
	modal.find('button#select').click(
		function (e) {
			e.key = "Enter";
			e.preventDefault();
			additionSearchChanged(e, menuItem);
			var comment = "";
			modal.find('#comment').tokenize2().pressedDelimiter(new Event('click'));
			modal.find('#comment').tokenize2().toArray().forEach(function (com) {
				comment = comment != "" ? (comment + ";" + com) : com;
			});
			var timing = $(modal.find('label.timing.active')).attr('id') ? $(modal.find('label.timing.active')).attr('id').substring(7) : "asap";
			var quantity = menuItem.freeQuantity ? modal.find('input#freeQuantity').val() :
				$(modal.find('label.quantity.active input')).length == 0 ? Number(modal.find('input#quantity').val()) : $(modal.find('label.quantity.active input')).attr('id').substring(6);

			//var quantity = $(modal.find('label.quantity.active input')).length == 0 ? Number(modal.find('input#quantity').val()) : $(modal.find('label.quantity.active input')).attr('id').substring(6);

			var unit_price = modal.find('input#price').length == 1 ? Number(modal.find('input#price').val()) * quantity : undefined;
			var quantityType = $(modal.find('label.quantityType.active input')).length == 0 ? undefined : $(modal.find('label.quantityType.active input')).attr('id').substring(12);
			//var ids = tableMateChoices.length == 0 ? [ auth.myStatus.tableMate.id ] : [];
			ids = [];
			var count = 0;

			var productionLines = [];
			modal.find('#priceBlock .list-group-item.active').each(function (ind, t) {
				productionLines.push({ id: $(t).attr('id') })
			});



			if (tableMateChoices.length > 0) {
				modal.find('#matesBlock .list-group-item.active').each(function (ind, t) {
					for (let i = 0; i < $(t).attr('count'); i++) {
						ids[count] = $(t).attr('id');
						count = count + 1;
					}
				});
			} else {
				ids = [localStorage.id];
				count = 1;
			}

			const tempId = "" + new Date().getTime() + Math.round(Math.random() * 10000);

			var order = {
				menuItemId: menuItem.id,
				tempId,
				quantity: quantity,
				comment: comment,
				timing: timing,
				tableMateIds: ids,
				additions: [],
				orderIds: orderIds,
				price: unit_price,
				quantityType: quantityType,
				productionLines: productionLines,
				takeaway: takeaway
			}

			var ok = true;
			$(modal).find('.additionsBlock div.optionCategory').each(function (ind, optionCategory) {

				const optionType = $(optionCategory).attr('type');

				var o = false;
				$(optionCategory).find('input[type="checkbox"],input[type="radio"]').each(function (ind, a) {
					var addition = optionType === "separate" ? $(a).parent().attr('id') : $(a).attr('id').substring(7);
					var quantity = addition.split("-")[1];
					if (quantity === "on")
						quantity = 1;
					addition = addition.split("-")[0];
					if (optionType === "separate") {
						o = true;
						a = $(a).parent();
						quantity = $(a).find('input').val();
						if (quantity === "on")
							quantity = 1;
						var originalQuantity = $(a).attr('originalQuantity');
						if ($(a).hasClass('included') && !$(a).hasClass('active')) {
							order.additions.push({ menuItemId: addition, addition: false, quantity: quantity });
							o = true;
						} else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity)) {
							order.additions.push({ menuItemId: addition, addition: quantity > originalQuantity, quantity: Math.abs(quantity - originalQuantity) });
							o = true;
						} else if ($(a).hasClass('optional') && $(a).hasClass('active')) {
							order.additions.push({ menuItemId: addition, addition: true, quantity: quantity });
							o = true;
						}
					} else if ($(a).prop('checked') && !$(a).hasClass('included')) {
						order.additions.push({ menuItemId: addition.split("-")[0], addition: true, quantity: quantity });
						o = true;
					}
				});
				$(optionCategory).find('input.included:not(:checked)').each(function (ind, a) {
					var addition = $(optionCategory).attr('type') === "separate" ? $(a).parent().attr('id') : $(a).attr('id').substring(7);
					var quantity = addition.split("-")[1];
					order.additions.push({ menuItemId: addition.split("-")[0], addition: false, quantity: quantity });
					o = true;
				});
				$(optionCategory).find('input.included:checked').each(function (ind, a) {
					o = true;
				});
				if ($(optionCategory).attr('type') === "maxone")
					o = true;

				if (!o) {
					auth.vibrateOk(1000);
					$(optionCategory).removeClass('highlight-3');
					setTimeout(function () {
						$(optionCategory).addClass('highlight-3');
					}, 100);
				}
				ok = ok && o;
			});
			if (!ok) {
				return;
			}

			$(modal.find('.additionsBlock > ul > li')).each(function (ind, a) {
				var addition = $(a).attr('id').split('-')[0];
				var quantity = $(a).find('input').val();
				var originalQuantity = $(a).attr('originalQuantity');
				if ($(a).hasClass('included') && !$(a).hasClass('active'))
					order.additions.push({ menuItemId: addition, addition: false, quantity: quantity });
				else if ($(a).hasClass('included') && $(a).hasClass('active') && Number(originalQuantity) != Number(quantity))
					order.additions.push({ menuItemId: addition, addition: quantity > originalQuantity, quantity: Math.abs(quantity - originalQuantity) });
				else if ($(a).hasClass('optional') && $(a).hasClass('active'))
					order.additions.push({ menuItemId: addition, addition: true, quantity: quantity });
			});

			if (count > 0) {
				if (order.orderIds.filter(o => o < 0).length === 0) {
					post("adminService/" + localStorage.restaurantSelected + "/changeOrders" + "?tableOccupation=" + localStorage.tableOccupationSelected + "&tableNumber="
						+ localStorage.tableNumberSelected + "&lang=" + localStorage.language, order).done(function (data) {
							modal.modal('hide');
							modal.hide();
							data.orders.forEach(function (order) {
								var index = orderIds.indexOf(order.id);
								if (index >= 0)
									orderIds.splice(index, 1);
							});
							deleteOrders(orderIds, true);
							update(data);
							/*
							updateTableOccupations([data.tableOccupation], );
							tableLayout.updateTableOccupations();
							updateOrders(data.orders);
							*/
							var orderId = data.orders[0].id;
							if (localStorage.atTheTableSelectedTab == 'byCustomer') {
								var v = $('table.byCustomer');
								v = v.find('div.draggable.byCustomer[order=' + orderId + ']');
								v = v.get(0);
								if (v != undefined) {
									// $('div.orderScrollable').scrollTo(v);
									v.scrollIntoView();
									$(v).addClass('highlight-3')
								}
							}

						});
				} else {
					orders.forEach((o, ind) => {
						if (ind >= order.tableMateIds.length) {
							console.log("delete:" + o.id);
							deleteOrders([o.id]);
						} else {
							orderListUtil.newOrders = orderListUtil.newOrders.filter(oo => oo.id !== o.id);
							var fullPrice = getPriceForQuantity(menuItem, quantity);
							const childOrders = order.additions.map(addition => {
								return {
									addition: addition.addition,
									tempId: (tempId + ind + order.menuItemId + addition.menuItemId),
									menuItem: { id: addition.menuItemId, entityType: getMenuItem(addition.menuItemId).entityType },
									quantity: addition.quantity,
									fullPrice: getPriceForQuantity(getMenuItem(addition.menuItemId), addition.quantity),
									parentOrderId: o.id
								}
							})
							childOrders.forEach(a => {
								fullPrice += (a.addition ? 1 : -1) * a.fullPrice;
							})
							order.childOrders = childOrders;
							order.menuItem = { id: order.menuItemId };
							order.fullPrice = fullPrice;
							orderListUtil.newOrders.push({ ...o, ...order, modificationTimeStamp: new Date().getTime(), tempId: (tempId + order.menuItemId + ind), tableMateIds: [order.tableMateIds[0]] });
						}
					})
					for (let i = orders.length; i < order.tableMateIds.length; i++) {
						const maxOrderId = orderListUtil.newOrders.reduce((a, b) => Math.min(a, b.id), 0);
						var fullPrice = getPriceForQuantity(menuItem, quantity);
						const childOrders = order.additions.map(addition => {
							return {
								addition: addition.addition,
								tempId: (tempId + order.menuItemId + addition.menuItemId),
								menuItem: { id: addition.menuItemId, entityType: getMenuItem(addition.menuItemId).entityType },
								quantity: addition.quantity,
								fullPrice: getPriceForQuantity(getMenuItem(addition.menuItemId), addition.quantity),
								parentOrderId: orders[0].id
							}
						})
						childOrders.forEach(a => {
							fullPrice += (a.addition ? 1 : -1) * a.fullPrice;
						})
						order.childOrders = childOrders;
						order.menuItem = { id: order.menuItemId };
						order.fullPrice = fullPrice;
						orderListUtil.newOrders.push({ ...orders[0], ...order, modificationTimeStamp: new Date().getTime(), id: maxOrderId - 1, tempId: (tempId + order.menuItemId + i), tableMateIds: [order.tableMateIds[0]] });
					}
					console.log(orderListUtil.newOrders);
					updateTableOccupations([], orderListUtil.newOrders);
					modal.modal('hide');
					modal.hide();
				}
			}
		});

}


export const additionSearchChanged = (e, menuitem) => {
	var modal = $(".modal#orderMenuItem");
	var additionalSearch = modal.find('input#additionSearch')
	if (additionalSearch.length == 0)
		return;
	var search = additionalSearch.val();
	if (search == "") {
		filterAdditions(search);
		return;
	}
	if (search.match(/^-?(\d+)\*$/)) {
		var count = search.substring(0, search.length - 1);
		var person = modal.find('.selectable.mate.list-group-item.active.selected');
		if (person.length == 0) {
			person = modal.find('.selectable.mate.list-group-item.active');
		}
		if (person.length == 1) {
			person.attr('count', count);
			person.find('span.times').text("x " + count);
			auth.vibrateOk(1000);
			person.addClass('highlight-3');
		}
		filterAdditions("");
		additionalSearch.val("");
		return;
	}
	if (search.match(/^(\d+)\+$/)) {
		var count = search.substring(0, search.length - 1);
		var timing = modal.find('#timingBlock label.timing:nth-child(' + count + ')').click();
		timing.addClass('highlight-3');
		auth.vibrateOk(1000);
		filterAdditions("");
		additionalSearch.val("");
		return;
	}
	if (search.match(/^-?(\d+(.\d+)?)$/) && e.key == "Enter") {
		var count = search.substring(0, search.length);
		if (menuitem.availableQuantities.length == 1 && (menuitem.availableQuantities[0].fake == true || (menuitem.availableQuantities[0].onMenu == false && menuitem.availableQuantities[0].inventoryItem == true))) {
			modal.data('quantity', count);
		} else {
			count = Math.round(count);
			var person = modal.find('.selectable.mate.list-group-item.active.selected');
			if (person.length == 0) {
				person = modal.find('.selectable.mate.list-group-item.active');
			}
			if (person.length == 1) {
				person.attr('count', count);
				person.find('span.times').text("x " + count);
				auth.vibrateOk(1000);
				person.addClass('highlight-3');
			}
		}
		filterAdditions("");
		additionalSearch.val("");
		if (!e.isDefaultPrevented || !e.isDefaultPrevented())
			modal.find('button#select').click();
		return;
	}
	if (search.match(/(\d+)\*$/)) {
		filterAdditions("");
		return;
	}
	if (search.match(/(\d+)$/)) {
		return;
	}
	if (e.key == "Enter") {
		$('#orderMenuItem.modal.show button[type="submit"]').click();
		e.preventDefault();
	}
	filterAdditions(search);
}

export const additionIsSelected = (addition, additionsSelected, possibleAdditions) => {
	var qualitySelected = addition.split('-')[1];
	var idSelected = addition.split('-')[0];
	var isAddition = addition.split('-')[2];
	var b = null;
	var bq = 0;
	var a = additionsSelected.find(add => {
		var q = add.split('-')[1];
		var i = add.split('-')[0];
		var ad = add.split('-')[2];
		if (i == idSelected && q === qualitySelected && isAddition === ad) {
			if (a == null)
				return true;
			else if (a.split('-')[1] >= q && q >= qualitySelected) {
				return true;
			}
		}
		return false;
	});
	var count = 0;
	possibleAdditions.forEach(function (add) {
		qualitySelected = addition.split('-')[1];
		var q = add.value.split('-')[1];
		var i = add.id;
		if (i == idSelected) {
			count++;
			if (b == null) {
				b = add;
				bq = b.value.split('-')[1];
			} else if (q >= qualitySelected && qualitySelected > bq && bq < q) {
				b = add;
				bq = b.value.split('-')[1];
			}
		}
	});
	if (a != null)
		return a != null &&
			(count < 2 || a?.startsWith(b.value));
}

export const additionOptionIsIncluded = (addition, additionsSelected, possibleAdditions, options) => {
	var b = null;
	possibleAdditions.forEach(function (add) {
		if (add.value == addition)
			b = add;
	});
	return b != null;
}

export const additionOptionIsChecked = (addition, additionsSelected, possibleAdditions, options) => {
	var idSelected = addition.split('-')[0];
	var a = null;
	var b = null;
	var bq = 0;
	if (additionsSelected)
		additionsSelected.forEach(add => {
			if (add.split('-')[0] !== idSelected)
				return;
			options.forEach(option => {
				var menuItem = getMenuItem(option.id);
				if (menuItem.availableQuantities.length > 0) {
					menuItem.availableQuantities.forEach(quantity => {
						if (option.id + "-" + quantity.quantity + "-true" === add) {
							a = add;
						}
					});
				} else {
					if (option.id + "-1-false" === add || option.id + "-1-true" === add) {
						a = add;
					}
				}
			})
		});
	var count = 0;
	possibleAdditions.forEach(function (add) {
		if (add.value.startsWith(addition))
			b = add.value + "-false";
	});
	const c = (a?.startsWith(addition) || b?.startsWith(addition)) && ((!a && !!b) || (!!a && !b));
	return c;
}


export const additionQuantitySelected = (addition, additionsSelected, possibleAdditions) => {
	var q3 = addition.split('-')[1];
	var id = addition.split('-')[0];
	var a = null;
	a = additionsSelected.find(add => addition === add);
	if (!a)
		additionsSelected.forEach(function (addition) {
			var q = addition.split('-')[1];
			var i = addition.split('-')[0];
			if (i == id) {
				if (a == null)
					a = addition;
				else if (a.split('-')[1] >= q)
					a = addition;
			}
		});
	const r = (additionIsSelected(addition, additionsSelected, possibleAdditions)) ? a.split('-')[1] : q3;
	return r;
}

export const includedInCategoryOption = (menuItem, addition) => {
	return getMenuCategoryOptions(menuItem).filter(option => (getMenuCategory(option.additionId).activeMenuItems ? getMenuCategory(option.additionId).activeMenuItems : getMenuCategory(option.additionId).children).filter(m => m.availableQuantities && m.id === addition.id).length > 0).length > 0;
}

export const updateBookingComment = (occupationId, comment) => {
	const occupation = getTableOccupation(occupationId);
	const bookedProperties = occupation.bookedProperties;
	bookedProperties.comment = comment;
	post("adminService/" + localStorage.restaurantSelected + "/updateBooking/" + occupationId, bookedProperties).done(data => {
		update(data);
	});
}

export const updateBookingUserComment = (occupationId, comment) => {
	const occupation = getTableOccupation(occupationId);
	const bookedProperties = occupation.bookedProperties;
	bookedProperties.user_comment = comment;
	post("adminService/" + localStorage.restaurantSelected + "/updateBooking/" + occupationId, bookedProperties).done(data => {
		update(data);
	});
}