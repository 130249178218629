import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import React, { Component } from "react";

import AICommandBox from "../../js/ai/AICommandBox";
import BackButton from "../../components/menus/BackButton";
import EatWithMeText from "../../components/EatWIthMeText";
import FlexGrowSpace from "../../widgets/FlexGrowSpace";
import RestaurantSelector from "../../components/RestaurantSelector";
import ReservationComponent from "./ReservationComponent";
import { tablesOverview } from "../../js/tables-overview";

class Reservations extends Component {
  state = {};

  render() {
    return this.props.myStatus.restaurant_id ? (
      <div id="reservations" style={{ display: "flex", flexFlow: "column", flexGrow: 1, flexShrink: 1 }}>
        <div
          className="menuItemHeader"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            height: "50px",
            alignItems: "center",
            fontSize: "18px",
            backgroundColor: "#FAF8EA",
            color: "#837B6C",
            paddingRight: "30px"
          }}
        >
          <EatWithMeText />
          <BackButton {...this.props} />
          <FlexGrowSpace />
          <RestaurantSelector {...this.props} />
          <FlexGrowSpace />
          <span className="myTitle" style={{ fontSize: "24px", marginRight: "20px" }}>
            {this.props.qrActionsOnly === true ? <Translate value="admin_local.menus.orders_quick_action" /> : <Translate value="admin_local.menus.orders" />}
          </span>
        </div>
        <ReservationComponent qrActionsOnly={this.props.qrActionsOnly} />
        <AICommandBox />
      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable
    };
  }
  return {};
};

export default connect(mapStateToProps)(Reservations);
