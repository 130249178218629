import React from "react";
import logo from "../img/EwM-landscape.svg";
import { Link } from "react-router-dom";
function EatWithMeText(props) {
  return (
    <Link to="">
      <img className="eatwithmetext hide-print" onClick={props.resetMenu} alt="EatWithMe" style={{ ...props.styles, height: "43px", fill: "blue" }} src={logo}></img>
    </Link>
  );
}

export default EatWithMeText;
