import admin, { getMenuItems, resultHandler, getStores, clearSelect2Cache, getQuantityForCount, getMenuItemById } from "../admin";
import $ from "jquery";
import moment from "moment";
import { tmplparams } from "../order-list-util";
import auth, { startpoll, isVisible, confirmDialog, executeLongJob, getLocale, parseNumber, isTop, getMenuItemImageUrl, get, ajaxCallStart, ajaxCallEnd, whatDecimalSeparator, getKeyboardFocusableElements, calcCount } from "../auth";
import Cleave from "cleave.js";
import { createTree } from "jquery.fancytree";
import { I18n } from "react-redux-i18n";
import languages from "../../langs/languages";
import TableToExcel from "@linways/table-to-excel";
import menuitemutil from "./menuitem";
import { saveAs } from "file-saver";



const { local, admin_local } = languages;

var inventories = {
	inventories: null
}
var loaded = false;
export const clearData = () => {
	loaded = false;
	inventories = {
		inventories: null
	};
	$('body').unbind('keyup', keyupHandler);
}

var numeralDecimalMark = whatDecimalSeparator();

export const ready = () => {
	loaded = true;
	clearSelect2Cache();
	menuitemutil.entityType = undefined;
	menuitemutil.entitytype = undefined;

	const def = $.Deferred();
	getMenuItems(function (data) {
		admin.categories = data;

		$('#inventoriesTemplate').tmpl(tmplparams()).appendTo("#main");

		$('#editInventory').keyup(keyupHandler);

		$("#excel").click(() => {
			const entries = [];
			$('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').each(function (ind, e) {
				var product = $(e).find('select.product').select2('val');
				if (product === null)
					return;
				var entry = {};
				const menuItem = getMenuItem(product);
				entry.name = getLocale(menuItem.name);
				entry.quantity = $(e).find('select.quantity_type').select2('val').split(';')[0];
				entry.quantityType = $(e).find('select.quantity_type').select2('data')[0].text;
				entry.count = $(e).find('input.count').val();
				entry.originalCount = $(e).find('input.ocount').data('cleave').getRawValue();
				entry.valueDifference = $(e).find('input.ovaluediff').data('cleave').getRawValue();
				entries.push(entry);
			});
			const table = $("#InventoryExcelTable").tmpl({ entries, ...tmplparams() });
			$(table).appendTo("body");
			TableToExcel.convert($('#excelTable').get(0));
			$('#excelTable').remove();
		})

		inventories.categories = data;
		$('#date.datepicker').datepicker({
			format: 'yyyy-mm-dd',
			uiLibrary: 'bootstrap4',
			enableOnReadonly: false
		});
		$('#time.timepicker').timepicker({
			format: 'HH:MM',
			uiLibrary: 'bootstrap4',
			enableOnReadonly: false
		});
		if (waiterAccessOnly()) {
			$('#date.datepicker + .input-group-append').remove();
			$('#time.timepicker + .input-group-append').remove();
		}

		var i = $('#entriesFreezeTable > table tfoot input#value-difference').get(0);

		$(i).data('cleave', new Cleave(i, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: false,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		}));

		getStores(data => {
			inventories.stores = data
			admin.initializeSelect2($('select.select2')).done(() => {
				loadInventories();

				startpoll(serverSideMessageHandler, [{ Restaurant: localStorage.restaurantSelected }]);



				updateHooks();

				$("select#product_filter.select2").change(function (e) {
					reload();
				});

				if (waiterAccessOnly())
					$('#editInventory #store.select2').change(e => {
						populateAllItems();
					});

				def.resolve();
			});
		});
	}, undefined, false, true);
	return def.promise();

}

function keyupHandler(e) {
	switch (e.key) {
		case '+':
			if (isVisible($('#editInventory.modal')))
				addNewEntry();
			break;
		case 'ArrowDown':
			if (isVisible($('#editInventory'))) {
				var column = $("#entriesFreezeTable > table#entriesTable").find(':focus')
				var tr = column.parents('tr');
				var td = column.parents('td');
				if (tr.length === 1) {
					var tds = tr.find('td');
					var index = tds.toArray().indexOf(td[0]) + 1;
					tr = $(tr).next();
					td = tr.find('td:nth-child(' + index + ')');
					td.find('input').focus();
					td.find('input.input-numeral').select();
					td.find('select.select2').select2('focus');
				}
				e.preventDefault();
				e.stopPropagation();
			} else {
			}
			break;
		case 'ArrowUp':
			if (isVisible($('#editInventory'))) {
				column = $("#entriesFreezeTable > table#entriesTable").find(':focus')
				tr = column.parents('tr');
				td = column.parents('td');
				if (tr.length === 1) {
					tds = tr.find('td');
					index = tds.toArray().indexOf(td[0]) + 1;
					tr = $(tr).prev();
					td = tr.find('td:nth-child(' + index + ')');
					td.find('input').focus();
					td.find('input.input-numeral').select();
					td.find('select.select2').select2('focus');
				}
				e.preventDefault();
				e.stopPropagation();
			} else {
			}
			break;
		case 'Insert':
			if (e.ctrlKey) {
				if (isVisible($('#editInventory'))) {
					addNewEntry();
					e.preventDefault();
					e.stopPropagation();
				} else {
					$('button#add').click();
				}
			}
			break;
		case 'Delete':
			if (e.ctrlKey) {
				if (isVisible($('#editInventory'))) {
					var data = $("#entriesFreezeTable > table#entriesTable").find(':focus');
					e.currentTarget = data;
					deleteEntry(e);
				} else {
					$('button#delete').click();
				}
				e.preventDefault();
				e.stopPropagation();
			}
			break;
		case 'Enter':
			if (!isTop($('#editInventory'))) {
				$('button#edit').click();
				e.preventDefault();
			}
			break;
		default:
	}
}

export const reload = () => {
	loadInventories();
}

function emptyIfNull(val) {
	if (val === null)
		return "";
	return val;
}

function loadInventories() {
	if (!loaded)
		return;
	var filter = {
		from: localStorage.admin_stocklist_start,
		to: localStorage.admin_stocklist_end,
		menuitem: emptyIfNull($('select#product_filter').select2('val')),
	};
	admin.getEntitiesFiltered("Inventorie", filter, processInventories);
}

function serverSideMessageHandler(data) {
}


function processInventories(data) {
	data.forEach(function (v, i) {
		v.title = moment(v.date).format('YYYY-MM-DD HH:mm');
		v.folder = false;
		v.key = v.id;
	});
	if (!(inventories.inventories))
		inventories.inventories = createTree("#listFreezeTable > table#listTable", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: data,
			zindex: 1000,
			icon: false,
			keyboard: true,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			persist: {
				cookiePrefix: 'fancytree-1-Inventory',
				expandLazy: false,
				overrideSource: false, // true: cookie takes precedence
				// over `source` data
				// attributes.
				store: "auto" // 'cookie', 'local': use localStore,
				// 'session': sessionStore
			},
			gridnav: {
				autofocusInput: true, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				$('#edit').removeAttr('disabled');
				$('#delete').removeAttr('disabled');
				if (node.data.isActive) {
					$('#deactivate').removeAttr('disabled');
					$('#activate').prop('disabled', 'true');
				} else {
					$('#deactivate').prop('disabled', 'true');
					$('#activate').removeAttr('disabled');
				}

			},
			click: (event, data) => {
				if (inventories.clickTimestamp && inventories.selectedNodeKey === data.node.key && new Date().getTime() - inventories.clickTimestamp < 1000) {
					$('button#edit').click();
				};
				inventories.clickTimestamp = new Date().getTime();
				inventories.selectedNodeKey = data.node.key;
			},
			/*
			dblclick: function (event, data) {
				$('button#edit').click();
			},
			*/
			renderColumns: function (event, data) {
				var node = data.node, $tdList = $(node.tr).find(">td");

				node.expanded = true;

				var c = 1;

				$tdList.eq(c++).find("input").prop('checked', node.data.draft);
				$tdList.eq(c++).text(getStore(node.data.store.id).name);

				var entries1 = "";
				var entries2 = "";
				var entries3 = "";
				var entries4 = "";
				var entries5 = "";
				var less = 0;
				var more = 0;
				var diff = 0;

				const _waiterAccessOnly = waiterAccessOnly();

				node.data.entries.forEach((e, ind) => {
					getMenuItem(e.menuItem.id, menuItem => {
						if (menuItem.availableQuantities)
							menuItem.availableQuantities.forEach((quantity) => {

								var qt = getLocale(quantity.quantityType.name);
								if (!qt) {
									qt = local[menuItem.quantityType];
								}

								var clazz = ""
								if (quantity.quantity === e.quantity) {
									if (e.count - e.originalCount < 0 && !_waiterAccessOnly) {
										clazz = "negative";
										less++;
									} else if (e.count - e.originalCount > 0 && !_waiterAccessOnly) {
										clazz = "positive";
										more++;
									}
									entries1 += '<div class="' + clazz + '">' + Math.round(e.originalCount * 1000) / 1000 + "</div>";
									const change = Math.round((((e.count - e.originalCount) > 0 ? "+" : "") + (e.count - e.originalCount)) * 1000) / 1000;
									entries2 += '<div class="' + clazz + '">' + change + "</div>";
									entries3 += '<div class="' + clazz + '">' + Math.round(e.count * 1000) / 1000 + "</div>";
									entries4 += '<div class="' + clazz + '">' + Math.round((e.count - e.originalCount) * menuItem.cost * 1000 * menuitemutil.getInventoryQuantity(menuItem, e.quantity)) / 1000 + "</div>";
									entries5 += '<div class="' + clazz + '">' + (menuItem.manufacturer ? menuItem.manufacturer + " / " : "") + getLocale(menuItem.name) + " " + e.quantity + " " + I18n.t("local." + menuItem.quantityType) + " " + qt + "</div>";
									diff += (e.count - e.originalCount) * menuItem.cost * menuitemutil.getInventoryQuantity(menuItem, e.quantity);
								}
							});
						else {
							entries5 += '<div class="">' + getLocale(menuItem.name) + "</div>";
						}
						$tdList.eq(8).html(entries5);
					});
				})

				var l = "<div style='text-align:right'>" + node.data.entries.length + "</div>";
				if (!_waiterAccessOnly) {
					if (less)
						l += "<div style='text-align:right' class='negative'>" + I18n.t('admin_local.shortage') + ": " + less + "</div>";
					if (more)
						l += "<div style='text-align:right' class='positive'>" + I18n.t('admin_local.surplus') + ": " + more + "</div>";
					if (diff > 0)
						l += "<div style='text-align:right' class='positive'>" + I18n.t('admin_local.value_difference') + ": " + Math.round(diff * 1000) / 1000 + "</div>";
					else if (diff < 0)
						l += "<div style='text-align:right' class='negative'>" + I18n.t('admin_local.value_difference') + ": " + Math.round(diff * 1000) / 1000 + "</div>";
				}
				$tdList.eq(c++).html(l);
				if (node.data.entries.length > 2) {
					entries1 += '<div class="total">&nbsp;...</div>';
					entries2 += '<div class="total">&nbsp;...</div>';
					entries3 += '<div class="total">&nbsp;...</div>';
					entries4 += '<div class="total">&nbsp;...</div>';
					entries5 += '<div class="total">&nbsp;...</div>';
				}
				$tdList.eq(c++).html(entries1);
				if (!_waiterAccessOnly) {
					$tdList.eq(c++).html(entries2);
					$tdList.eq(c++).html(entries3);
					$tdList.eq(c++).html(entries4);
				}
				$tdList.eq(c++).html(entries5);

				var other = "";
				if (node.data.createdBy) {
					var t = moment(node.data.created).format('YYYY-MM-DD   HH:mm');
					other += "<div>" + t + " - " + node.data.createdBy.name + " - " + I18n.t("admin_local.created_by") + "</div>";
				}
				if (node.data.modifiedBy) {
					t = moment(node.data.modified).format('YYYY-MM-DD   HH:mm');
					other += "<div>" + t + " - " + node.data.modifiedBy.name + " - " + I18n.t("admin_local.modified_by") + "</div>";
				}
				$tdList.eq(c++).html(other);


			}

		});
	else {
		inventories.inventories.reload(data);
		var node = inventories.inventories.getActiveNode();
		$('#edit').prop('disabled', node === null);
		$('#delete').prop('disabled', node === null);
	}
}

var tt = null;
var pattern = null;
var saveDraft = null;
var draftId = null;

function updateHooks() {
	$('#entriesFreezeTable > table#entriesTable tfoot .input_count.count').each(function (ind, i) {
		var c = $(i).data('cleave', new Cleave(i, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: true,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		}));
		i.data('cleave', c);
	})
	$('#entriesFreezeTable > table#entriesTable tfoot .input_count.ocount').each(function (ind, i) {
		var c = $(i).data('cleave', new Cleave(i, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: false,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		}));
		i.data('cleave', c);
	})
	$('#entriesFreezeTable > table#entriesTable tfoot .input_count.ovaluediff').each(function (ind, i) {
		var c = $(i).data('cleave', new Cleave(i, {
			numeral: true,
			numeralThousandsGroupStyle: 'thousand',
			numeralPositiveOnly: false,
			numeralDecimalScale: 3,
			delimiter: ' ',
			numeralDecimalMark: numeralDecimalMark,
		}));
		i.data('cleave', c);
	})

	$('div#editInventory').on('hidden.bs.modal', function (event) {
		$('#entrylist').removeClass("hidden");
		$('.menuItemHeader').removeClass("hidden");
		var modal = $(this);
		var id = modal.find('#id').val();
		if (!id && draftId) {
			_deleteInventory(draftId, loadInventories);
		}
		modal.find('#entriesFreezeTable > table#entriesTable tbody tr:not(.head)').remove();
		if (saveDraft) {
			clearInterval(saveDraft);
			saveDraft = null;
		}
	});

	const exportit = (array, stores, stocks) => {

		const quantities = [];
		stores.forEach(store => {
			quantities.push("s_" + store.name);
		})
		quantities.sort((a, b) => ("" + a).localeCompare("" + b));
		var r = [];
		array.forEach(m => {
			const path = menuitemutil.getPath(m).split("/");
			if (m.id == 280) {
				console.log(m);
			}
			//getLocale(m.name).split("/").forEach(() => path.pop());
			//const iq = m.availableQuantities.find(q => q.quantity == menuitemutil.getInventoryQuantity(m));
			m.availableQuantities.filter(a => a.inventoryItem).map(a => {
				const c = {
					id: m.id,
					category: path.join("/"),
					name: getLocale(m.name),
					manufacturer: m.manufacturer,
					label: m.labels.map(l => l.name).join(" / "),
					quantityType: m.quantityType,
					"inventory quantity unit": a.quantity
				}
				stores.forEach(store => {
					c["s_" + store.name] = "";
				})
				r.push(c);
			})
		})
		return r;
	}

	$("#download").click(function (e) {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		get("adminService/" + localStorage.restaurantSelected + "/getStores").done(stores => {
			get("adminService/" + localStorage.restaurantSelected + "/getStocks").done(stock => {
				const ws = window.XLSX.utils.json_to_sheet(exportit(menuitemutil.getFlatMenuItems([], admin.categories.children).filter(m => m.defaultInventoryItem), stores, stock));

				const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
				const excelBuffer = window.XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
				const data = new Blob([excelBuffer], { type: fileType });

				saveAs(data, auth.myStatus.restaurant_name + "s.xlsx");
			});
		});

	});

	$("#upload").click(function () {
		$("#file").attr("files", "");
		$("#file").get(0).value = null;
		$("#file").click();
	});

	$("#file").change(function () {
		if (document.getElementById("file").files[0] == "") return;
		var reader = new FileReader();

		reader.addEventListener("load", () => {
			try {

				var workbook = window.XLSX.read(reader.result, { type: "binary" });

				workbook.SheetNames.forEach(sheet => {
					let rowObject = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

					inventories.uploadeddata = {
						entries: []
					};
					let queue = 0;
					admin.getStores(stores => {
						queue++;
						rowObject.map(row => {
							if (row.id) {
								queue++;
								getMenuItem(row.id, menuItem => {
									queue--;
									Object.keys(row).filter(key => key.startsWith("s_") && row[key] !== "").forEach(key => {
										const storeName = key.substring(2);
										const store = stores.find(s => s.name === storeName);
										inventories.uploadeddata.store = store;

										if (store)
											inventories.uploadeddata.entries.push({
												menuItem: { id: menuItem.id, type: menuItem.entityType.toLowerCase() },
												quantityType: row.quantityType,
												quantity: row["inventory quantity unit"],
												count: row[key],
											});
									})
									if (!queue) {
										$("#edit").prop("disabled", false);
										$("#edit").trigger('click');
										$("#edit").prop("disabled", true);
									}
								});
							}
						});
						queue--;
						if (!queue) {
							$("#edit").prop("disabled", false);
							$("#edit").trigger('click');
							$("#edit").prop("disabled", true);
						}
					});

				});

				/*
				  const newmenuItems = JSON.parse(reader.result);
				  */
			} catch (ex) {
				console.log(ex);
			}

		},
			false
		);


		//if (file) {
		reader.readAsBinaryString(document.getElementById("file").files[0]);
		//}

	});



	$('div#editInventory').on('shown.bs.modal', function (event) {

		draftId = null;

		$('#entrylist').addClass("hidden");
		$('.menuItemHeader').addClass("hidden");

		var modal = $(this)

		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		modal.find('#entriesFreezeTable > table#entriesTable tbody tr:not(.head)').remove();
		// attributes
		if (recipient === 'edit') {
			var data;
			if (inventories.uploadeddata) {
				data = inventories.uploadeddata;
				delete inventories.uploadeddata;
			} else {
				if (!inventories.inventories.getActiveNode()) {
					modal.hide();
					$('.modal-backdrop').remove();
					return;
				}
				data = inventories.inventories.getActiveNode().data;
				modal.find('#id').val(data.id);
			}

			modal.find('#id').val(data.id);
			var formattedDate = moment(data.date).format('YYYY-MM-DD');
			modal.find('input#date').val(formattedDate);
			formattedDate = moment(data.date).format('HH:mm');
			modal.find('input#time').val(formattedDate);
			if (data.store)
				modal.find('select#store').val(data.store.id);
			modal.find('select#store').trigger('change');
			modal.find('input#draft').prop('checked', data.draft);

			$("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2().on('select2:select', function (e) {
				/*
				if (modal.find('tbody#InventoryEntries tr:not(.head)').length === 0)
					if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2('val') !== null)
						addNewEntry();
				*/
				e.preventDefault();
			});
			data.entries.forEach(entry => {
				//console.log(entry);
				entry = addNewEntry(entry, false);
				updateStockCount($(entry));
			});

		} else {
			modal.find('select#store').val(null);
			modal.find('select#store').trigger('change');
			modal.find('#id').val("");
			modal.find('#active').prop("checked", 'true');
			modal.find("tbody#entriesTable tr.head select.quantity_type").select2().on('select2:select', function (e) {
				if (modal.find('tbody#entriesTable tr:not(.head)').length === 0)
					if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2('val') !== null)
						addNewEntry();
				e.preventDefault();
			});
			var m = moment();
			formattedDate = m.format('YYYY-MM-DD');
			modal.find('input#date').val(formattedDate);
			formattedDate = m.format('HH:mm');
			modal.find('input#time').val(formattedDate);
			//$(".datepicker").datepicker("setDate", new Date());
			$("textarea#regexp").keyup(function (e) {
				var p = $("textarea#regexp").val();
				if (pattern !== p && p !== "" && $("textarea#rawtextdata").val() !== "") {
					tt = new Date().getTime();
					var ttt = tt;
					setTimeout(function () {
						if (ttt === tt) {
							pattern = $("textarea#regexp").val();
							tryExecuteRegexp();
						}
					}, 1000);
				}
				e.preventDefault();
			});
			modal.find('input#draft').prop('checked', false);
			modal.find('select#store').select2('open');
			saveDraft = setInterval(() => saveInventory(true), 20000);
		}

		modal.find('#entriesFreezeTable > table input#date').change(() => {
			modal.find('#entriesFreezeTable > table tbody tr').each((ind, entry) => {
				updateStockCount($(entry));
			})
		});
		modal.find('#entriesFreezeTable > table input#time').change(() => {
			modal.find('#entriesFreezeTable > table tbody tr').each((ind, entry) => {
				updateStockCount($(entry));
			})
		});

	})
}

function _addNewEntry2(data, index, tbody) {
	var existingRow = tbody.find('tr:nth-child(' + index + ')');
	if (existingRow.length === 1) {
		existingRow.removeClass('existing');
		Object.keys(data).forEach(function (key) {
			var value = data[key];
			var input = existingRow.find('input#' + key);
			if (input.attr('type') === 'number')
				value = parseNumber(value);
			existingRow.find('input#' + key).val(value);
		});
	} else {
		var entry = $("script#InventoryEntryTemplate").tmpl({ ...data, ...tmplparams() });
		$('#entriesFreezeTable > table tbody#entriesTable').append(entry);
		// admin.initializeSelect2(entry.find('select.select2'));
	}
}
function _updateEntries2() {
	admin.initializeSelect2($('#entriesFreezeTable > table tbody tr:not(.head) select.select2:not(.select2-hidden-accessible)'));
}

export const addNewEntry = (data2, init = true) => {
	if (!$('#editInventory #store').select2('val')) {
		$('#editInventory #store + span').removeClass('highlight-3');
		setTimeout(() => {
			$('#editInventory #store + span').addClass('highlight-3');
		}, 100)
		return;
	}
	var data;
	$('.select2.select2-container.select2-container--default.select2-container--below.select2-container--open').prev('select').select2('close');
	if (typeof data2 == "undefined")
		data = { store: {}, vatCategory: {}, quantityType: {} }
	else
		data = data2;
	const entry = $("script#InventoryEntryTemplate").tmpl({ ...data, ...tmplparams() });

	if (waiterAccessOnly()) {
		entry.find('td[tabIndex1=3]').css('visibility', 'hidden');
	}

	entry.appendTo('div#editInventory #entriesFreezeTable > table tbody');

	if (!init) {
		getMenuItem(data.menuItem.id, menuItem => {
			var newOption1 = new Option((menuItem.manufacturer ? menuItem.manufacturer + " / " : "") + getLocale(menuItem.name), menuItem.id, false, false);
			entry.find('select.product').append(newOption1).trigger('change');
			//var newOption2 = new Option(getQuantityForCount(menuItem, data.quantity, 1), data.quantity, false, false);
			var quantityTypes = [];
			menuItem.availableQuantities.forEach((quantity) => {
				if (quantity.inventoryItem === true)
					quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
			});
			if (quantityTypes.length === 0) {
				quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
			}
			entry.find('select.quantity_type option').remove();
			var qt = entry.find('select.quantity_type');
			const select2 = qt.select2({
				data: quantityTypes
			});
			qt.data('select2', select2);

			entry.find('select.product').select2();
			//entry.find('select.quantity_type').append(newOption2).trigger('change');
			//entry.find('select.quantity_type').select2();

			entry.find('select.quantity_type').select2().val(data.quantity + ";" + menuItem.quantityType);
			entry.find('select.quantity_type').select2().trigger('change');

			updatePattern(entry, menuItem.quantityType);

			entry.find('.input_count.ocount').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 3,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				}));
			})
			entry.find('.input_count.ocount').data("cleave").setRawValue(data.originalCount);
			entry.find('.input_count.count').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: false,
				}));
			})
			entry.find('.input_count.count').val(calcCount(data.count, menuItem.quantityType, data.quantity));
			entry.find('.input_count.ovaluediff').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 3,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				}));
			})
			entry.find('input.ovaluediff').data('cleave').setRawValue(Math.round((Math.round(1000 * data.count) / 1000 - Math.round(1000 * data.originalCount) / 1000) * menuItem.cost * menuitemutil.getInventoryQuantity(menuItem, data.quantity)));
			updateDiff();

			function init(item) {
				entry.find('select.product + .select2').unbind("mouseover", init);
				entry.find('select.product + .select2').unbind("focus", init);
				entry.find('select.quantity_type + .select2').unbind("mouseover", init);
				entry.find('select.quantity_type + .select2').unbind("focus", init);

				var menuItemId = entry.find('select.product').val();
				var quantity = entry.find('select.quantity_type').val();
				entry.find('select.product option').remove();
				entry.find('select.product quantity_type').remove();
				entry.find('select.product').val("");
				entry.find('select.quantity_type').val("");
				admin.initializeSelect2(entry.find('select.product.select2')).done(() => {
					if (menuItemId) {
						getMenuItem(menuItemId, menuItem => {
							if (!menuItem.defaultInventoryItem || menuItem.type === "deleted") {
								var newOption = new Option((menuItem.manufacturer ? menuItem.manufacturer + " / " : "") + getLocale(menuItem.name), menuItem.id, false, false);
								entry.find('select.product').append(newOption).trigger('change');
								entry.find('select.product').select2().val(menuItem.id);
								entry.find('select.product').append(newOption).trigger('change');
								//handleProductChange(null, entry);
								checkEntry({ currentTarget: entry });
							}
						});
					}

					var default_quantity_type = data2 ? data.quantityType : undefined;

					entry.find('select.product').on('select2:select', function () {
						var menuItemId = $(this).val();
						if (menuItemId == null)
							return;
						getMenuItem(menuItemId, menuItem => {
							updatePattern(entry, menuItem.quantityType);

							var quantityTypes = [];
							menuItem.availableQuantities.forEach((quantity) => {
								if (quantity.inventoryItem === true)
									quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
							});
							if (quantityTypes.length === 0) {
								quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
							}
							entry.find('select.quantity_type option').remove();
							var qt = entry.find('select.quantity_type');
							const select2 = qt.select2({
								data: quantityTypes
							});
							qt.data('select2', select2);
							if (default_quantity_type) {
								qt.val(quantity + ";" + default_quantity_type);
							} else if (quantityTypes.length > 0)
								qt.val(quantityTypes[0].id);
							//qt.data('select2').trigger('select2:select');
							qt.data('select2').trigger("change");
							setTimeout(() => { updateStockCount(entry, data.ocount) }, 100);
						});
					});

					if (data && data.menuItem) {
						getMenuItem(menuItemId, menuItem => {
							entry.find('img.product-image').attr('src', getMenuItemImageUrl(menuItem, quantity));
							entry.find('select.product').val(menuItemId);
							entry.find('select.product').select2().val(menuItemId)
							entry.find('select.product').select2().trigger('change');;
							//entry.find('select.product').trigger('select2:select');
							entry.find('select.quantity_type').val(quantity + ";" + menuItem.quantityType);
						})
					}
					if (default_quantity_type !== null) {
						entry.find('select.quantity_type').select2({
							allowClear: true
						});
						entry.find('select.quantity_type').select2().val(data.quantity + ";" + default_quantity_type);
						//s.trigger('select2:select');
						entry.find('select.quantity_type').select2().trigger('change');
					}
					checkEntry({ currentTarget: entry[0] });

				});
			}

			entry.find('input').change(checkEntry);
			entry.find('select').select2().change(checkEntry);

			entry.find('input.expiry').change(() => {
				updateStockCount(entry);
			});
			entry.find('input.count').change(() => {
				updateStockCount(entry);
			});

			entry.find('select.quantity_type').select2().change(() => {
				updateStockCount(entry);
			});
			entry.find('input.expiry').change(() => {
				updateStockCount(entry);
			});
			entry.find('input.count').change(() => {
				updateStockCount(entry);
			});

			entry.find('select.product + .select2').on("mouseover", init);
			entry.find('select.product + .select2').on("focus", init);
			entry.find('select.quantity_type + .select2').on("mouseover", init);
			entry.find('select.quantity_type + .select2').on("focus", init);
		});
		checkEntry({ currentTarget: entry[0] });

	} else
		admin.initializeSelect2(entry.find('select.select2')).done(function () {
			var menuItemId = $(this).select2('val');
			if (!menuItemId && data.menuItem) menuItemId = data.menuItem.id
			if (menuItemId) {
				getMenuItem(menuItemId, menuItem => {
					if (!menuItem.defaultInventoryItem || menuItem.type === "deleted") {
						var newOption = new Option((menuItem.manufacturer ? menuItem.manufacturer + " / " : "") + getLocale(menuItem.name), menuItem.id, false, false);
						entry.find('select.product').append(newOption).trigger('change');
						entry.find('select.product').select2().val(menuItem.id);
						entry.find('select.product').append(newOption).trigger('change');
						//handleProductChange(null, entry);
						checkEntry({ currentTarget: entry });
					}
				});
			}

			var default_quantity_type = data2 ? data.quantityType : undefined;

			updatePattern(entry, default_quantity_type);

			entry.find('select.product').on('select2:select', function () {
				var menuItemId = $(this).val();
				if (menuItemId == null)
					return;
				getMenuItem(menuItemId, menuItem => {
					updatePattern(entry, menuItem.quantityType);

					var quantityTypes = [];
					menuItem.availableQuantities.forEach((quantity) => {
						if (quantity.inventoryItem === true)
							quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
					});
					if (quantityTypes.length === 0) {
						quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
					}
					entry.find('select.quantity_type option').remove();
					var qt = entry.find('select.quantity_type');
					const select2 = qt.select2({
						data: quantityTypes
					});
					qt.data('select2', select2);
					if (default_quantity_type) {
						qt.val(data.quantity + ";" + default_quantity_type);
					} else if (quantityTypes.length > 0)
						qt.val(quantityTypes[0].id);
					qt.data('select2').trigger('select2:select');
					setTimeout(() => { updateStockCount(entry, data.ocount) }, 100);
				});
			});
			if (data && data.menuItem) {
				getMenuItem(data.menuItem.id, menuItem => {
					entry.find('img.product-image').attr('src', getMenuItemImageUrl(menuItem, data.quantity));
					entry.find('select.product').val(data.menuItem.id);
					entry.find('select.product').trigger('select2:select');
					entry.find('select.quantity_type').val(data.quantity);
				})
			}
			if (default_quantity_type !== null) {
				var s = entry.find('select.quantity_type').select2({
					allowClear: true
				});
				s.val(data.quantity + ";" + default_quantity_type);
				s.trigger('select2:select');
			}
			entry.find('.datepicker').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4'
			});
			if (data.expiryDate) {
				var formattedDate = moment(data.expiryDate).format('YYYY-MM-DD');
				entry.find('input.expiry').val(formattedDate);
			}

			entry.find('input').change(checkEntry);
			entry.find('select').select2().change(checkEntry);
			entry.find('.input_count.count').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: false,
				}));
			})
			entry.find('.input_count.count').val(data.count);

			entry.find('.input_count.ocount').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 3,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				}));
			})

			entry.find('.input_count.ovaluediff').each(function (ind, i) {
				$(i).data('cleave', new Cleave($(i), {
					numeral: true,
					numeralThousandsGroupStyle: 'thousand',
					numeralPositiveOnly: false,
					numeralDecimalScale: 3,
					delimiter: ' ',
					numeralDecimalMark: numeralDecimalMark,
				}));
			})

			entry.find('select.quantity_type').select2().change(() => {
				updateStockCount(entry);
			});
			entry.find('input.expiry').change(() => {
				updateStockCount(entry);
			});
			entry.find('input.count').change(() => {
				updateStockCount(entry);
			});

			checkEntry({ currentTarget: entry[0] });

			if (data2 === undefined) {
				const div = $("#entriesFreezeTable").get(0);
				div.scrollTop = div.scrollHeight - div.clientHeight;
				entry.find('select.product').select2('open');
			}

		});


	entry.find('.input_count.count').on("change", (event) => {
		if (!event.currentTarget.checkValidity()) {
			$(event.currentTarget).val($(event.currentTarget).data("value"));
			$(event.currentTarget).trigger('change');
		} else {
			var value = event.currentTarget.value.replace(/,/g, '.');
			if (isNaN(value)) {
				const quantityType = entry.find('select.quantity_type').select2('val').split(';')[1];
				const quantity = entry.find('select.quantity_type').select2('val').split(';')[0];
				const found = value.match("(\\d*[,\\.]?\\d+) ?(cl|dl|l|g|dkg|kg|" + local.pcs + ")");
				if (!found) {
					$(event.currentTarget).val($(event.currentTarget).data("value"));
					$(event.currentTarget).trigger('change');
					return;
				}
				const newValue = calcCount(value, quantityType, quantity);
				$(event.currentTarget).val(newValue);
				$(event.currentTarget).data("value", newValue);
				$(event.currentTarget).trigger('change');
			} else {
				if (value != event.currentTarget.value) {
					$(event.currentTarget).val(value);
					$(event.currentTarget).data("value", value);
					$(event.currentTarget).trigger('change');
				} else
					$(event.currentTarget).data("value", event.currentTarget.value);
			}


		}
	})
	entry.find('.input_count.count').on("keyup", (event) => {
		if (!event.currentTarget.checkValidity()) {
			$(event.currentTarget).addClass("invalid");
		} else {
			$(event.currentTarget).removeClass("invalid");
		}
	})


	return entry;
}

function updatePattern(entry, quantityType) {
	if (quantityType === "dl" || quantityType === "cl" || quantityType === "l") {
		entry.find('.input_count.count').attr("pattern", "(\\d*[,.]?\\d+) ?(l|dl|cl)?")
	} else if (quantityType === "dkg" || quantityType === "g" || quantityType === "kg") {
		entry.find('.input_count.count').attr("pattern", "(\\d*[.,]?\\d+) ?(g|dkg|kg)?")
	} else if (quantityType === "pcs") {
		entry.find('.input_count.count').attr("pattern", "(\\d*[.,]?\\d+) ?(" + local.pcs + ")?")
	} else {
		entry.find('.input_count.count').attr("pattern", "(\\d*[,.]?\\d+)")
	}
}

function updateStockCount(entry, originalcount) {
	if (waiterAccessOnly())
		return;
	var modal = $('div#editInventory');

	if (!entry.find('input.count').data('cleave'))
		return;
	var menuItemId = entry.find('select.product').val();
	var count = entry.find('input.count').val();
	var ocount = entry.find('input.ocount').data('cleave').getRawValue();
	var quantity = entry.find('select.quantity_type').val() ? entry.find('select.quantity_type').val().split(";")[0] : 0;
	if (quantity === 0)
		return;
	var dateString = modal.find('input#date').val() + " " + modal.find('input#time').val();
	var date = moment(dateString, "YYYY-MM-DD HH-mm").toDate().getTime();
	var store = modal.find('select#store').select2('val');
	var expiry = new Date(entry.find('input.expiry').val()).getTime();
	if (isNaN(expiry))
		expiry = "";
	var key = menuItemId + "-" + quantity + "-" + date + "-" + store + "-" + expiry;
	getMenuItem(menuItemId, menuItem => {
		entry.find('input.ovaluediff').data('cleave').setRawValue(Math.round((Math.round(1000 * count) / 1000 - Math.round(1000 * ocount) / 1000) * menuItem.cost * menuitemutil.getInventoryQuantity(menuItem, quantity)));
		updateDiff();
	});
	if ($(entry).data('key') === key)
		return;
	$(entry).data('key', key);

	if (typeof originalcount !== "undefined") {
		if (entry.find('input.ocount').data('cleave')) {
			entry.find('input.ocount').data('cleave').setRawValue(originalcount);
			getMenuItem(menuItemId, menuItem => {
				entry.find('input.ovaluediff').data('cleave').setRawValue(Math.round((Math.round(1000 * count) / 1000 - Math.round(1000 * originalcount) / 1000) * menuItem.cost * menuitemutil.getInventoryQuantity(menuItem, quantity)));
				updateDiff();
			});
		}
		delete entry.ocount;
	} else {
		admin.getCostAndPriceHistoryAt(menuItemId, quantity, store, expiry, date, data => {
			if (entry.find('input.ocount').data('cleave')) {
				data.count = Math.round(1000 * data.count) / 1000;
				entry.find('input.ocount').data('cleave').setRawValue(data.count);
				getMenuItem(menuItemId, menuItem => {
					entry.find('input.ovaluediff').data('cleave').setRawValue(Math.round((Math.round(1000 * count) / 1000 - data.count) * menuItem.cost * menuitemutil.getInventoryQuantity(menuItem, quantity)));
					updateDiff();
				});
			}
		}, data => {
			entry.find('input.ocount').data('cleave').setRawValue(0);
		})
	}
}

const updateDiff = () => {
	var diff = 0;
	$('#entriesFreezeTable > table tbody#entriesTable input.ovaluediff').each((ind, e) => {
		if ($(e).data('cleave')) {
			diff += Number($(e).data('cleave').getRawValue());
		}
	});
	$('#entriesFreezeTable > table tfoot input#value-difference').data('cleave').setRawValue(diff);

}

export const saveInventory = (draft) => {
	if (!draft) {
		clearInterval(saveDraft);
		saveDraft = null;
	}
	var modal = $('div#editInventory');
	var id = modal.find('#id').val();
	var inventory = {};
	inventory.date = new moment(modal.find('input#date').val() + " " + modal.find('input#time').val(), "YYYY-MM-DD HH-mm").toDate().getTime();
	inventory.store = { id: modal.find('select#store').select2('val') };
	inventory.entries = [];
	inventory.draft = id || !draft ? modal.find('input#draft').prop('checked') : true;

	if (!inventory.store.id)
		return;

	const entries = [];

	modal.find('#entriesFreezeTable > table tbody#entriesTable tr:not(.head)').each(function (ind, e) {
		var entry = {};
		try {
			var product = $(e).find('select.product').select2('val');;
			if (product === null)
				return;
			const menuItem = getMenuItem(product);
			entry.menuItem = { id: product, type: menuItem.entityType.toLowerCase() };
			entry.quantity = $(e).find('select.quantity_type').select2('val').split(';')[0];
			entry.quantityType = $(e).find('select.quantity_type').select2('val').split(';')[1];
			entry.id = $(e).attr('id');
			entry.count = $(e).find('input.count').val();
			entry.originalCount = $(e).find('input.ocount').data('cleave').getRawValue();
			entry.expiryDate = new Date($(e).find('input.expiry').val()).getTime();
		} catch (ex) {

		}
		if (entry.menuItem && entry.menuItem.id) {
			entries.push(e);
			inventory.entries.push(entry);
		}
	});


	if (!id && draftId) {
		id = draftId;
	}

	if (draft && inventory.store.id && inventory.entries.length === 0)
		return;

	if (!draft)
		draftId = null;

	if (id !== '') {
		inventory.id = id;
		modifyInventory(inventory, data => {
			if (!draft) {
				draftId = null;
				loadInventories(data);
			}
			entries.forEach((e, ind) => {
				$(e).attr('id', data.entries[ind].id);
			});
		});
	} else {
		addInventory(inventory, data => {
			if (draft)
				draftId = data.id;
			else
				draftId = null;
			entries.forEach((e, ind) => {
				$(e).attr('id', data.entries[ind].id);
			});
			if (!draft)
				loadInventories(data);
		});
	}
}

export const deleteInventory = () => {
	var data = inventories.inventories.getActiveNode().data;
	confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.are_you_sure_you_want_to_delete_the_inventory")).done(function () {
		_deleteInventory(data.id, loadInventories);
	});

}

function addInventory(Inventory, handler, errorHandler) {
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/addInventory?lang=" + localStorage.language, false, Inventory).done(function (data) { resultHandler(data, handler, errorHandler) });
}

function modifyInventory(Inventory, handler, errorHandler) {
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/modifyInventory?lang=" + localStorage.language, false, Inventory).done(function (data) {
		console.log("finished");
		resultHandler(data, handler, errorHandler)
	});
}

function _deleteInventory(id, handler, errorHandler) {
	executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/deleteInventory/" + id + "?lang=" + localStorage.language, false).done(function (data) { resultHandler(data, handler, errorHandler) });
}

export const checkEntry = (e) => {
	var ok = true;
	var entry = e.currentTarget.localName === 'tr' ? $(e.currentTarget) : $(e.currentTarget).parents('tr');
	var v = entry.find('input.count').val() ? parseNumber(entry.find('input.count').val()) : undefined;
	ok = ok && v !== undefined && v !== "" && v >= 0;
	v = parseNumber(entry.find('select.product').select2('val'));
	ok = ok && v !== undefined && v !== "" && v > 0;
	//console.log(entry.find('select.quantity_type').select2('val'));
	v = parseNumber(entry.find('select.quantity_type').select2('val'));
	ok = ok && v !== undefined && v !== "";
	v = new Date(entry.find('input.expiry').val()).getTime();
	ok = ok && (isNaN(v) || v > 0);
	if (ok)
		entry.removeClass('error');
	else
		entry.addClass('error');

	updateDiff();
	$('button#save').attr('disabled', $('#entriesFreezeTable > table tbody#entriesTable tr.error').length > 0)
}

export const deleteEntry = (e) => {
	var entry = e.currentTarget.localName === "tr" ? $(e.currentTarget) : $(e.currentTarget).parents("tr");
	var p = entry.next();
	if (p.length === 0) p = entry.prev();

	var column = entry.find(":focus");
	var td = column.parents("td");
	var tds = entry.find("td");
	var index = tds.toArray().indexOf(td[0]) + 1;

	entry.remove();
	$("button#save").attr("disabled", $("#entriesFreezeTable > table tbody#entriesTable tr.error").length > 0);
	if (p.length === 1) {
		$(getKeyboardFocusableElements(p)).trigger('focus');
		td = p.find("td:nth-child(" + index + ")");
		$(getKeyboardFocusableElements(td)).trigger('focus');
	}
}


function tryExecuteRegexp() {
	try {
		// $('table tbody tr:not(.head)').remove();
		var tbody = $('#entriesFreezeTable > table tbody#entriesTable');
		var regexp = $('textarea#regexp').val();
		var text = $('textarea#rawtextdata').val();
		regexp = new RegExp(regexp, 'ig');
		tbody.find('tr:not(.head)').addClass('existing');
		if (regexp.test(text)) {
			var m;
			var index = 2;
			do {
				m = regexp.exec(text);
				if (m !== null && m.groups) {
					console.log(index);
					console.log(m.groups);
					_addNewEntry2(m.groups, index, tbody);
				}
				index++;
			} while (m !== null && m.groups);
		}
		tbody.find('tr.existing').remove();
		_updateEntries2();
	} catch (ex) {
		console.log(ex);
	}
}

export const findEntry = (item, inventoryQuantity) => {
	const entries = $('table#entriesTable tbody tr');
	var entry = entries.filter((ind, entry) => {
		const product = Number($(entry).find('select.product').select2('val'));
		if (product !== item)
			return false;
		const quantityType = $(entry).find('select.quantity_type').val();
		const res = quantityType && Number(quantityType.split(";")[0]) === inventoryQuantity;
		return res;
	})
	return entry;
}

var deletedMenuItems = {}
function getMenuItem(id, handler) {
	var menuItem = _getMenuItem(id, inventories.categories.activeMenuCategories ? inventories.categories.activeMenuCategories : inventories.categories.children);
	if (menuItem === null || menuItem === '') {
		if (deletedMenuItems[id]) {
			if (handler)
				handler(deletedMenuItems[id]);
			return menuItem;
		}
		if (handler) {
			getMenuItemById(id, menuItem => {
				menuItem.type = 'deleted';
				menuItem.name = admin_local.deleted + ' --- ' + getLocale(menuItem.name) + " --- ";
				deletedMenuItems[id] = menuItem;
				if (handler)
					handler(menuItem);
			});
		}
		return;
	}
	if (handler)
		handler(menuItem);
	return menuItem;
}

function _getMenuItem(id, categories) {
	var val = null;
	categories.forEach(function (item) {
		if (val !== null)
			return;
		if (item.entityType.indexOf('Category') === -1 && item.id === Number(id)) {
			val = Object.assign({}, item);
			delete val.image;
		} else {
			if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
				val = _getMenuItem(id, item.activeMenuCategories);
			if (val === null && item.activeMenuItems && item.activeMenuItems.length > 0)
				val = _getMenuItem(id, item.activeMenuItems);
			if (val === null && item.children && item.children.length > 0)
				val = _getMenuItem(id, item.children);
		}
	});
	return val;
}

function getStore(id) {
	var store = null;
	inventories.stores.forEach(s => {
		if (s.id === Number(id))
			store = s;
	});
	return store;
}

export const setSelectedScale = (scale) => {
	localStorage.selected_scale = scale;
	$('#editInventory #selectedScale').html(scale);
}

export const populateAllItems = () => {

	if (!$('#editInventory #store').select2('val')) {
		$('#editInventory #store + span').removeClass('highlight-3');
		setTimeout(() => {
			$('#editInventory #store + span').addClass('highlight-3');
		}, 100)
		return;
	}

	function compare(a, b) {
		const menuItemA = getMenuItem(a.menuItem.id)
		const menuItemB = getMenuItem(b.menuItem.id);
		const menuItemAOrder = menuItemA.globalOrder;
		const menuItemBOrder = menuItemB.globalOrder;
		return menuItemAOrder - menuItemBOrder;
	}

	ajaxCallStart("populate", 0);

	const existingEntries = [];
	$('#entriesFreezeTable tbody tr').each((ind, row) => {
		existingEntries.push(Number($(row).find('td#product select').select2('val')) + "-" + Number($(row).find('td#quantity select.quantity_type').select2('val').split(';')[0]));
	});

	const store = $('#editInventory select#store').val();
	get("adminService/" + localStorage.restaurantSelected + "/" + store + "/getStocks").done(stocks => {
		function f(s) {
			const menuItem = getMenuItem(s.menuItem.id);
			if (!getMenuItem(s.menuItem.id) || getMenuItem(s.menuItem.id).type === "deleted")
				return false;

			return menuItem.defaultInventoryItem;
		}
		stocks.filter(f).sort(compare).forEach((stock, ind) => {
			//if (stock.count === 0 && (!stock.lastCheck || moment(stock.lastCheck).isBefore(moment().add(-1, 'month'))))
			//	return;
			const menuItem = getMenuItem(stock.menuItem.id);
			if (menuItem.recordState === "DELETED" || !menuItem.defaultInventoryItem)
				return;
			if (!menuItem.availableQuantities.filter(q => q.inventoryItem && q.quantity === stock.quantity).length)
				return;
			var found = false;
			if (found) return;
			if (!stock.menuItem)
				return;
			if (existingEntries.indexOf(stock.menuItem.id + "-" + stock.quantity) !== -1)
				return;

			//console.log(menuItem.id, menuItem.name, menuItem.defaultInventoryItem)

			const entry = addNewEntry({
				menuItem: menuItem,
				quantity: stock.quantity,
				quantityType: menuItem.quantityType,
				originalCount: stock.count
			}, false);

			checkEntry({
				currentTarget: entry
			})
		})
		ajaxCallEnd("populate");
	})
}

export const setViewType = (type) => {
	if (type === 'list') {
		$('#editInventory #view-type-dropdown').removeClass('i_list');
		$('#editInventory #view-type-dropdown').addClass('i_list_35');
		$('#editInventory').removeClass('thumbs');
		$('#editInventory').addClass('list')
	} else {
		$('#editInventory #view-type-dropdown').removeClass('i_list_35');
		$('#editInventory #view-type-dropdown').addClass('i_list');
		$('#editInventory').removeClass('list');
		$('#editInventory').addClass('thumbs')
	}
	localStorage.inventoryViewType = type;
}

export const waiterAccessOnly = () => {
	return auth.myStatus.roles.filter(role => role.role === "superadmin" || role.role === "admin" || role.role === "Store").length === 0;
}
