import admin, { getMenuItems, resultHandler, getStores, clearSelect2Cache, getQuantityForCount, getMenuItemById } from "../admin";
import $ from "jquery";
import moment from "moment";
import { tmplparams } from "../order-list-util";
import { startpoll, isVisible, confirmDialog, executeLongJob, getLocale, parseNumber, _floatTableHead, isTop, getKeyboardFocusableElements } from "../auth";
import Cleave from "cleave.js";
import { createTree } from "jquery.fancytree";
import { I18n } from "react-redux-i18n";
import languages from "../../langs/languages";
const { admin_local } = languages;

var loaded = false;

var inventories = {
  inventories: null
}
export const clearData = () => {
  loaded = false;
  inventories = {
    inventories: null
  };
  $('body').unbind('keyup', keyupHandler);
}

export const ready = () => {
  loaded = true;
  clearSelect2Cache();

  const def = $.Deferred();
  getMenuItems(function (data) {

    $('#inventoryScrappingTemplate').tmpl(tmplparams()).appendTo("#main");

    _floatTableHead("#listFreezeTable");
    _floatTableHead("#entriesFreezeTable");

    inventories.categories = data;
    $('#date.datepicker').datepicker({
      format: 'yyyy-mm-dd',
      uiLibrary: 'bootstrap4',
    });
    $('#time.timepicker').timepicker({
      format: 'HH:MM',
      uiLibrary: 'bootstrap4',
    });

    getStores(data => {

      inventories.stores = data
      admin.initializeSelect2($('select.select2')).done(() => {
        loadInventoryDisposals();

        startpoll(serverSideMessageHandler, [{ Restaurant: localStorage.restaurantSelected }]);

        updateHooks();

        $("select#product_filter.select2").change(function (e) {
          reload();
        });

        def.resolve();
      });
    });
  });
  return def.promise();
}

function keyupHandler(e) {
  switch (e.key) {
    case "+":
      if (isVisible($("#editInventoryDisposal.modal"))) addNewEntry();
      break;
    case "ArrowDown":
      if (isVisible($("#editInventoryDisposal"))) {
        var column = $("#entriesFreezeTable > table#entriesTable").find(":focus");
        var tr = column.parents("tr");
        var td = column.parents("td");
        if (tr.length === 1) {
          var tds = tr.find("td");
          var index = tds.toArray().indexOf(td[0]) + 1;
          tr = $(tr).next();
          td = tr.find("td:nth-child(" + index + ")");
          $(getKeyboardFocusableElements(td)).trigger('focus');
        }
        e.preventDefault();
        e.stopPropagation();
      } else {
      }
      break;
    case "ArrowUp":
      if (isVisible($("#editInventoryDisposal"))) {
        column = $("#entriesFreezeTable > table#entriesTable").find(":focus");
        tr = column.parents("tr");
        td = column.parents("td");
        if (tr.length === 1) {
          tds = tr.find("td");
          index = tds.toArray().indexOf(td[0]) + 1;
          tr = $(tr).prev();
          td = tr.find("td:nth-child(" + index + ")");
          $(getKeyboardFocusableElements(td)).trigger('focus');
        }
        e.preventDefault();
        e.stopPropagation();
      } else {
      }
      break;
    case "Insert":
      if (e.ctrlKey) {
        if (isVisible($("#editInventoryDisposal"))) {
          addNewEntry();
          e.preventDefault();
          e.stopPropagation();
        } else {
          $("button#add").click();
        }
      }
      break;
    case "Delete":
      if (e.ctrlKey) {
        if (isVisible($("#editInventoryDisposal"))) {
          var data = $("#entriesFreezeTable > table#entriesTable").find(":focus");
          e.currentTarget = data;
          deleteEntry(e);
        } else {
          $("button#delete").click();
        }
        e.preventDefault();
        e.stopPropagation();
      }
      break;
    case "Enter":
      if (!isTop($("#editInventoryDisposal"))) {
        $("button#edit").click();
        e.preventDefault();
      }
      break;
    default:
  }
}

export const reload = () => {
  loadInventoryDisposals();
}

function emptyIfNull(val) {
  if (val === null)
    return "";
  return val;
}

function loadInventoryDisposals() {
  if (!loaded)
    return;
  var filter = {
    from: localStorage.admin_stocklist_start,
    to: localStorage.admin_stocklist_end,
    menuitem: emptyIfNull($('select#product_filter').select2('val')),
  };

  admin.getEntitiesFiltered("InventoryDisposal", filter, processInventoryDisposals);
}

function serverSideMessageHandler(data) { }

function processInventoryDisposals(data) {
  data.forEach(function (v, i) {
    v.title = moment(v.date).format("YYYY-MM-DD HH:mm");
    v.folder = false;
    v.key = v.id;
  });
  if (!(inventories.inventories))
    inventories.inventories = createTree("#listFreezeTable > table#listTable", {
      checkbox: false,
      titlesTabbable: true, // Add all node titles to TAB chain
      source: data,
      zindex: 1000,
      icon: false,
      keyboard: true,
      extensions: ["table", "gridnav", "persist"],
      table: {
        checkboxColumnIdx: 0, // render the checkboxes into the this
        indentation: 16, // indent every node level by 16px
        nodeColumnIdx: 0
        // render node expander, icon, and title to this column (default:
        // #0)
      },
      persist: {
        cookiePrefix: "fancytree-1-InventoryDisposal",
        expandLazy: false,
        overrideSource: false, // true: cookie takes precedence
        // over `source` data
        // attributes.
        store: "auto" // 'cookie', 'local': use localStore,
        // 'session': sessionStore
      },
      gridnav: {
        autofocusInput: true, // Focus first embedded input if node
        // gets activated
        handleCursorKeys: true
        // Allow UP/DOWN in inputs to move to prev/next node
      },
      activate: function (event, data) {
        // A node was activated: display its title:
        var node = data.node;
        $("#edit").removeAttr("disabled");
        $("#delete").removeAttr("disabled");
        if (node.data.isActive) {
          $("#deactivate").removeAttr("disabled");
          $("#activate").prop("disabled", "true");
        } else {
          $("#deactivate").prop("disabled", "true");
          $("#activate").removeAttr("disabled");
        }
      },
      click: (event, data) => {
        if (inventories.clickTimestamp && inventories.selectedNodeKey === data.node.key && new Date().getTime() - inventories.clickTimestamp < 1000) {
          $('button#edit').click();
        };
        inventories.clickTimestamp = new Date().getTime();
        inventories.selectedNodeKey = data.node.key;
      },
      renderColumns: function (event, data) {
        var node = data.node, $tdList = $(node.tr).find(">td");

        node.expanded = true;

        $tdList.eq(1).text(getStore(node.data.store.id).name);

        var other = "";
        if (node.data.createdBy) {
          var t = moment(node.data.created).format("YYYY-MM-DD   HH:mm");
          other += "<div>" + t + " - " + node.data.createdBy.name + " - " + I18n.t("admin_local.created_by") + "</div>";
        }
        if (node.data.modifiedBy) {
          t = moment(node.data.modified).format("YYYY-MM-DD   HH:mm");
          other += "<div>" + t + " - " + node.data.modifiedBy.name + " - " + I18n.t("admin_local.modified_by") + "</div>";
        }

        var entries1 = "";
        var entries2 = "";
        var entries3 = "";
        node.data.entries.forEach((e, ind) => {
          getMenuItem(e.menuItem.id, menuItem => {
            menuItem.availableQuantities.forEach(quantity => {
              var clazz = "";
              if (quantity.quantity === e.quantity) {
                entries1 += '<div class="' + clazz + '">' + getLocale(menuItem.name) + " " + e.quantity + " " + I18n.t('local.' + menuItem.quantityType) + " " + getLocale(quantity.quantityType.name) + "</div>";
                entries2 += '<div class="' + clazz + '">' + e.count + "</div>";
                entries3 += '<div class="' + clazz + '">' + e.comment + "</div>";
              }
            });
            $tdList.eq(2).html(entries1);
            $tdList.eq(3).html(entries2);
            $tdList.eq(4).html(entries3);
          });
        });
        if (node.data.entries.length > 2) {
          entries1 += '<div class="total">&nbsp;...</div>';
          entries2 += '<div class="total">&nbsp;...</div>';
          entries3 += '<div class="total">&nbsp;...</div>';
        }
        $tdList.eq(2).html(entries1);
        $tdList.eq(3).html(entries2);
        $tdList.eq(4).html(entries3);

        $tdList.eq(5).html(other);
      }
    });
  else {
    inventories.inventories.reload(data);
    var node = inventories.inventories.getActiveNode();
    $('#edit').prop('disabled', node === null);
    $('#delete').prop('disabled', node === null);
  }
}

var tt = null;
var pattern = null;
function updateHooks() {
  $("#entriesFreezeTable > table#entriesTable tfoot .input_count,#entriesFreezeTable > table#entriesTable tfoot .input_ocount").each(function (ind, i) {
    i.data(
      "cleave",
      new Cleave(i, {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numeralPositiveOnly: true,
        numeralDecimalScale: 3,
        delimiter: " "
      })
    );
  });

  $("div#editInventoryDisposal").on("hidden.bs.modal", function (event) {
    $('#listTable').removeClass("hidden");
    $("#entriesFreezeTable > table tbody#entriesTable tr:not(.head)").remove();
  });

  $("div#editInventoryDisposal").on("shown.bs.modal", function (event) {
    var modal = $(this);
    var button = $(event.relatedTarget); // Button that triggered the modal
    var recipient = button.data("whatever"); // Extract info from data-*
    $('#listTable').addClass("hidden");
    $("#entriesFreezeTable > table tbody#entriesTable tr:not(.head)").remove();
    // attributes
    if (recipient === "edit") {
      var data = inventories.inventories.getActiveNode().data;
      modal.find("#id").val(data.id);
      var formattedDate = moment(data.date).format("YYYY-MM-DD");
      modal.find("input#date").val(formattedDate);
      formattedDate = moment(data.date).format("HH:mm");
      modal.find("input#time").val(formattedDate);

      $("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type")
        .select2()
        .change(function (e) {
          if (modal.find("#entriesFreezeTable > table tbody#entriesTable tr:not(.head)").length === 0) if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2("val") != null) addNewEntry();
          e.preventDefault();
        });
      data.entries.forEach(function (entry) {
        addNewEntry(entry);
      });
    } else {
      modal.find("#id").val("");
      modal.find("#active").prop("checked", "true");
      $("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type")
        .select2()
        .change(function (e) {
          if (modal.find("#entriesFreezeTable > table tbody#entriesTable tr:not(.head)").length === 0) if ($("#entriesFreezeTable > table tbody#entriesTable tr.head select.quantity_type").select2("val") != null) addNewEntry();
          e.preventDefault();
        });
      var m = moment();
      formattedDate = m.format("YYYY-MM-DD");
      modal.find("input#date").val(formattedDate);
      formattedDate = m.format("HH:mm");
      modal.find("input#time").val(formattedDate);
      //$(".datepicker").datepicker("setDate", new Date());
      $("textarea#regexp").keyup(function (e) {
        var p = $("textarea#regexp").val();
        if (pattern !== p && p !== "" && $("textarea#rawtextdata").val() !== "") {
          tt = new Date().getTime();
          var ttt = tt;
          setTimeout(function () {
            if (ttt === tt) {
              pattern = $("textarea#regexp").val();
              tryExecuteRegexp();
            }
          }, 1000);
        }
        e.preventDefault();
      });
    }

    modal.find("input#date").change(() => {
      modal.find("#entriesFreezeTable > table tbody tr").each((ind, entry) => {
        updateStockCount($(entry));
      });
    });
    modal.find("input#time").change(() => {
      modal.find("#entriesFreezeTable > table tbody tr").each((ind, entry) => {
        updateStockCount($(entry));
      });
    });
  });
}

function _addNewEntry2(data, index, tbody) {
  var existingRow = tbody.find("tr:nth-child(" + index + ")");
  if (existingRow.length === 1) {
    existingRow.removeClass("existing");
    Object.keys(data).forEach(function (key) {
      var value = data[key];
      var input = existingRow.find("input#" + key);
      if (input.attr("type") === "number") value = parseNumber(value);
      existingRow.find("input#" + key).val(value);
    });
  } else {
    var entry = $("script#InventoryDisposalEntryTemplate").tmpl(data);
    $("#entriesFreezeTable > table tbody#entriesTable").append(entry);
    // admin.initializeSelect2(entry.find('select.select2'));
  }
}
function _updateEntries2() {
  admin.initializeSelect2($("table tbody tr:not(.head) select.select2:not(.select2-hidden-accessible)"));
}

function updatePattern(entry, quantityType) {
  if (quantityType === "dl" || quantityType === "cl" || quantityType === "l") {
    entry.find('.input_count.count').attr("pattern", "\\d*[,.]?\\d+ ?(l|dl|cl)?")
  } else if (quantityType === "dkg" || quantityType === "g" || quantityType === "kg") {
    entry.find('.input_count.count').attr("pattern", "\\d*[.,]?\\d+ ?(g|dkg|kg)?")
  } else {
    entry.find('.input_count.count').attr("pattern", "\\d*[,.]?\\d+")
  }
}

export const addNewEntry = (data2) => {
  var data;
  $(".select2.select2-container.select2-container--default.select2-container--below.select2-container--open").prev("select").select2("close");

  if (typeof data2 == "undefined") data = { store: {}, vatCategory: {}, quantityType: {} };
  else data = data2;
  var entry = $("script#InventoryDisposalEntryTemplate").tmpl(data);
  entry.appendTo("div#editInventoryDisposal table tbody");
  admin.initializeSelect2(entry.find("select.select2")).done(function () {
    var default_quantity_type = data2 ? data2.quantityType : null;
    entry
      .find("select.product")
      .select2()
      .on("select2:select", function () {
        var menuItemId = $(this).select2('val');
        if (!menuItemId && data.menuItem) menuItemId = data.menuItem.id
        if (menuItemId)
          getMenuItem(menuItemId, menuItem => {
            updatePattern(entry, menuItem.quantityType);
            if (!menuItem.defaultInventoryItem || menuItem.type === "deleted") {
              var newOption = new Option(getLocale(menuItem.name), menuItem.id, false, false);
              entry.find('select.product').append(newOption).trigger('change');
              entry.find('select.product').select2().val(menuItem.id);
              entry.find('select.product').append(newOption).trigger('change');
              //handleProductChange(null, entry);
              checkEntry({ currentTarget: entry });
            }
            var quantityTypes = [];
            updatePattern(entry, menuItem.quantityType);
            menuItem.availableQuantities.forEach(quantity => {
              if (quantity.inventoryItem === true)
                quantityTypes.push({ id: quantity.quantity + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, quantity.quantity, 1) });
            });
            if (quantityTypes.length === 0) {
              quantityTypes.push({ id: 1 + ";" + menuItem.quantityType, text: getQuantityForCount(menuItem, 1, 1) });
            }
            //var s = entry.find('select.quantity_type').selectw();
            //entry.find('select.quantity_type').select2('destroy');
            entry.find("select.quantity_type option").remove();
            entry.find("select.quantity_type").select2({
              data: quantityTypes
            });
            if (default_quantity_type != null) {
              entry.find("select.quantity_type").val(data.quantity + ";" + default_quantity_type);
            } else if (quantityTypes.length > 0) entry.find("select.quantity_type").val(quantityTypes[0].id);
            updateStockCount(entry);
          });
      });
    if (data && data.menuItem) {
      entry
        .find("select.product")
        .select2()
        .val(data.menuItem.id);
      entry
        .find("select.product")
        .select2()
        .trigger("select2:select");
      entry
        .find("select.quantity_type")
        .select2()
        .val(data.quantity);
    }
    if (default_quantity_type != null) {
      var s = entry.find("select.quantity_type").select2({
        allowClear: true
      });
      s.val(data.quantity + ";" + default_quantity_type);
      s.trigger("change");
    }
    entry.find(".datepicker").datepicker({
      format: "yyyy-mm-dd",
      uiLibrary: "bootstrap4"
    });
    if (data.expiryDate) {
      var formattedDate = moment(data.expiryDate).format("YYYY-MM-DD");
      entry.find("input.expiry").val(formattedDate);
    }

    entry.find("input").change(checkEntry);
    entry
      .find("select")
      .select2()
      .change(checkEntry);
    entry.find(".input_count.count").each(function (ind, i) {
      $(i).data(
        "cleave",
        new Cleave($(i), {
          numeral: false,
        })
      );
    });
    entry.find(".input_count.ocount").each(function (ind, i) {
      $(i).data(
        "cleave",
        new Cleave($(i), {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralPositiveOnly: false,
          numeralDecimalScale: 3,
          delimiter: " "
        })
      );
    });
    entry.find("input.count").val(data.count);
    entry
      .find("select.quantity_type")
      .select2()
      .change(() => {
        updateStockCount(entry);
      });
    entry.find("input.expiry").change(() => {
      updateStockCount(entry);
    });

    checkEntry({ currentTarget: entry[0] });

    if (data2 == null) {
      entry.find("select.product").focus();
      entry.find("select.product").select2("open");
    }
  });

  entry.find('.input-numeral.count').on("change", (event) => {
    if (!event.currentTarget.checkValidity()) {
      $(event.currentTarget).val($(event.currentTarget).data("value"));
      $(event.currentTarget).trigger('change');
    } else {
      var value = event.currentTarget.value.replace(/,/g, '.');
      if (isNaN(value)) {
        const quantityType = entry.find('select.quantity_type').select2('val').split(';')[1];
        const quantity = entry.find('select.quantity_type').select2('val').split(';')[0];
        const found = value.match("(\\d*[,\\.]?\\d+) ?(cl|dl|l|g|dkg|kg)");
        if (!found) {
          $(event.currentTarget).val($(event.currentTarget).data("value"));
          $(event.currentTarget).trigger('change');
          return;
        }
        value = found[1];
        switch (found[2]) {
          case 'cl':
            value = value / 100;
            break;
          case 'dl':
            value = value / 10;
            break;
          case 'l':
            break;
          case 'g':
            value = value / 1000;
            break;
          case 'dkg':
            value = value / 100;
            break;
        }
        switch (quantityType) {
          case 'cl':
            value = value * 100;
            break;
          case 'dl':
            value = value * 10;
            break;
          case 'l':
            break;
          case 'g':
            value = value * 1000;
            break;
          case 'dkg':
            value = value * 100;
            break;
          case 'kg':
        }
        value = Math.round(1000 * value / quantity) / 1000;
        $(event.currentTarget).val(value);
        $(event.currentTarget).data("value", value);
        $(event.currentTarget).trigger('change');
      } else
        if (value != event.currentTarget.value) {
          $(event.currentTarget).val(value);
          $(event.currentTarget).data("value", value);
          $(event.currentTarget).trigger('change');
        } else
          $(event.currentTarget).data("value", event.currentTarget.value);
    }
  })
  entry.find('.input-numeral.count').on("keyup", (event) => {
    if (!event.currentTarget.checkValidity()) {
      $(event.currentTarget).addClass("invalid");
    } else {
      $(event.currentTarget).removeClass("invalid");
    }
  })
}

function updateStockCount(entry) {
  var modal = $("div#editInventoryDisposal");
  var menuItemId = entry.find("select.product").select2("val");
  var quantity = entry
    .find("select.quantity_type")
    .select2("val")
    .split(";")[0];
  var date = new Date(modal.find("input#date").val() + " " + modal.find("input#time").val()).getTime();
  var store = modal.find("select#store").select2("val");
  var expiry = new Date(entry.find("input.expiry").val()).getTime();
  if (isNaN(expiry)) expiry = "";
  var key = menuItemId + "-" + quantity + "-" + date + "-" + store + "-" + expiry;
  if ($(entry).data("key") === key) return;
  $(entry).data("key", key);
  admin.getCostAndPriceHistoryAt(
    menuItemId,
    quantity,
    store,
    expiry,
    date,
    data => {
      entry
        .find("input.ocount")
        .data('cleave')
        .setRawValue(data.count);
    },
    data => {
      entry
        .find("input.ocount")
        .data('cleave')
        .setRawValue(0);
    }
  );
}

export const saveInventoryDisposal = () => {
  var modal = $("div#editInventoryDisposal");
  var id = modal.find("#id").val();
  var inventory = {};
  inventory.date = new Date(modal.find("input#date").val() + " " + modal.find("input#time").val()).getTime();
  inventory.store = { id: modal.find("select#store").select2("val") };
  inventory.entries = [];
  modal.find("#entriesFreezeTable > table tbody#entriesTable tr:not(.head)").each(function (ind, e) {
    var product = $(e)
      .find("select.product")
      .select2("val");
    if (product == null) return;
    var entry = {};
    getMenuItem(product, menuItem => {

      entry.menuItem = { id: product, type: menuItem.entityType.toLowerCase() };
      entry.quantity = $(e)
        .find("select.quantity_type")
        .select2("val")
        .split(";")[0];
      entry.quantityType = $(e)
        .find("select.quantity_type")
        .select2("val")
        .split(";")[1];
      entry.id = $(e).attr("id");
      entry.count = parseNumber(
        $(e)
          .find("input.count")
          .val()
      );
      entry.expiryDate = new Date(
        $(e)
          .find("input.expiry")
          .val()
      ).getTime();
      entry.comment = $(e)
        .find("input.comment")
        .val();
      inventory.entries.push(entry);
    })

  });

  if (id !== "") {
    inventory.id = id;
    modifyInventoryDisposal(inventory, loadInventoryDisposals);
  } else {
    addInventoryDisposal(inventory, loadInventoryDisposals);
  }
}

export const deleteInventoryDisposal = () => {
  var data = inventories.inventories.getActiveNode().data;
  confirmDialog(I18n.t("local.confirmation"), I18n.t("admin_local.are_you_sure_you_want_to_delete_the_inventory")).done(function () {
    _deleteInventoryDisposal(data.id, loadInventoryDisposals);
  });
}

function addInventoryDisposal(InventoryDisposal, handler, errorHandler) {
  //eslint-disable-next-line no-useless-concat
  executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/addInventoryDisposal" + "?lang=" + localStorage.language, false, InventoryDisposal).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}

function modifyInventoryDisposal(InventoryDisposal, handler, errorHandler) {
  //eslint-disable-next-line no-useless-concat
  executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/modifyInventoryDisposal" + "?lang=" + localStorage.language, false, InventoryDisposal).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}

function _deleteInventoryDisposal(id, handler, errorHandler) {
  //eslint-disable-next-line no-useless-concat
  executeLongJob("", "adminService/" + localStorage.restaurantSelected + "/deleteInventoryDisposal/" + id + "?lang=" + localStorage.language, false).done(function (data) {
    resultHandler(data, handler, errorHandler);
  });
}

function checkEntry(e) {
  var ok = true;
  var entry = e.currentTarget.localName === "tr" ? $(e.currentTarget) : $(e.currentTarget).parents("tr");
  var v = parseNumber(entry.find("input.count").val());
  ok = ok && v !== undefined && v !== "" && v > 0;
  v = entry.find("select.product").select2("val");
  ok = ok && v !== undefined && v !== "" && v > 0;
  v = entry.find("select.quantity_type").select2("val");
  ok = ok && v !== undefined && v !== "";
  v = new Date(entry.find("input.expiry").val()).getTime();
  ok = ok && (isNaN(v) || v > 0);
  if (ok) entry.removeClass("error");
  else entry.addClass("error");
  $("button#save").attr("disabled", $("#entriesFreezeTable > table tbody#entriesTable tr.error").length > 0);
}

export const deleteEntry = (e) => {
  var entry = e.currentTarget.localName === "tr" ? $(e.currentTarget) : $(e.currentTarget).parents("tr");
  var p = entry.next();
  if (p.length === 0) p = entry.prev();

  var column = entry.find(":focus");
  var td = column.parents("td");
  var tds = entry.find("td");
  var index = tds.toArray().indexOf(td[0]) + 1;

  entry.remove();
  $("button#save").attr("disabled", $("#entriesFreezeTable > table tbody#entriesTable tr.error").length > 0);
  if (p.length === 1) {
    $(getKeyboardFocusableElements(p)).trigger('focus');
    td = p.find("td:nth-child(" + index + ")");
    $(getKeyboardFocusableElements(td)).trigger('focus');
  }
}

function tryExecuteRegexp() {
  try {
    // $('table tbody tr:not(.head)').remove();
    var tbody = $("#entriesFreezeTable > table tbody#entriesTable");
    var regexp = $("textarea#regexp").val();
    var text = $("textarea#rawtextdata").val();
    regexp = new RegExp(regexp, "ig");
    tbody.find("tr:not(.head)").addClass("existing");
    if (regexp.test(text)) {
      var m;
      var index = 2;
      do {
        m = regexp.exec(text);
        if (m != null && m.groups) {
          console.log(index);
          console.log(m.groups);
          _addNewEntry2(m.groups, index, tbody);
        }
        index++;
      } while (m != null && m.groups);
    }
    tbody.find("tr.existing").remove();
    _updateEntries2();
  } catch (ex) {
    console.log(ex);
  }
}

var deletedMenuItems = {}
function getMenuItem(id, handler) {
  var menuItem = _getMenuItem(id, inventories.categories.activeMenuCategories ? inventories.categories.activeMenuCategories : inventories.categories.children);
  if (menuItem === null || menuItem === '') {
    if (deletedMenuItems[id]) {
      handler(deletedMenuItems[id]);
      return menuItem;
    }
    getMenuItemById(id, menuItem => {
      menuItem.type = 'deleted';
      menuItem.name = admin_local.deleted + ' --- ' + getLocale(menuItem.name) + " --- ";
      deletedMenuItems[id] = menuItem;
      handler(menuItem);
    });
    return;
  }
  handler(menuItem);
  return menuItem;
}
function _getMenuItem(id, categories) {
  var val = null;
  categories.forEach(function (item) {
    if (val !== null)
      return;
    if (item.entityType.indexOf('Category') === -1 && item.id === Number(id)) {
      val = Object.assign({}, item);
      delete val.image;
    } else {
      if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
        val = _getMenuItem(id, item.activeMenuCategories);
      if (val === null && item.activeMenuItems && item.activeMenuItems.length > 0)
        val = _getMenuItem(id, item.activeMenuItems);
      if (val === null && item.children && item.children.length > 0)
        val = _getMenuItem(id, item.children);
    }
  });
  return val;
}

function getStore(id) {
  var store = null;
  inventories.stores.forEach(s => {
    if (s.id === Number(id))
      store = s;
  });
  return store;
}