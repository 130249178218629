import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import "../../flag-icon/css/flag-icon.min.css";
import languages, { locals, admin_locals } from "../../langs/languages";
import { Button } from "react-bootstrap";
import auth from "../../js/auth";
import { connect } from "react-redux";
import { dispatchChangeLanguage } from "../../actions/EatWithMeActions";
import { setLocale } from "react-redux-i18n";
import { store } from "../..";

const { admin_local, languages_and_countries } = languages;

const flagIcon = {
  borderRadius: "100%",
  height: "24px",
  width: "24px",
  marginRight: "9px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "32px 24px",
  backgroundPosition: "-4px 0px"
};

const flagIcon2 = {
  height: "50px",
  width: "50px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "50px auto"
};

class ChangeLanguage extends Component {
  state = {
    show: false
  };
  render() {
    var lang = "flag-icon-" + (typeof this.props.country != "undefined" ? this.props.country : this.props.language ? this.props.language : localStorage.language);
    var langs = this.props.restaurant_languages ? this.props.restaurant_languages : ["hu", "en", "it"];
    return (
      <React.Fragment>
        <span onClick={() => this.setState({ show: true })} className={lang + " hide-print"} style={flagIcon}></span>
        <Modal onHide={() => this.setState({ show: false })} show={this.state.show}>
          <Modal.Body>
            {langs.map(ll => (
              <div key={ll} style={{ flexShrink: 0, display: "flex", justifyContent: "space-around" }}>
                {languages_and_countries[ll].map(l => (
                  <div
                    key={l}
                    onClick={() => this.setLanguage(l, ll)}
                    className="round-button-3d"
                    style={{ flexShrink: 0, flexGrow: 1, height: "44px", width: "44px", borderRadius: "1em", display: "flex", justifyContent: "center" }}
                  >
                    <span className={this.getFlagClass(l)} style={flagIcon2}></span>
                  </div>
                ))}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ show: false })}>{admin_local.close}</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }

  setLanguage(country, language) {
    localStorage.country = country;
    localStorage.language = language;
    let local = locals[language];
    let admin_local = admin_locals[language];
    if (this.props.myStatus)
      auth.get("login/setLanguage/" + language).done(() => {
        // eslint-disable-next-line no-restricted-globals
        this.props.changeLanguage(local, admin_local, language, country);
      });
    // eslint-disable-next-line no-restricted-globals
    else this.props.changeLanguage(local, admin_local, language, country);
    this.setState({ show: false });
    store.dispatch(setLocale(localStorage.language));
  }

  getFlagClass(lang) {
    return "flag-icon flag-icon-" + lang;
  }
}

const mapStateToProps = state => {
  return {
    restaurant_languages: state.rootReducer.myStatus.restaurant_languages,
    language: state.rootReducer.language,
    myStatus: state.rootReducer.language,
    country: state.rootReducer.country,
    admin_local: state.rootReducer.admin_local,
    local: state.rootReducer.local
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLanguage: dispatchChangeLanguage(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage);
