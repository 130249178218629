import $ from "jquery";
import admin from "../admin";
import { startpoll, confirmDialog, get, getString } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import ClipboardJS from "clipboard";
import "../../css/printers.css";

class PrintersCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['savePrinter', 'deletePrinter', 'activatePrinter', 'installPrinterServer', 'generateAccessToken', 'copyToClipboard']);
	}

	clearData = () => {

	}

	ready = () => {

		$('#main').replaceWith($('#printersMainTemplate').tmpl({
			...tmplparams()
		}));

		new ClipboardJS('#copyToClipboard');

		this.loadPrinters();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	reload = () => {
		this.loadPrinters();
	}

	loadPrinters = () => {
		admin.getPrinters(this.processPrinters);
	}

	serverSideMessageHandler(data) {
	}


	printers = null;
	processPrinters = (data) => {
		data.forEach(function (v, i) {
			v.title = v.number;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});
		if (this.printers == null)
			this.printers = createTree("table#printers", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.connectedPrinter);
					$tdList.eq(3).text(node.data.format);
				}
			});
		else {
			this.printers.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editPrinter').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			if (recipient === 'edit') {
				var data = instance.printers.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#format').val(data.format);
				modal.find('#width').val(data.width);
				if (data.isActive === true)
					modal.find('#active').prop("checked", 'true');
				else
					modal.find('#active').removeAttr("checked");
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#active').prop("checked", 'true');
				modal.find('#format').val("html");
				modal.find('#width').val("48");
			}
		})
	}

	savePrinter = () => {
		var modal = $('div#editPrinter');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var format = modal.find('#format').val();
		var width = modal.find('#width').val();
		var active = modal.find('#active').prop('checked');
		if (id !== '') {
			admin.modifyPrinter(id, name, format, width, active, this.loadPrinters);
		} else {
			admin.addPrinter(name, format, width, active, this.loadPrinters);
		}
	}

	activatePrinter = (active) => {
		var data = this.printers.getActiveNode().data;
		admin.modifyPrinter(data.id, data.name, data.format, active, this.loadPrinters);
	}

	deletePrinter = () => {
		var data = this.printers.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_printer'), data.name)).done(function () {
			admin.deletePrinter(data.id, instance.loadPrinters);
		});

	}

	installPrinterServer = () => {
		//eslint-disable-next-line no-restricted-globals
		getString("adminService/" + localStorage.restaurantSelected + "/installPrinterServer/" + $('#accessTokenName').val()).done(data => {
			const filename = "installPrinterServer.bat";
			var file = new Blob([data], { type: "text" });
			if (window.navigator.msSaveOrOpenBlob) // IE10+
				window.navigator.msSaveOrOpenBlob(file, filename);
			else { // Others
				var a = document.createElement("a"),
					url = URL.createObjectURL(file);
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				setTimeout(function () {
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				}, 0);
			}
		});
		//location.href = "resources/EatWithMePrinterServer_install.exe";
	}

	generateAccessToken = () => {
		get("adminService/" + localStorage.restaurantSelected + "/generateAccessToken?name=" + $('#accessTokenName').val() + "&role=printerServer").done(data => {
			$('#accessToken').val(data.token);
		})
	}

	copyToClipboard = () => {
		//eslint-disable-next-line no-undef
		cordova.plugins.clipboard.clear();
		//eslint-disable-next-line no-undef
		cordova.plugins.clipboard.copy($('#accessToken').text());
	}

}

export default PrintersCode;