import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../..";
import EatWithMeActions from "../../actions/EatWithMeActions";

class AICommandBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.myStatus.restaurant_settings && this.props.myStatus.restaurant_settings.ai !== undefined && this.props.myStatus.restaurant_settings.ai.enabled === true ? (
          <form id="speechForm" style={{ display: "flex", width: "100%", margin: "0", padding: "0" }} onSubmit={this.handleSubmit}>
            {this.props.speechRecognitionAvailable === true ? (
              <button
                style={{ flexGrow: "0", flexShrink: "0", fontSize: "20px", width: "70px", padding: "0", color: "red" }}
                onClick={event => {
                  event.preventDefault();
                  if (this.props.speechRecognitionOn === false) this.props.ai.startSpeechListening();
                  else this.props.ai.stopSpeechListening();
                }}
                className={"speechRecording btn btn-none " + (this.props.speechRecognitionOn ? "icon-stop" : "icon-record")}
              ></button>
            ) : (
              ""
            )}
            <input
              onChange={this.handleChange2}
              value={this.props.speechCommand2}
              className="selectable browser-only"
              level="2"
              id="aicommand2"
              type="text"
              style={{ flexGrow: "1", flexShrink: "1", fontSize: "15px", height: "unset", marginBottom: "0px" }}
            />
            <input
              onChange={this.handleChange}
              value={this.props.speechCommand}
              className="selectable"
              level="2"
              id="aicommand"
              type="text"
              style={{ flexGrow: "1", flexShrink: "1", fontSize: "15px", height: "unset", marginBottom: "0px" }}
            />
            <button style={{ flexGrow: "0", flexShrink: "0", fontSize: "20px", width: "70px", padding: "0" }} type="submit" className="btn btn-none icon-paper-plane"></button>
          </form>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  handleSubmit = event => {
    this.props.ai.sendCommand([this.props.speechCommand]);
    event.preventDefault();
  };

  handleChange = event => {
    store.dispatch(EatWithMeActions.setSpeechCommand(event.target.value));
  };

  handleChange2 = event => {
    this.props.ai.textReceived([event.target.value]);
  };

}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
      speechCommand: props.rootReducer.speechCommand
    };
  }
  return {};
};

export default connect(mapStateToProps)(AICommandBox);
