import React from "react";
import EatWithMeText from "../../components/EatWIthMeText";
import UserImageButton from "../../components/icon-buttons/UserImageButton";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import AbstractMenuItem from "../../components/AbstractMenuItem";
import MenuItemHeader from "../../components/MenuItemHeader";
import { Grid } from "react-redux-grid";
import { store } from "../..";
import { connect } from "react-redux";
import restaurantConfig from "./restaurant_config";
import { Translate, I18n } from "react-redux-i18n";
import { Button } from "react-redux-grid/dist/components/plugins/pager/toolbar/Button";
import rolesConfig from "./roles_config";
import currenciesConfig from "./currency_config";
import paymentMethodsConfig from "./paymentMethods_config";
import { Tabs, Tab } from "react-bootstrap";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import RestaurantSettings from "./RestaurantSettings";
import RestaurantLocation from "./RestaurantLocation";
import "./styles.css";
import RestaurantEditor from "./RestaurantEditor";
import AbstractGrid from "./AbstractGrid";
import EatWithMeActions from "../../actions/EatWithMeActions";
import { restaurantGridStateKey } from "../../reducers/rootReducer";
import { getMyImageUrl, confirmDialog } from "../../js/auth";


Button.defaultProps.nextButtonText = I18n.t("admin_local.Grid.Pager.Button.nextButtonText");
Button.defaultProps.backButtonText = I18n.t("admin_local.Grid.Pager.Button.backButtonText");

class SuperRestaurantAdmin extends AbstractMenuItem {
  state = {};

  constructor(props) {
    super(props);
    this.layoutRef = React.createRef();
  }

  componentDidMount() {
    store.dispatch(EatWithMeActions.restaurantSelected(null))
  }

  componentDidUpdate() {
    if (this.state.cancel === true) {
      this.setState({ ...this.state, cancel: false });
    }
  }

  render() {
    const { myStatus } = this.props;
    if (myStatus) {
      return (
        <div id="restaurantSuperAdmin" style={{ display: "flex", flexFlow: "column", flexGrow: 1 }}>
          <div
            className="menuItemHeader"
            style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
          >
            <EatWithMeText />
            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.restaurants" />} />
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <ChangeLanguage />
              <UserImageButton src={getMyImageUrl()} />
            </div>
          </div>
          <div style={{ flexGrow: 1, position: "relative" }}>
            <SplitterLayout vertical={true}>
              <Grid {...this.props.restaurantConf} ref={g => (this.restaurantsGrid = g)}>
              </Grid>
              <Tabs>
                <Tab eventKey="location" title={I18n.t("admin_local.map")}>
                  <div ref={this.layoutRef} style={{ height: "100%", width: "100%" }}>
                    <RestaurantLocation key={this.layoutRef.current ? this.layoutRef.current.getBoundingClientRect().height : 0} height={this.layoutRef.current ? this.layoutRef.current.getBoundingClientRect().height : 0} cancel={this.state.cancel} handleSave={restaurant => this.performSave(restaurant)} handleCancel={this.performCancel} restaurant={this.props.restaurant} />
                  </div>
                </Tab>
                <Tab eventKey="baseSettings" title={I18n.t("admin_local.basic_data")}>
                  <RestaurantEditor
                    cancel={this.state.cancel}
                    handleSave={restaurant => this.performSave(restaurant)}
                    handleCancel={this.performCancel}
                    restaurant={this.props.restaurant}
                    myStatus={this.props.myStatus}
                  />
                </Tab>
                <Tab eventKey="settings" title={I18n.t("admin_local.settings")}>
                  <RestaurantSettings id="RetaurantSettings" cancel={this.state.cancel} handleSave={restaurant => this.performSave(restaurant)} handleCancel={this.performCancel} restaurant={this.props.restaurant} myStatus={this.props.myStatus} />
                </Tab>
                <Tab eventKey="roles" title={I18n.t("admin_local.roles")}>
                  <AbstractGrid {...this.props.rolesConf} restaurant={this.props.restaurant} onChange={this.updateRole}></AbstractGrid>
                </Tab>
                <Tab eventKey="currencies" title={I18n.t("admin_local.currencies")}>
                  <AbstractGrid {...this.props.currenciesConf} restaurant={this.props.restaurant}></AbstractGrid>
                </Tab>
                <Tab eventKey="paymentMethods" title={I18n.t("admin_local.payment_methods")}>
                  <AbstractGrid {...this.props.paymentMethodsConf} restaurant={this.props.restaurant} onChange={this.updatePaymentMethod} onDelete={this.deletePaymentMethod}></AbstractGrid>
                </Tab>
              </Tabs>
            </SplitterLayout>
          </div>
        </div>
      );
    }
    return null;
  }

  performSave(restaurant) {
    store.dispatch(EatWithMeActions.updateRow(restaurantGridStateKey, restaurant));
  }

  performCancel = () => {
    this.setState({ cancel: true });
  };

  deleteOrders = () => {
    confirmDialog(I18n.t("local.confirmation"), I18n.t("Are you sure you want to delete ALL the table occupations and orders?")).done(function () {
      //var restaurant = $("table#restaurants").fancytree('getTree').getActiveNode().data;
      /*
      superadmin.deleteAllTableOccupations(restaurant.id, function (data) {

      })*/
    });
  }

}

const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    const myStatus = props.rootReducer.myStatus;
    if (myStatus.currencies)
      return {
        restaurant: props.rootReducer.restaurant,
        admin_local: props.rootReducer.admin_local,
        local: props.rootReducer.local,
        myStatus: props.rootReducer.myStatus,
        nested: props.nested,
        restaurantConf: state.restaurantConf ? state.restaurantConf : restaurantConfig({ myStatus: myStatus }, store),
        rolesConf: state.rolesConf ? state.rolesConf : rolesConfig({ myStatus: myStatus }, store),
        currenciesConf: state.currenciesConf ? state.currenciesConf : currenciesConfig({ myStatus: myStatus }, store),
        paymentMethodsConf: state.paymentMethodsConf ? state.paymentMethodsConf : paymentMethodsConfig({ myStatus: myStatus }, store)
      };
    return {}
  }
  return {};
};

export default connect(mapStateToProps)(SuperRestaurantAdmin);
