import React from 'react';

const Line = ({ link, ...restProps }) => {
    return (
        <React.Fragment>
            <line
                {...restProps}
                stroke="lightgray"
            />
        </React.Fragment>
    )
};

export default Line;