import $ from "jquery";
import admin, { allNormalRoles, getMenuCategoryById, getMenuItemById } from "../admin";
import auth, { startpoll, confirmDialog, getLocale } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";
import SortedSet from "js-sorted-set";
import moment from "moment";

class DiscountTypesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveDiscountType', 'deleteDiscountType', 'activateDiscountType']);
	}

	ready = () => {

		try {

			$('#main').replaceWith($('#discountTypesMainTemplate').tmpl({
				...tmplparams()
			}));

			$('.datepicker#fromDate').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4'
			});
			$('.datepicker#toDate').datepicker({
				format: 'yyyy-mm-dd',
				uiLibrary: 'bootstrap4'
			});
			$('.timepicker#fromTime').timepicker({
				uiLibrary: 'bootstrap4'
			});
			$('.timepicker#toTime').timepicker({
				uiLibrary: 'bootstrap4'
			});

			const instance = this;

			admin.getMenuItems(function (data) {
				instance.categories = {
					children: data.children
				};
			});

			admin.getLabels(function (data) {
				instance.labels = data;
			});

			admin.getTables(function (data) {
				instance.tables = data.restaurantTables;
			});

			instance.roles = allNormalRoles;


			this.loadDiscountTypes();


			startpoll(this.serverSideMessageHandler);


			this.updateHooks();


			this.hooks();

		} catch (ex) {
			console.log(ex);
			console.log(ex.stack)
		}

	}

	reload = () => {
		this.loadDiscountTypes();
	}

	loadDiscountTypes = () => {
		admin.getDiscountTypes(this.processDiscountTypes);
	}

	serverSideMessageHandler = (data) => {
	}

	discountTypes = null;
	processDiscountTypes = (data) => {

		data.forEach(function (v, i) {
			v.title = v.name;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";

		});
		if (this.discountTypes === null)
			this.discountTypes = createTree("table#discountTypes", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 1
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");
					node.expanded = true;
					$tdList.eq(1).text(I18n.t("admin_local." + node.data.purpose));
					$tdList.eq(2).text(admin.getName(node.data.name, localStorage.language));
				}
			});
		else {
			this.discountTypes.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editDiscountType').on('show.bs.modal', function (event) {

			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			modal.find('#name').val("");
			[1, 2, 3, 4, 5, 6, 7].forEach(function (day) {
				modal.find('input#' + day + '.day').prop('checked', null);
			})
			instance.clearTokens(modal.find('#scope').tokenize2());
			instance.clearTokens(modal.find('#labels').tokenize2());
			instance.clearTokens(modal.find('#whom_labels').tokenize2());
			instance.clearTokens(modal.find('#excluded_scope').tokenize2());
			instance.clearTokens(modal.find('#excluded_labels').tokenize2());
			instance.clearTokens(modal.find('#excluded_whom_labels').tokenize2());
			instance.clearTokens(modal.find('#tables').tokenize2());
			instance.clearTokens(modal.find('#excluded_tables').tokenize2());
			instance.clearTokens(modal.find('#roles').tokenize2());
			instance.clearTokens(modal.find('#excluded_roles').tokenize2());
			auth.myStatus.restaurant_languages.forEach(function (lang) {
				modal.find('#name_' + lang).val("");
			});
			modal.find('#name_all').val("");

			if (recipient === 'edit') {
				var data = instance.discountTypes.getActiveNode().data;
				modal.find('#id').val(data.id);
				auth.myStatus.restaurant_languages.forEach(function (lang) {
					modal.find('#name_' + lang).val(data.name[lang]);
				});
				modal.find('#name_all').val(data.name.all);

				modal.find('#amount').val(data.amount);
				if (data.fromDate) {
					modal.find('#fromDate').val(moment(data.fromDate).format("YYYY-MM-DD"));
				} else
					modal.find('#fromDate').val(null);
				if (data.toDate)
					modal.find('#toDate').val(moment(data.toDate).format("YYYY-MM-DD"));
				else
					modal.find('#toDate').val(null);
				if (data.fromTime) {
					var h = ('0' + Math.floor(data.fromTime / 60));
					var m = ('0' + data.fromTime % 60);
					modal.find('#fromTime').val(h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length));
				} else
					modal.find('#fromTime').val(null);
				if (data.toTime) {
					h = ('0' + Math.floor(data.toTime / 60));
					m = ('0' + data.toTime % 60);
					modal.find('#toTime').val(h.substring(h.length - 2, h.length) + ":" + m.substring(m.length - 2, m.length));
				} else
					modal.find('#toTime').val();
				data.days.forEach(function (day) {
					modal.find('input#' + (day + 1) + '.day').prop('checked', true);
				})
				modal.find('input#amount_type').prop('checked', data.discountType === 'amount');
				modal.find('input#percent_type').prop('checked', data.discountType === 'percent');
				modal.find('input#cumulative').prop('checked', data.cumulative);
				modal.find('input#active').prop('checked', data.isActive);
				modal.find('input#perItem').prop('checked', data.perItem);

				modal.find('input#waiterOrder').prop('checked', data.waiterOrder);
				modal.find('input#customerOrder').prop('checked', data.customerOrder);
				modal.find('input#localConsumption').prop('checked', data.localConsumption);
				modal.find('input#takeawayConsumption').prop('checked', data.takeawayConsumption);

				data.menuCategoryIds.forEach(function (id) {
					var menuCategory = instance.getMenuCategory(id);
					if (menuCategory)
						modal.find('#scope').trigger('tokenize:tokens:add',
							['menuCategory;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name)]);
					else
						getMenuCategoryById(id, menuCategory => {
							modal.find('#scope').trigger('tokenize:tokens:add',
								['menuItem;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name) + "(" + I18n.t("admin_local.deleted") + ")"]);
						});
				});
				data.excludedMenuCategoryIds.forEach(function (id) {
					var menuCategory = instance.getMenuCategory(id);
					if (menuCategory)
						modal.find('#excluded_scope').trigger('tokenize:tokens:add',
							['menuCategory;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name)]);
					else
						getMenuCategoryById(id, menuCategory => {
							modal.find('#excluded_scope').trigger('tokenize:tokens:add',
								['menuItem;' + menuCategory.entityType + ';' + id + ';', instance.getLocal(menuCategory.name) + "(" + I18n.t("admin_local.deleted") + ")"]);
						});
				});
				data.menuItemIds.forEach(function (id) {
					var menuItem = instance.getMenuItem(id);
					if (menuItem)
						modal.find('#scope').trigger('tokenize:tokens:add',
							['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name)]);
					else
						getMenuItemById(id, menuItem => {
							modal.find('#scope').trigger('tokenize:tokens:add',
								['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name) + "(" + I18n.t("admin_local.deleted") + ")"]);
						});
				});
				data.excludedMenuItemIds.forEach(function (id) {
					var menuItem = instance.getMenuItem(id);
					if (menuItem)
						modal.find('#excluded_scope').trigger('tokenize:tokens:add',
							['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name)]);
					else
						getMenuItemById(id, menuItem => {
							modal.find('#excluded_scope').trigger('tokenize:tokens:add',
								['menuItem;' + menuItem.entityType + ';' + id + ';' + menuItem.quantity, instance.getLocal(menuItem.name) + "(" + I18n.t("admin_local.deleted") + ")"]);
						});
				});
				data.labels.forEach(function (label) {
					modal.find('#labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.whomLabels.forEach(function (label) {
					modal.find('#whom_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.exclusionLabels.forEach(function (label) {
					modal.find('#excluded_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.exclusionWhomLabels.forEach(function (label) {
					modal.find('#excluded_whom_labels').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name]);
				});
				data.tables.forEach(function (label) {
					modal.find('#tables').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name ? label.name + "(" + label.number + ")" : label.number]);
				});
				data.excludedTables.forEach(function (label) {
					modal.find('#excluded_tables').trigger('tokenize:tokens:add',
						['label;' + label.id + ';', label.name ? label.name + "(" + label.number + ")" : label.number]);
				});
				data.roles.forEach(function (role) {
					modal.find('#roles').trigger('tokenize:tokens:add',
						['role;' + role + ';', I18n.t("admin_local.role_names." + role)]);
				});
				data.excludedRoles.forEach(function (role) {
					modal.find('#excluded_roles').trigger('tokenize:tokens:add',
						['role;' + role + ';', I18n.t("admin_local.role_names." + role)]);
				});
				modal.find('input#apply_manual').prop('checked', !data.automatic);
				modal.find('input#apply_auto').prop('checked', data.automatic);
				modal.find('input#discount').prop('checked', data.purpose == "discount");
				modal.find('input#pointout').prop('checked', data.purpose == "pointout");
				modal.find('input#pointin').prop('checked', data.purpose == "pointin");


			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#fromDate').val("");
				modal.find('#toDate').val("");
				modal.find('#fromTime').val("");
				modal.find('#amount').val("");
				modal.find('#toTime').val("");
				modal.find('input#percent_type').prop('checked', true);
				modal.find('input#cumulative').prop('checked', false);
				modal.find('input#active').prop('checked', true);
				modal.find('input#perItem').prop('checked', false);
				modal.find('input#apply_manual').prop('checked', true);
				modal.find('input#discount').prop('checked', true);
				modal.find('input#pointout').prop('checked', false);
				modal.find('input#pointin').prop('checked', false);
				modal.find('input#waiterOrder').prop('checked', true);
				modal.find('input#customerOrder').prop('checked', true);
				modal.find('input#localConsumption').prop('checked', true);
				modal.find('input#takeawayConsumption').prop('checked', true);

			}


		})
	}

	saveDiscountType = () => {
		var modal = $('div#editDiscountType');
		var id = modal.find('#id').val();

		var discountType = {

		}
		discountType.name = {};
		modal.find('.name').each(function (ind, v) {
			if ($(v).val() !== "") {
				discountType.name[$(v).attr('lang')] = $(v).val();
			}
		});

		discountType.amount = modal.find('input#amount').val();
		discountType.fromDate = modal.find('input#fromDate').val();
		discountType.toDate = modal.find('input#toDate').val();
		discountType.fromTime = modal.find('input#fromTime').val();
		if (discountType.fromTime !== '') {
			discountType.fromTime = Number(discountType.fromTime.split(':')[0] * 60) + Number(discountType.fromTime.split(':')[1]);
		} else {
			discountType.fromTime = null;
		}
		discountType.toTime = modal.find('input#toTime').val();
		if (discountType.toTime !== '') {
			discountType.toTime = Number(discountType.toTime.split(':')[0] * 60) + Number(discountType.toTime.split(':')[1]);
		} else {
			discountType.toTime = null;
		}
		discountType.discountType = modal.find('input#amount_type').prop('checked') ? 'amount' : 'percent';
		discountType.days = [];
		[1, 2, 3, 4, 5, 6, 7].forEach(function (day) {
			var d = modal.find('input#' + day + '.day').prop('checked');
			if (d)
				discountType.days.push(day - 1);
		})
		discountType.isActive = modal.find('input#active').prop('checked');
		discountType.cumulative = modal.find('input#cumulative').prop('checked');
		discountType.perItem = modal.find('input#perItem').prop('checked');
		discountType.automatic = modal.find('input#apply_auto').prop('checked');

		discountType.waiterOrder = modal.find('input#waiterOrder').prop('checked');
		discountType.customerOrder = modal.find('input#customerOrder').prop('checked');
		discountType.localConsumption = modal.find('input#localConsumption').prop('checked');
		discountType.takeawayConsumption = modal.find('input#takeawayConsumption').prop('checked');

		discountType.purpose = modal.find('input[name = "purpose"]:checked').prop('id');
		discountType.menuItems = [];
		discountType.menuCategories = [];
		discountType.excludedMenuItems = [];
		discountType.excludedMenuCategories = [];
		discountType.labels = [];
		discountType.whomLabels = [];
		discountType.tables = [];
		discountType.excludedTables = [];
		discountType.exclusionLabels = [];
		discountType.exclusionWhomLabels = [];
		discountType.roles = [];
		discountType.excludedRoles = [];

		var a = modal.find('#scope');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			var entityType = id[1];
			if (id[0] === 'menuItem')
				discountType.menuItems.push({
					id: id[2],
					type: entityType,
				});
			else
				discountType.menuCategories.push({
					id: id[2],
					type: entityType,
				});
		});
		a = modal.find('#excluded_scope');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			var entityType = id[1];
			if (id[0] === 'menuItem')
				discountType.excludedMenuItems.push({
					id: id[2],
					type: entityType,
				});
			else
				discountType.excludedMenuCategories.push({
					id: id[2],
					type: entityType,
				});
		});
		a = modal.find('#labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.labels.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#excluded_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.exclusionLabels.push({
				id: id[1],
				name: id[0]
			});
		});

		a = modal.find('#tables');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.tables.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#excluded_tables');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.excludedTables.push({
				id: id[1],
				name: id[0]
			});
		});


		a = modal.find('#roles');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.roles.push(id[1]);
		});
		a = modal.find('#excluded_roles');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.excludedRoles.push(id[1]);
		});

		a = modal.find('#whom_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.whomLabels.push({
				id: id[1],
				name: id[0]
			});
		});
		a = modal.find('#excluded_whom_labels');
		a.tokenize2().toArray().forEach(function (addition) {
			var id = addition.split(";");
			discountType.exclusionWhomLabels.push({
				id: id[1],
				name: id[0]
			});
		});


		if (id !== undefined && id !== '') {
			discountType.id = Number(id);
			admin.modifyDiscountType(discountType, this.loadDiscountTypes);
		} else {
			admin.addDiscountType(discountType, this.loadDiscountTypes);
		}
	}

	activateDiscountType = (active) => {
		var data = { ...this.discountTypes.getActiveNode().data };
		data.isActive = active;
		delete data.menuItemSpecific;
		delete data.tableMateSpecific;
		admin.modifyDiscountType(data, this.loadDiscountTypes);
	}

	deleteDiscountType = () => {
		const instance = this;
		var data = this.discountTypes.getActiveNode().data;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_discount_type'), getLocale(data.name))).done(function () {
			admin.deleteDiscountType(data.id, instance.loadDiscountTypes);
		});
	}

	hooks = () => {
		const instance = this;
		var options = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getMenuItems(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};
		var options2 = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getLabels(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};
		$('div#editDiscountType #scope').tokenize2(options);
		$('div#editDiscountType #labels').tokenize2(options2);
		$('div#editDiscountType #whom_labels').tokenize2(options2);
		$('div#editDiscountType #excluded_scope').tokenize2(options);
		$('div#editDiscountType #excluded_labels').tokenize2(options2);
		$('div#editDiscountType #excluded_whom_labels').tokenize2(options2);

		var options3 = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getTables(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};
		$('div#editDiscountType #tables').tokenize2(options3);
		$('div#editDiscountType #excluded_tables').tokenize2(options3);

		var options3 = {
			placeholder: "",
			tokensAllowCustom: true,
			searchMinLength: 0,
			dataSource: function (term, object) {
				object.trigger('tokenize:dropdown:fill', [instance.getRoles(term)]);
			},
			displayNoResultsMessage: true,
			noResultsMessageText: I18n.t('local.noResultsMessageText'),
			dropdownMaxItems: 7,
			searchFromStart: false,
		};

		$('div#editDiscountType #roles').tokenize2(options3);
		$('div#editDiscountType #excluded_roles').tokenize2(options3);
	}


	populateTokenizers = (modal, recipient, data, menuCategoryId) => {
		this.clearTokens(modal.find('#scope').tokenize2());
		this.clearTokens(modal.find('#labels').tokenize2());
		this.clearTokens(modal.find('#whom_labels').tokenize2());
		this.clearTokens(modal.find('#excluded_scope').tokenize2());
		this.clearTokens(modal.find('#excluded_labels').tokenize2());
		this.clearTokens(modal.find('#excluded_whom_labels').tokenize2());
	}

	clearTokens = (tokenizer) => {
		tokenizer.toArray().forEach(function (addition) {
			tokenizer.trigger('tokenize:tokens:remove', addition);
		});
	}

	getMenuItems = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getMenuItems(search, set, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}


	_getMenuItems = (s, set, categories) => {
		const instance = this;
		categories.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			if (typeof item.activeMenuItems === 'undefined' && typeof item.children === 'undefined') {
				var name = instance.getLocal(item.name);
				var r = regexp.test(name);
				if (r) {
					if (item.availableQuantities.length === 1)
						set.insert({
							value: "menuItem;" + item.entityType + ";" + item.id + ";" + item.availableQuantities[0].quantity,
							text: name
						})
					else {
						item.availableQuantities.forEach(function (q) {
							set.insert({
								value: "menuItem;" + item.entityType + ";" + item.id + ";" + q.quantity,
								text: name + " " + q.quantity + " " + I18n.t('local.' + item.discountType)
							})
						});
					}
				}
			} else {
				name = instance.getLocal(item.name);
				r = regexp.test(name);
				if (r) {
					set.insert({
						value: "menuCategory;" + item.entityType + ";" + item.id + ";",
						text: name
					})
				}
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					instance._getMenuItems(s, set, item.activeMenuCategories);
				if (item.activeMenuItems && item.activeMenuItems.length > 0)
					instance._getMenuItems(s, set, item.activeMenuItems);
				if (item.children && item.children.length > 0)
					instance._getMenuItems(s, set, item.children);
			}
		});
	}

	getLabels = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getLabels(search, set, this.labels);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}

	getTables = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getTables(search, set, this.tables);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}

	_getLabels = (s, set, labels) => {
		labels.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			var name = item.name;
			var r = regexp.test(name);
			if (r) {
				set.insert({
					value: "label;" + item.id + ";",
					text: name
				})
			}
		});
	}

	_getTables = (s, set, tables) => {
		tables.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			var name = item.name || "";
			var r = regexp.test(name + " (" + item.number + ")");
			if (r) {
				set.insert({
					value: "table;" + item.id + ";",
					text: name ? name + " (" + item.number + ")" : "" + item.number
				})
			}
		});
	}

	getRoles = (search) => {
		var set = new SortedSet({
			comparator: function (a, b) {
				if (a.text.toLowerCase().startsWith(search.toLowerCase()) && !b.text.toLowerCase().startsWith(search.toLowerCase()))
					return -1;
				if (b.text.toLowerCase().startsWith(search.toLowerCase()) && !a.text.toLowerCase().startsWith(search.toLowerCase()))
					return 1;
				return a.text.localeCompare(b.text);
			}
		});
		var menuItem = this._getRoles(search, set, this.roles);
		if (menuItem === '') {
			return null;
		}
		var result = [];
		set.forEach(function (e) {
			result[result.length] = e;
		});
		return result;
	}

	_getRoles = (s, set, roles) => {
		roles.forEach(function (item) {
			var regexp = new RegExp(s, 'ig');
			var name = item;
			var r = regexp.test(I18n.t("admin_local.role_names." + name));
			if (r) {
				set.insert({
					value: "roles;" + item + ";",
					text: I18n.t("admin_local.role_names." + name)
				})
			}
		});
	}

	getLocal = (name) => {
		if (typeof name === 'string')
			return name;
		return name[localStorage.language] ? name[localStorage.language] : name['all'];
	}


	getMenuItem = (id) => {
		var menuItem = this._getMenuItem(id, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			return null;
		}
		return menuItem;
	}

	_getMenuItem = (id, categories) => {
		const instance = this;
		var val = null;
		categories.forEach(function (item) {
			if (val !== null)
				return;
			if (item.entityType.indexOf('Category') < 0 && Number(item.id) === Number(id)) {
				val = item;
			} else {
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					val = instance._getMenuItem(id, item.activeMenuCategories);
				if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0)
					val = instance._getMenuItem(id, item.activeMenuItems);
				if (val == null && item.children && item.children.length > 0)
					val = instance._getMenuItem(id, item.children);
			}
		});
		return val;
	}

	getMenuCategory = (id) => {
		var menuItem = this._getMenuCategory(id, this.categories.activeMenuCategories ? this.categories.activeMenuCategories : this.categories.children);
		if (menuItem === '') {
			console.log("failed to find menu item " + id);
			return null;
		}
		return menuItem;
	}

	_getMenuCategory = (id, categories) => {
		const instance = this;
		var val = null;
		categories.forEach(function (item) {
			if (val != null)
				return;
			if (item.entityType.indexOf('Category') > 0 && Number(item.id) === Number(id)) {
				val = item;
			} else {
				if (item.activeMenuCategories && item.activeMenuCategories.length > 0)
					val = instance._getMenuCategory(id, item.activeMenuCategories);
				if (val == null && item.activeMenuItems && item.activeMenuItems.length > 0)
					val = instance._getMenuCategory(id, item.activeMenuItems);
				if (val == null && item.children && item.children.length > 0)
					val = instance._getMenuCategory(id, item.children);
			}
		});
		return val;
	}

	clearTokens = (tokenizer) => {
		tokenizer.toArray().forEach(function (addition) {
			tokenizer.trigger('tokenize:tokens:remove', addition);
		});

	}
}

export default DiscountTypesCode;