import React, { Component } from "react";
import orderListUtil, { prevState, selectNextUnprocessedOrder, nextState } from "../../js/order-list-util";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, stopIntervals } from "../../js/drinkbar";
import LogoutButton from "../../components/icon-buttons/LogoutButton";
import LoginButton from "../../components/icon-buttons/LoginButton";
import AICommandBox from "../../js/ai/AICommandBox";
import auth, { getMyImageUrl, avoidMultipleClick, isDuplicateClickEvent } from "../../js/auth";
import EatWithMeText from "../../components/EatWIthMeText";
import MenuItemHeader from "../../components/MenuItemHeader";
import ChangeLanguage from "../../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../../components/icon-buttons/UserImageButton";

class DrinkBar extends Component {
  state = {
    initialized: false
  };

  componentDidMount() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id) {
      ready(this.props);
      this.setState({ initialized: true });
    }
    window.avoidMultipleClick = avoidMultipleClick;
    window.isDuplicateClickEvent = isDuplicateClickEvent;
    window.editOrder = function () { };
  }

  componentDidUpdate() {
    const { myStatus } = this.props;
    orderListUtil.props = this.props;

    if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
      ready(this.props);
      this.setState({ initialized: true });
    }
  }

  componentWillUnmount() {
    stopIntervals();
    this.setState({ initialized: false });

    delete window.avoidMultipleClick;
    delete window.editOrder;
    delete window.isDuplicateClickEvent;
  }

  render() {
    const { myStatus } = this.props;
    if (myStatus && myStatus.restaurant_id) {
      return (
        <div style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
          {!auth.isIncludedInFrame ? (
            <div
              className="menuItemHeader"
              style={{ flexShrink: 0, display: "flex", flexFlow: "row", width: "100%", alignItems: "center", height: "50px", justifyContent: "space-between", paddingRight: "30px" }}
            >
              <EatWithMeText />
              <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.bar" />} />
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <ChangeLanguage />
                <UserImageButton src={getMyImageUrl()} />
                <div style={{ marginLeft: "10px", width: "70px", display: "flex", justifyContent: "space-between" }}>
                  <LoginButton query="keep=true&redirect=/tablesOverview" />
                  <LogoutButton query="keep=true&redirect=/tablesOverview" />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div id="main" ></div>
          <div style={{ height: "6rem", flexShrink: "0", display: "flex" }}>
            <button onClick={prevState} id="" style={{ height: "6rem", flexGrow: "1" }} type="button" className="btn btn-info">
              <span style={{ fontSize: "4rem" }} className="icon icon-left"></span>
            </button>
            <button onClick={() => selectNextUnprocessedOrder(true)} id="" style={{ height: "6rem", flexGrow: "1" }} type="button" className="btn btn-info">
              <span style={{ fontSize: "4rem" }} className="icon icon-down-circled"></span>
            </button>
            <button onClick={nextState} id="" style={{ height: "6rem", flexGrow: "1" }} type="button" className="btn btn-info">
              <span style={{ fontSize: "4rem" }} className="icon icon-right"></span>
            </button>
          </div>
          <div id="editMenuItemDialog" />
          <div id="editDiscountDialog" />
          <div id="markTableMateForPaymentDialog" />
          <div id="tableMatePaidDialog" />
          {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
        </div>
      );
    } else return null;
  }
}
const mapStateToProps = (props, state) => {
  if (props.rootReducer.myStatus) {
    return {
      restaurant: props.rootReducer.restaurant,
      myStatus: props.rootReducer.myStatus,
      admin_local: props.rootReducer.admin_local,
      local: props.rootReducer.local,
      speechRecognitionOn: props.rootReducer.speechRecognitionOn,
      ai: props.rootReducer.ai,
      speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable
    };
  }
  return {};
};

export default connect(mapStateToProps)(DrinkBar);
