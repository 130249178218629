import $ from "jquery";
import admin from "../admin";
import { startpoll, confirmDialog, scaleMeasure } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";
import EntityPageCode from "../../components/EntityPageCode";

class ScalesCode extends EntityPageCode {

	constructor() {
		super();
		super.publishFunctions(['saveScale', 'deleteScale', 'activateScale', 'installScaleServer', 'testScale']);
	}

	clearData = () => {

	}

	ready = () => {

		$('#main').replaceWith($('#scalesMainTemplate').tmpl({
			...tmplparams()
		}));

		this.loadScales();

		startpoll(this.serverSideMessageHandler);

		this.updateHooks();


	}

	reload = () => {
		this.loadScales();
	}

	loadScales = () => {
		admin.getScales(this.processScales);
	}

	serverSideMessageHandler(data) {
	}


	Scales = null;
	processScales = (data) => {
		data.forEach(function (v, i) {
			v.title = v.name;
			v.type2 = v.type;
			v.folder = false;
			if (!v.isActive)
				v.extraClasses = "itemDeactivated";
			if (v.shareable)
				v.extraClasses += " itemShareable";
		});
		if (this.Scales == null)
			this.Scales = createTree("table#Scales", {
				checkbox: false,
				titlesTabbable: true, // Add all node titles to TAB chain
				source: data,
				zindex: 1000,
				icon: false,
				extensions: ["table", "gridnav", "persist"],
				table: {
					checkboxColumnIdx: 0, // render the checkboxes into the this
					// column index (default: nodeColumnIdx)
					indentation: 16, // indent every node level by 16px
					nodeColumnIdx: 2
					// render node expander, icon, and title to this column (default:
					// #0)
				},
				gridnav: {
					autofocusInput: false, // Focus first embedded input if node
					// gets activated
					handleCursorKeys: true
					// Allow UP/DOWN in inputs to move to prev/next node
				},

				activate: function (event, data) {
					// A node was activated: display its title:
					var node = data.node;
					$('#edit').removeAttr('disabled');
					$('#delete').removeAttr('disabled');
					if (node.data.isActive) {
						$('#deactivate').removeAttr('disabled');
						$('#activate').prop('disabled', 'true');
					} else {
						$('#deactivate').prop('disabled', 'true');
						$('#activate').removeAttr('disabled');
					}

				},
				renderColumns: function (event, data) {
					var node = data.node, $tdList = $(node.tr).find(">td");

					node.expanded = true;

					$tdList.eq(1).text(node.data.name);
					$tdList.eq(2).text(node.data.connectedDevice);
					$tdList.eq(3).text(node.data.format);
					$tdList.eq(4).text(node.data.type2);
				}
			});
		else {
			this.Scales.reload(data);
			$('#edit').prop('disabled', 'true');
			$('#activate').prop('disabled', 'true');
			$('#deactivate').prop('disabled', 'true');
			$('#delete').prop('disabled', 'true');
		}
	}

	updateHooks = () => {
		const instance = this;
		$('div#editScale').on('show.bs.modal', function (event) {
			var modal = $(this)
			var button = $(event.relatedTarget) // Button that triggered the modal
			var recipient = button.data('whatever') // Extract info from data-*
			// attributes
			if (recipient === 'edit') {
				var data = instance.Scales.getActiveNode().data;
				modal.find('#id').val(data.id);
				modal.find('#name').val(data.name);
				modal.find('#format').val(data.format);
				modal.find('#type').val(data.type2);
				if (data.isActive === true)
					modal.find('#active').prop("checked", 'true');
				else
					modal.find('#active').removeAttr("checked");
			} else {
				modal.find('#id').val("");
				modal.find('#name').val("");
				modal.find('#active').prop("checked", 'true');
				modal.find('#format').val("qrcode");
				modal.find('#type').val("fiscat");
			}
		})
	}

	saveScale = () => {
		var modal = $('div#editScale');
		var id = modal.find('#id').val();
		var name = modal.find('#name').val();
		var format = modal.find('#format').val();
		var type = modal.find('#type').val();
		var active = modal.find('#active').prop('checked');

		if (id !== '') {
			admin.modifyScale(id, name, format, type, active, this.loadScales);
		} else {
			admin.addScale(name, format, type, active, this.loadScales);
		}
	}

	activateScale = (active) => {
		var data = this.Scales.getActiveNode().data;
		admin.modifyScale(data.id, data.name, data.format, data.type, active, this.loadScales);
	}

	deleteScale = () => {
		var data = this.Scales.getActiveNode().data;
		const instance = this;
		confirmDialog(I18n.t('local.confirmation'), String.format(I18n.t('admin_local.are_you_sure_you_want_to_delete_the_cashregister'), data.name)).done(function () {
			admin.deleteScale(data.id, instance.loadScales);
		});

	}

	installScaleServer = () => {
		//eslint-disable-next-line no-restricted-globals
		location.href = "resources/EatWithMeScaleServer_install.exe";
	}

	testScale = () => {
		$("span#testresult").html("");
		scaleMeasure($('#name').val()).done(result => {
			$("span#testresult").html(result + "g");
		})
	}

}

export default ScalesCode;