import $ from "jquery";
import auth, { navigateToLogin, startpoll, messageDialog, confirmDialog, post, updateToolTips, getImageById, getGlobal, loadMyImage, getMyImageUrl } from "../auth";

import languages from "../../langs/languages";
import { crop } from "../camera-util";

const { local } = languages;

var rcrop = false;

export const clearData = () => {

}

export const reload = () => {

}

export const ready = () => {
	const def = $.Deferred();

	startpoll(function serverSideMessageHandler(data) {
		try {
			if (data === null) {
				console.log("Failed wait for refresh!");
			} else {
				if (data.type === 'timeout') {
				} else {
					console.log("Refresh event received");
					if (data.type === 'refresh' && data.message === 'true') {
						console.log("Refresh event received");
					} else if (data.type === 'message') {
						messageDialog(String.format(local.message_from, data.from), String.format(local[data.message], data.from));
					}
				}
			}
		} catch (ex) {
			console.log(ex);
		}
	});

	$('script#profileTemplate').tmpl({ local: local }).appendTo($('div#main'));

	getGlobal("customerService/getCustomer?role=eatwithme").done(function (data) {
		if (data === null) {
			console.log("Failed to update profile!");
		} else {
			if (data.error) {
				//eslint-disable-next-line no-eval
				messageDialog(local.error_message, eval(data.error));
			} else if (data.question) {
				try {
					//eslint-disable-next-line no-eval
					confirmDialog(local.question, eval(data.question)).done(function () {
					});
				} catch (ex) {
					alert(ex);
				}
			} else {
				$('#first-name').val(data.name);
				$('#sur-name').val(data.surname);
				$('#email').val(data.email);
				$('#phone').val(data.phone);
				$('#address').val(data.address);
				$('#town').val(data.town);
				$('#postcode').val(data.postcode);
				$('#country').val(data.country);
				$('#quick-access-code').val(data.quickAccessCode);
				if (data.requireQuickAccessCode === true)
					$('#require-quick-access-code').prop('checked', 'true');
			}
			$('#smallImage').attr('src', getImageById(data.id));
			updateToolTips();
		}
		def.resolve();
	});

	return def.promise();

}



export const saveProfile = (restaurant, table, action) => {
	var password = $('input#password').val();
	var quickAccessCode = $('input#quick-access-code').val();
	post("customerService/updateProfile", {
		'firstName': $('input#first-name').val(),
		'surName': $('input#sur-name').val(),
		'email': localStorage.email,
		'newEmail': $('input#email').val(),
		'phone': $('input#phone').val(),
		'address': $('input#address').val(),
		'town': $('input#town').val(),
		'country': $('input#country').val(),
		'postcode': $('input#postcode').val(),
		'password': password !== null && password !== '' ? password : "",
		'quickAccessCode': quickAccessCode !== null && quickAccessCode !== '' ? quickAccessCode : "",
		'requireQuickAccessCode': $('input#require-quick-access-code').prop('checked'),
		'image': $('#smallImage').parent().hasClass('rcrop-wrapper') ? $('#smallImage').rcrop('getDataURL', 160, 160) : $('#smallImage').attr('src'),
	}).done(function (data) {
		if (data === null) {
			console.log("Failed to update profile!");
		} else {
			if (data.type === 'error') {
				//eslint-disable-next-line no-eval
				messageDialog(local.error_message, eval(data.message));
			} else if (data.question) {
				$(".user-img").attr("src", "");
				setTimeout(loadMyImage, 1000);
				try {
					//eslint-disable-next-line no-eval
					confirmDialog(local.question, eval(data.question)).done(function () {
					});
				} catch (ex) {
					alert(ex);
				}
			} else {
				$('#crop').hide();
				$("#user_img").each(function (ind, userimg) {
					$(userimg).attr("src", "");
					setTimeout(() => {
						$(userimg).attr("src", getMyImageUrl());
					}, 1000)
				});
				messageDialog(local.success_message, local.profileUpdatedSuccessfully);
				localStorage.email = $('input#email').val();
				if (rcrop === true) {
					crop();
					rcrop = false;
				}
			}
		}
	});
}

export const deleteProfile = (restaurant, table, action) => {
	confirmDialog(local.confirmation, local.confirm_account_deletion).done(function () {
		getGlobal("customerService/deleteProfile").done(function (data) {
			if (data === null) {
				console.log("Failed to delete profile!");
			} else if (data.error) {
				//eslint-disable-next-line no-eval
				messageDialog(local.error_message, eval(data.error));
			} else if (data.success) {
				//eslint-disable-next-line no-eval
				messageDialog(local.success_message, eval(data.success)).done(function () {
					localStorage.email = null;
					localStorage.id = null;
					navigateToLogin();
				});
			}
		});
	});
}
