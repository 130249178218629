import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { getRowFromStore, flattenObject } from "../components/utils";
import EatWithMeActions from "../actions/EatWithMeActions";
import CustomerEmailAutoComplete from "../components/CustomerEmailAutoComplete";
import RestaurantAutoComplete from "../components/RestaurantAutoComplete";

export class CheckBoxEditor extends Component {
  state = {};

  render() {
    const { store, stateKey, column, row, onChange, value } = this.props;
    return <input onChange={this.handleCheckBoxChange.bind(null, { store, stateKey, column, row, onChange })} type="checkbox" defaultChecked={value} required />;
  }

  handleCheckBoxChange = ({ store, stateKey, column, row }, event) => {
    const target = event.target;
    var values = row;
    values[column.dataIndex] = target.checked;
    const originalRow = getRowFromStore(stateKey, row._key);
    const newRow = { ...originalRow, ...values };
    store.dispatch(EatWithMeActions.updateRow(stateKey, newRow));
    this.setState({ value: target.checked });
  };
}

export class DropDownEditor extends Component {
  render() {
    const { store, stateKey, column, row, options, onChange, value } = this.props;
    if (options === undefined || options.find === undefined) return "";
    const option = options.find(v => v.id === value);
    const value2 = option ? options.find(v => v.id === value).name : "";
    return (
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ minWidth: "10rem" }}>
          {value2}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map(option => {
            return (
              <Dropdown.Item onClick={this.handleDropDownChange.bind(null, { store, stateKey, column, row, onChange })} id={option.id} key={row.key + "-" + option.id}>
                {option.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  handleDropDownChange = ({ store, stateKey, column, row }, event) => {
    const target = event.target;
    var values = row;
    values[column.dataIndex] = isNaN(target.id) ? target.id : Number(target.id);
    const originalRow = getRowFromStore(stateKey, row._key);
    const newRow = { ...originalRow, ...values };
    store.dispatch(EatWithMeActions.updateRow(stateKey, newRow));
    this.setState({ value: isNaN(target.id) ? target.id : Number(target.id) });
  };
}

export class CustomerEmailEditor extends Component {
  render() {
    const { store, stateKey, column, row, onChange } = this.props;
    return <CustomerEmailAutoComplete {...this.props} onSelectionChange={this._handleChange.bind(null, { store, stateKey, column, row, onChange })} />;
  }

  _handleChange = ({ store, stateKey, column, row, onChange }, value) => {
    const v = {};
    if (column)
      v[column.dataIndex.split(".")[0]] = value[0];
    if (stateKey) {
      const values = flattenObject(v);
      const originalRow = getRowFromStore(stateKey, row._key);
      const newRow = { ...originalRow, ...values };
      if (stateKey)
        store.dispatch(EatWithMeActions.updateRow(stateKey, newRow));
    }
    if (onChange)
      this.props.onSelectionChange(value);
  };
}

export class RestaurantNameEditor extends Component {
  render() {
    const { store, stateKey, column, row, onChange } = this.props;
    return <RestaurantAutoComplete {...this.props} onChange={this._handleChange.bind(null, { store, stateKey, column, row, onChange })} />;
  }

  _handleChange = ({ store, stateKey, column, row }, value) => {
    const v = {};
    v[column.dataIndex.split(".")[0]] = value[0];
    const values = flattenObject(v);
    const originalRow = getRowFromStore(stateKey, row._key);
    const newRow = { ...originalRow, ...values };
    store.dispatch(EatWithMeActions.updateRow(stateKey, newRow));
  };
}
