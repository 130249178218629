import React, { Component } from "react";

class LabeledInput extends Component {
  state = {};
  render() {
    return (
      <div className="inputRow">
        <label style={{ textAlign: "center", width: "100%" }}>{this.props.label}</label>
        <input autoComplete={this.props.autocomplete} placeholder={this.props.placeHolder} id={this.props.id} type={this.props.type} className="inputStyle" defaultValue={this.props.value} ref={node => (this.inputNode = node)}></input>
      </div>
    );
  }
}

export default LabeledInput;
