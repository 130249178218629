import React, { Component } from "react";
import orderListUtil from "../js/order-list-util";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { ready, clearData, reload, saveProfile, deleteProfile } from "../js/admin/myprofile";
import LogoutButton from "../components/icon-buttons/LogoutButton";
import AICommandBox from "../js/ai/AICommandBox";
import auth, { getMyImageUrl, setRem } from "../js/auth";
import EatWithMeText from "../components/EatWIthMeText";
import MenuItemHeader from "../components/MenuItemHeader";
import ChangeLanguage from "../components/icon-buttons/ChangeLanguage";
import UserImageButton from "../components/icon-buttons/UserImageButton";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { capturePhotoWithData } from "../js/camera-util";

class Profile extends Component {
    state = {
        initialized: false,
        startDate: moment(Number(localStorage.admin_stocklist_start)),
        endDate: moment(Number(localStorage.admin_stocklist_end)),
    };

    static getDerivedStateFromProps(props, state) {
        return state;
    }

    componentDidMount() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id) {
            ready(this.props);
            this.setState({ initialized: true });
        }
        auth.minfontsize = 10;
        setRem();
        window.saveProfile = saveProfile;
        window.deleteStore = deleteProfile;
        window.capturePhotoWithData = capturePhotoWithData;
    }

    componentDidUpdate() {
        const { myStatus } = this.props;
        orderListUtil.props = this.props;

        if (myStatus && myStatus.restaurant_id && !this.state.initialized) {
            ready(this.props);
            this.setState({ initialized: true });
        }
    }

    componentWillUnmount() {
        clearData();
        this.setState({ initialized: false });
        delete window.saveProfile;
        delete window.deleteProfile;
        delete window.capturePhotoWithData;
    }

    render() {
        const ranges = {}
        ranges[I18n.t("admin_local.today")] = [moment(), moment()];
        ranges[I18n.t("admin_local.yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[I18n.t("admin_local.last_7_days")] = [moment().subtract(6, 'days'), moment()];
        ranges[I18n.t("admin_local.last_30_days")] = [moment().subtract(29, 'days'), moment()];
        ranges[I18n.t("admin_local.last_3_months")] = [moment().subtract(2, 'month').startOf('month'), moment()];
        ranges[I18n.t("admin_local.this_month")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[I18n.t("admin_local.last_month")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

        const { myStatus } = this.props;
        if (myStatus && myStatus.restaurant_id) {

            return (
                <div id="Profile" className="stock-actions" style={{ display: "flex", flexFlow: "column", flexGrow: 1, height: "0" }}>
                    {!auth.isIncludedInFrame ? (
                        <div className="menuItemHeader">
                            <EatWithMeText />
                            <MenuItemHeader {...this.props} header={<Translate value="admin_local.menus.Profile" />} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <ChangeLanguage />
                                <UserImageButton src={getMyImageUrl()} />
                                <div style={{ marginLeft: "10px", width: "30x", display: "flex", justifyContent: "space-between" }}>
                                    <LogoutButton query="keep=true&redirect=/tablesOverview" />
                                </div>
                            </div>
                        </div>
                    ) : (
                            ""
                        )}
                    <div id="main" ></div>
                    {!auth.isIncludedInFrame ? <AICommandBox /> : ""}
                </div >
            );
        } else return null;
    }

    dateRangeChanged = (event, values) => {
        console.log(values)
        this.setState({
            startDate: values.startDate,
            endDate: values.endDate
        })
        localStorage.admin_stocklist_start = values.startDate._d.getTime();
        localStorage.admin_stocklist_end = values.endDate._d.getTime();
        reload(this.state);
    }

}
const mapStateToProps = (props, state) => {
    if (props.rootReducer.myStatus && props.rootReducer.myStatus.restaurant_id) {
        return {
            restaurant: props.rootReducer.restaurant,
            myStatus: props.rootReducer.myStatus,
            admin_local: props.rootReducer.admin_local,
            local: props.rootReducer.local,
            speechRecognitionOn: props.rootReducer.speechRecognitionOn,
            ai: props.rootReducer.ai,
            speechRecognitionAvailable: props.rootReducer.speechRecognitionAvailable,
        };
    }
    return {};
};

export default connect(mapStateToProps)(withRouter(Profile));
