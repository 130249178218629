import React, { Component } from "react";
import ImageButton from "./ImageButton";
import icon from "../../img/logout@2x.png";
import auth, { get, switchServer } from "../../js/auth";
import { withRouter } from "react-router-dom";

class LogoutButton extends Component {
  state = {};
  render() {
    return <ImageButton {...this.props} id="logout" src={icon} handleClick={this.logout} style={{ width: "24px", height: "24px", marginLeft: "9px" }}></ImageButton>;
  }

  logout = () => {
    get("https://superset.eatwitme.online/logout").fail(() => auth.ajaxError = false);
    auth.logout(true).done(() => {
      if (auth.myStatus && auth.myStatus.customers && auth.myStatus.customers.length > 0) {
        switchServer({ globalId: 0, serverUrl: auth.globalserver }).done(() => {
          localStorage.restaurantSelected = 0;
          this.props.history.push("/login" + (this.props.query ? "?" + this.props.query : ""));
        });
      } else {
        localStorage.restaurantSelected = 0;
        this.props.history.push("/login" + (this.props.query ? "?" + this.props.query : ""));
      }
    });
  };
}

export default withRouter(LogoutButton);
