import $ from "jquery";
import { resultHandler, getStores } from "../admin";
import { startpoll, confirmDialog, post, get, _floatTableHead } from "../auth";
import { I18n } from "react-redux-i18n";
import { tmplparams } from "../order-list-util";
import { createTree } from "jquery.fancytree";

export const ready = () => {

	const def = $.Deferred();

	$("#storesTemplate").tmpl(tmplparams()).appendTo("#main");

	_floatTableHead("#storesFreezeTable");

	loadStores();

	startpoll(serverSideMessageHandler);

	updateHooks();

	def.resolve();
	return def.promise();

}


var stores = {
	stores: null
}

export const clearData = () => {
	stores = {
		stores: null
	};
}


export const reload = () => {
	loadStores();
}

function loadStores() {
	getStores(processStores);
}

function serverSideMessageHandler(data) {
}

function processStores(data) {
	data.forEach(function (v, i) {
		v.title = v.name == null ? "???" : v.name;
		v.folder = false;
		v.key = v.id;
	});
	if (stores.stores == null)
		stores.stores = createTree("table#stores", {
			checkbox: false,
			titlesTabbable: true, // Add all node titles to TAB chain
			source: data,
			zindex: 1000,
			icon: false,
			extensions: ["table", "gridnav", "persist"],
			table: {
				checkboxColumnIdx: 0, // render the checkboxes into the this
				// column index (default: nodeColumnIdx)
				indentation: 16, // indent every node level by 16px
				nodeColumnIdx: 0
				// render node expander, icon, and title to this column (default:
				// #0)
			},
			gridnav: {
				autofocusInput: false, // Focus first embedded input if node
				// gets activated
				handleCursorKeys: true
				// Allow UP/DOWN in inputs to move to prev/next node
			},
			click: (event, data) => {
				if (stores.clickTimestamp && stores.selectedNodeKey === data.node.key && new Date().getTime() - stores.clickTimestamp < 1000) {
					$('button#edit').click();
				};
				stores.clickTimestamp = new Date().getTime();
				stores.selectedNodeKey = data.node.key;
			},
			activate: function (event, data) {
				// A node was activated: display its title:
				var node = data.node;
				$('#edit').removeAttr('disabled');
				$('#delete').removeAttr('disabled');
				if (node.data.isActive) {
					$('#deactivate').removeAttr('disabled');
					$('#activate').prop('disabled', 'true');
				} else {
					$('#deactivate').prop('disabled', 'true');
					$('#activate').removeAttr('disabled');
				}

			},
			renderColumns: function (event, data) {
			}
		});
	else {
		stores.stores.reload(data);
		var node = stores.stores.getActiveNode();
		$('#edit').prop('disabled', node === null);
		$('#delete').prop('disabled', node === null);
	}
}

function updateHooks() {
	$('div#editStore').on('show.bs.modal', function (event) {
		var modal = $(this)
		var button = $(event.relatedTarget) // Button that triggered the modal
		var recipient = button.data('whatever') // Extract info from data-*
		// attributes
		if (recipient === 'edit') {
			var data = stores.stores.getActiveNode().data;
			modal.find('#id').val(data.id);
			modal.find('#name').val(data.name);
			modal.find('#color').val(data.color);
		} else {
			modal.find('#id').val("");
			modal.find('#name').val("");
			modal.find('#color').val("");
		}
	})
}

export const saveStore = () => {
	var modal = $('div#editStore');
	var id = modal.find('#id').val();
	var name = modal.find('#name').val();
	var color = modal.find('#color').val();
	if (id !== '') {
		modifyStore({ id: id, name: name, color: color }, loadStores);
	} else {
		addStore({ name: name }, loadStores);
	}
}

export const activateStore = (active) => {
	var data = stores.stores.getActiveNode().data;
	modifyStore(data.id, data.name, data.percent, active, loadStores);
}

export const deleteStore = () => {
	var data = stores.stores.getActiveNode().data;
	confirmDialog(I18n.t("local.confirmation"), String.format(I18n.t("admin_local.are_you_sure_you_want_to_delete_the_store"), data.name)).done(function () {
		_deleteStore(data.id, loadStores);
	});

}

function addStore(discountType, handler, errorHandler) {
	post("adminService/" + localStorage.restaurantSelected + "/addStore", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
}

function modifyStore(discountType, handler, errorHandler) {
	post("adminService/" + localStorage.restaurantSelected + "/modifyStore", discountType).done(function (data) { resultHandler(data, handler, errorHandler) });
}

function _deleteStore(id, handler, errorHandler) {
	get("adminService/" + localStorage.restaurantSelected + "/deleteStore/" + id).done(function (data) { resultHandler(data, handler, errorHandler) });
}
