import React, { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";

class LabeledRadioButtonsStyled extends Component {
  state = {};

  render() {
    return (
      <div id={this.props.id} className={this.props.className} >
        <label>{this.props.label}</label>
        <ButtonGroup aria-label="radio-group">
          {this.props.children.props ?
            <Button variant="primary" key={this.props.children.props.id}>{this.props.children.props.value}</Button>
            : this.props.children.map(div =>
              <Button className={div.props.className + (this.props.value === div.props.id ? " active" : "")} onClick={() => { this.props.setValue(this.props.id, div.props.id) }} variant="primary" key={div.props.id}>{div.props.value}</Button>
            )}
        </ButtonGroup>
      </div >
    );
  }
}

export default LabeledRadioButtonsStyled;
