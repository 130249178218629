import React from "react";
import auth from "../js/auth";
import $ from 'jquery';

export const TemplateLoadFrame = props => {
  var scriptName = "";
  try {
    const scriptPath = $("script").map((ind, s) => s.src).filter((ind, s) => s.indexOf("static/js/main") > 0)[0];
    scriptName = scriptPath.substring(scriptPath.lastIndexOf('/') + 1);
  } catch (ex) {

  }

  return <iframe onLoad={event => auth.frameLoaded(event)} title={props.src} style={{ visibility: "hidden", height: "0", border: "0" }} w3-include-html="true" src={"templates/" + props.src + ".html?src=" + scriptName}></iframe>;
};
