import React from "react";
import MenuButton from "./MenuButton";
import "./menu.css";
import AbstractMenu from "./AbstractMenu";
import SubmenuHeader from "./SubMenuHeader";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import IncomingStocks from "../../stockManagement/IncomingStocks";
import Suppliers from "../../stockManagement/Suppliers";
import Stores from "../../stockManagement/Stores";
import StockMovements from "../../stockManagement/StockMovements";
import Inventories from "../../stockManagement/Inventories";
import "../../stockManagement/style.css";
import auth, { startpoll } from "../../js/auth";
import InventoryScrapping from "../../stockManagement/InventoryScrapping";
import StockList from "../../stockManagement/StockList";
import StockStatistics from "../../stockManagement/StockStatistics";
import Barcodes from "../../stockManagement/Barcodes";
import ProfitAndLoss from "../../stockManagement/ProfitAndLoss";
import ShoppingList from "../../stockManagement/ShoppingList";


class StockManagementMenu extends AbstractMenu {

  componentDidMount = () => {

    if (typeof localStorage.admin_stocklist_timestamp === undefined || (new Date().getTime() - Number(localStorage.admin_stocklist_timestamp)) > 1000 * 3600 * 8) {
      localStorage.admin_stocklist_end = new Date().getTime();
      localStorage.admin_stocklist_start = new Date().getTime() - 1000 * 3600 * 24 * 7;
      localStorage.admin_stocklist_timestamp = new Date().getTime();
    }
    localStorage.admin_stocklist_timestamp = new Date().getTime();
  }

  render() {
    const { admin_local, myStatus } = this.props;
    const background = "#847556";
    if (admin_local)
      return (
        <Switch>
          <Route path="/stockManagementMenu/stockList" render={props => { return (<StockList />) }} />
          <Route path="/stockManagementMenu/shoppingList" render={props => { return (<ShoppingList />) }} />
          <Route path="/stockManagementMenu/stockStatistics" render={props => { return (<StockStatistics />) }} />
          <Route path="/stockManagementMenu/pandl" render={props => { return (<ProfitAndLoss />) }} />
          <Route path="/stockManagementMenu/incomingStocks/:id" render={props => { return (<IncomingStocks {...props} />) }} />
          <Route path="/stockManagementMenu/incomingStocks" render={props => { return (<IncomingStocks {...props} />) }} />
          <Route path="/stockManagementMenu/suppliers" render={props => { return (<Suppliers />) }} />
          <Route path="/stockManagementMenu/stores" render={props => { return (<Stores />) }} />
          <Route path="/stockManagementMenu/stockMovements" render={props => { return (<StockMovements />) }} />
          <Route path="/stockManagementMenu/inventories" render={props => { return (<Inventories />) }} />
          <Route path="/stockManagementMenu/inventoryScrapping" render={props => { return (<InventoryScrapping />) }} />
          <Route path="/stockManagementMenu/barcodes" render={props => { return (<Barcodes />) }} />
          <Route path="/stockManagementMenu" render={props => {
            if (this.props.myStatus.sessionId) {
              startpoll(auth.serverSideMessageHandler, [{ Restaurant: localStorage.restaurantSelected }]);
              return (
                <div style={{ flexGrow: 1, flexShrink: 1, display: "flex", flexFlow: "column", overflowY: "auto" }}>
                  <SubmenuHeader {...this.props} header={admin_local.menus.stockManagement} />
                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", flexShrink: 0, flexGrow: 1 }}>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/stockList" i="i_warehouse_inventory" background={background} name={admin_local.menus.stockList}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/shoppingList" i="icon-dollar" background={background} name={admin_local.menus.shoppingList}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/stockStatistics" i="i_report" background={background} name={admin_local.menus.stockReport}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/pandl" i="i_report" background={background} name={admin_local.menus.analytics}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/incomingStocks" i="i_box_incoming" background={background} name={admin_local.menus.incomingStocks}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/stockMovements" i="i_stock" background={background} name={admin_local.menus.stockMovements}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store", "waiter"]} href="/stockManagementMenu/inventories" i="i_inventory_list" background={background} name={admin_local.menus.inventory}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/inventoryScrapping" i="i_scrap" background={background} name={admin_local.menus.inventoryScrapping}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/suppliers" i="i_supplier" background={background} name={admin_local.menus.suppliers}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/stores" i="i_inventory_quantity" background={background} name={admin_local.menus.stores}></MenuButton>
                    <MenuButton myStatus={myStatus} roles={["superadmin", "admin", "store"]} href="/stockManagementMenu/barcodes" i="i_bottle a_barcode_33 a_bottomright" background={background} name={admin_local.menus.barcodes}></MenuButton>
                  </div>
                </div>)
            } else return null;
          }} />
        </Switch>
      )
    else return null;
  }
}

const mapStateToProps = state => {
  return {
    admin_local: state.rootReducer.admin_local,
    myStatus: state.rootReducer.myStatus,
    local: state.rootReducer.local
  };
};

export default connect(mapStateToProps)(StockManagementMenu);
