import React, { Component } from "react";
import { Button } from "react-bootstrap";

class Buttons extends Component {
  render() {
    if (this.props.changed)
      return (
        <React.Fragment>
          <Button onClick={() => this.props.handleSave(this.props.restaurant)} className="save-button icon icon-floppy" style={{ position: "absolute", zIndex: "1000", right: "1rem", top: "1rem" }}></Button>
          <Button
            onClick={() => this.props.handleCancel(this.props.restaurant)}
            variant="danger"
            className="cancel-button icon icon-cancel-circled"
            style={{ position: "absolute", zIndex: "1000", right: "5rem", top: "1rem" }}
          ></Button>
        </React.Fragment>
      );
    return null;
  }
}

export default Buttons;
